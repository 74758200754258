import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {UserService} from '../core/services/user.service';
import {PatientService} from '../core/services/patient.service';
import {AuthGroupService} from '../core/services/auth-group.service';
import {ParentDetailService} from '../core/services/parent-detail.service';
import {IBaseViewService} from '../shared/interfaces/base-view.interface';
import {User} from '../core/models/user.model';
import {PatientDetails} from '../core/models/patient-details.model';
import {PatientDetailService} from '../core/services/patient-detail.service';

@Injectable()
export class PatientViewService implements IBaseViewService<User> {

  currentSubjectType: BehaviorSubject<string> = new BehaviorSubject('');
  currentSubject: BehaviorSubject<User> = new BehaviorSubject(new User());
  currentSubjectDetail: BehaviorSubject<PatientDetails> = new BehaviorSubject(new PatientDetails());
  currentSubjects: BehaviorSubject<User[]> = new BehaviorSubject([]);

  currentTitle: BehaviorSubject<string> = new BehaviorSubject('');
  constructor(private parentDetailService: ParentDetailService,
              private patientService: PatientService,
              private userService: UserService,
              private authGroupService: AuthGroupService,
              private patientDetailService: PatientDetailService) { }

  getSubjects(): void {
    // we should have a patient at this point, if we don't then something is very wrong
    this.userService.getAllPromise()
      .then(users => {
        let new_patients: User[];
        if (users && users.length > 0) {
          // we know that the patients have the 'detail_cls' value web.patientdetail as this is their endpoint
          new_patients = users.filter(u => u.user_type && u.user_type.toLocaleLowerCase().indexOf('patient') > -1);

          // now we get the PS and SBT for each patient
          for (const patient of new_patients) {
            if (patient.parents_repr && patient.parents_repr.length > 0) {
              let filtered = patient.parents_repr.filter(r => r.relationship_type.toLocaleLowerCase() === 'ps');
              if (filtered && filtered.length > 0) {
                const id = filtered[0].user;
                patient.program_supervisor = users.filter(u => u.id === id)[0];
              }
              filtered = patient.parents_repr.filter(r => r.relationship_type.toLocaleLowerCase() === 'sbt');
              if (filtered && filtered.length > 0) {
                const id = filtered[0].user;
                patient.sbt = users.filter(u => u.id === id)[0];
              }
            }
          }
        }
        this.currentSubjects.next(new_patients);
      })
      .catch(
        err => {
          console.log(err);
          throw err;
        })
  }
  save(): Promise<any> {
    let curr_user = this.currentSubject.getValue();
    return this.userService.save(curr_user).then(
      (res) => {
        curr_user = res;
        this.currentSubject.next(null);
        return this.saveDetail(curr_user);
      })
      .then((user) => {
        return curr_user;
      })
      .catch(err => throwError(err).toPromise());
  }

  saveDetail(curr_user: User): Promise<any> {
    let curr_detail = this.currentSubjectDetail.getValue();
    curr_detail.user = curr_user.id;
    return this.patientDetailService.save(curr_detail).then(
      (res: PatientDetails) => {
        // curr_detail = this.userService.newUser.getValue();
        curr_detail = res;
        this.currentSubjectDetail.next(null);
        // saving patient group
        return this.userService.assignRole(curr_user.id, 8);
      })
      .then((group_result) => {
        return group_result;
      })
      .catch(err => throwError(err).toPromise());
  }

  getDetails(patient: User): Promise<PatientDetails> {
    if (!patient) {
      // if we don't have a patient then we have a problem
      throw new Error('User was not found or was null');
    }
    return this.patientDetailService.getByParamPromise(patient.id.toString(), 'user')
      .then((res: PatientDetails[]) => {
        let detail: PatientDetails;
        if (res && res.length > 0) {
          detail = res[0];
        }
        return detail;
      });
  }

  update(): Promise<any> {
    return undefined;
  }

  getByPatient(id: string): Promise<User[]> {
    return undefined;
  }

  search(status?: string, search_text?: string): Promise<User[]> {
    return undefined;
  }

  getById(id: number): Promise<User> {
    return undefined;
  }

}
