import {AfterViewChecked, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {SelectOption} from '../../core/models/select-option.model';
import {User} from '../../core/models/user.model';
import {UserService} from '../../core/services/user.service';
import {ParentDetailViewService} from '../services/parent-detail-view.service';
import {ConstantsService} from '../../core/services/constants.service';
import {ParentDetailService} from '../../core/services/parent-detail.service';
import {CurrentFormService} from '../../core/services/current-form.service';
import {AppValidationMessages} from '../app.messages';
import {PatientViewService} from '../../patient/patient-view.service';
import {PatientDetails} from '../../core/models/patient-details.model';
import { Subscription } from "rxjs/index"

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
  @Input() public user_id: number;
  @ViewChild('relationship') public relationship: any;
  @ViewChild('addPatientForm') public currentForm: any;
  addPatientForm: NgForm;

  private DEFAULT_TITLE = 'Error';
  subscriptions: Subscription[];

  title: string;
  user: User;
  user_detail: PatientDetails;
  choices: Map<string, any>;
  regions: SelectOption[];
  loading: boolean = false;
  relationship_data: SelectOption[];
  comm_profile_options: SelectOption[];
  safety_information_options: SelectOption[];

  formErrors = {
    first_name: '',
    last_name: '',
    dob_date: '',
    comm_profile: '',
    safety_information: '',
    email: '',
    region: ''
  };

  constructor(private userService: UserService,
              private parentDetailViewService: ParentDetailViewService,
              private parentDetailService: ParentDetailService,
              private constantsService: ConstantsService,
              private currentFormService: CurrentFormService,
              private patientViewService: PatientViewService) {

  }

  private updateValue(): void {
    this.userService.newUser.next(this.user);
    this.patientViewService.currentSubject.next(this.user);
    this.patientViewService.currentSubjectDetail.next(this.user_detail);
  }

  updateDetails(): void {
    this.updateValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateValue();
  }

  getChoices(): void {
    if (!this.constantsService.choices || this.constantsService.choices.size === 0) {
      this.constantsService.getChoices().then(
        (choices: Map<string, any>) => {
          this.choices = choices;
          // need to use the get method for a map in the sub-map....
          this.regions = this.choices.get('user').get('region');
          this.comm_profile_options = this.choices.get('patientdetail').get('comm_profile');
          this.safety_information_options = this.choices.get('patientdetail').get('safety_information');

        });
    } else {
      this.choices = this.constantsService.choices;
      this.regions = this.choices.get('user').get('region');
      this.comm_profile_options = this.choices.get('patientdetail').get('comm_profile');
      this.safety_information_options = this.choices.get('patientdetail').get('safety_information');
    }
  }

  formChange(): void {
    if (this.currentForm === this.addPatientForm) {
      return;
    }

    this.addPatientForm = this.currentForm;
    if (this.addPatientForm) {
      this.addPatientForm.valueChanges
        .subscribe(data => this.onValueChanged());
    }
  }

  onValueChanged() {
    if (!this.addPatientForm) {
      return;
    }
    const form = this.addPatientForm.form;

    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }
  }

  subscribeToErrorMessage(): void {
    this.subscriptions.push(this.currentFormService.currentErrorMessage.subscribe(
      (message: any) => {
        // const split_message = message.split(':');
        if (message && Object.keys(message).length > 0) {
          const keys = Object.keys(message);
          for (const key of keys) {
            let value = message[key];
            if (key === 'email' && value[0].indexOf('unique') > -1) {
              value = 'Email is already in use';
            }
            this.formErrors[key] = value;
          }

        }

      }));
  }

  getPatientDetail(): void {
    this.patientViewService.getDetails(this.user)
      .then(
        (user_detail: PatientDetails) => {
          this.user_detail = user_detail;
        });
  }

  unsubscribe(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  submitForm() {
    // just a dummy function for the moment
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = this.currentForm.form.get(field);

      if (control && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }
  }

  ngAfterViewChecked(): void {
    this.formChange();
  }


  ngOnDestroy(): void {
    this.updateValue();
    this.unsubscribe();
  }

  ngOnInit() {
    this.title = this.patientViewService.currentTitle.getValue();
    if (this.title == null) {
      this.title = 'Contact';
    }
    this.regions = [];
    this.relationship_data = [];
    this.subscriptions = [];

    this.user = this.patientViewService.currentSubject.getValue();
    this.user_detail = new PatientDetails();
    this.user_detail.is_lead = false;

    if (!this.user) {
      this.user = new User();
      this.user.user_type = 'PATIENT';
      this.updateDetails();
    } else {
      // now we have to get the detail
      this.getPatientDetail();
    }
    this.getChoices();
    this.subscribeToErrorMessage();
    this.currentFormService.currentSubject.next(this.currentForm);
  }

}
