export  class AppValidationMessages {
  static errorMessages = {
    'name' : {
      'required' : 'Name is required'
    },
    'first_name' : {
      'required' : 'First name is required'
    },
    'last_name' : {
      'required' : 'Last name is required'
    },
    'email' : {
      'email' : 'Please enter a valid email address',
      'required' : 'Email address is required'
    },
    'password' : {
      'required' : 'Password is required',
      'minlength' : 'Password must be at least 8 characters',
    },
    'status' : {
      'required' : 'Status is required'
    },
    'date_started' : {
      'required' : 'Date started is required'
    },
    'ps_hpm' : {
      'required' : 'PS hours per month is required'
    },
    'therapist_hpw' : {
      'required' : 'Therapist hours per week is required'
    },
    'developed_domains' : {
      'required' : 'Developed domains are required'
    },
    'focus_domains' : {
      'required' : 'Focus domains are required'
    },
    'assessments' : {
      'required' : 'Assessments are required'
    },
    'goals' : {
      'required' : 'Goals is required'
    },
    'service_plan' : {
      'required' : 'Service plan is required'
    },
    'skill_objective' : {
      'required' : 'Skill objective is required'
    },
    'st_objective' : {
      'required' : 'Skill objective description is required'
    },
    'materials' : {
      'required' : 'Materials is required'
    },
    'procedure' : {
      'required' : 'Procedure is required'
    },
    'sd' : {
      'required' : 'SD is required'
    },
    'correct_response' : {
      'required' : 'Correct response is required'
    },
    'baseline' : {
      'required' : 'Baseline is required'
    },
    'teaching_format' : {
      'required' : 'Teaching format is required'
    },
    'mastery_criteria' : {
      'required' : 'Mastery criteria is required'
    },
    'num_active_targets' : {
      'required' : 'Number of active targets is required'
    },
    'data_sheet' : {
      'required' : 'Data sheet is required'
    },
    'code' : {
      'required' : 'Code is required'
    },
    'prompt_type' : {
      'required' : 'Prompt type is required'
    },
    'dob_date' : {
      'required' : 'DOB is required'
    },
    'comm_profile' : {
      'required' : 'Communication profile is required'
    },
    'safety_information' : {
      'required' : 'Safety information is required'
    },
    'region' : {
      'required' : 'Region is required'
    },
    'conducted_by' : {
      'required' : 'Conducted by is required'
    },
    'type' : {
      'required' : 'Type is required'
    },
    'date' : {
      'required' : 'Date is required'
    },
    'date_completed' : {
      'required' : 'Completed is required'
    },
    'next_due' : {
      'required' : 'Next due is required'
    },
    'document_name' : {
      'required' : 'Document name is required'
    },
    'file' : {
      'required' : 'No file was selected'
    },
    'num_pretr_to_move': {
      'required': 'field is required'
    },
    'num_tr_for_pretr_move': {
      'required': 'field is required'
    }
  };
}
