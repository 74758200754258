import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Inject, LOCALE_ID, NgModule, Optional} from '@angular/core';

import {AppComponent} from './app.component';
import {LoginModule} from './login/login.module';
import {AppRoutingModule} from './app-routing/app-routing.module';
import {ResetPasswordModule} from './reset-password/reset-password.module';
import {PatientModule} from './patient/patient.module';
import {ProfileModule} from './profile/profile.module';
import {ListPatientModule} from './list-patient/list-patient.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LayoutModule} from '@progress/kendo-angular-layout';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {SharedModule} from './shared/shared.module';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {CoreModule} from './core/core.module';
import {ChartModule} from '@progress/kendo-angular-charts';
import {PopupModule} from '@progress/kendo-angular-popup';
import {IntlModule} from '@progress/kendo-angular-intl';
import {NgReduxModule, NgRedux} from '@angular-redux/store';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {StoreModule} from '@ngrx/store';
import {ApplicationEffects} from './core/state/application/effect';
import {EffectsModule} from '@ngrx/effects';
import {reducers} from './core/state/reducers';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {ToastyModule} from 'ng2-toasty';
import {ErrorsModule} from './core/errors/errors.module';
import {ErrorsHandler} from './core/errors/handler/errors-handler';
import {SentryModule} from './core/shared/sentry/sentry.module';
import {environment as env} from '../environments/environment';
import {CookieService} from 'ngx-cookie-service';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    ErrorsModule,
    CoreModule,
    BrowserModule,
    LoginModule,
    ResetPasswordModule,
    // DashboardModule,
    AppRoutingModule,
    PatientModule,
    ProfileModule,
    ListPatientModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    DialogModule,
    SharedModule,
    ButtonsModule,
    ChartModule,
    PopupModule,
    IntlModule,
    InputsModule,
    NgReduxModule,
    ToastyModule.forRoot(),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([ApplicationEffects]),
    SentryModule.forRoot({enabled: true, sentry: {
        environment: env.sentry_environment,
        dsn: 'https://9a257ac960d9459a968203d488ff5a76@sentry.io/1837041'
      }, http: { enabled: true}})
  ],
  providers: [
    CookieService,
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {
}
