
export class BaseDetail {
  id: string;
  archived: boolean;
  created: string;
  created_by: number;
  updated: string;
  user: number;
  last_updated_by: number;

}
