import * as ApplicationActions from './actions';

export interface State {
  isLoggedIn: boolean;
  autoLogout: boolean;
}

const initialState: State = {
  isLoggedIn: false,
  autoLogout: false
};

export function reducer(state = initialState, action: ApplicationActions.All): State {
  switch (action.type) {
    case ApplicationActions.LOG_OUT: {
      return {
        ...state,
        isLoggedIn: false,
        autoLogout: false
      }
    }

    case ApplicationActions.LOG_IN: {
      return {
        ...state,
        isLoggedIn: true,
        autoLogout: false
      }
    }

    case ApplicationActions.AUTO_LOG_OUT: {
      return {
        ...state,
        isLoggedIn: false,
        autoLogout: true
      }
    }

    default: {
      return state;
    }

  }
}
