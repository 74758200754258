import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {BaseService} from '../base.service';
import {IBaseService} from '../interfaces/base-interface';
import {Communication} from '../models/communication.model';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CommunicationService extends BaseService<Communication> {
  currentSubject: BehaviorSubject<Communication>;

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.datasheet/', Communication);
  }

}
