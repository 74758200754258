import {EventEmitter, Injectable} from '@angular/core';
// import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {BehaviorSubject} from 'rxjs';
@Injectable()
export class ShowNavService {

  showNav: boolean;
  showNavUpdated: EventEmitter<boolean> = new EventEmitter();
  showNavSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showButtons: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor() { }

  set(value: boolean) {
    this.showNav = value;
    this.showNavUpdated.emit(this.showNav);
    this.showNavSubject.next(this.showNav);
  }

  get(): boolean {
    return this.showNav;
  }
}
