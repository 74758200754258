import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IncidentalTeachingComponent} from '../incidental-teaching/incidental-teaching.component';
import {DialogService} from '@progress/kendo-angular-dialog';
import {ConstantsService} from '../../../core/services/constants.service';
import {DailySessionViewService} from '../../../shared/services/daily-session-view.service';
import {UserViewService} from '../../../shared/services/user-view.service';
import {ToastyService} from 'ng2-toasty';
import {ShowNavService} from '../../../core/services/show-nav.service';
import {CurrentFormService} from '../../../core/services/current-form.service';
import {UserService} from '../../../core/services/user.service';
import {FilterViewService} from '../../../shared/services/filter-view.service';
import {CommonService} from '../../../core/shared/common/common.service';
import {BehaviorSubject, throwError} from 'rxjs';
import {TherapySession} from '../../../core/models/therapy-session.model';
import * as moment from 'moment';
import {DataSheet} from '../../../core/models/data-sheet.model';
import {User} from '../../../core/models/user.model';
import {Procedure} from '../../../core/models/procedure.model';
import {ProcedureSession} from '../../../core/models/procedure-session.model';
import {SessionData} from '../../../core/models/session-data.model';
import {SessionTrial} from '../../../core/models/session-trial.model';
import * as _ from 'lodash';
import {SheetInfo} from '../../../core/models/sheet-info.model';
import {BaseSessionComponent} from '../../../shared/base-session/base-session.component';
import {ProcedureViewService} from '../../../shared/services/procedure-view.service';

@Component({
  selector: 'app-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.css']
})
export class DurationComponent extends IncidentalTeachingComponent implements OnInit, OnDestroy {
  // constants
  DEFAULT_SESSION_NUM = 3;
  DELIMITER = '&';

  @Input() data_sheet_type: string;
  // // dataSheetForPage: string = 'DT';
  // private _data_sheets = new BehaviorSubject<any[]>([]);
  // private _patient = new BehaviorSubject<any>({});
  // private _sheet_info = new BehaviorSubject<any>({});
  // private _is_readonly: boolean;
  // private _readonly_data: any;
  // private _showNavService: ShowNavService;
  // private _userService: UserService;
  // private _filterViewService: FilterViewService;
  // private _commonService: CommonService;

  // objects
  BASE_LINE_STAGE = 'baseline';
  data_sheet: DataSheet;
  getting_data: boolean;
  dailySessionViewService: DailySessionViewService;
  current_user: User;
  max_col_span: number;
  selected_host: number;
  saving_trial: boolean;
  procedure_session_timeout_map: Map<number, any> = new Map<number, any>();
  procedure_session_timeout: any;

  // lists
  procedures: Procedure[];
  all_procedures: Procedure[];
  procedure_sessions: ProcedureSession[];
  procedure_session_defaults: any[];

  // objects for readonly view
  readonly_sessions: SessionData[];
  max_trials: number;
  // procedure_mean_total_Map: Map<number, any> = new Map<number, any>();

  constructor(dialogService: DialogService,
              constantsService: ConstantsService,
              dailySessionViewService: DailySessionViewService,
              userViewService: UserViewService,
              toastyService: ToastyService,
              showNavService: ShowNavService,
              currentFormService: CurrentFormService,
              userService: UserService,
              filterViewService: FilterViewService,
              commonService: CommonService,
              procedureViewService: ProcedureViewService) {
    super(dialogService, constantsService, dailySessionViewService, userViewService, toastyService,
      showNavService, currentFormService, userService, filterViewService, commonService, procedureViewService);
  }

  padSessionTrials(session_trials: SessionTrial[], num_trials?: number): SessionTrial[] {
    // for (const procedure_session of this.procedure_sessions) {
    const col_span = num_trials || this.max_col_span;
    // const max_length = this.max_col_span > session_trials.length ? this.max_col_span - session_trials.length : 0;

    if (session_trials.length <= col_span) {
      session_trials = [...session_trials, ...Array
        .apply(null, Array(col_span - session_trials.length))
        .map(() => new SessionTrial())];
    }
    return session_trials;
    // }
  }

  updateSession(is_trial_click?: boolean, procedure?: ProcedureSession, idx?: number,
                procedure_session_idx?: number): Promise<boolean | void> {
    console.log('the current this object: ', this);
    // don't need this anymore
    // this.dailySessionViewService.currentSubject.next(this.therapy_session);
    this.dailySessionViewService.currentProcedureSessions.next(this.procedure_sessions);
    if (is_trial_click) {

      // setTimeout(() => {
      let promise: Promise<number | void>;
      if (procedure.id == null) {
        promise = this.saveProcedureSession(procedure_session_idx);
      } else {
        promise = Promise.resolve(this.procedure_sessions[procedure_session_idx].id);
      }
      return promise.then((procedure_id: number) => {
        let sub_promise: Promise<any>;
        if (procedure.session_trials[idx].procedure_session == null) {
          procedure.session_trials[idx].procedure_session = procedure_id;
        }
        // save it if we have a value, otherwise we delete it
        if (procedure.session_trials[idx].value == null && procedure.session_trials[idx].id != null) {
          sub_promise = this.dailySessionViewService.deleteSessionTrial(procedure.session_trials[idx]);
        } else if (procedure.session_trials[idx].value != null) {
          console.log(procedure.session_trials[idx]);
          sub_promise = this.dailySessionViewService.saveSessionTrial(procedure.session_trials[idx]);
        } else {
          console.log(procedure.session_trials[idx]);
          sub_promise = Promise.resolve(procedure.session_trials[idx]);
        }
        return sub_promise;
      })
        .then((res: any) => {
          // because the return values could either be a session tiral or a success code
          // for deleting the object successfully, then need to check if we have the id
          // if we don't then it should've been deleted
          if (res.id != null) {
            procedure.session_trials[idx].id = res.id;
          } else {
            delete procedure.session_trials[idx].id;
          }

          // now we get the procedure session
          if (procedure.getting_procedure_id == null) {
            // setting the trial that is getting the procedure
            procedure.getting_procedure_id = idx;
            return this.dailySessionViewService.getProcedureSessionById(procedure.id);
          } else {
            return Promise.resolve(procedure);
          }
        })
        .then((procedure_session) => {
          procedure = Object.assign(procedure, procedure_session);
          if (procedure.getting_procedure_id == idx) {
            procedure.getting_procedure_id = null;
          }
          if (procedure.total_duration == null) {
            procedure.edit_total_duration = false;
          }
          if (procedure.mean_duration == null) {
            procedure.edit_mean_duration = false;
          }
          if (procedure.total_trials == null) {
            procedure.edit_total_trials = false;
          }

          return true;
        })
        .catch((err) => {

        });
      // }, 10);
    }
  }

  saveProcedureSession(i?: number): Promise<number | void> {
    if (i == null) {
      return throwError('Could not find procedure session').toPromise();
    }
    if (this.procedure_sessions[i].procedure != null) {

      if (this.procedure_sessions[i].therapy_session == null) {
        this.procedure_sessions[i].therapy_session = this.therapy_session.id;
      }
      if (this.procedure_sessions[i].perc_correct == '') {
        this.procedure_sessions[i].perc_correct = null;
      }
      if (this.procedure_sessions[i].total_duration == '') {
        this.procedure_sessions[i].total_duration = null;
      }
      if (this.procedure_sessions[i].mean_duration == '') {
        this.procedure_sessions[i].mean_duration = null;
      }
      if (this.procedure_sessions[i].total_trials == '') {
        this.procedure_sessions[i].total_trials = '0';
      }
      // if for some reason therapy session id is still null on proc session, then abort
      if (this.procedure_sessions[i].therapy_session == null) {
        return throwError('no therapy id on procedure session').toPromise();
      }
      if (this.procedure_sessions[i]._order == null) {
        this.procedure_sessions[i]._order = i;
      }

      // // saving the total and mean duration
      // if (this.procedure_sessions[i].mean_duration != null) {
      //   this.procedure_mean_total_Map.set(i, {mean_duration: this.procedure_sessions[i].mean_duration});
      //   delete this.procedure_sessions[i].mean_duration;
      // }
      // if (this.procedure_sessions[i].total_duration != null) {
      //   if (this.procedure_mean_total_Map.has(i)) {
      //     this.procedure_mean_total_Map.get(i)['total_duration'] = this.procedure_sessions[i].total_duration;
      //   } else {
      //     this.procedure_mean_total_Map.set(i, {total_duration: this.procedure_sessions[i].total_duration});
      //   }
      //   delete this.procedure_sessions[i].total_duration;
      // }
      return this.dailySessionViewService.saveProcedureSession(this.procedure_sessions[i])
        .then((procedureSession: ProcedureSession) => {
          // in theory we should be ok as the values shold be passed by reference
          // if (this.procedure_sessions[i].id == null) {
          //   this.procedure_sessions[i].id = procedureSession.id;
          // }
          // return procedureSession.id;
          this.addToast('Done', 'success');
          this.procedure_sessions[i] = _.merge(this.procedure_sessions[i], procedureSession);
          this.procedure_sessions[i].saving = false;
          if (this.procedure_sessions[i].total_duration != null) {
            this.procedure_sessions[i].edit_total_duration = false;
          }
          if (this.procedure_sessions[i].mean_duration != null) {
            this.procedure_sessions[i].edit_mean_duration = false;
          }
          if (this.procedure_sessions[i].total_trials != null) {
            this.procedure_sessions[i].edit_total_trials = false;
          }
          return this.procedure_sessions[i].id;
          // for now we buffer up to the default max
        }).catch(
          (err) => {
            if (err.error && err.error.total_trials && err.error.total_trials.length) {
              err.error.total_trials.forEach((val: string) => {
                this.addToast(val, 'error');
              })
            }
            this.procedure_sessions[i].saving = true;
            return throwError(err).toPromise();
          });
    }
  }

  subscribeToSessionCache(): void {
    // TODO: simplify this data structure that's returned to a list of ids instead of a map
    this.subscriptions.push(this.dailySessionViewService.dailySessionsSaved.subscribe((sessions) => {
      const keys = sessions.keys();
      let key = keys.next();
      while (!key.done) {
        let k = key.value;
        // now need to check each of the procedure sessions found here and in the 'future' save to update with the id or delete
        // NOTE: we don't need to do this anymore since we are disabling the UI

        const idx = this.procedure_sessions.findIndex(ps => ps.id == k);
        const ret_list = sessions.get(k);
        for (let i = 0; i < ret_list.length; i++) {
          const saved = ret_list[i];
          if (this.procedure_sessions[idx]) {
            this.procedure_sessions[idx].session_trials[saved._order].id = saved.id;
          }
        }

        // now we just reload the procedure sessions that were saved
        this.dailySessionViewService.getProcedureSessionById(k)
          .then((procedure_session: ProcedureSession) => {
            if (idx > -1) {
              // since we are copying from right to left, need to get the mean and total duration so we can keep it
              // const mean_duration = this.procedure_sessions[idx].mean_duration;
              // const total_duration = this.procedure_sessions[idx].total_duration;
              this.procedure_sessions[idx] = _.merge(this.procedure_sessions[idx], procedure_session);
              // this.procedure_sessions[idx].mean_duration = mean_duration;
              // this.procedure_sessions[idx].total_duration = total_duration;
            }
          });
        key = keys.next();
      }
      // now let's save the therapy_session if we need to
      if (this.therapy_session.ignore_update) {
        this.dailySessionViewService.saveTherapySession(this.therapy_session)
          .then((therapy_session) => {
            const ignore_update = this.therapy_session.ignore_update;
            this.therapy_session = _.merge(this.therapy_session, therapy_session);
            this.therapy_session.ignore_update = ignore_update;
            this.dailySessionViewService.currentSubject.next(this.therapy_session);
          });
      }
      this.saving_trial = false;
      if (this.toastyId != null && sessions.size > 0) {
        this.toastyService.clear(this.toastyId);
        this.addToast('Done', 'success');
      }
      this.toastyId = null;
    }));
  }

  processReadonlyData(): void {
    if (this.data && this.data.data) {
      this.max_trials = this.data.max_trials;
      this.max_col_span = this.data.max_trials;
      for (const obj of this.data.data) {
        let new_session_data = new SessionData();
        // new_session_data.date = obj.date;
        // new_session_data.description = obj.description;
        // new_session_data.host = obj.host;
        // new_session_data.perc_correct = obj.perc_correct;
        // new_session_data.num_trials = obj.state_prescribed_trials;
        // new_session_data.total_duration = obj.total_duration;
        // new_session_data.mean_duration = obj.mean_duration;
        // new_session_data.include = obj.include;
        new_session_data = Object.assign(obj);
        new_session_data.num_trials = obj.state_prescribed_trials;
        new_session_data.session_trials = [];
        if (obj.counted_for_mastery != null) {
          new_session_data.counted_for_mastery = obj.counted_for_mastery;
        }
        if (obj.trials && obj.trials.length > 0) {
          for (let i = 0; i < obj.trials.length; i++) {
            const trial = new SessionTrial();
            trial.value = obj.trials[i].value;
            trial._order = i;
            new_session_data.session_trials.push(_.cloneDeep(trial));
          }
        }
        new_session_data.session_trials = this.padSessionTrials(new_session_data.session_trials, new_session_data.num_trials);
        if (new_session_data.session_trials && new_session_data.session_trials.length > this.max_trials) {
          this.max_trials = new_session_data.session_trials.length;
          this.max_col_span = this.max_trials;
        }
        this.readonly_sessions.push(_.cloneDeep(new_session_data));
      }
    }
    this.loading = false;
  }

  handleChange($event: any) {
    if ($event === null) {
      this.addToast('Use format "xh (and/or) xm (and/or) xs" where "x" is a number of hours, minutes and seconds. Or just put the number of seconds.', 'error', null, 10000);
      return;
    }
    if (!$event.first_load) {
      // event should now have the session, trial, trial idx and procedure session idx
      this.trialClick($event.trial, $event.procedure_session, $event.trial_idx, $event.procedure_session_idx);
    }
  }

  ngOnInit() {
    this.cmp_name = 'Duration';
    this.loading = true;
    this.procedure_sessions = [];
    // this.selected_date = moment().toDate();
    this.subscriptions = [];
    this.prompt_types = [];
    this.phase_types = [];
    this.phase_type_tactics_list = [];
    this.saving_proc_session = new Map<number, BehaviorSubject<number>>();

    this.subscribeToChoices();

    if (!this.is_readonly) {
      this.max_col_span = this.DEFAULT_SESSION_NUM;
      this.therapy_session = this.dailySessionViewService.currentSubject.getValue();
      if (!this.therapy_session || this.therapy_session.id == null) {
        this.therapy_session = new TherapySession();
        this.therapy_session.date_date = moment().toDate();
      }
      if (this.therapy_session.ignore_update) {
        delete this.therapy_session.ignore_update;
      }

      this.saving_trial = false;
      // this.getInitialData();
      this.subscribeToDataSheets();
      this.subscribeToPatient();
      this.subscribeToSheetInfo();

      this.subscribeToTherapySession();
      this.subscribeToUsers();
      this.subscribeToSessionSave();
      this.subscribeToSessionCache();
      this.subscribeToSavingSessions();
    } else {
      this.readonly_sessions = [];
      this.processReadonlyData();
    }
    this._showNavService.showButtons.next(false);
  }

  ngOnDestroy(): void {
    this.saving_trial = false;

    this.unsubscribe();
    this._showNavService.showButtons.next(true);
    if (this.sessionSave) {
      // killing the incremental save for this page
      this.dailySessionViewService.killTrigger.next();
      this.sessionSave.unsubscribe();
    }
    if (this.sessionSaving) {
      this.sessionSaving.unsubscribe();
    }
    if (this.dailySessionsSavedSubscription) {
      this.dailySessionsSavedSubscription.unsubscribe();
    }
    if (this.therapySessionSubscription) {
      this.therapySessionSubscription.unsubscribe();
    }
  }

}
