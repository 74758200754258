import {AfterViewChecked, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DomainService} from '../../core/services/domain.service';
import {SkillService} from '../../core/services/skill.service';
import {SkillObjectiveService} from '../../core/services/skill-objective.service';
import {Domain} from '../../core/models/domain.model';
import {Skill} from '../../core/models/skill.model';
import {SkillObjective} from '../../core/models/skill-objective.model';
import {ServicePlan} from '../../core/models/service-plan.model';
import {ConstantsService} from '../../core/services/constants.service';
import {ServicePlanViewService} from '../services/service-plan-view.service';
import {SelectOption} from '../../core/models/select-option.model';
import * as moment from 'moment';
import {Assessment} from '../../core/models/assessment.model';
import {User} from '../../core/models/user.model';
import {AssessmentService} from '../../core/services/assessment.service';
import {Subscription} from 'rxjs'
import {PatientService} from '../../core/services/patient.service';
import {FormControl, FormGroup, NgForm} from '@angular/forms';
import {CurrentFormService} from '../../core/services/current-form.service';
import {AppValidationMessages} from '../app.messages';
import {SkillObjectiveM2M} from '../../core/models/skill-objective-m2m.model';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import * as _ from 'lodash';
import {ProcedureViewService} from '../services/procedure-view.service';

@Component({
  selector: 'app-add-service-plan',
  templateUrl: './add-service-plan.component.html',
  styleUrls: ['./add-service-plan.component.css']
})
export class AddServicePlanComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild('skillObjectiveDropdown') public skillObjectiveDropdown: any;
  @ViewChild('addServicePlanForm') public currentForm: any;

  private DEFAULT_TITLE = 'Error';
  addServicePlanForm: NgForm;
  domains: Domain[];
  domain: number;
  skills: Skill[];
  all_skills: Skill[];
  skill: number;
  all_skill_objectives: SkillObjective[];
  skill_objectives: SkillObjective[];
  skill_objective_list: number[];
  skill_objective: number;
  skill_objective_m2m: SkillObjectiveM2M;
  service_plan: ServicePlan;
  loading: boolean = false;
  choices: Map<string, any>;
  statuses: SelectOption[] = [];
  assessments: Assessment[];
  patient: User;
  subscriptions: Subscription[];
  publish_statuses: SelectOption[];
  grouped_skill_objectives: any; // is in format { <itga_name> : SkillObjectiveM2M[] ... }
  grouped_objective_keys: string[];

  formErrors = {
    name: '',
    status: '',
    date_started: '',
    ps_hpm: '',
    therapist_hpw: '',
    developed_domains: '',
    focus_domains: '',
    assessments: ''
  };

  error_message: string = '';

  skill_objectives_error: boolean = false;

  // public addServicePlanForm: FormGroup = new FormGroup({
  //   service_plan_name: new FormControl({}, requiredValidatorLogic)
  // })

  constructor(private domainService: DomainService,
              private skillService: SkillService,
              private skillObjectiveService: SkillObjectiveService,
              private constantsService: ConstantsService,
              private servicePlanViewService: ServicePlanViewService,
              private assessmentService: AssessmentService,
              private patientService: PatientService,
              private procedureViewService: ProcedureViewService,
              private currentFormService: CurrentFormService,
              private toastyService: ToastyService) {
  }

  updateSkillObjecteM2M(): void {
    let skill_objectives: SkillObjectiveM2M[] = [];
    for (const key of this.grouped_objective_keys) {
      skill_objectives = [...skill_objectives, ...this.grouped_skill_objectives[key]];
    }
    this.service_plan.skill_objectives_m2m = skill_objectives.slice();
  }

  updateServicePlan(): void {
    this.updateSkillObjecteM2M();
    this.servicePlanViewService.currentSubject.next(this.service_plan);
  }

  domainChange(event: any): void {
    // this.loading = true;
    // this.skills = [];
    this.skill = null;
    this.skill_objectives = [];
    this.skill_objective_list = [];
    this.skillObjectiveDropdown.reset();

    const domain_id = typeof event === 'string' ? event : event.id;
    this.skills = this.all_skills.filter(s => s.domain === domain_id);
  }

  skillChange(event: any): void {
    this.loading = true;
    this.skill_objectives = [];
    this.skill_objective_list = [];
    this.skillObjectiveDropdown.reset();
    const skill_id = typeof event === 'string' ? event : event.id;

    this.procedureViewService.getSkillObjectivesById$(skill_id, 'skill')
      .subscribe(
        (skill_objectives) => {
          this.skill_objectives = _.cloneDeep(skill_objectives);
          for (const objective of this.skill_objectives) {
            objective.display_name = objective.tag + ' ' + objective.program;
          }
          this.loading = false;
        }, (err) => {
          // uh-oh, something went wrong, inform the user
          this.loading = false;
          this.skill_objectives = [];
        });
  }

  addObjectives(): void {
    const filtered: SkillObjectiveM2M[] = [];
    if (!this.service_plan.skill_objectives_m2m) {
      this.service_plan.skill_objectives_m2m = [];
    }

    // we should still have the objects in the dropdown list
    if (!this.service_plan.skill_objectives_display) {
      this.service_plan.skill_objectives_display = [];
    }

    // for (const skill of this.skill_objective_list) {
    //   const idx = this.service_plan.skill_objectives.indexOf(skill);
    //   if (idx === -1) {
    //     filtered.push(skill);
    //   }
    // }

    const objective = this.skill_objectives.find(so => so.id === this.skill_objective_m2m.skill_objective);
    if (objective) {
      this.skill_objective_m2m.ltga_name = objective.ltga_name;
      this.skill_objective_m2m.name = objective.program;
      if (!this.grouped_skill_objectives[this.skill_objective_m2m.ltga_name]) {
        this.grouped_skill_objectives[this.skill_objective_m2m.ltga_name] = [];
      }

      const idx = this.grouped_skill_objectives[this.skill_objective_m2m.ltga_name].findIndex(som => som.skill_objective == this.skill_objective_m2m.skill_objective);
      if (idx === -1) {
        // filtered.push(_.cloneDeep(this.skill_objective_m2m));
        //// this assumes the skill objective that is being added doesn't exist yet

        this.grouped_skill_objectives[this.skill_objective_m2m.ltga_name].push(_.cloneDeep(this.skill_objective_m2m));
        if (this.grouped_objective_keys.indexOf(this.skill_objective_m2m.ltga_name) === -1) {
          this.grouped_objective_keys.push(this.skill_objective_m2m.ltga_name);
        }
      }
    }

    // this.service_plan.skill_objectives_m2m = this.service_plan.skill_objectives_m2m.concat(filtered);

    // const skill_objectives_m2m = this.service_plan.skill_objectives_m2m.slice();
    // for (let i = 0; i < skill_objectives_m2m.length; i++) {
    //   const objective = this.skill_objectives.find(so => so.id === skill_objectives_m2m[i].skill_objective);
    //   if (objective) {
    //     skill_objectives_m2m[i].skill_objective_display = i.toString() + ' ' + objective.program;
    //   }
    // }
    // this.service_plan.skill_objectives_m2m = skill_objectives_m2m.slice();


    this.skill_objectives_error = false;
    this.skill_objective_m2m = new SkillObjectiveM2M();
    this.updateServicePlan();
  }

  saveObjective(idx: number, group_name?: string): void {
    console.log(idx);
    this.edit(idx, group_name);
    this.updateServicePlan();
  }

  removeObjective(idx: number, group_name?: string): void {
    if (!this.grouped_skill_objectives || !this.grouped_skill_objectives[group_name]) {
      return;
    }
    const group_idx = this.grouped_objective_keys.indexOf(group_name);
    if (group_name && group_idx > -1) {
      if (this.grouped_skill_objectives[group_name]) {

        this.grouped_skill_objectives[group_name] = [...this.grouped_skill_objectives[group_name].slice(0, idx), ...this.grouped_skill_objectives[group_name].slice(idx + 1)];
        if (this.grouped_skill_objectives[group_name].length === 0) {
          this.grouped_objective_keys = [...this.grouped_objective_keys.slice(0, group_idx), ...this.grouped_objective_keys.slice(group_idx + 1)];
          // now we remove the objective

          delete this.grouped_skill_objectives[group_name];
        }

      }
    }

    // worry about this later
    // this.service_plan.skill_objectives_m2m.splice(idx, 1);

    this.updateServicePlan();
  }

  getDomains(): void {
    if (this.domainService.collection && this.domainService.collection.length > 0) {
      this.domains = this.domainService.collection.slice();
    } else {
      this.domainService.getAllPromise()
        .then((domains) => {
          this.domains = domains;
        })
        .catch((err) => {
          // TODO: Show user there was an error
          console.log(err);

          throw err;
        });
    }
  }

  getSkills(): void {
    if (this.skillService.collection && this.skillService.collection.length > 0) {
      this.all_skills = this.skillService.collection.slice();
    } else {
      this.skillService.getAllPromise()
        .then((skills) => {
          this.all_skills = skills.slice();
          this.skills = skills.slice();
        })
        .catch((err) => {
          // TODO: Show user there was an error
          console.log(err);

          throw err;
        });
    }
  }

  getChoices(): void {
    if (!this.constantsService.choices || this.constantsService.choices.size === 0) {
      this.constantsService.getChoices().then(
        (choices: Map<string, any>) => {
          this.choices = choices;
          this.statuses = choices.get('serviceplan').get('status');
          this.publish_statuses = choices.get('global').get('publish_status');
        });
    } else {
      this.choices = this.constantsService.choices;
      this.statuses = this.choices.get('serviceplan').get('status');
      this.publish_statuses = this.choices.get('global').get('publish_status');
    }
  }

  getObjectivesForPlan(): void {
    if (this.skillObjectiveService.collection && this.skillObjectiveService.collection.length > 0) {
      this.all_skill_objectives = _.cloneDeep(this.skillObjectiveService.collection);

      if (this.service_plan.skill_objectives_m2m &&
        this.service_plan.skill_objectives_m2m.length > 0) {

        const grouped_data = _(this.service_plan.skill_objectives_m2m)
          .groupBy(x => x.ltga_name)
          .value();

        this.grouped_skill_objectives = grouped_data;
        this.grouped_objective_keys = Object.keys(grouped_data);
      }

    } else {
      this.procedureViewService.getSkillObjectives$()
        .subscribe((objectives) => {
          this.all_skill_objectives = _.cloneDeep(objectives);
          // this.service_plan.skill_objectives_display = [];
          if (this.service_plan.skill_objectives_m2m &&
            this.service_plan.skill_objectives_m2m.length > 0) {

            const grouped_data = _(this.service_plan.skill_objectives_m2m)
              .groupBy(x => x.ltga_name)
              .value();

            this.grouped_skill_objectives = grouped_data;
            this.grouped_objective_keys = Object.keys(grouped_data);
          }
        }, (err) => {
          console.log(err);
          this.loading = false;
        });
    }
  }

  edit(idx: number, group_name?: string): void {
    //this.service_plan.skill_objectives_m2m[idx].edit = !this.service_plan.skill_objectives_m2m[idx].edit;

    if (this.grouped_skill_objectives[group_name]) {
      this.grouped_skill_objectives[group_name][idx].edit = !this.grouped_skill_objectives[group_name][idx].edit;
    }
  }

  updateDates(): void {
    if (this.service_plan.date_implemented) {
      const now = moment(this.service_plan.date_implemented);
      let selected = now.clone().add(6, 'months').add(-1, 'days');
      this.service_plan.date_6m_check = selected.format('YYYY-MM-DD');
      selected = now.clone().add(1, 'years').add(-1, 'days');
      this.service_plan.date_12m_check = selected.format('YYYY-MM-DD');
    }
  }

  onDateChange($event: any): void {
    if ($event) {
      const now = moment($event);
      let selected = now.clone().add(6, 'months').add(-1, 'days');
      this.service_plan.date_6m_check = selected.format('YYYY-MM-DD');
      selected = now.clone().add(1, 'years').add(-1, 'days');
      this.service_plan.date_12m_check = selected.format('YYYY-MM-DD');
      this.updateServicePlan();
    }
  }

  getAssessments(): void {
    // we should have a patient at this point, if we don't then something is very wrong
    this.assessmentService.getByParamPromise(this.patient.id.toString(), 'patient')
      .then(plans => {
        this.assessments = plans;
        this.loading = false;
      })
      .catch(
        err => {
          console.log(err);
          this.loading = false;

          throw err;
        })
  }

  updateSkillObjective(event: any) {
    if (event == null) {
      return;
    }
    const objective = this.skill_objectives.find(so => so.id == event);
    if (objective) {
      this.skill_objective_m2m.skill_objective = objective.id;
      this.skill_objective_m2m.objective = objective.objective;
    }
  }

  subscribeToPatient(): void {
    this.subscriptions.push(this.patientService.currentPatient.subscribe(
      (patient: User) => {
        this.patient = patient;
        if (this.patient && this.patient.id != null) {
          if (this.service_plan.patient == null) {
            this.service_plan.patient = this.patient.id;
          }
          this.getAssessments();
        }
        // if (this.patient.dob_date) {
        //   // now we get the calculated age
        //   const now = moment();
        //   const age = moment(this.patient.dob_date);
        //   const diff = now.diff(age, 'years');
        //   this.patient.calculated_age = diff.toString();
        // }
      }));
  }

  subscribeToNonFieldErrorMessage(): void {
    this.subscriptions.push(this.currentFormService.currentNonFieldErrorMessage.subscribe(
      (message: string) => {
        // this.error_message = message;
        if (message && typeof message === 'string') {
          this.error_message = message;
          this.addToast(message, 'Error');
        }
      }));
  }

  addToast(message: string, title: string) {
    // Or create the instance of ToastOptions
    const toastOptions = this.getToastOptions(message, title);
    // // Add see all possible types in one shot
    // this.toastyService.info(toastOptions);
    // this.toastyService.success(toastOptions);
    // this.toastyService.wait(toastOptions);
    // this.toastyService.error(toastOptions);
    // this.toastyService.warning(toastOptions);

    switch (title.toLowerCase()) {
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
    }
  }

  getToastOptions(message: string, title: string): ToastOptions {
    title = title != null ? title : this.DEFAULT_TITLE;

    return {
      title: title,
      msg: message,
      showClose: true,
      timeout: 5000,
      theme: 'default'
    };
  }

  formChange(): void {
    if (this.currentForm === this.addServicePlanForm) {
      return;
    }

    this.addServicePlanForm = this.currentForm;
    if (this.addServicePlanForm) {
      this.addServicePlanForm.valueChanges
        .subscribe(data => this.onValueChanged());
    }
  }

  onValueChanged() {
    if (!this.addServicePlanForm) {
      return;
    }
    const form = this.addServicePlanForm.form;

    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }
    this.skill_objectives_error = !!(!this.service_plan.skill_objectives_m2m ||
      (this.service_plan.skill_objectives_m2m && this.service_plan.skill_objectives_m2m.length === 0));
  }

  submitForm() {
    // just a dummy function for the moment
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = this.currentForm.form.get(field);

      if (control && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }

    this.skill_objectives_error = !!(!this.service_plan.skill_objectives_m2m ||
      (this.service_plan.skill_objectives_m2m && this.service_plan.skill_objectives_m2m.length === 0));
  }

  ngAfterViewChecked(): void {
    this.formChange();
  }

  ngOnInit() {
    this.loading = true;
    this.skill_objective_m2m = new SkillObjectiveM2M();
    this.subscriptions = [];
    // this.domain = new Domain();
    this.domains = [];
    // this.skill = new Skill();
    this.skills = [];
    // this.skill_objective = new SkillObjective();
    this.skill_objectives = [];
    this.skill_objective_list = [];
    this.statuses = [];
    this.publish_statuses = [];
    this.all_skill_objectives = [];
    this.grouped_objective_keys = [];
    this.grouped_skill_objectives = {};

    // clear the error message before we do anything else so it doesn't show on subsequent edits
    this.currentFormService.currentNonFieldErrorMessage.next(null);


    const service_plan = this.servicePlanViewService.currentSubject.getValue();
    if (!service_plan) {
      this.service_plan = new ServicePlan();
    } else {
      this.service_plan = _.cloneDeep(service_plan);
      this.updateDates();
      this.getObjectivesForPlan();
    }

    this.getDomains();
    this.getSkills();
    this.getChoices();
    this.subscribeToPatient();
    this.subscribeToNonFieldErrorMessage();
    this.currentFormService.currentSubject.next(this.currentForm);
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
    this.currentFormService.currentSubject.next(null);
  }

}
