export class DataSheet {
  id: number;
  created: string;
  updated: string;
  archived: boolean;
  name: string;
  x_axis: string;
  y_axis: string;
  x_label: string;
  y_label: string;
  created_by: number;
  last_updated_by: number;
  code: string;
}
