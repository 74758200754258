import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenText'
})
export class ShortenTextPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return;
    }
    let length: number;
    try {
      if (length != null) {
        length = +args;
      } else {
        length = 100;
      }
    } catch (e) {
      length = 100;
    }
    return value.length > length ? value.substring(0, length) + '...' : value
  }
}
