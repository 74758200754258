import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService} from '@progress/kendo-angular-dialog';
import {ParentDetailService} from '../../core/services/parent-detail.service';
import {ParentDetailViewService} from '../../shared/services/parent-detail-view.service';
import {User} from '../../core/models/user.model';
import {PatientService} from '../../core/services/patient.service';
import {UserService} from '../../core/services/user.service';
import {BaseComponent} from '../../shared/base/base.component';
import {ConstantsService} from '../../core/services/constants.service';
import {ToastyConfig, ToastyService} from 'ng2-toasty';
import {UserViewService} from '../../shared/services/user-view.service';
import {HttpErrorCodes} from '../../core/shared/enum';
import {CurrentFormService} from '../../core/services/current-form.service';
import {SelectOption} from '../../core/models/select-option.model';
import {ShowNavService} from '../../core/services/show-nav.service';
import {SuccessResponse} from '../../core/models/response.model';
import {PermissionModel} from '../../core/models/permission.model';
import { ConfirmDeleteService } from 'app/core/services/confirm-delete.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent extends BaseComponent implements OnInit, OnDestroy {
  opened = true;
  result: string;
  contacts: User[];
  patient: User;
  logged_in_user: User;
  dialogService: DialogService;
  relationship_can_edit: string = 'PARENT';
  user_type_can_edit: string = 'CM';
  parentDetailViewService: ParentDetailViewService;
  user_types: SelectOption[];
  permissions: PermissionModel[];
  updateUserAssigned: PermissionModel;

  constructor(dialogService: DialogService,
              constantsService: ConstantsService,
              toastyService: ToastyService,
              private toastyConfig: ToastyConfig,
              private parentDetailService: ParentDetailService,
              parentDetailViewService: ParentDetailViewService,
              private patientService: PatientService,
              userViewService: UserViewService,
              private userService: UserService,
              currentFormService: CurrentFormService,
              private showNavService: ShowNavService,
              private confirmDeleteService: ConfirmDeleteService) {
    // need to pass userViewService as the second and fourth argument as the base class requires the class that extends IBaseViewService
    // to be used as the modal popup service
    super(constantsService, userViewService, dialogService, userViewService, currentFormService, toastyService);
    this.toastyConfig.theme = 'material';
    this.dialogService = dialogService;
    this.parentDetailViewService = parentDetailViewService;
  }

  getPatientAndContacts(): Promise<User[]> {
    let data = [];
    this.patient = this.patientService.currentPatient.getValue();
    data = this.getContacts();
    return Promise.resolve(data);
  }

  inviteUser(contact: User): void {
    this.userService.inviteUser(contact.id)
      .then((res: SuccessResponse) => {
        this.addToast('Invite was sent.', 'Success');
        contact.invite_sent = true;
      })
      .catch((err) => {
        this.addToast('Could not send invite', 'Error');
        throw err;
      });
  }

  openConfirmDelete(idx: number) {
    const content = `Are you sure you want to delete contact ${this.contacts[idx].first_name} ${this.contacts[idx].last_name}?`
    this.confirmDeleteService.openConfirmDialog(content)
      .subscribe(res => {
        if (res) {
          this.remove(idx);
        }
      })
  }

  remove(idx: number): void {
    if (idx == null) {
      return
    }
    // need to find out if we have to archive it or just blatently remove
    this.userService.unassignChild(this.contacts[idx].id, this.patient.id, this.contacts[idx].relationship_type)
      .then((res) => {
        this.contacts.splice(idx, 1);
        this.addToast('Contact was removed from patient.', 'Success');
        this.userViewService.getSubjects();
      })
      .catch((err) => {
        if (typeof err === 'object' && err.status === HttpErrorCodes.UNAUTHORIZED) {
          this.addToast('You do not have permission to remove this contact...', 'Error');
        } else {
          this.addToast('Could not remove contact from patient, please try again...', 'Error');
        }
      });
  }

  getContacts(): User[] {
    this.contacts = [];
    // const patient = this.patientService.currentPatient.getValue();
    if (this.patient && this.patient.parents_repr && this.patient.parents_repr.length > 0) {
      // loop over all the parent relationships and find the objects
      for (const relationship of this.patient.parents_repr) {
        const user = this.full_users_list.find(au => au.id == relationship.user);
        if (user) {
          user.relationship_type = relationship.relationship_type;
          user.relationship_type_desc = relationship.relationship_type_description;
          this.contacts.push(user);
        }
      }
      return this.contacts;
    }
  }

  subscribeToFiltered(): void {
    this.subscriptions.push(this.userViewService.currentSubjectsFiltered.subscribe(
      (filtered: User[]) => {
        if (this.filter_text != null && this.filter_status != null) {
          this.contacts = filtered.slice();
        }
      }));
  }

  // need to subscribe to both user and parent view services
  subscribeToAllUsers(): void {
    this.subscriptions.push(this.userViewService.currentFullUserSubjects.subscribe(
      (all_users: User[]) => {
        this.full_users_list = all_users;

      }));
  }
  subscribeToParentAllUsers(): void {
    this.subscriptions.push(this.parentDetailViewService.currentSubjects.subscribe(
      (all_users: User[]) => {
        this.full_users_list = all_users;

      }));
  }

  subscribeToChoices(): void {
    this.subscriptions.push(this.choicesSubject.subscribe(
      (choices: Map<string, any>) => {
        if (choices.size > 0 && choices.has('user')) {
          this.user_types = choices.get('user').get('user_type');
        }
      }));
  }

  subscribeToRefreshSubjects(): void {
    this.subscriptions.push(this.refreshSubjects.subscribe(
      (refresh: boolean) => {
        if (refresh) {
          this.loading = true;
          this.userViewService.getById(this.patient.id).then(
            (patient) => {
              // we should have an updated or the same list in the user view service

              this.patient = patient;
              this.getContacts();
              this.loading = false;
            });
        }
      }, (err) => {
        this.loading = false;
      }));
  }

  private subscribeToLoggedInUser(): void {
    const logged_in_user = this.userService.currentUserUpdated.getValue();
    if (!logged_in_user || !logged_in_user.id) {
      //   this.userService.getCurrentUser()
      //     .then(
      //       (user) => {
      //         this.logged_in_user = user;
      //   });
      // } else {
      this.subscriptions.push(this.userService.currentUserUpdated.subscribe(
        (user: User) => {
          this.logged_in_user = user;
        }));
    } else {
      this.logged_in_user = logged_in_user;
    }
  }

  subscribeToPermissions(): void {
    const permissionSubjects = this.userViewService.currentPermissionSubjects.getValue();
    if (!permissionSubjects || permissionSubjects.length === 0) {
      this.subscriptions.push(this.userViewService.getPermissions()
        .subscribe((permissions: PermissionModel[]) => {
          if (permissions) {
            this.permissions = permissions;
          }
        }));
    } else {
      this.permissions = permissionSubjects.slice();
    }
  }

  checkCanEditUser(): boolean{
    return this.userViewService.hasPermission('web.user', 'assign_child')
  }

  ngOnInit() {
    this.loading = true;
    this.contacts = [];
    this.subscriptions = [];
    this.permissions = [];
    this.patient = this.patientService.currentPatient.getValue();
    if (!this.patient) {
      this.patient = new User();
    }
    this.getChoices();
    this.subscribeToLoggedInUser();
    // this.subscribeToPatient();
    this.getFullUsersList()
      .then(users => {
        // this.subscribeToUsers();
        return this.getPatientAndContacts();
      })
      .then(contacts => {
        this.subscribeToFiltered();
        this.subscribeToChoices();
        this.subscribeToRefreshSubjects();
        this.subscribeToAllUsers();
        this.subscribeToParentAllUsers();
        this.subscribeToPermissions();

        this.loading = false;
      })
    this.showNavService.showButtons.next(false);
  }

  ngOnDestroy() {
    this.unsubscribe();
    this.showNavService.showButtons.next(true);
  }

}
