import {Attribute, Directive, forwardRef, Input} from '@angular/core';
import {FormGroup, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {MustMatchValidator} from './must-match.validator';

@Directive({
  selector: '[mustMatch]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: MustMatchDirective,
    multi: true
  }]
})
export class MustMatchDirective implements Validator {
  // @Input() appCompareTo: string;
  // @Attribute('reverse') public reverse: string;
  @Input('mustMatch') public mustMatch: string[] = [];
  constructor() { }

  validate(formGroup: FormGroup): ValidationErrors {
    return this.mustMatch ? MustMatchValidator(this.mustMatch[0], this.mustMatch[1])(formGroup)
      : null;
  }

  registerOnValidatorChange(fn: () => void): void {
    throw new Error('Method not implemented.');
  }
}
