import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {AuthenticationService} from '../authentication.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
// import 'rxjs/add/observable/of';
import {SelectOption} from '../models/select-option.model';
import {environment as env} from '../../../environments/environment';

@Injectable()
export class ConstantsService extends BaseService<any> {
  PREFIX = 'web';
  choices: Map<string, any> = new Map<string, any>();

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'static.choices/', Object);
  }

  getProgramsStatic(): SelectOption[] {
    return [
      new SelectOption('0', 'Lizard EI'),
      new SelectOption('1', 'School'),
      new SelectOption('1', 'Nextt Steps'),
    ];
  }

  convertListToSelctOptions(list: any[]): SelectOption[] {
    if (!list || list.length === 0) return [];

    const ret_list: SelectOption[] = [];
    for (const value of list) {
      ret_list.push(new SelectOption(
        value[0],
        value[1]
      ));
    }
    return ret_list
  }

  getChoices(): Promise<any> {
    if (!this.choices || this.choices.size === 0) {
      return this.get().then(
        (res) => {
          // if we actually get something
          if (res != null && typeof res === 'object') {
            const keys = Object.keys(res);
            // should be an object
            // should be in form:
            // { web.<endpoint>.<field_or_whatever>: ["", ""]}
            // or { web.<endpoint>.<field_or_whatever>: {"": ["", ""]}}
            for (const key of keys) {
              const split_key = key.split('.');

              const map_key = split_key.length === 1 ? env.constants_map_key : split_key[1];
              const sub_key = split_key.length === 1 ? split_key[0] : split_key[2];
              if (res[key] && typeof res[key] === 'object' && res[key].hasOwnProperty('length')) {
                let temp_arr = [];
                if (!this.choices.has(map_key)) {
                  this.choices.set(map_key, new Map<string, any>());
                }
                if (!this.choices.get(map_key).has(sub_key)) {
                  this.choices.get(map_key).set(sub_key, []);
                }
                temp_arr = this.convertListToSelctOptions(res[key]);
                this.choices.get(map_key).set(sub_key, temp_arr);
              } else if (res[key] && typeof res[key] === 'string') {
                if (!this.choices.has(map_key)) {
                  this.choices.set(map_key, new Map<string, any>());
                }
                this.choices.get(map_key).set(sub_key, res[key]);
              } else {
                // if we have an object - don't know what to do with it yet
                // this may only need the key/value pairs
                if (!this.choices.has(map_key)) {
                  this.choices.set(map_key, new Map<string, any>());
                }
                // if (!this.choices[map_key][sub_key]) {
                //   this.choices[map_key].set(sub_key, []);
                // }
                this.choices.get(map_key).set(sub_key, res[key]);
              }
            }
          }
          return this.choices;
      })
        .catch((error: any) => throwError(error || 'Server Error'));
    } else {
      return of(this.choices).toPromise();
    }
  }

}
