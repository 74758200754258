import { ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import {NotificationService} from '../../services/notification.service';
import {AuthenticationService} from '../../authentication.service';
import {LoggingService} from '../../services/logging.service';
import {UserService} from '../../services/user.service';
import { BrowserModule } from '@angular/platform-browser';

// import { NotificationService } from '../../services/notification/notification.service';
import {environment as env} from '../../../../environments/environment';
import * as Sentry from '@sentry/browser';

Sentry.init({
  environment: env.sentry_environment,
  dsn: 'https://9a257ac960d9459a968203d488ff5a76@sentry.io/1837041'
});

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(
    private injector: Injector,
    private ngZone: NgZone,
    private authenticationService: AuthenticationService
  ) {}

  handleError(error) {
    // need to add a notification service
    const notificationService = this.injector.get(NotificationService);
    const router = this.injector.get(Router);
    const loggingService = this.injector.get(LoggingService);
    const userService = this.injector.get(UserService);

    // debugger;

    if (error instanceof HttpErrorResponse) {
      if (error.status == 401) {
        this.ngZone.run(() => router.navigate(['/login'])).then();
      }
      // Server error happened
      if (!navigator.onLine) {
        // No Internet connection
        return notificationService.notify('No Internet Connection');
      }
      // Http Error
      // send error to the server
      const user = userService.current_user;
      const userName = user != null ? user.display_name : '';
      loggingService.log(error, userName).subscribe();
      // return notificationService.notify(`${error.status} - ${error.message}`);
    } else if (error.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') == -1) {
      if (error.message.indexOf('"status":401') != -1) {
        this.ngZone.run(() => router.navigate(['/login'])).then();
      }
    // } else {
      const eventId = Sentry.captureException(error.originalError || error);
      // TODO: uncomment below line to show the report dialog when the UI is more stable
      // Sentry.showReportDialog({ eventId });


      // // Client Error Happend
      // loggingService
      //   .log(error)
      //   .subscribe(errorWithContextInfo => {
      //     // router.navigate(['/error'], { queryParams: errorWithContextInfo });
      //
      //     // log to sentry instead
      //   });
    }
    if (!router.url.includes('login') &&
      !router.url.includes('resetpassword') &&
      !this.authenticationService.isAuthenticated()) {
      this.ngZone.run(() => router.navigate(['/login'])).then();
    }
  }
}
