import { Injectable } from '@angular/core';
import {IBaseViewService} from '../interfaces/base-view.interface';
import {ServicePlan} from '../../core/models/service-plan.model';
import {ServicePlanService} from '../../core/services/service-plan.service';
import {throwError, BehaviorSubject, Observable} from 'rxjs';
import {PatientService} from '../../core/services/patient.service';
import * as _ from 'lodash';

@Injectable()
export class ServicePlanViewService implements IBaseViewService<ServicePlan> {

  currentSubjectType: BehaviorSubject<string> = new BehaviorSubject('');
  // single and plurals
  currentSubject: BehaviorSubject<ServicePlan> = new BehaviorSubject(new ServicePlan());
  currentSubjects: BehaviorSubject<ServicePlan[]> = new BehaviorSubject([]);
  currentSubjectsFiltered: BehaviorSubject<ServicePlan[]> = new BehaviorSubject([]);

  keys_to_search: string[] = ['name'];

  constructor(private servicePlanService: ServicePlanService,
              private patientService: PatientService) { }

  search(status?: string, search_text?: string): Promise<ServicePlan[]> {
    let promise: Promise<ServicePlan[]>;
    let params: any;
    if (status) {
      params = {
        status: status
      }
    }
    // if (search_text) {
    //   if (!params) {
    //     params = {};
    //   }
    // }
    const patient = this.patientService.currentPatient.getValue();
    if (!params) {
      promise = this.servicePlanService.getByParamPromise(patient.id.toString(), 'patient');
    } else {
      params['patient'] = patient.id.toString();
      promise = this.servicePlanService.getByParamObjPromise(params);
    }
    return promise
      .then((filtered: ServicePlan[]) => {
        // check through all keys, not sure if there is an aggregate yet
        let result = [];
        if (search_text) {
          for (const item of filtered) {
            // const keys = Object.keys(item);
            for (const key of this.keys_to_search) {
              if (typeof item[key] === 'string' &&
                item[key].toLocaleLowerCase().indexOf(search_text.toLocaleLowerCase()) > -1) {
                result.push(_.cloneDeep(item));
                break;
              }
            }
          }
        } else {
          result = filtered;
        }
        this.currentSubjectsFiltered.next(result);
        return result;
      })
      .catch((err) => throwError(err).toPromise());
  }

  save(): Promise<ServicePlan> {
    const service_plan = this.currentSubject.getValue();
    if (service_plan.assessments == null || service_plan.assessments.length == 0) {
      delete service_plan.assessments;
    }
    // need to delete the 3 and 6 month dates as well before saving
    delete service_plan.date_6m_check;
    delete service_plan.date_12m_check;
    // need to make sure slp_hpw is null
    if (service_plan.slp_hpw == '') {
      service_plan.slp_hpw = null;
    }
    return this.servicePlanService.save(service_plan)
      .then((ret_val) => {
        this.currentSubject.next(ret_val);
        return ret_val;
      })
      .catch(
        (err) => {
        return throwError(err).toPromise();
      });
  }

  /**
   * get the service plan by param, and do anything special for the UI here
   * @param {string} value
   * @param {string} key
   * @returns {Promise<ServicePlan>}
   */
  getByPatient(value: string): Promise<ServicePlan[]> {
    return this.servicePlanService.getByParamPromise(value, 'patient')
      .then((plans: ServicePlan[]) => {
        return plans;
      })
      .catch(
        err => {
          return throwError(err).toPromise();
        });
  }

  getSubjects(): void {
    const patient = this.patientService.currentPatient.getValue();
    // we should have a patient at this point, if we don't then something is very wrong
    this.servicePlanService.getByParamPromise(patient.id.toString(), 'patient')
      .then(plans => {
        this.currentSubjects.next(plans);
      })
      .catch(
        err => {
          console.log(err);
        })
  }

  update(): Promise<ServicePlan> {
    return undefined;
  }

  getById(id: number): Promise<ServicePlan> {
    return this.servicePlanService.getById(id)
      .then((plan: ServicePlan) => {
        return plan;
      })
      .catch(
        err => {
          return throwError(err).toPromise();
        })
  }

  sendForReview(): Observable<any> {
    const service_plan = this.currentSubject.getValue();
    if (service_plan && service_plan.id != null) {
      return this.servicePlanService.sendForReview(service_plan.id);
    } else {
      return throwError('Need to save the Program Plan first before sending it for review');
    }
  }

  markAsReviewed(): Observable<any> {
    const service_plan = this.currentSubject.getValue();
    if (service_plan && service_plan.id != null) {
      return this.servicePlanService.markAsReviewed(service_plan.id);
    } else {
      return throwError('Need to save the Program Plan first before sending it for review');
    }
  }

}
