import { Injectable } from '@angular/core';
import {SdResponse} from '../models/sd-response.model';
import {BaseService} from '../base.service';
import {IBaseService} from '../interfaces/base-interface';
import { HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';

@Injectable()
export class SdResponseService extends BaseService<SdResponse> {

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.sdresponse/', SdResponse);
  }

}
