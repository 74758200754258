import { Injectable } from '@angular/core';
import {BaseService} from '../base.service';
import {ServicePlan} from '../models/service-plan.model';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {PatientService} from './patient.service';
import {environment as env} from '../../../environments/environment';

@Injectable()
export class ServicePlanService extends BaseService<ServicePlan> {
  currentSubject: BehaviorSubject<any>;

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService,
              private patientService: PatientService,
              private router: Router) {
    super(authenticationService, http, 'web.serviceplan/', ServicePlan);
    this.subRoute = '';
    this.date_obj_keys = ['date_implemented', 'date_6m_check', 'date_12m_check'];
    // this.date_obj_keys = ['date_implemented'];
  }

  // not sure what I'm trying to do here
  sendForReview(id: number): Observable<any> {
    // need to move this into the base class
    const url = this.apiUrl + id + '/send_for_review/';
    const patient = this.patientService.currentPatient.getValue();
    const options = this.getOptions(true);
    const endpoint = window.location.origin;
    const callback = this.router.createUrlTree(['/patient', patient.id, 'program-plan', id]).toString();

    const callback_url = endpoint.lastIndexOf('/') == endpoint.length - 1 ?
      endpoint.slice(0, endpoint.length - 1) + callback :
      endpoint + callback;
    const body = {
      'approval_callback': callback_url
      // 'approval_callback': 'http://localhost:4200/patient/154/program-plan'
    };

    return this._http.put(url, body, options)
      .pipe(
        tap(res => {
          console.log(res);
        })
      );
  }

  // not sure what I'm trying to do here
  markAsReviewed(id: number): Observable<any> {
    // need to move this into the base class
    const url = this.apiUrl + id + '/mark_as_reviewed/';
    const options = this.getOptions(true);

    return this._http.put(url, {}, options)
      .pipe(
        tap(res => {
          console.log(res);
        })
      );
  }
}
