import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ResetPasswordComponent} from '../reset-password/reset-password.component';
import {LoginComponent} from '../login/login.component';
import {AccessGuard} from '../core/guards/access.guard';
import {LoginModule} from '../login/login.module';
import {ResetPasswordModule} from '../reset-password/reset-password.module';
import {PatientRoutingModule} from '../patient/patient-routing.module';
import {PatientModule} from '../patient/patient.module';
import {ListPatientComponent} from '../list-patient/list-patient.component';
import {ErrorComponent} from '../shared/error/error.component';
import {ComingSoonComponent} from '../shared/coming-soon/coming-soon.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  }, {
    path: 'login',
    component: LoginComponent,
    data: {
      requiresLogin: false
    },
    canActivate: [AccessGuard]
  }, {
    path: 'resetpassword',
    component: ResetPasswordComponent,
    data: {
      requiresLogin: false
    },
    canActivate: [AccessGuard]
  }, {
    path: 'resetpassword/:uidb36:token',
    component: ResetPasswordComponent,
    data: {
      requiresLogin: false
    },
    canActivate: [AccessGuard]
  }, {
    path: 'invite',
    component: ResetPasswordComponent,
    data: {
      requiresLogin: false
    },
    canActivate: [AccessGuard]
  }, {
    path: 'invite/:uidb36:token',
    component: ResetPasswordComponent,
    data: {
      requiresLogin: false
    },
    canActivate: [AccessGuard]
  }, {
    path: 'patient',
    loadChildren: 'app/patient/patient.module#PatientModule',
    data: {
      requiresLogin: true // TODO: need to change this to true once we have login stuff
    },
    canActivate: [AccessGuard]
  }, {
    path: 'patients',
    component: ListPatientComponent,
    data: {
      requiresLogin: true
    },
    canActivate: [AccessGuard]
  }, {
    path: 'error',
    component: ErrorComponent,
    data: {
      requiresLogin: false
    },
    canActivate: [AccessGuard]
  }, {
    path: 'mobile-coming-soon',
    component: ComingSoonComponent,
    data: {
      requiresLogin: false
    },
    canActivate: []
  }
];


@NgModule({
  imports: [
    CommonModule,
    PatientModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
