import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {SapsComponent} from './saps.component';
import {ChartModule} from '@progress/kendo-angular-charts';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '../../core/core.module';
import {IntlModule} from '@progress/kendo-angular-intl';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';
import {SharedModule} from '../../shared/shared.module';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    IntlModule,
    DateInputsModule,
    DropDownsModule,
    DialogModule,
    MatTooltipModule,
    ChartModule,
    SharedModule
  ],
  declarations: [SapsComponent],
  exports: [
    SapsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SapsModule { }
