import {Base} from './base.model';

export class ReinforcementSchedule extends Base<ReinforcementSchedule> {
  id: number;
  types: string[];
  types_text: string;
  created: string;
  updated: string;
  _order: number;
  archived: boolean;
  schedule: string;
  schedule_text: string;
  created_by: number;
  last_updated_by: number;
  procedure: number;
  edit: boolean;
  is_submitted: boolean;

  constructor() {
    super(ReinforcementSchedule);
    this.types = [];
  }
}
