import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from '../core/services/user.service';
import {User} from '../core/models/user.model';
import {Observable, Subscription} from 'rxjs/index';
import {BaseComponent} from '../shared/base/base.component';
import {ConstantsService} from '../core/services/constants.service';
import {ShowNavService} from '../core/services/show-nav.service';
import {UserViewService} from '../shared/services/user-view.service';
import {CurrentFormService} from '../core/services/current-form.service';
import {DialogService} from '@progress/kendo-angular-dialog';
import {PatientViewService} from '../patient/patient-view.service';
import {Procedure} from '../core/models/procedure.model';
import {HttpErrorCodes} from '../core/shared/enum';
import {ToastyService} from 'ng2-toasty';
import {PatientDetailViewService} from '../shared/services/patient-detail-view.service';
import {PatientDetails} from '../core/models/patient-details.model';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import { ConfirmDeleteService } from 'app/core/services/confirm-delete.service';
import { SelectOption } from 'app/core/models/select-option.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-list-patient',
  templateUrl: './list-patient.component.html',
  styleUrls: ['./list-patient.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ListPatientComponent extends BaseComponent implements OnInit, OnDestroy {

  patients: User[];
  client_details: PatientDetails[];
  patientViewService: PatientViewService;
  loading: boolean;
  subscriptions: Subscription[];
  users_list: User[];
  staffData: SelectOption[];
  regionData: SelectOption[];
  searchObject: any = {
    name: '',
    regions: [],
    staff: []
  };

  constructor(dialogService: DialogService,
              private userService: UserService,
              userViewService: UserViewService,
              constantsService: ConstantsService,
              private showNavService: ShowNavService,
              patientViewService: PatientViewService,
              currentFormService: CurrentFormService,
              toastyService: ToastyService,
              private patientDetailViewService: PatientDetailViewService,
              private confirmDeleteService: ConfirmDeleteService) {
    super(constantsService, userViewService, dialogService, patientViewService, currentFormService, toastyService);
    this.patientViewService = patientViewService;
  }

  getClients(): void {
    // this.getPatientDetails()
    this.patientDetailViewService.getSubjects$()
      .pipe(
        switchMap(val => {
          return this.userService.getAll();
        })
      ).subscribe(
      (users: User[]) => {
        if (users && users.length > 0) {
          // we know that the patients have the 'detail_cls' value web.patientdetail as this is their endpoint
          this.patients = users.filter(u => u.user_type && u.user_type.toLocaleLowerCase().indexOf('patient') > -1);

          // now we get the PS and SBT for each patient
          for (const patient of this.patients) {
            if (patient.parents_repr && patient.parents_repr.length > 0) {
              let filtered = patient.parents_repr.filter(r => r.relationship_type.toLocaleLowerCase() === 'ps');
              if (filtered && filtered.length > 0) {
                const id = filtered[0].user;
                patient.program_supervisor = users.filter(u => u.id === id)[0];
              }
              filtered = patient.parents_repr.filter(r => r.relationship_type.toLocaleLowerCase() === 'sbt');
              if (filtered && filtered.length > 0) {
                const id = filtered[0].user;
                patient.sbt = users.filter(u => u.id === id)[0];
              }
            }
          }
          if (this.patients) {
            this.findClientDetail();
          }
        }
        this.loading = false;
      }, (err) => {
        this.loading = false;
      });
  }

  openConfirmDelete(patientId: number) {
    if (patientId == null) {
      this.addToast('Client ID is undefined', 'error');
      return;
    }

    const index = this.patients.findIndex(patient => patient.id === patientId);

    if (index >= 0) {
      const content = `Are you sure you want to delete client ${this.patients[index].first_name} ${this.patients[index].last_name}?`;

      this.confirmDeleteService.openConfirmDialog(content)
        .subscribe(res => {
          if (res) {
            this.remove(index);
          }
        })
    } else {
      this.addToast('Client not found', 'error');
    }
  }

  remove(idx: number): void {
    this.userService.removeObj(this.patients[idx])
      .then((res) => {
        this.patients.splice(idx, 1);
        this.addToast('Client was deleted.', 'Success');
      })
      .catch((err) => {
        if (typeof err === 'object' && err.status === HttpErrorCodes.UNAUTHORIZED) {
          this.addToast('You do not have permission to remove this client...', 'Error');
        } else {
          this.addToast('Could not delete client, please try again...', 'Error');
        }
        throw err;
      });
  }

  subscribeToClients(): void {
    this.subscriptions.push(this.patientViewService.currentSubjects.subscribe(
      (filtered: User[]) => {
        this.patients = filtered;
      }));
  }

  findClientDetail(): void {

    if (this.patients && this.client_details) {
      for (const patient of this.patients) {
        const detail = this.client_details.find(pd => pd.user == patient.id);

        if (detail != null) {
           patient.details = detail;
        }
      }
    }
  }

  subscribeToClientDetails(): void {
    this.subscriptions.push(this.patientDetailViewService.currentSubjects.subscribe(
      (client_details: PatientDetails[]) => {
        this.client_details = client_details;
        if (this.patients) {
          this.findClientDetail();
        }
      }));
  }

  //TODO: make other list pages use this
  subscribeToRefreshSubjects(): void {
    this.subscriptions.push(this.refreshSubjects.subscribe(
      (refresh: boolean) => {
        if (refresh) {
          this.loading = true;
          this.getClients();
        }
      }, (err) => {
        this.loading = false;
      }));
  }
  getPatientDetails(): void {
    this.patientDetailViewService.getSubjects();
  }
  // getPatientDetails$(): Observable<any> {
  //   this.patientDetailViewService.getSubjects();
  // }

  getStaffUsers(): void {
    this.getAllUsers().subscribe(
      (users) => {
        this.users_list = users.filter(u => !u.is_hidden);
        this.staffData = this.users_list.map(user => ({
          value: user.id + '',
          text: user.display_name
        }))
      })
  }

  filterChange(filter: string, key: string): void {
    filter = filter.toLowerCase();
    switch(key) {
      case 'region': {
        this.regionData = this.regions.filter(region =>
          region.text.toLowerCase().includes(filter)
        );
        break;
      }
      case 'staff': {
        this.staffData = this.users_list
          .filter(user =>
            user.display_name.toLowerCase().includes(filter)
          )
          .map(user => ({
            value: user.id + '',
            text: user.display_name
          }));
          break;
      }
      default: break;
    }
  }

  ngOnInit() {
    this.loading = true;
    this.subscriptions = [];
    this.patients = [];
    this.users_list = [];
    this.getClients();
    this.getStaffUsers();
    this.subscribeToClients();
    this.subscribeToClientDetails();
    this.subscribeToRefreshSubjects();
    this.showNavService.set(true);
    this.regionData = _.cloneDeep(this.regions);
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

}
