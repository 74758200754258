import {Base} from './base.model';

export class Note extends Base<Note> {
  id: number;
  created: string;
  updated: string;
  archived: boolean;
  type: string;
  text: string;
  object_id: number;
  created_by: number;
  last_updated_by: number;
  patient: number;
  content_type: string;
  created_date: Date;

  constructor() {
    super(Note);
  }
}
