import { Injectable } from '@angular/core';
import {IBaseViewService} from '../interfaces/base-view.interface';
import {Session} from '../../core/models/session.model';
import {SessionService} from '../../core/services/session.service';
import {Observable, BehaviorSubject, throwError} from 'rxjs';
import {ServicePlan} from '../../core/models/service-plan.model';
import {HttpErrorCodes} from '../../core/shared/enum';

@Injectable()
export class SessionViewService implements IBaseViewService<Session> {

  currentSubjectType: BehaviorSubject<string> = new BehaviorSubject('');

  currentSubject: BehaviorSubject<Session> = new BehaviorSubject(new Session());
  currentSubjects: BehaviorSubject<Session[]> = new BehaviorSubject([]);

  constructor(private sessionService: SessionService) { }

  search(status?: string, search_text?: string): Promise<Session[]> {
    return undefined;
  }

  getSubjects(): void {
    throw new Error('need to implement');
  }

  save(): Promise<any> {
    const session = this.currentSubject.getValue();

    return this.sessionService.save(session)
      .then((ret_val) => {
        this.currentSubject.next(ret_val);
        return ret_val;
      })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  /**
   * get the service plan by param, and do anything special for the UI here
   * @param {string} value
   * @param {string} key
   * @returns {Promise<ServicePlan>}
   */
  getByPatient(value: string): Promise<Session[]> {
    return this.sessionService.getByParamPromise(value, 'patient')
      .then((sessions: Session[]) => {
        return sessions;
      })
      .catch(
        err => {
          return throwError(err).toPromise();
        })
  }

  /**
   * get the service plan by param, and do anything special for the UI here
   * @param {string} value
   * @param {string} key
   * @returns {Promise<ServicePlan>}
   */
  getByProcedure(value: string): Promise<Session[]> {
    return this.sessionService.getByParamPromise(value, 'procedure')
      .then((sessions: Session[]) => {
        return sessions;
      })
      .catch(
        err => {
          return throwError(err).toPromise();
        })
  }


  update(): Promise<any> {
    return undefined;
  }


  /**
   * deleting the session object
   *
   * @param {Session} session
   * @returns {Promise<boolean>}
   */
  removeSession(session: Session): Promise<boolean> {
    return this.sessionService.removeObj(session)
      .then((res) => {
        return true
      })
      .catch((err) => {
        return throwError(err).toPromise();
      });
  }

  getById(id: number): Promise<Session> {
    return undefined;
  }
}
