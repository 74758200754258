import {Injectable} from '@angular/core';
import {IBaseViewService} from '../interfaces/base-view.interface';
import {PatientDetails} from '../../core/models/patient-details.model';
import {Behaviour} from '../../core/models/behaviour.model';
import {BehaviourReductionService} from '../../core/services/behaviour-reduction.service';
import {throwError, BehaviorSubject, Observable} from 'rxjs';
import {PatientDetailService} from '../../core/services/patient-detail.service';
import {tap} from 'rxjs/operators';

@Injectable()
export class PatientDetailViewService implements IBaseViewService<PatientDetails> {

  currentSubjectType: BehaviorSubject<string> = new BehaviorSubject('');
  currentSubject: BehaviorSubject<PatientDetails> = new BehaviorSubject(new PatientDetails());
  currentSubjects: BehaviorSubject<PatientDetails[]> = new BehaviorSubject([]);

  behaviours: Behaviour[];

  constructor(private behaviourReductionService: BehaviourReductionService,
              private patientDetailService: PatientDetailService) {
  }

  processArray(array, fn): Promise<any> {
    const that = this;
    return array.reduce(function (p, item) {
      return p.then(function () {
        return fn.call(that, item);
      });
    }, Promise.resolve());
  }

  getBehaviours(id: number): Promise<Behaviour[]> {
    const params = {
      patient: id
    };
    return this.behaviourReductionService.getByParamObjPromise(params)
      .then((behaviours) => {
        // return list of behaviours after it has finished
        return behaviours;
      })
      .catch((err) => throwError(err).toPromise());
  }

  saveBehaviours(behaviours: Behaviour[]): Promise<Behaviour[]> {
    this.behaviours = []
    return this.processArray(behaviours, this.saveBehaviour)
      .then((last_behaviour) => {
        // return list of behaviours after it has finished
        return this.behaviours;
      })
      .catch((err) => throwError(err).toPromise());
  }

  saveBehaviour(behaviour: Behaviour): Promise<Behaviour> {
    return this.behaviourReductionService.save(behaviour)
      .then((result: Behaviour) => {
        this.behaviours.push(Object.assign(result));
        return result;
      })
      .catch((err) => throwError(err).toPromise());
  }

  removeBehaviours(behaviours: Behaviour[]): Promise<Behaviour> {
    const promise = this.processArray(behaviours, this.removeBehaviour);
    return promise
      .then((result: Behaviour) => {
        // result should be empty or a boolean
        return result;
      })
      .catch((err) => throwError(err).toPromise());
  }

  removeBehaviour(behaviour: Behaviour): Promise<boolean> {
    return this.behaviourReductionService.removeObj(behaviour)
      .then((result: boolean) => {
        return result;
      })
      .catch((err) => throwError(err).toPromise());
  }

  save(): Promise<any> {
    return undefined;
  }

  update(): Promise<any> {
    return undefined;
  }

  getByPatient(id: string): Promise<PatientDetails[]> {
    return undefined;
  }

  getSubjects(): void {
    this.patientDetailService.getAllPromise()
      .then((patientDetails: PatientDetails[]) => {
        this.currentSubjects.next(patientDetails);
      })
      .catch((err) => {
        console.log(err);
      })
  }
  getSubjects$(): Observable<PatientDetails[]> {
    return this.patientDetailService.getAll()
      .pipe(
        tap((patientDetails: PatientDetails[]) => {
          this.currentSubjects.next(patientDetails);
        })
      );
  }

  search(status?: string, search_text?: string): Promise<PatientDetails[]> {
    return undefined;
  }

  getById(id: number): Promise<PatientDetails> {
    return undefined;
  }
}
