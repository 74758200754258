import {Base, IBaseModel} from './base.model';

export class SessionTrial extends Base<SessionTrial> {
  procedure_session: number;
  procedure_step: number;
  value: string;
  count: number;
  _order: number;
  timestamp: Date;
  earliest: Date;
  is_updated: boolean;
  is_deleted: boolean;
  to_delete: boolean;
  proc_item: number;

  constructor() {
    super(SessionTrial);
  }
}
