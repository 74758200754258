import {AfterViewChecked, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {UserViewService} from '../services/user-view.service';
import {User} from '../../core/models/user.model';
import {SelectOption} from '../../core/models/select-option.model';
import {ConstantsService} from '../../core/services/constants.service';
import {NgForm} from '@angular/forms';
import {CurrentFormService} from '../../core/services/current-form.service';
import {AppValidationMessages} from '../app.messages';
import {Subscription} from 'rxjs/index'
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {ParentDetail} from '../../core/models/parent-detail.model';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
  detailError = 'Cannot assign inactive users.';
  choices: Map<any, any>;
  relationship_types: SelectOption[];
  users_list: User[];
  user: User;
  subscriptions: Subscription[];
  user_has_email: boolean;
  update_user: boolean;
  readonly: boolean = true;
  relationship_data: SelectOption[];
  private DEFAULT_TITLE = 'Error';

  @ViewChild('addUserForm') public currentForm: any;
  addUserForm: NgForm;
  error_message: string;
  formErrors = {};


  constructor(private userViewService: UserViewService,
              private constantsService: ConstantsService,
              private currentFormService: CurrentFormService,
              private toastyService: ToastyService) {

  }

  checkUpdateUser(): void {
    this.user.update_user = this.user.email !== '';
  }

  updateUser(): void {
    this.update_user = true;
    this.userViewService.currentSubject.next(this.user);
  }

  getUserList(): void {
    this.userViewService.getAddUserList().subscribe(
      (users) => {
        this.users_list = users.filter(u => !u.is_hidden);
      })
  }

  getChoices(): void {
    if (!this.constantsService.choices || this.constantsService.choices.size === 0) {
      this.constantsService.getChoices().then(
        (choices: Map<string, any>) => {
          this.choices = choices.get('global');
          this.relationship_types = this.choices.get('relationship_type');
          const relationship_desc = choices.get('parentdetail').get('relationship_desc');
          this.relationship_data = relationship_desc.slice();
        });
    } else {
      this.choices = this.constantsService.choices.get('global');
      this.relationship_types = this.choices.get('relationship_type');
      const relationship_desc = this.constantsService.choices.get('parentdetail').get('relationship_desc');
      this.relationship_data = relationship_desc.slice();
    }
  }

  subscribeToErrorMessage(): void {
    this.subscriptions.push(this.currentFormService.currentErrorMessage.subscribe(
      (message: any) => {
        if (message && Object.keys(message).length > 0) {
          const keys = Object.keys(message);
          for (const key of keys) {
            if (key === 'detail') {
              this.addToast(this.detailError, 'Error');
            }
          }
        }
      }));
  }

  unsubscribe(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  formChange(): void {
    if (this.currentForm === this.addUserForm) {
      return;
    }

    this.addUserForm = this.currentForm;
    if (this.addUserForm) {
      this.addUserForm.valueChanges
        .subscribe(data => this.onValueChanged());
    }
  }

  onValueChanged() {
    if (!this.addUserForm) {
      return;
    }
    const form = this.addUserForm.form;

    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }
  }

  submitForm() {
    // just a dummy function for the moment
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = this.currentForm.form.get(field);

      if (control && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }
  }

  addToast(message: string, title: string) {
    // Or create the instance of ToastOptions
    const toastOptions = this.getToastOptions(message, title);
    // // Add see all possible types in one shot
    // this.toastyService.info(toastOptions);
    // this.toastyService.success(toastOptions);
    // this.toastyService.wait(toastOptions);
    // this.toastyService.error(toastOptions);
    // this.toastyService.warning(toastOptions);

    switch (title.toLowerCase()) {
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
    }
  }

  getToastOptions(message: string, title: string): ToastOptions {
    title = title != null ? title : this.DEFAULT_TITLE;

    return {
      title: title,
      msg: message,
      showClose: true,
      timeout: 5000,
      theme: 'default'
    };
  }


  ngAfterViewChecked(): void {
    this.formChange();
  }

  ngOnInit() {
    this.users_list = [];
    this.user = this.userViewService.currentSubject.getValue();
    if (!this.user) {
      this.user = new User();
      this.readonly = false;
      this.update_user = false;
    } else {
      this.user.update_child_link = true;
      this.update_user = true;
    }
    this.user_has_email = true;
    this.subscriptions = [];
    this.getChoices();
    this.getUserList();
    //this.subscribeToErrorMessage();
    this.currentFormService.currentSubject.next(this.currentForm);
  }

  ngOnDestroy(): void {
    this.updateUser();
    this.unsubscribe();
    this.currentFormService.currentSubject.next(null);
    this.currentFormService.currentErrorMessage.next(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  checkHasEmail(event: any): void {
    this.user.update_user = false;
    this.update_user = true;
    if (event != null) {
      let user: User;
      if (typeof event === 'string') {
        user = this.users_list.find(ul => ul.id == +event);
      } else {
        user = this.users_list.find(ul => ul.id == event.id);
      }
      this.user = user;
      this.user_has_email = user && user.email != null && user.email !== '';
    }
  }
}
