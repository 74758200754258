import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscreteTrialComponent } from './discrete-trial/discrete-trial.component';
import { TaskAnalysisComponent } from './task-analysis/task-analysis.component';
import {CoreModule} from '../../core/core.module';
import {SharedModule} from '../../shared/shared.module';
import { SessionsComponent } from './sessions.component';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IntlModule} from '@progress/kendo-angular-intl';
import { IncidentalTeachingComponent } from './incidental-teaching/incidental-teaching.component';
import { MultipleExampleInstructionComponent } from './multiple-example-instruction/multiple-example-instruction.component';
import {MatTooltipModule} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { DurationComponent } from './duration/duration.component';
import {ComponentsModule} from '../../core/components/components.module';
import { MultipleExampleInstructionSecondComponent } from './multiple-example-instruction-second/multiple-example-instruction-second.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    IntlModule,
    DateInputsModule,
    DropDownsModule,
    SharedModule,
    MatTooltipModule,
    ComponentsModule
  ],
  declarations: [
    DiscreteTrialComponent,
    TaskAnalysisComponent,
    SessionsComponent,
    IncidentalTeachingComponent,
    MultipleExampleInstructionComponent,
    DurationComponent,
    MultipleExampleInstructionSecondComponent
  ],
  entryComponents: [
    DiscreteTrialComponent,
    TaskAnalysisComponent,
    SessionsComponent,
    IncidentalTeachingComponent,
    MultipleExampleInstructionComponent,
    DurationComponent,
    MultipleExampleInstructionSecondComponent
  ],
  exports: [
    DiscreteTrialComponent,
    TaskAnalysisComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SessionsModule { }
