import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddContactComponent } from './add-contact/add-contact.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';
import {IntlModule} from '@progress/kendo-angular-intl';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {ParentDetailViewService} from './services/parent-detail-view.service';
import { AddServicePlanComponent } from './add-service-plan/add-service-plan.component';
import { AddSapComponent } from './add-sap/add-sap.component';
import {ProcedureViewService} from './services/procedure-view.service';
import {ServicePlanViewService} from './services/service-plan-view.service';
import {UserViewService} from './services/user-view.service';
import {CoreModule} from '../core/core.module';
import { AddSessionComponent } from './add-session/add-session.component';
import {SessionViewService} from './services/session-view.service';
import {CommunicationViewService} from './services/communication-view.service';
import { AddUserComponent } from './add-user/add-user.component';
import { AddCommunicationComponent } from './add-communication/add-communication.component';
import { AddDocumentComponent } from './add-document/add-document.component';
import { PreviewDocumentComponent } from './preview-document/preview-document.component';
import { ListDocumentComponent } from './list-document/list-document.component';
import {DocumentViewService} from './services/document-view.service';
import {SortableModule} from '@progress/kendo-angular-sortable';
import {AssessmentViewService} from './services/assessment-view.service';
import {DailySessionViewService} from './services/daily-session-view.service';
import { AddClientComponent } from './add-client/add-client.component';
import {PatientDetailViewService} from './services/patient-detail-view.service';
import { AddAssessmentComponent } from './add-assessment/add-assessment.component';
import {ErrorComponent} from './error/error.component';
import { FilterComponent } from './filter/filter.component';
import {FilterViewService} from './services/filter-view.service';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTooltipModule} from '@angular/material';
import {TooltipModule} from '@progress/kendo-angular-tooltip';
import {ToastService} from './services/toast.service';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    IntlModule,
    DateInputsModule,
    DropDownsModule,
    CoreModule,
    SortableModule,
    InputsModule,
    MatTooltipModule,
    TooltipModule
  ],
  declarations: [
    AddContactComponent,
    AddServicePlanComponent,
    AddSapComponent,
    AddSessionComponent,
    AddUserComponent,
    AddCommunicationComponent,
    AddDocumentComponent,
    PreviewDocumentComponent,

    ListDocumentComponent,
    AddClientComponent,
    AddAssessmentComponent,
    ErrorComponent,
    FilterComponent,
    ComingSoonComponent
  ],
  entryComponents: [
    AddContactComponent,
    AddServicePlanComponent,
    AddSapComponent,
    AddSessionComponent,
    AddUserComponent,
    AddCommunicationComponent,
    AddDocumentComponent,
    PreviewDocumentComponent,
    ListDocumentComponent,
    AddClientComponent,
    AddAssessmentComponent,
    FilterComponent
  ],
  exports: [
    AddContactComponent,
    AddServicePlanComponent,
    AddSapComponent,
    AddSessionComponent,
    AddUserComponent,
    AddCommunicationComponent,
    AddDocumentComponent,
    PreviewDocumentComponent,
    ListDocumentComponent,
    AddClientComponent,
    AddAssessmentComponent,
    FilterComponent
  ],
  providers: [
    ParentDetailViewService,
    ProcedureViewService,
    ServicePlanViewService,
    UserViewService,
    SessionViewService,
    CommunicationViewService,
    DocumentViewService,
    AssessmentViewService,
    DailySessionViewService,
    PatientDetailViewService,
    FilterViewService,
    ToastService
  ]
})
export class SharedModule { }
