import {Base, IBaseModel} from './base.model';

export class MasteryCriteria extends Base<MasteryCriteria> {
  procedure: number;
  _order: number;
  phase_type: string;
  people: number;
  settings: number;
  accuracy_to: string;
  accuracy_from: string;
  sessions: number;
  num_responses: number;
  max_errors: number;
  response_interval: number;
  mastery_criteria_required: string[];
  mastery_criteria_allowed: string[];
  accuracy_calc_field: string;
  min_trials_per_person: number;

  constructor() {
    super(MasteryCriteria);
    this.required_fields = [
      'phase_type',
      'sessions'
    ];
  }
}
