import {Component, Injectable, Input, OnInit} from '@angular/core';
import {Subscription, BehaviorSubject} from 'rxjs'
import {BaseComponent} from '../base/base.component';
import {DialogService} from '@progress/kendo-angular-dialog';
import {ToastData, ToastOptions, ToastyService} from 'ng2-toasty';
import {ConstantsService} from '../../core/services/constants.service';
import {UserViewService} from '../services/user-view.service';
import {DailySessionViewService} from '../services/daily-session-view.service';
import {CurrentFormService} from '../../core/services/current-form.service';
import {SelectOption} from '../../core/models/select-option.model';
import {SheetData} from '../../core/models/sheet-info.model';
import {TherapySession} from '../../core/models/therapy-session.model';
import {InitialTactic} from '../../core/models/initial-tactic.model';
import {ProcedureViewService} from '../services/procedure-view.service';
import {SessionTrial} from '../../core/models/session-trial.model';
import {ProcedureSession} from '../../core/models/procedure-session.model';
import {ProcItem} from '../../core/models/proc-item.model';

@Injectable()
export abstract class BaseSessionComponent extends BaseComponent {
  sessionSave: Subscription;
  sessionSaving: Subscription;
  saving_trial: boolean;
  cmp_name: string;
  therapy_session: TherapySession;
  proc_items: ProcItem[];
  max_row_num: number;
  current_group: string;
  counter = Array; // we get a reference to the Array object so we can use it in the view


  subscriptions: Subscription[];
  stages: SelectOption[];
  prompt_types: SelectOption[];
  daily_session_trial_options: SelectOption[];
  proc_items_to_show: string[] = ['baseline', 'training'];
  saving_proc_session: Map<number, BehaviorSubject<number>>;
  initial_tactics: InitialTactic[];

  // services
  dailySessionViewService: DailySessionViewService;

  constructor(constantsService: ConstantsService,
              userViewService: UserViewService,
              dialogService?: DialogService,
              dailySessionViewService?: DailySessionViewService,
              currentFormService?: CurrentFormService,
              toastyService?: ToastyService,
              private procedureViewService?: ProcedureViewService) {
    super(constantsService, userViewService, dialogService, dailySessionViewService, currentFormService, toastyService);
    this.dailySessionViewService = dailySessionViewService;
  }

  public abstract saveProcedureSession(idx: number): Promise<number | void>;


  processArray(array, fn): Promise<any> {
    const that = this;

    return array.reduce(function (p, item) {
      return p.then(function () {
        return fn.apply(that, item);
      });
    }, Promise.resolve());
  }

  trialClick(trial: SessionTrial, procedure_session?: ProcedureSession, trial_idx?: number, proc_item_idx?: number, proc_sesh_idx?: number): void {
    // if the procedure session doesn't exist yet, then add it
    // function to save procedure returns the id
    let promise: Promise<number | void>;
    if (!procedure_session.id && !this.saving_proc_session.has(proc_sesh_idx)) {

      if (procedure_session.state_group) {
        procedure_session.state_group = this.current_group;
      }
      promise = this.saveProcedureSession(proc_sesh_idx);
      this.saving_proc_session.set(proc_sesh_idx, new BehaviorSubject<number>(null));
    } else if (!procedure_session.id && this.saving_proc_session.has(proc_sesh_idx)) {
      promise = this.saving_proc_session.get(proc_sesh_idx).toPromise();
    } else {
      promise = Promise.resolve(procedure_session.id);
    }

    promise.then((id: number) => {
      procedure_session.id = id;

      if (this.saving_proc_session.has(proc_sesh_idx)) {
        this.saving_proc_session.delete(proc_sesh_idx);
      }
      // this.procedure_sessions[proc_sesh_idx].id = id;
      let dailySessions: Map<number, SessionTrial[]> = this.dailySessionViewService.dailySessions.getValue();
      if (dailySessions == null) {
        dailySessions = new Map<number, SessionTrial[]>();
      }

      // if (!this.trial_timeout_map.has(procedure_session.id)) {
      //   this.trial_timeout_map.set(procedure_session.id, new Map<number, any[]>());
      // }

      // if the trial count is -1, then we are looking at Duration or some other sheet that doesn't use the Tick, cross, P for the trial data
      if (trial.count == null) {
        trial.count = 0;
      } else if (trial.count === 2) {
        trial.count = null;
      } else if (trial.count !== -1) {
        trial.count = (trial.count + 1) % 3;
      }

      if (trial.count != null && trial.count > -1) {
        trial.value = this.daily_session_trial_options[trial.count].value;
      } else if (trial.count == null) {
        trial.value = null;
      }
      if (proc_item_idx != null) {
        // because of how the session trials are laid out, each of the items have 5 rows
        // so the index is based on the procedure item and there can be a max of 5 rows for each - then the trial is based on it's idx
        // this is taken from the list of numbers in session_trial_index (integer from 0 - 5)
        trial._order = (proc_item_idx * this.max_row_num) + trial_idx;
        if (this.proc_items && this.proc_items.length > 0) {
          trial.proc_item = this.proc_items[proc_item_idx].id;
        }
      } else {
        trial._order = trial_idx;
      }

      // // here we set the timestamps to check we have saved everything
      // trial.timestamp = moment().toDate();
      // if (!trial.earliest) {
      //   this.saveTrial(trial, procedure_session, index, procedure_session_idx)
      // }

      if (!dailySessions.has(procedure_session.id)) {
        // if we don't have the object in the map then we create it
        // if we have proc_items, then we use this to create the map (MEI), otherwise we use the session_trials
        if (this.proc_items != null) {
          dailySessions.set(procedure_session.id, new Array(this.proc_items.length * this.max_row_num));
          dailySessions.get(procedure_session.id)[(proc_item_idx * this.max_row_num) + trial_idx] = trial;
        } else {
          dailySessions.set(procedure_session.id, new Array(procedure_session.session_trials.length));
          dailySessions.get(procedure_session.id)[proc_sesh_idx] = trial;
        }
      }

      // TODO: need to resize the array if the user adds more trials after doing edits
      this.dailySessionViewService.dailySessions.next(dailySessions);
    });
  }

  setTherapySessionStateId(sheetData: SheetData, proc_session_id: number): void{
    if (proc_session_id == null || sheetData == null || this.therapy_session.state_sheet_meta == null) {
      return;
    }
    const idx = this.therapy_session.state_sheet_meta.data.findIndex(d =>
      d.data_sheet_code == sheetData.data_sheet_code && d.phase_type == sheetData.phase_type &&
      d.item == sheetData.item && d.prompt_step == sheetData.prompt_step && d.phase == sheetData.phase
    );
    if (idx != null) {
      this.therapy_session.state_sheet_meta.data[idx].procedure_session_id = proc_session_id;
      this.therapy_session.ignore_update = true;
    }
  }


  subscribeToChoices(): void {
    this.subscriptions.push(this.choicesSubject.subscribe(
      (choices: Map<string, any>) => {
        if (choices && choices.has('procedure')) {
          this.prompt_types = choices.get('procedure').get('prompt_type');
          this.stages = choices.get('sessionsummary').get('stage');
          this.daily_session_trial_options = choices.get('sessiontrial').get('value');
          // this.phase_types = choices['proceduresession'].get('phase_type');
        }
      }));
  }

  subscribeToSessionSave(): void {
    if (this.sessionSave ) return;

    this.sessionSave = this.dailySessionViewService.incrementalSave()
      .pipe(

      )
      .subscribe(() => {
      // empty subscription
    }, err => {
      console.log('error in base session component', err);
    });
  }

  subscribeToSavingSessions(): void {
    if (this.sessionSaving) return;

    this.sessionSaving = this.dailySessionViewService.savingSessions.subscribe((is_saving: boolean) => {
      // empty subscription
      if (is_saving) {
        this.saving_trial = is_saving;
        this.addToast('Saving...', 'saving');
      }
    });
  }

  subscribeToInitialTactics(): void {
    if (!this.procedureViewService) {
      this.initial_tactics = [];
      return;
    }
    this.procedureViewService.getAllInitialTactics$()
      .subscribe(
        (initial_tactics: InitialTactic[]) => {
          this.initial_tactics = initial_tactics;
        }, (err) => {
          console.log(err);
          // TODO: inform user
        });
  }

}
