import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectOption} from '../../models/select-option.model';
import {ProcedureSession} from '../../models/procedure-session.model';
import {InitialTactic} from '../../models/initial-tactic.model';

@Component({
  selector: 'app-prompt-steps',
  templateUrl: './prompt-steps.component.html',
  styleUrls: ['./prompt-steps.component.css'],
  providers: [
    // {provide: NG_VALIDATORS, useExisting: PromptStepsComponent, multi: true},
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PromptStepsComponent), multi: true}
  ]
})
export class PromptStepsComponent implements OnInit, OnChanges, ControlValueAccessor {
  TACTIC_ERRORLESS_CRITERION = 'errorless_criterion';
  private _disabled = new BehaviorSubject<boolean>(false);
  private _prompt_steps = new BehaviorSubject<SelectOption[]>([]);
  private _name = new BehaviorSubject<string>('');
  private _session = new BehaviorSubject<ProcedureSession>(null);
  private _phase_type = new BehaviorSubject<string>(null);
  private _phase_types = new BehaviorSubject<any[]>([]);
  private _initial_tactic = new BehaviorSubject<InitialTactic>(null);
  private _prompt_description = new BehaviorSubject<string>(null);
  @Input() _currentSelection: string;
  emptyDataSelection = '';
  public defaultItem: { step: string, id: number } = { step: 'Select item...', id: null };

  propagateChange = (_: any) => {};

  constructor() {
  }

  @Input()
  set disabled(value: any) {
    this._disabled.next(value);
  }

  get disabled() {
    return this._disabled.getValue();
  }

  @Input()
  set name(name: any) {
    this._name.next(name);
  }

  get name() {
    return this._name.getValue();
  }

  @Input()
  set prompt_steps(prompt_steps: any) {
    this._prompt_steps.next(prompt_steps);
  }

  get prompt_steps() {
    return this._prompt_steps.getValue();
  }

  @Input()
  set session(session: any) {
    this._session.next(session);
  }

  get session() {
    return this._session.getValue();
  }

  @Input()
  set initial_tactic(value) {
    this._initial_tactic.next(value);
  }

  get initial_tactic() {
    return this._initial_tactic.getValue();
  }

  @Input()
  set prompt_description(value) {
    this._prompt_description.next(value);
  }

  get prompt_description() {
    return this._prompt_description.getValue();
  }

  set currentSelection(value) {
    this._currentSelection = value;
    this.propagateChange(this._currentSelection);
  }

  get currentSelection() {
    return this._currentSelection;
  }

  isBaseline(): boolean {
    return this.session && this.session.stage == 'baseline';
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(value: any): void {
    this.currentSelection = value;
  }

  @Output('valueChange') valueChange = new EventEmitter();


  ngOnInit() {
  }

  updateChanges() {
  }

}
