import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AccessGuard} from '../core/guards/access.guard';
import {PatientComponent} from './patient.component';
import {CommunicationsComponent} from './communications/communications.component';
import {ContactsComponent} from './contacts/contacts.component';
import {ProgramSupervisionComponent} from './program-supervision/program-supervision.component';
import {TrainingComponent} from './training/training.component';
import {SapsComponent} from './saps/saps.component';
import {BehaviourInterventionsComponent} from './behaviour-interventions/behaviour-interventions.component';
import {ReportsComponent} from './reports/reports.component';
import {AssessmentsComponent} from './assessments/assessments.component';
import {DetailsComponent} from './details/details.component';
import {ServicePlanComponent} from './service-plan/service-plan.component';
import {DocumentsComponent} from './documents/documents.component';
import {SessionsComponent} from './sessions/sessions.component';

const routes: Routes = [
  { path: 'patient/:id', redirectTo: '/patient/:id/details', pathMatch: 'full' },
  {
    path: 'patient/:id',
    component: PatientComponent,
    data: {
      requiresLogin: false // TODO: need to change this to true once we have login stuff
    },
    canActivate: [AccessGuard],
    children: [
      {
        path: 'details',
        component: DetailsComponent,
        data: {
          requiresLogin: true
        },
        canActivate: [
          AccessGuard
        ]
      },
      {
        path: 'program-plan/:id',
        component: ServicePlanComponent,
        data: {
          requiresLogin: true
        },
        canActivate: [
          AccessGuard
        ]
      },
      {
        // path: 'program-plan/:id',
        path: 'program-plan',
        component: ServicePlanComponent,
        data: {
          requiresLogin: true
        },
        canActivate: [
          AccessGuard
        ]
      },
      {
        path: 'assessments',
        component: AssessmentsComponent,
        data: {
          requiresLogin: true
        },
        canActivate: [
          AccessGuard
        ]
      },
      {
        path: 'reports',
        component: ReportsComponent,
        data: {
          requiresLogin: true
        },
        canActivate: [
          AccessGuard
        ]
      },
      {
        path: 'procedures',
        component: SapsComponent,
        data: {
          requiresLogin: true
        },
        canActivate: [
          AccessGuard
        ]
      },
      {
        path: 'behaviour-interventions',
        component: BehaviourInterventionsComponent,
        data: {
          requiresLogin: true
        },
        canActivate: [
          AccessGuard
        ]
      },
      {
        path: 'training',
        component: TrainingComponent,
        data: {
          requiresLogin: true
        },
        canActivate: [
          AccessGuard
        ]
      },
      {
        path: 'program-supervision',
        component: ProgramSupervisionComponent,
        data: {
          requiresLogin: true
        },
        canActivate: [
          AccessGuard
        ]
      },
      {
        path: 'contacts',
        component: ContactsComponent,
        data: {
          requiresLogin: true
        },
        canActivate: [
          AccessGuard
        ]
      },
      {
        path: 'communications',
        component: CommunicationsComponent,
        data: {
          requiresLogin: true
        },
        canActivate: [
          AccessGuard
        ]
      },
      {
        path: 'documents',
        component: DocumentsComponent,
        data: {
          requiresLogin: true
        },
        canActivate: [
          AccessGuard
        ]
      },
      {
        path: 'data-entry',
        component: SessionsComponent,
        data: {
          requiresLogin: true
        },
        canActivate: [
          AccessGuard
        ]
      }
    ]
  }
  // { path: '**', redirectTo: '/patient/details', pathMatch: 'full' }
  // {
  //   path: 'details',
  //   component: DetailsComponent,
  //   data: {
  //     requiresLogin: false // TODO: need to change this to true once we have login stuff
  //   },
  //   canActivate: [AccessGuard]
  // }
];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  declarations: [],
  exports: [
    RouterModule
  ],
})
export class PatientRoutingModule { }
