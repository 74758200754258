import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subscription, throwError} from 'rxjs';
import {UserViewService} from '../../../shared/services/user-view.service';
import {BaseSessionComponent} from '../../../shared/base-session/base-session.component';
import {DialogService} from '@progress/kendo-angular-dialog';
import {ConstantsService} from '../../../core/services/constants.service';
import {DailySessionViewService} from '../../../shared/services/daily-session-view.service';
import {ProcedureService} from '../../../core/services/procedure.service';
import {PatientService} from '../../../core/services/patient.service';
import {CurrentFormService} from '../../../core/services/current-form.service';
import {ShowNavService} from '../../../core/services/show-nav.service';
import {UserService} from '../../../core/services/user.service';
import {DataSheetService} from '../../../core/services/data-sheet.service';
import {ToastyService} from 'ng2-toasty';
import {FilterViewService} from '../../../shared/services/filter-view.service';
import {ProcedureViewService} from '../../../shared/services/procedure-view.service';
import {CommonService} from '../../../core/shared/common/common.service';
import {TherapySession} from '../../../core/models/therapy-session.model';
import * as moment from 'moment';
import {ProcedureSession} from '../../../core/models/procedure-session.model';
import {Procedure} from '../../../core/models/procedure.model';
import * as _ from 'lodash';
import {SessionTrial} from '../../../core/models/session-trial.model';
import {SessionData} from '../../../core/models/session-data.model';
import {DataSheet} from '../../../core/models/data-sheet.model';
import {SelectOption} from '../../../core/models/select-option.model';
import {SdResponse} from '../../../core/models/sd-response.model';
import {ProcItem} from '../../../core/models/proc-item.model';
import {catchError, concatMap, map} from 'rxjs/operators';
import {PromptFadingStep} from '../../../core/models/prompt-fading-step.model';

@Component({
  selector: 'app-multiple-example-instruction-second',
  templateUrl: './multiple-example-instruction-second.component.html',
  styleUrls: ['./multiple-example-instruction-second.component.css']
})
export class MultipleExampleInstructionSecondComponent extends BaseSessionComponent implements OnInit, OnDestroy {
  @Input() data_sheet_type: string;
  private _data_sheets = new BehaviorSubject<any[]>([]);
  private _patient = new BehaviorSubject<any>({});
  private _sheet_info = new BehaviorSubject<any>({});
  private _is_readonly: boolean;
  private _readonly_data: any;
  private _userViewService: UserViewService;

  // services
  dailySessionViewService: DailySessionViewService;
  currentFormService: CurrentFormService;
  _procedureViewService: ProcedureViewService;

  // variables
  DELIMITER = '&';
  MAX_NUM_ITEMS = 4; // max number of items is 4
  DEFAULT_SESSION_NUM = 5; // max number of trials per item (as a requirement, there are 5 trials per item)
  proc_item_ids: number[];
  current_prompt_description: string;
  current_procedure: Procedure;
  current_procedure_id: number;
  data_sheet: DataSheet;
  error_message = '';
  showCollectDataButton = false;
  stage: string;
  prompt_step: number;
  phase: number;
  loading_session = false;
  dailySessionsSavedSubscription: Subscription;
  therapySessionSubscription: Subscription;

  procedures: Procedure[];
  procedure_session_defaults: any[];
  procedure_sessions: ProcedureSession[];
  // all procedures sessions for the procedure, this doesn't filter by group
  // This just stores the defaults for the saved_procedure_sessions to override
  all_procedure_sessions: ProcedureSession[];
  saved_procedure_sessions: ProcedureSession[];
  group_ids = [];
  session_trial_index: number[];
  sd_responses: SdResponse[];
  item_groups: string[];
  stages: SelectOption[];
  groups: SelectOption[];
  prompt_steps: PromptFadingStep[];
  readonly_sessions: Map<string, any[]>; // need to keep track of the items index, since different sessions might have different items
  phase_type_tactics_map: Map<number, any[]>;
  isPhaseError: boolean;

  constructor(dialogService: DialogService,
              constantsService: ConstantsService,
              dailySessionViewService: DailySessionViewService,
              userViewService: UserViewService,
              private procedureService: ProcedureService,
              private patientService: PatientService,
              currentFormService: CurrentFormService,
              private showNavService: ShowNavService,
              private userService: UserService,
              private dataSheetService: DataSheetService,
              toastyService: ToastyService,
              private filterViewService: FilterViewService,
              procedureViewService: ProcedureViewService,
              private commonService: CommonService) {
    super(constantsService, userViewService, dialogService, dailySessionViewService,
      currentFormService, toastyService, procedureViewService);
    this.dailySessionViewService = dailySessionViewService;
    this.currentFormService = currentFormService;
    this._userViewService = userViewService;
    this._procedureViewService = procedureViewService;
  }

  @Input()
  set data_sheet_list(data: any[]) {
    this._data_sheets.next(data);
  }

  get data_sheets() {
    return this._data_sheets;
  }

  @Input()
  set patient(obj: any) {
    this._patient.next(obj);
  }

  get patient() {
    return this._patient;
  }

  @Input()
  set sheet_info(obj: any) {
    this._sheet_info.next(obj);
  }

  get sheet_info() {
    return this._sheet_info;
  }

  @Input()
  set is_readonly(readonly: boolean) {
    this._is_readonly = readonly;
  }

  get is_readonly() {
    return this._is_readonly;
  }

  @Input()
  set data(readonly_data: any) {
    this._readonly_data = readonly_data;
  }

  get data() {
    return this._readonly_data;
  }

  getDataSheet(data_sheets: DataSheet[]): void {
    this.data_sheet = data_sheets.find(ds => ds.code.toLowerCase() == this.data_sheet_type.toLowerCase());
  }

  /**
   * This function relies on the therapy_session object to be set, otherwise we won't show any data since we
   * can't get the meta object
   *
   */
  private setSessionDetaults() {
    const state_sheet_meta = this.therapy_session.state_sheet_meta;
    if (state_sheet_meta != null) {
      this.procedure_session_defaults = state_sheet_meta.data.filter(d => d.data_sheet_code == this.data_sheet_type);
    }
  }

  private getProceduresForTherapySession(): Observable<boolean> {
    let observable: Observable<Procedure[]>;
    const procedure_ids = this.procedure_session_defaults.reduce((arr, curr) => {
      if (arr.indexOf(curr.procedure) == -1) {
        arr = [...arr, curr.procedure];
      }
      return arr;
    }, []);
    if (procedure_ids.length === 0) {
      observable = of([]);
    } else {
      observable = this._procedureViewService.getProceduresByIds$(procedure_ids);
    }
    return observable
      .pipe(
        map((procedures) => {
          this.procedures = procedures;
          // let's stop loading for now
          // TODO: remove the below as we haven't finished getting stuff yet
          this.loading = false;
          return this.loading;
        }), catchError(err => {
          this.loading = false;
          throw err;
        })
      );
  }

  private getProcedureSessions(): Observable<boolean> {
    return this.dailySessionViewService.getProcedureSessionByTherapySessionAndProcedureAndDataSheet$(
      this.therapy_session.id, this.current_procedure.id, this.data_sheet.id, true
    )
      .pipe(
        map(procedure_sessions => {
          this.saved_procedure_sessions = procedure_sessions.slice();
          // so we return if there are any procedure sessions since we want to show the user a button to create them
          // to start the session
          return this.saved_procedure_sessions.length > 0;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  private getProcedureSessionsByGroup(): Observable<boolean> {
    return this.dailySessionViewService.getProcedureSessionByTherapySessionAndProcedureAndDataSheet$(
      this.therapy_session.id, this.current_procedure.id, this.data_sheet.id, true
    )
      .pipe(
        map(procedure_sessions => {
          this.saved_procedure_sessions = procedure_sessions.filter(ps => ps.state_group == this.current_group);
          // so we return if there are any procedure sessions since we want to show the user a button to create them
          // to start the session
          return this.saved_procedure_sessions.length > 0;
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  private updateGrid(have_saved_sessions?: boolean) {
    if (have_saved_sessions) {
      for (const procedure_session of this.saved_procedure_sessions) {
        const idx = this.procedure_sessions.findIndex(ps => ps.sd == procedure_session.sd && ps.state_group == procedure_session.state_group);
        if (idx > -1) {
          this.procedure_sessions[idx] = _.merge(this.procedure_sessions[idx], procedure_session);
        }
      }
    }
  }

  private getSessionDefaultsForProcedure() {
    if (this.procedure_session_defaults == null || this.procedure_session_defaults.length == 0) {
      return [];
    }
    const session_defaults = this.procedure_session_defaults.reduce((arr, curr) => {
      if (curr.procedure == this.current_procedure_id) {
        arr.push(curr);
      }
      return arr;
    }, []);
    return session_defaults;
  }

  private setDefaults(): void {
    const procedure_sessions: ProcedureSession[] = [];
    const group_ids = [];
    // now we loop over all the defaults we have found
    if (this.procedure_session_defaults && this.procedure_session_defaults.length > 0) {

      const session_defaults = this.getSessionDefaultsForProcedure();
      for (const session_default of session_defaults) {

        // this.sd_responses = session_default.all_sds;

        if (!this.phase_type_tactics_map.has(this.current_procedure.id)) {
          this.phase_type_tactics_map.set(this.current_procedure.id, []);
        }
        this.phase_type_tactics_map.get(this.current_procedure.id)
          .push(session_default.phase_type_tactics || this.current_procedure.phase_type_tactic);

        const procedure_session = new ProcedureSession();
        procedure_session.procedure = this.current_procedure_id;

        if (session_default.warnings && session_default.warnings.length > 0) {
          procedure_session.warning = session_default.warnings.join(', ');
        }

        procedure_session.state_group = session_default.item_group;
        if (procedure_session.state_group != null) {
          group_ids.push(procedure_session.state_group);
        }
        procedure_session.sd_order = session_default.sd_order;

        procedure_session.sd = session_default.sd;
        procedure_session.sd_name = session_default.sd_name;
        procedure_session.phase = session_default.phase;
        procedure_session.proc_item = session_default.item;
        procedure_session.proc_items = session_default.items;
        procedure_session.prompt_step = session_default.prompt_step;
        procedure_session.stage = session_default.stage;
        procedure_session.sd_responses = session_default.all_sds;
        procedure_session.state_items = session_default.state_items;
        procedure_session.state_prompt_steps = session_default.state_prompt_steps;
        procedure_session.state_sds = session_default.state_sds;
        procedure_session.prompt_description = session_default.prompt_description;

        // if (!procedure_session.session_trials || procedure_session.session_trials.length == 0) {
        //   const proc_item_length = this.proc_items != null ? this.proc_items.length : 1;
        //   procedure_session.session_trials = Array
        //     .apply(null, Array((this.max_row_num * proc_item_length)))
        //     .map(() => new SessionTrial());
        // }
        procedure_sessions.push(_.cloneDeep(procedure_session));
      }
    }

    this.group_ids = group_ids.slice();
    this.procedure_sessions = procedure_sessions.slice();
    this.all_procedure_sessions = procedure_sessions.slice();
  }

  private getSessionGroups(): Observable<boolean> {
    // // getting the list of session groups from the ids we got from the session defaults
    // const groups = this.group_ids.reduce((accum, curr) => {
    //   accum.push(curr);
    //   return accum;
    // }, []);
    // this.groups = this.constantsService.convertListToSelctOptions(groups);
    // this.current_group = this.groups.length > 0 ? this.groups[0].value : '';

    let observable: Observable<ProcItem[]>;
    // if we have session_defaults, then we should be able to get the procitems for the current procedure
    const session_defaults = this.getSessionDefaultsForProcedure();

    let proc_items = [];
    for (const session_default of session_defaults) {
      if (session_default.items != null && session_default.items.length > 0) {
        proc_items = [...proc_items, ...session_default.items];
      }
    }
    // now we remove the duplicates
    proc_items = proc_items.reduce((arr, curr) => {
      if (arr.findIndex(a => a.id == curr.id) == -1) {
        arr.push(curr);
      }
      return arr;
    }, []);

    if (proc_items.length == 0) {
      observable = of([]);
    } else {
      const proc_item_ids = proc_items.reduce((arr, curr) => {
        if (arr.indexOf(curr.id) == -1) {
          arr.push(curr.id);
        }
        return arr;
      }, []);
      observable = this.dailySessionViewService.getProcItemsByIds$(proc_item_ids);
    }
    return observable.pipe(
      map((p_items) => {
        const groups = p_items.reduce((accum, curr) => {
          const obj = [curr.group, curr.group];
          const idx = accum.findIndex(a => a[0] == obj[0]);
          if (idx === -1) {
            accum.push(obj.slice());
          }
          return accum;
        }, []);
        this.groups = this.constantsService.convertListToSelctOptions(groups);
        this.current_group = this.groups.length > 0 ? this.groups[0].value : '';
        return true;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  trialClick(trial: SessionTrial, procedure_session?: ProcedureSession, trial_idx?: number,
             proc_item_idx?: number, proc_sesh_idx?: number): void {
    // if the procedure session doesn't exist yet, then add it
    // function to save procedure returns the id
    let promise: Promise<number | void>;
    if (!procedure_session.id && !this.saving_proc_session.has(proc_sesh_idx)) {

      if (procedure_session.state_group) {
        procedure_session.state_group = this.current_group;
      }
      promise = this.saveProcedureSession(proc_sesh_idx);
      this.saving_proc_session.set(proc_sesh_idx, new BehaviorSubject<number>(null));
    } else if (!procedure_session.id && this.saving_proc_session.has(proc_sesh_idx)) {
      promise = this.saving_proc_session.get(proc_sesh_idx).toPromise();
    } else {
      promise = Promise.resolve(procedure_session.id);
    }

    promise.then((id: number) => {
      procedure_session.id = id;

      if (this.saving_proc_session.has(proc_sesh_idx)) {
        this.saving_proc_session.delete(proc_sesh_idx);
      }
      // this.procedure_sessions[proc_sesh_idx].id = id;
      let dailySessions: Map<number, SessionTrial[]> = this.dailySessionViewService.dailySessions.getValue();
      if (dailySessions == null) {
        dailySessions = new Map<number, SessionTrial[]>();
      }

      if (trial.count == null) {
        trial.count = 0;
      } else if (trial.count === 2) {
        trial.count = null;
      } else {
        trial.count = (trial.count + 1) % 3;
      }

      if (trial.count != null) {
        trial.value = this.daily_session_trial_options[trial.count].value;
      } else {
        trial.value = null;
      }
      if (proc_item_idx != null) {
        // because of how the session trials are laid out, each of the items have 5 rows
        // so the index is based on the procedure item and there can be a max of 5 rows for each - then the trial is based on it's idx
        // this is taken from the list of numbers in session_trial_index (integer from 0 - 5)
        trial._order = (proc_item_idx * this.max_row_num) + trial_idx;
        if (this.proc_items && this.proc_items.length > 0) {
          trial.proc_item = this.proc_items[proc_item_idx].id;
        }
      }

      if (!dailySessions.has(procedure_session.id)) {
        // if we don't have the object in the map then we create it
        dailySessions.set(procedure_session.id, new Array(this.proc_items.length * this.max_row_num));
      }

      // TODO: need to resize the array if the user adds more trials after doing edits
      dailySessions.get(procedure_session.id)[(proc_item_idx * this.max_row_num) + trial_idx] = trial;
      this.dailySessionViewService.dailySessions.next(dailySessions);
    });
  }

  private updateSessions(): void {
    for (let i = 0; i < this.procedure_sessions.length; i++) {
      this.procedure_sessions[i].phase = this.phase;
      this.procedure_sessions[i].stage = this.stage;
      this.procedure_sessions[i].prompt_step = this.prompt_step;
      if (this.procedure_sessions[i].phase != null) {
        this.saveProcedureSession(i)
          .then((proc_session_id: number) => {
            if (this.isPhaseError) {
              this.isPhaseError = false;
            }
            console.log('finished saving proc session number: ' + i);
          }).catch((err) => {
            if (this.isPhaseError) {
              this.isPhaseError = false;
            }
            if (err.error) {
              for (let key in err.error) {
                this.addToast(err.error[key][0], 'error');
                if (key === 'phase') {
                  this.isPhaseError = true;
                }
              }
            }
            console.log(err);

            throw err;
          });
      }
    }
  }

  orderProcedureSessionsBySds(): void {
    // let's try using the sd_responses instead
    const procedure_sessions = _.cloneDeep(this.procedure_sessions);
    if (procedure_sessions && procedure_sessions.length > 0) {
      let sd_ids = procedure_sessions[0]['state_sds'];
      if (!sd_ids) {
        sd_ids = procedure_sessions[0]['sd_responses'].map(sr => sr.id);
      }
      const new_procedures = [];
      if (sd_ids != null && sd_ids.length > 0) {
        // const keys = Object.keys(sd_order);
        // if (keys && keys.length > 0) {
        for (const sd of sd_ids) {
          const proc = procedure_sessions.find(p => p.sd == sd);
          if (proc) {
            new_procedures.push(_.cloneDeep(proc));
          }
        }
        // }
      }
      if (new_procedures && new_procedures.length > 0) {
        this.procedure_sessions = new_procedures.slice();
      }
    }
  }

  updateGlobalValues(): void {
    this.stage = this.procedure_sessions.length > 0 ? this.procedure_sessions[0].stage : '';
    this.prompt_step = this.procedure_sessions.length > 0 ? this.procedure_sessions[0].prompt_step : null;
    this.phase = this.procedure_sessions.length > 0 ? this.procedure_sessions[0].phase : null;
  }

  collectData(): void {
    this.loading = true;
    // TODO: go and generate the grid and save the procedure sessions
    this.setDefaults();
    this.filterSessionsByGroup();
    this.setSds();
    this.setPromptSteps();
    this.setProcItems();
    this.setDefaultSessionTrials();
    // now we order the procedure sessions
    this.orderProcedureSessionsBySds();
    this.updateGrid();
    this.updateGlobalValues();

    // now we go and save any procedure sessions
    this.createProcedureSessions()
      .then(res => {
        this.loading = false;

        // last, but not least, show the grid
        this.showCollectDataButton = false;
      }).catch(err => {
      console.log(err);

      throw err;
    });
  }

  /**
   * We only create procedure sessions that don't have an id
   * this is so we can generate proc sessions if they don't exist in previous sessions
   */
  createProcedureSessions(): Promise<any> {
    const idx_list = [];
    for (let i = 0; i < this.procedure_sessions.length; i++) {
      if (this.procedure_sessions[i].id == null) {
        // for some reason, it has to be a list of lists with the index number inside
        idx_list.push([i]);
      }
    }
    return this.processArray(idx_list, this.saveProcedureSession)
      .then(final => {
        return this.procedure_sessions;
      })
      .catch(err => {
        console.log(err);

        throw err;
      });
  }

  saveProcedureSession(idx: number): Promise<number | void> {
    // TODO: remove below line so we can save stuff
    // for now let's skip this so we don't save anyhing
    if (this.procedure_sessions[idx].procedure != null) {
      this.procedure_sessions[idx].saving = true;
      if (this.procedure_sessions[idx].therapy_session == null) {
        this.procedure_sessions[idx].therapy_session = this.therapy_session.id;
      }
      if (this.procedure_sessions[idx].perc_correct == '') {
        this.procedure_sessions[idx].perc_correct = null;
      }
      if (this.procedure_sessions[idx].total_trials == '' || !this.procedure_sessions[idx].total_trials) {
        this.procedure_sessions[idx].total_trials = null;
      }
      if (this.procedure_sessions[idx]._order == null) {
        this.procedure_sessions[idx]._order = idx;
      }
      return this.dailySessionViewService.saveProcedureSession(this.procedure_sessions[idx])
        .then((procedureSession: ProcedureSession) => {
          // in theory we should be ok as the values shold be passed by reference

          // this.procedure_sessions[idx].id = procedureSession.id;
          this.procedure_sessions[idx] = _.merge(this.procedure_sessions[idx], procedureSession);

          this.procedure_sessions[idx].saving = false;
          if (this.procedure_sessions[idx].perc_correct == null) {
            this.procedure_sessions[idx].edit_perc_correct = false;
          }
          if (this.procedure_sessions[idx].total_trials == null) {
            this.procedure_sessions[idx].edit_total_trials = false;
          }
          if (this.saving_proc_session.has(idx)) {
            this.saving_proc_session.get(idx).next(this.procedure_sessions[idx].id);
          }

          return this.procedure_sessions[idx].id;
          // for now we buffer up to the default max
        })
        .catch(
          (err) => {
            this.procedure_sessions[idx].saving = false;

            throw err;
          });
    } else {
      return throwError('no phase has been selected').toPromise();
    }
  }

  padSessionTrials(session_trials: SessionTrial[]): SessionTrial[] {
    if (!this.proc_items || this.proc_items.length === 0) {
      return session_trials;
    }
    // now we know the session trials, if any, are sorted by _order
    let new_session_trials: SessionTrial[] = this.fillSessionTrials(session_trials);

    if (this.proc_items && this.proc_items.length > 0) {
      if ((this.max_row_num * this.proc_items.length) - new_session_trials.length < 0) {
        this.addToast('There are too many session trials for procedure, Please contact your system administrator', 'error');
      } else {
        new_session_trials = [...new_session_trials, ...(Array
          .apply(null, Array((this.max_row_num * this.proc_items.length) - new_session_trials.length))
          .map(() => new SessionTrial()))];
      }
    }
    return new_session_trials;
  }

  getSessionTrials(): void {
    let proc_session_ids = this.procedure_sessions.map(ps => ps.id);
    proc_session_ids = proc_session_ids != null ? proc_session_ids.filter(ps => ps != null) : [];
    if (proc_session_ids && proc_session_ids.length > 0) {
      this.dailySessionViewService.getSessionTrialsByProcedureSessionIds(proc_session_ids, true)
        .then(session_trials => {
          const grouped_sessions = _(session_trials)
            .groupBy(st => st.procedure_session)
            .value();

          const keys = Object.keys(grouped_sessions);
          for (const key of keys) {
            const temp: SessionTrial[] = [];
            // for each session trial we then set the count to the index of the value
            // from static choices
            const trials = this.commonService.sortByProperty(grouped_sessions[key], '_order');
            for (let idx = 0; idx < trials.length; idx++) {
              trials[idx].count = this.daily_session_trial_options.findIndex(dst => dst.value == trials[idx].value);

              temp.push(_.cloneDeep(trials[idx]));
            }
            const proc_session_idx = this.procedure_sessions.findIndex(ps => ps.id === +key);
            if (proc_session_idx > -1) {
              this.procedure_sessions[proc_session_idx].session_trials = temp.slice();
              this.procedure_sessions[proc_session_idx].session_trials = this.padSessionTrials(this.procedure_sessions[proc_session_idx].session_trials);
            }
          }
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  completeSession(complete_session?: boolean): Promise<TherapySession> {
    return this.dailySessionViewService.save(complete_session)
      .then((therapy_session) => {
        // in theory we should be ok as the values shold be passed by reference
        this.therapy_session = _.cloneDeep(therapy_session);
        if (this.therapy_session.id != null) {
          const saved_procedure_sessions = this.dailySessionViewService.currentProcedureSessions.getValue();
          for (const session of saved_procedure_sessions) {
            const idx = this.procedure_sessions.findIndex(ps => ps.id == session.id);
            if (idx > -1) {
              this.procedure_sessions[idx] = _.merge(this.procedure_sessions[idx] || {}, session);
              this.procedure_sessions[idx].session_trials = this.padSessionTrials(this.procedure_sessions[idx].session_trials || []);
            }
          }
        }

        return therapy_session;
        // for now we buffer up to the default max
      });
  }

  subscribeToSessionCache(): void {
    // TODO: simplify this data structure that's returned to a list of ids instead of a map
    if (this.dailySessionsSavedSubscription) {
      return;
    }

    this.dailySessionsSavedSubscription = this.dailySessionViewService.dailySessionsSaved.subscribe((sessions) => {
      const keys = sessions.keys();
      let key = keys.next();
      while (!key.done) {
        const k = key.value;
        // now need to check each of the procedure sessions found here and in the 'future' save to update with the id or delete
        // NOTE: we don't need to do this anymore since we are disabling the UI

        const idx = this.procedure_sessions.findIndex(ps => ps.id == k);
        const ret_list = sessions.get(k);
        for (let i = 0; i < ret_list.length; i++) {
          const saved = ret_list[i];
          if (this.procedure_sessions[idx] &&
            this.procedure_sessions[idx].session_trials != null &&
            this.procedure_sessions[idx].session_trials.length > saved._order) {
            this.procedure_sessions[idx].session_trials[saved._order].id = saved.id;
          }
        }

        // now we just reload the procedure sessions that were saved so we can get the updated totals
        this.dailySessionViewService.getProcedureSessionById(k)
          .then((procedure_session: ProcedureSession) => {
            if (idx > -1) {
              this.procedure_sessions[idx] = _.merge(this.procedure_sessions[idx], procedure_session);
            }
          });
        key = keys.next();
      }

      this.saving_trial = false;
      if (this.toastyId != null) {
        this.toastyService.clear(this.toastyId);
      }
      if (sessions.size > 0) {
        this.addToast('Done', 'success');
      }
      this.toastyId = null;

    });
  }

  subscribeToTherapySession(): void {
    if (this.therapySessionSubscription) {
      return;
    }

    this.therapySessionSubscription = this.dailySessionViewService.currentSubject.subscribe(
      (therapy_session: TherapySession) => {
        // make sure we clear the error_message variable just in case
        this.error_message = null;

        if (this.therapy_session && !this.therapy_session.after_complete && !this.therapy_session.ignore_update && !this.therapy_session.is_error_finish) {
          this.therapy_session = therapy_session;

          if (this.therapy_session.is_complete && !this.therapy_session.filtering) {
            this.loading = true;
            this.completeSession(true)
              .then((completed) => {
                this.max_row_num = this.DEFAULT_SESSION_NUM;
                this.procedure_sessions = [];
                this.loading = false;
              })
              .catch((err) => {
                //this.loading = false;
                throw err;
              });
            return;
          }
          if (therapy_session && therapy_session.id != null && (therapy_session.filtering || therapy_session.clear_filter)) {
            if (!therapy_session.after_complete) {
              this.loading_session = true;
              this.therapy_session = therapy_session;
              this.setSessionDetaults();

              this.procedures = [];
              this.procedure_sessions = [];
              this.proc_items = [];
              this.prompt_steps = [];
              this.sd_responses = [];

              if (this.procedure_session_defaults != null && this.procedure_session_defaults.length > 0) {
                this.getProceduresForTherapySession()
                  .pipe(
                    concatMap(res => {
                      // now we set the default current procedure and current procedure id

                      try {
                        this.current_procedure = this.procedures.length > 0 ? this.procedures[0] : null;
                        this.current_procedure_id = this.current_procedure.id;
                      } catch (e) {
                        this.loading_session = false;
                        this.showCollectDataButton = false;
                        this.loading = false;
                        throw e;
                      }

                      // now we get the groups
                      return this.getSessionGroups();
                    }),
                    concatMap(res => {
                      this.setDefaults();
                      // first we get the procedure sessions so we can see if we proceed with rendering the sheet
                      return this.getProcedureSessionsByGroup();
                    }),
                    catchError(e => {
                      this.loading_session = false;
                      this.showCollectDataButton = false;
                      this.loading = false;
                      // if we get here then we don't have any procedures for the current data sheet

                      // if we don't have any therapy sessions this means that this session didn't have MEI
                      if (this.procedures.length == 0 && this.therapy_session.state_sheet_meta.data.length > 0) {
                        this.error_message = 'MEI was not performed for this therapy session';
                      } else {
                        this.error_message = 'The Therapy Session could not be rendered due to compatibility issues, ' +
                          'please contact your Systems Administrator';
                      }

                      debugger;
                      // we are returning a false so that the rest of the code can at least fun
                      return of(false);
                    })
                  ).subscribe(haveSavedSessions => {
                  // if we don't have any procedures, skip over and just show the message set in the catch error block
                  if (this.procedures.length > 0) {
                    if (haveSavedSessions) {
                      this.filterSessionsByGroup();
                      this.setSds();
                      this.setPromptSteps();
                      this.setProcItems();
                      this.setDefaultSessionTrials();
                      // now we order the procedure sessions
                      this.orderProcedureSessionsBySds();
                      this.updateGrid(true);
                      this.updateGlobalValues();
                      // in this instance, we want to show as much as we can, only create or get the sessions if there is data
                      // now we save any sessions that don't exist
                      this.createProcedureSessions()
                        .then(res => {
                          this.getSessionTrials();

                          // last, but not least, show the grid
                          this.loading_session = false;
                          this.showCollectDataButton = false;
                          this.loading = false;
                        });
                    } else {
                      // else we have a previous session that doesn'y have any procedure sessions
                      this.loading_session = false;
                      this.showCollectDataButton = true;
                      this.loading = false;
                    }
                  }

                }, err => {
                  //TODO: need to add in error message as defined in document
                  throw err; // throwing error so Sentry can catch it
                })
              } else {
                // making sure all the loading divs are hidden if we don't have a meta data object from the backend
                this.loading_session = false;
                this.showCollectDataButton = false;
                this.loading = false;
                this.error_message = 'The Therapy Session could not be rendered due to compatibility issues, ' +
                  'please contact your Systems Administrator';

                // TODO need to show an error message here that the procedure sheet is not compatible
              }

            }
          } else if (therapy_session && therapy_session.id == null) {
            this.therapy_session = therapy_session;
            // if (this.therapy_session.filtering) {
            this.procedures = [];
            this.procedure_sessions = [];
            this.proc_items = [];
            this.sd_responses = [];
          }
        } else if (this.therapy_session.ignore_update) {
          delete this.therapy_session.ignore_update;
          this.loading = false;
          // this.therapy_session = therapy_session;
        }
        if (this.therapy_session.is_error_finish) {
          delete this.therapy_session.is_error_finish;
          this.loading = false;
        }
      });
  }

  private setProcItems() {
    const proc_ids = this.procedures.map(p => p.id);

    this.proc_items = this.procedure_sessions != null && this.procedure_sessions.length > 0 ?
      this.procedure_sessions[0].proc_items : [];
    // now we need to go get the sd_responses and proc items if we don't have them
    if (this.proc_items == null || this.proc_items.length == 0) {
      this.dailySessionViewService.getProcItemsByProcedureIds$(proc_ids, true)
        .subscribe(res => {
          this.proc_items = res;
        })
    }
  }

  private setSds() {
    this.sd_responses = this.procedure_sessions != null && this.procedure_sessions.length > 0 ?
      this.procedure_sessions[0].sd_responses : [];

    const proc_ids = this.procedures.map(p => p.id);

    if (this.sd_responses == null || this.sd_responses.length == 0) {
      this._procedureViewService.getSdResponsesByProcedureIds$(proc_ids)
        .subscribe(sd_responses => {
          this.sd_responses = sd_responses;
        })
    }
  }

  private setPromptSteps() {
    if (this.procedure_sessions != null && this.procedure_sessions.length > 0) {
      this.current_prompt_description = this.procedure_sessions[0].prompt_description;
    }
    if ((this.prompt_steps == null || this.prompt_steps.length == 0) &&
      this.current_procedure.prompt_step_ids != null && this.current_procedure.prompt_step_ids.length > 0) {
      this._procedureViewService.getPromptFadingStepsByIds$(this.current_procedure.prompt_step_ids)
        .subscribe(prompt_steps => {
          this.prompt_steps = prompt_steps;
        })
    }
  }

  private setDefaultSessionTrials() {
    for (const procedure_session of this.procedure_sessions) {
      if (!procedure_session.session_trials || procedure_session.session_trials.length == 0) {
        const proc_item_length = this.proc_items != null ? this.proc_items.length : 1;
        procedure_session.session_trials = Array
          .apply(null, Array((this.max_row_num * proc_item_length)))
          .map(() => new SessionTrial());
      }
    }
  }

  private filterSessionsByGroup() {
    this.procedure_sessions = this.all_procedure_sessions.filter(aps => aps.state_group == this.current_group);
    // the below might not be correct, have to look about removing this
    if (this.procedure_sessions != null && this.procedure_sessions.length > 0) {
      this.current_procedure.prompt_step_ids = this.procedure_sessions[0].state_prompt_steps;
      this.current_procedure.proc_item_ids = this.procedure_sessions[0].state_items;
    }
  }

  currentGroupChange($event) {
    this.procedure_sessions = [];
    this.proc_items = [];
    this.prompt_steps = [];
    this.sd_responses = [];
    this.setDefaults();

    this.getProcedureSessionsByGroup()
      .subscribe(haveSavedSessions => {
        if (haveSavedSessions) {
          this.filterSessionsByGroup();
          this.setSds();
          this.setPromptSteps();
          this.setProcItems();
          this.setDefaultSessionTrials();
          // now we order the procedure sessions
          this.orderProcedureSessionsBySds();
          // if we are changing the current group, we should have current procedures
          this.updateGrid(true);
          this.updateGlobalValues();
          // now we save any sessions that don't exist
          this.createProcedureSessions()
            .then(res => {
              this.getSessionTrials();

              // last, but not least, show the grid
              this.showCollectDataButton = false;
            }).catch(err => {
            this.loading = false;
            throw err;
          });
        } else {
          this.showCollectDataButton = true;
        }
      });
  }

  currentProcedureChange(event?: any): void {
    this.loading = true;
    this.max_row_num = this.DEFAULT_SESSION_NUM;
    this.procedure_sessions = [];
    this.sd_responses = [];
    this.prompt_steps = [];
    const procedure = this.procedures.find(p => p.id === this.current_procedure_id);
    if (procedure) {
      this.current_procedure = _.cloneDeep(procedure);

      this.getSessionGroups()
        .pipe(concatMap((res) => {
          this.setDefaults();
          return this.getProcedureSessionsByGroup();
        }))
        .subscribe(haveSavedSessions => {
          if (haveSavedSessions) {
            this.filterSessionsByGroup();
            this.setSds();
            this.setPromptSteps();
            this.setProcItems();
            this.setDefaultSessionTrials();
            // now we order the procedure sessions
            this.orderProcedureSessionsBySds();
            this.updateGrid(haveSavedSessions);
            this.updateGlobalValues();
            // now we save any sessions that don't exist
            this.createProcedureSessions()
              .then(res => {
                this.getSessionTrials();

                // last, but not least, show the grid
                this.showCollectDataButton = false;
              })
              .catch(err => {
                this.loading = false;
                throw err;
              });
          } else {
            this.showCollectDataButton = true;
          }
          this.loading = false;
        }, err => {
          console.log(err);
          //TODO: need to add in error message as defined in document
        })
    } else {
      // TODO: show the global error that we have now if there is an error
    }
  }


  processReadonlyData(): void {
    if (this.data) {
      const filtered = this.data.data.filter(d => d.item_group != null);
      // // order by date first
      // const grouped_data = _.chain(filtered)
      //   .orderBy(['date'])
      //   .groupBy(['ts', 'item_group'])
      //   .value();
      const grouped_data = _(filtered)
        .groupBy(x => x.item_group)
        .value();
      const keys = Object.keys(grouped_data);
      for (const key of keys) {
        this.item_groups.push(key);
        this.readonly_sessions.set(key, []);
        for (const obj of grouped_data[key]) {
          const new_session_data = new SessionData();
          new_session_data.description = obj['description'];
          new_session_data.totals = obj['totals'];
          new_session_data.perc_correct = obj['perc_correct'];
          new_session_data.num_trials = obj['state_prescribed_trials'];
          new_session_data.sds = obj['headers'];
          new_session_data.items = obj['items'];
          new_session_data.item_group = obj['item_group'];
          new_session_data.session_trials = [];
          const arr = obj['data'];

          if (arr && arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
              let new_trials = arr[i].map(a => {
                const trial = new SessionTrial();
                trial._order = a._order;
                trial.value = a.value;
                return trial;
              });

              new_trials = this.commonService.sortByProperty(new_trials, '_order');
              new_trials = this.fillSessionTrials(new_trials);
              new_trials = [...new_trials, ...(Array
                .apply(null, Array(Math.abs(new_session_data.num_trials - new_trials.length)))
                .map(() => new SessionTrial()))];
              new_session_data.session_trials.push(_.cloneDeep(new_trials));
            }

          }
          // new_session_data.session_trials = this.padSessionTrials(new_session_data.session_trials, new_session_data.num_trials);
          this.readonly_sessions.get(key).push(_.cloneDeep(new_session_data));
        }
      }
    }
    this.loading = false;
  }

  // function to get the initial data passed to the component
  getInitialData(): Observable<any> {
    return combineLatest([this.data_sheets, this.patient])
      .pipe(
        map((res) => {
          // by now we should have everything, if we don't then this doesn't run anyway
          const data_sheets = res[0];
          // const sheet_info = res[1];
          // const patient = res[2];
          if (data_sheets && data_sheets.length > 0) {
            this.getDataSheet(data_sheets);
          }
          return true;
        }),
        catchError((err) => throwError(err))
      );
  }

  ngOnInit() {
    this.cmp_name = 'multiple example instruction';
    this.loading = true;
    this.subscriptions = [];
    this.current_procedure = new Procedure();
    this.procedure_sessions = [];
    this.all_procedure_sessions = [];
    this.sd_responses = [];
    this.max_row_num = this.DEFAULT_SESSION_NUM;
    this.proc_items = [];
    this.loading_session = false;

    // this is used as a way to show the grid for the ma_row num of MEI (which is always 5), there wasn't a better way of doing it
    // unfortunately....
    this.session_trial_index = Array.apply(null, Array(this.max_row_num)).map((curr, idx) => idx);
    this.phase_type_tactics_map = new Map<number, any[]>();
    this.saving_proc_session = new Map<number, BehaviorSubject<number>>();

    // so we get the therapy session from the the daillySessionViewService
    this.therapy_session = this.dailySessionViewService.currentSubject.getValue();
    if (this.therapy_session == null || this.therapy_session.id == null) {
      // if we don't have a therapy session, we should show a message and stop processing
      this.error_message = 'Something went wrong while loading the therapy session, please contact your systems administrator';
    }
    if (this.therapy_session.ignore_update) {
      delete this.therapy_session.ignore_update;
    }
    // we go get the stages from the static choices here
    this.subscribeToChoices();
    if (!this.is_readonly) {
      // we subscribe to everything after we have loaded
      // subscriptions for saving and reloading
      this.subscribeToSessionSave();
      this.subscribeToSessionCache();
      this.subscribeToSavingSessions();
      this.subscribeToTherapySession();

      // next we get the session defaults
      this.setSessionDetaults();
      // next is to get the procedures for the therapy session
      this.subscriptions.push(this.getInitialData()
        .pipe(
          concatMap(res => {
            this.loading = res;
            return this.getProceduresForTherapySession();
          }),
          concatMap(res => {
            // now we set the default current procedure and current procedure id
            try {
              this.current_procedure = this.procedures.length > 0 ? this.procedures[0] : null;
              this.current_procedure_id = this.current_procedure.id;
            } catch (e) {
              this.loading_session = false;
              this.showCollectDataButton = false;
              this.loading = false;
              throw e;
            }

            // now we get the groups
            return this.getSessionGroups();
          }),
          concatMap(res => {
            this.setDefaults();
            // first we get the procedure sessions so we can see if we proceed with rendering the sheet
            return this.getProcedureSessionsByGroup();
          }),
          catchError(e => {
            this.loading_session = false;
            this.showCollectDataButton = false;
            this.loading = false;
            // if we get here then we don't have any procedures for the current data sheet

            // if we don't have any therapy sessions this means that this session didn't have MEI
            if (this.procedures.length == 0 && this.therapy_session.state_sheet_meta.data.length > 0) {
              this.error_message = 'MEI was not performed for this therapy session';
            } else {
              this.error_message = 'The Therapy Session could not be rendered due to compatibility issues, ' +
                'please contact your Systems Administrator';
            }
            // we are returning a false so that the rest of the code can at least fun
            return of(false);
          })
        ).subscribe(haveSavedSessions => {

          // if we don't have any procedures, skip over and just show the message set in the catch error block
          if (this.procedures.length > 0) {
            if (haveSavedSessions) {
              this.filterSessionsByGroup();
              this.setSds();
              this.setPromptSteps();
              this.setProcItems();
              this.setDefaultSessionTrials();
              // now we order the procedure sessions
              this.orderProcedureSessionsBySds();
              this.updateGrid(haveSavedSessions);
              this.updateGlobalValues();
              // now we save any sessions that don't exist
              this.createProcedureSessions()
                .then(res => {
                  this.getSessionTrials();
                  // last, but not least, show the grid
                  this.showCollectDataButton = false;
                });
            } else {
              this.showCollectDataButton = true;
            }
          }
        }, err => {
          console.log(err);
          //TODO: need to add in error message as defined in document
        })
      );
    } else {
      // else we have a readonly popup to show
      this.item_groups = [];
      this.readonly_sessions = new Map<string, any[]>();
      this.processReadonlyData();
    }
  }

  ngOnDestroy() {
    // this.updateSession();
    this.unsubscribe();
    this.showNavService.showButtons.next(true);
    if (this.sessionSave) {
      // killing the incremental save for this page
      this.dailySessionViewService.killTrigger.next();
      this.sessionSave.unsubscribe();
    }
    if (this.sessionSaving) {
      this.sessionSaving.unsubscribe();
    }
    if (this.dailySessionsSavedSubscription) {
      this.dailySessionsSavedSubscription.unsubscribe();
    }
    if (this.therapySessionSubscription) {
      this.therapySessionSubscription.unsubscribe();
    }
  }

}
