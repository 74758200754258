import { Injectable } from '@angular/core';
import {BaseService} from '../base.service';
import {IBaseService} from '../interfaces/base-interface';
// import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {BehaviorSubject} from 'rxjs';
import { HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {Procedure} from '../models/procedure.model';
import {GraphData} from '../models/graph-data.model';
import {Observable} from 'rxjs/index';
import {tap} from 'rxjs/operators';
import {PatientDetails} from '../models/patient-details.model';

@Injectable()
export class ProcedureService extends BaseService<Procedure> {

  currentSubject: BehaviorSubject<Procedure> = new BehaviorSubject(new Procedure());
  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.procedure/', Procedure);
    this.subRoute = '';
    this.date_obj_keys = ['mastery_date'];
    this.graph_route = '/graph_data/';
    this.items_list_route = '/items_list/';
    this.raw_data_url = '/raw_data/';
  }

  addPhaseChangeLog(phaseLine): Observable<Boolean> {
    const params = {...phaseLine};
    const url = this.baseApi + 'data_collection.phasechange/';
    const options = this.getOptions();

    return this.http.post(url, params, options)
        .pipe(
            tap((phaseChangeLine: any) => {
              console.log('testing phase change line update', phaseChangeLine);
            })
        )
  }
}
