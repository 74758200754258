import {SessionTrial} from './session-trial.model';
import {PromptFadingStep} from './prompt-fading-step.model';
import {Base} from './base.model';
import {ProcItem} from './proc-item.model';
import {SelectOption} from './select-option.model';
import {SdResponse} from './sd-response.model';

export class ProcedureSession extends Base<ProcedureSession> {
  therapy_session: number;
  perc_correct: string;
  prompt_step: number;
  proc_item: number;
  stage: string;
  phase: number;
  phase_type: string;
  total_trials: string;
  tactic: string;
  procedure: number;
  host: number;
  date: string;
  data_sheet_code: string;
  date_date: Date;
  saving: boolean;
  session_trials: SessionTrial[];
  prompt_steps: PromptFadingStep[];
  proc_items: ProcItem[];
  edit_perc_correct: boolean;
  edit_total_trials: boolean;
  calc_total_trials: number;
  calc_perc_complete: number;
  getting_procedure_id: number;
  training_step: number;
  phase_types: SelectOption[];
  sd_responses: SdResponse[];
  sd: number;
  prompt_step_ids: number[];
  proc_item_ids: number[];
  state_group: string;
  state_proc_items: number[];
  state_prescribed_trials: number;
  num_trials: number;
  warning: string;
  default_session_idx: number;
  trial_padding: number[];
  show_prompt_description: boolean;
  phase_type_tactics: any;
  include: boolean;
  total_duration: string;
  mean_duration: string;
  calc_mean_duration: string;
  calc_total_duration: string;
  edit_total_duration: boolean;
  edit_mean_duration: boolean;
  state_proc_steps: number[];
  sd_order: any;

  phase_title: string;
  sd_name: string;
  state_items: number[];
  state_sds: number[];
  prompt_description: string;
  state_prompt_steps: number[];
  isPhaseError?: boolean;

  constructor (id?: number,
               therapy_session?: number,
               procedure?: number,
               host?: number,
               proc_item?: number) {
    super(ProcedureSession);
    this.id = id;
    this.therapy_session = therapy_session;
    this.procedure = procedure;
    this.host = host;
    this.proc_item = proc_item;

    this.phase_title = 'Test Phase with some etxt';

  }
}
