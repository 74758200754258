import {Component, OnDestroy, OnInit} from '@angular/core';
import {PatientService} from '../../core/services/patient.service';
// import 'rxjs/add/operator/switchMap';
import { switchMap} from "rxjs/operators"
import {ParamMap, Router} from '@angular/router';
import {BaseComponent} from '../../shared/base/base.component';
import {User} from '../../core/models/user.model';
import {PatientDetails} from '../../core/models/patient-details.model';
import {ConstantsService} from '../../core/services/constants.service';
import {PatientViewService} from '../patient-view.service';
import {GlobalService} from '../../core/services/global.service';
import {UserService} from '../../core/services/user.service';
import * as moment from 'moment';
import {SelectOption} from '../../core/models/select-option.model';
import {Behaviour} from '../../core/models/behaviour.model';
import {UserViewService} from '../../shared/services/user-view.service';
import {CurrentFormService} from '../../core/services/current-form.service';
import {DocumentViewService} from '../../shared/services/document-view.service';
import {DialogService} from '@progress/kendo-angular-dialog';
import {Document} from '../../core/models/document.model'
import {PatientDetailViewService} from '../../shared/services/patient-detail-view.service';
import {ShowNavService} from '../../core/services/show-nav.service';
import {ToastyService} from 'ng2-toasty';
import * as _ from 'lodash';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent extends BaseComponent implements OnInit, OnDestroy {

  client_id: string;
  patient: User;
  patient_details: PatientDetails;
  behaviours: Behaviour[];
  delete_behaviours: Behaviour[];
  new_behaviour: Behaviour;
  public documentViewService: DocumentViewService;
  document_type = 'consent_form';
  enroll_document_type = 'enrollment_form';
  loading: boolean;
  statuses: SelectOption[];
  genders: SelectOption[];
  programs: SelectOption[];

  // public multiple_options: Select2Options;
  // public single_options: Select2Options;

  constructor(dialogService: DialogService,
              private patientService: PatientService,
              constantsService: ConstantsService,
              private patientViewService: PatientViewService,
              private globalService: GlobalService,
              userViewService: UserViewService,
              private userService: UserService,
              currentFormService: CurrentFormService,
              documentViewService: DocumentViewService,
              private patientDetailViewService: PatientDetailViewService,
              private router: Router,
              private showNavService: ShowNavService,
              toastyService: ToastyService) {
    // don't need dialogs at the moment for this tab
    super(constantsService, userViewService, dialogService, null, currentFormService, toastyService);
    this.documentViewService = documentViewService;
  }

  subscribeToPatientDetails(): void {
    this.subscriptions.push(this.patientService.currentPatientDetails.subscribe(
      (patient_details: PatientDetails) => {
        this.patient_details = patient_details;
        if (this.patient && this.patient.id) {
          // now we get the documents
          this.getConsentForm();
          this.getEnrollmentForm();
          this.getBehaviours();
        }

      }));
  }

  subscribeToPatient(): void {
    this.subscriptions.push(this.patientService.currentPatient.subscribe(
      (patient: User) => {
        this.patient = patient;
        if (this.patient_details && this.patient_details.id) {
          // now we get the documents
          this.getConsentForm();
          this.getEnrollmentForm();
          this.getBehaviours();
        }
      }));
  }

  getConsentForm(): void {
    this.documentViewService.getByType(this.document_type)
      .then(
        (consent_form: Document[]) => {
          this.patient_details.consent_form = consent_form && consent_form.length > 0 ? consent_form[0] : new Document();
      })
  }

  getEnrollmentForm(): void {
    this.documentViewService.getByType(this.enroll_document_type)
      .then(
        (enrollment_form: Document[]) => {
          this.patient_details.enrollment_form = enrollment_form && enrollment_form.length > 0 ? enrollment_form[0] : new Document();
      })
  }

  /**
   * because there are no more global buttons, this has become obsolete
   * but still cool
   *
   */
  subscribeToGlobalSave(): void {
    this.subscriptions.push(this.globalService.listen().subscribe(
      (value: any) => {
        this.save();
      }));
  }

  save(): void {
    console.log('saving details');
    let saved_user: User;
    this.userService.save(this.patient)
      .then((res: User) => {
        saved_user = res;
        return this.patientService.save(this.patient_details);
      })
      .then((res) => {
        // now we delete the behaviours that were removed from the list
        return this.patientDetailViewService.removeBehaviours(this.delete_behaviours);
      })
      .then((res) => {
        this.delete_behaviours = [];
        // now we need to save the behaviour reductions
        for(const behaviour of this.behaviours) {
          behaviour.patient = saved_user.id;
        }
        return this.patientDetailViewService.saveBehaviours(this.behaviours)
      })
      .then((updated: Behaviour[]) => {
        // now they are saved, need to reload
        this.behaviours = updated.slice();
        this.patientService.currentPatient.next(saved_user);
        this.addToast('Data was saved', 'success');
      })
      .catch((err) => {
        if (err.error) {
          for (let key in err.error) {
            this.addToast(`${key}: ${err.error[key][0]}`, 'error');
          }
        }
        throw err;
      });
  }

  addNewBehaviour(behaviour: Behaviour): void {
    if (behaviour.name && behaviour.hypothesised_function && behaviour.measurement) {
      this.behaviours.push(_.cloneDeep(behaviour));

      this.new_behaviour = null;
      this.new_behaviour = new Behaviour();
    }
  }

  removeItem(idx: number): void {
    this.delete_behaviours = [...this.delete_behaviours, ...this.behaviours.splice(idx, 1)];
  }

  editBehaviour(idx: number): void {
    if (this.behaviours[idx]) {
      this.behaviours[idx].edit = true;
    }
  }

  saveBehaviour(idx: number): void {
    if (this.behaviours[idx]) {
      this.behaviours[idx].edit = false;
      if (this.patient.id != null) {
        if (this.behaviours[idx].patient == null) {
          this.behaviours[idx].patient = this.patient.id;
        }

        this.patientDetailViewService.saveBehaviour(this.behaviours[idx])
          .then(
            (behaviour) => {
              this.behaviours[idx] = _.merge(this.behaviours[idx], behaviour);
          }).catch(
            (err) => {
            console.log(err);
            throw err;
        });
      }

    }
  }

  getBehaviours(): void {
    if (this.patient && this.patient.id != null) {
      this.patientDetailViewService.getBehaviours(this.patient.id)
        .then(
          (behaviours) => {
            this.behaviours = behaviours.slice();
            // for now let's assume this is the last thing to load
            this.loading = false;
          })
    }
  }

  cancelEdit(idx: number): void {
    if (this.behaviours[idx]) {
      this.behaviours[idx].edit = false;
    }
  }

  updateLead(patient_details: PatientDetails, value: boolean) {
    patient_details.is_lead = value;
  }

  onenrollmentChange($event): void {
    if ($event) {
      this.patient_details.enrollment_date = moment($event).format('YYYY-MM-DD');
    }
  }

  cancel(): void {
    this.router.navigate(['/patients']);
  }

  subscribeToChoices(): void {
    this.subscriptions.push(this.choicesSubject.subscribe(
      (choices: Map<string, any>) => {
        if (choices.size > 0 && choices.has('patientdetail')) {
          this.statuses = choices.get('patientdetail').get('status');
          this.programs = choices.get('patientdetail').get('program');
          if (!this.programs || this.programs.length === 0) {
            this.programs = this.constantsService.getProgramsStatic();
          }
        }
        if (choices.size > 0 && choices.has('user')) {
          this.genders = choices.get('user').get('gender');
        }
      }));
  }

  ngOnInit() {
    this.title = 'Details';
    this.loading = true;
    this.patientViewService.currentTitle.next(this.title);
    // this.patient = new User();
    // this.patient_details = new PatientDetails();
    this.subscriptions = [];
    this.regions = [];
    this.genders = [];
    this.programs = [];
    this.delete_behaviours = [];

    // this.safety_information_options = [];

    this.behaviours = [];
    this.new_behaviour = new Behaviour();
    // this.multiple_options = {
    //   multiple: true,
    //   closeOnSelect: false,
    //   containerCssClass: ['form-control'],
    //   width: '100%'
    // };
    // this.single_options = {
    //   containerCssClass: ['form-control'],
    //   width: '100%'
    // };
    this.subscribeToChoices();
    this.subscribeToPatient();
    this.subscribeToPatientDetails();
    this.subscribeToGlobalSave();
    this.getChoices();

    // this.patient = this.patientService.currentPatient.getValue();
    // this.patient_details = this.patientService.currentPatientDetails.getValue();


    // this.route.paramMap
    //   .switchMap((params: ParamMap) => {
    //     this.client_id = params.get('id');
    //     console.log(this.client_id);
    //     return Promise.resolve([]);
    //   })
    //   .subscribe(contact => {
    //     // just a dummy return function since it doesn't need to really do anything
    //     return contact;
    //   });

    // TODO: remove this from all components as we don't have any global buttons anymore
    this.showNavService.showButtons.next(false);
  }

  ngOnDestroy() {
    this.unsubscribe();
    this.showNavService.showButtons.next(true);
  }
}
