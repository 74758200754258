import {AfterViewChecked, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {UserViewService} from '../services/user-view.service';
import {ConstantsService} from '../../core/services/constants.service';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {CurrentFormService} from '../../core/services/current-form.service';
import {NgForm} from '@angular/forms';
import {SelectOption} from '../../core/models/select-option.model';
import {User} from '../../core/models/user.model';
import {Subscription} from 'rxjs/index'
import {AppValidationMessages} from '../app.messages';
import {Note} from '../../core/models/note.model';
import * as moment from 'moment';
import {UserService} from '../../core/services/user.service';
import {CommunicationViewService} from '../services/communication-view.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-add-communication',
  templateUrl: './add-communication.component.html',
  styleUrls: ['./add-communication.component.css']
})
export class AddCommunicationComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {

  choices: Map<any, any>;
  user: User;
  subscriptions: Subscription[];
  private DEFAULT_TITLE = 'Error';
  users_list: User[];
  communication: Note;
  current_user: User;

  @ViewChild('addCommunicationForm') public currentForm: any;
  addCommunicationForm: NgForm;
  error_message: string;
  formErrors = {};

  constructor(private userViewService: UserViewService,
              private constantsService: ConstantsService,
              private currentFormService: CurrentFormService,
              private toastyService: ToastyService,
              private userService: UserService,
              private communicationViewService: CommunicationViewService) {

  }

  updateNote(): void {
    this.communicationViewService.currentSubject.next(this.communication);
  }


  getChoices(): void {
    if (!this.constantsService.choices || this.constantsService.choices.size === 0) {
      this.constantsService.getChoices().then(
        (choices: Map<string, any>) => {
          this.choices = choices.get('global');
        });
    } else {
      this.choices = this.constantsService.choices.get('global');
    }
  }

  subscribeToErrorMessage(): void {
    this.subscriptions.push(this.currentFormService.currentErrorMessage.subscribe(
      (message: string) => {
        // this.error_message = message;
        if (message && typeof message === 'string') {
          this.addToast(message, 'Error');
        }
      }));
  }

  unsubscribe(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  formChange(): void {
    if (this.currentForm === this.addCommunicationForm) {
      return;
    }

    this.addCommunicationForm = this.currentForm;
    if (this.addCommunicationForm) {
      this.addCommunicationForm.valueChanges
        .subscribe(data => this.onValueChanged());
    }
  }

  onValueChanged() {
    if (!this.addCommunicationForm) {
      return;
    }
    const form = this.addCommunicationForm.form;

    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }
  }

  submitForm() {
    // just a dummy function for the moment
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = this.currentForm.form.get(field);

      if (control && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }
  }


  addToast(message: string, title: string) {
    // Or create the instance of ToastOptions
    const toastOptions = this.getToastOptions(message, title);
    // // Add see all possible types in one shot
    // this.toastyService.info(toastOptions);
    // this.toastyService.success(toastOptions);
    // this.toastyService.wait(toastOptions);
    // this.toastyService.error(toastOptions);
    // this.toastyService.warning(toastOptions);

    switch (title.toLowerCase()) {
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
    }
  }

  getToastOptions(message: string, title: string): ToastOptions {
    title = title != null ? title : this.DEFAULT_TITLE;

    return {
      title: title,
      msg: message,
      showClose: true,
      timeout: 5000,
      theme: 'default'
    };
  }


  getAllUsers(): Observable<User[]> {
    return this.userViewService.get_users()
      .pipe(
        map((users) => {
          this.users_list = users;
          return users
        })
      );
  }


  ngAfterViewChecked(): void {
    this.formChange();
  }


  ngOnInit() {
    this.communication = this.communicationViewService.currentSubject.getValue();
    if (!this.communication) {
      this.communication = new Note();
      this.current_user = this.userService.currentUserUpdated.getValue();
      this.communication.created_date = moment().toDate();
      this.communication.created_by = this.current_user.id;
      this.communication.type = 'comm_book';
    }
    this.subscriptions = [];
    this.getChoices();
    this.subscriptions.push(this.getAllUsers().subscribe((users) => users));
    this.subscribeToErrorMessage();
    this.currentFormService.currentSubject.next(this.currentForm);

  }

  ngOnDestroy(): void {
    this.currentFormService.currentSubject.next(null);
    this.currentFormService.currentErrorMessage.next(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

}
