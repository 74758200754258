import {Component, OnInit, ViewChild} from '@angular/core';
// import {UserService} from '../core/services/user.service';
// import {User} from '../shared/user.model';
// import {ConstantsService} from "../core/services/constants.service";
// import {SelectOption} from "../core/models/select-option.model";
// import {Router} from "@angular/router";
// import {ShowNavService} from "../shared/show-nav.service";
// import {Location} from "@angular/common";
// import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  // avatar: File;
  // @ViewChild('profileForm') public profileForm: NgForm;
  // @ViewChild('phone') public phone;
  // @ViewChild('fileInput') fileInput;
  // user: User;
  // loaded: boolean;
  // loading: boolean;
  // languages: SelectOption[];
  // selectedLanguage: string;
  // avatarSrc: string;
  // error: string = '';
  // success: string = '';
  // public data: SelectOption[];
  //
  // constructor(private userService: UserService,
  //             private constantsService: ConstantsService,
  //             private router: Router,
  //             private showNavService: ShowNavService,
  //             private location: Location) {
  // }
  //
  // goBack(): void {
  //   // TODO: change this route based on who is logged in
  //   this.location.back();
  //   // this.router.navigate(['/operator']);
  // }
  //
  // handleLanguageFilter(value: any) {
  //   this.data = this.languages.filter((s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  // }
  //
  // handleValueChange(value: any) {
  //
  //   let code: string;
  //   if (this.user.languages == null) {
  //     this.user.languages = [];
  //   }
  //   let idx = this.languages.findIndex(l => l.text === value);
  //   if (idx === -1) {
  //     // now we check the code
  //     idx = this.languages.findIndex(l => l.value === value);
  //     if (idx === -1) {
  //       return;
  //     } else {
  //       code = this.languages[idx].value;
  //     }
  //   } else {
  //     code = this.languages[idx].value;
  //   }
  //   this.user.languages.push(code);
  // }
  //
  // removeLanguage(idx: number): void {
  //   if (idx != null && this.user.languages.length > idx) {
  //     this.user.languages.splice(idx, 1);
  //   }
  // }
  //
  // updateProfileImage(event: any): void {
  //
  //
  //   const avatar = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
  //
  //   const pattern = /image-*/;
  //   const reader = new FileReader();
  //
  //   if (!avatar.type.match(pattern)) {
  //     return;
  //   }
  //   this.loaded = false;
  //
  //   reader.onload = this._handleReaderLoaded.bind(this);
  //   reader.readAsDataURL(avatar);
  //   if (this.avatar != null) {
  //     this.user.profile_image = this.avatar;
  //   }
  // }
  //
  // _handleReaderLoaded(e): void {
  //   const reader = e.target;
  //   this.avatarSrc = reader.result;
  //   this.loaded = true;
  // }
  //
  // saveProfile(): void {
  //
  //   if (!this.profileForm.valid) {
  //     // should loop over all required fields, do this later
  //     if (this.profileForm.controls && this.profileForm.controls.phone && !this.profileForm.controls.phone.valid) {
  //       this.profileForm.controls.phone.markAsTouched()
  //     }
  //     return;
  //   }
  //
  //   this.loading = true;
  //   if (this.fileInput && this.fileInput.nativeElement && this.fileInput.nativeElement.files) {
  //     this.user.profile_image = this.fileInput.nativeElement.files[0];
  //   }
  //   this.error = '';
  //   this.success = '';
  //   this.userService.save(this.user).then(
  //     (res) => {
  //       console.log(res);
  //       this.user = res;
  //       this.avatarSrc = res.profile_image;
  //       this.loading = false;
  //       this.success = 'Successfully saved profile';
  //       if (this.profileForm.controls && this.profileForm.controls.phone && !this.profileForm.controls.phone.valid) {
  //         this.profileForm.controls.phone.reset()
  //       }
  //     })
  //     .catch(
  //       (err) => {
  //         let errors: any;
  //         try {
  //           errors = err.json();
  //         } catch (e) {
  //           console.log(e);
  //         }
  //         if (errors && typeof errors === 'object') {
  //           const keys = Object.keys(errors);
  //           // there should be only one key - if there's more... then something went wrong
  //           for (const key of keys) {
  //             this.error = errors[key];
  //           }
  //         } else {
  //           this.error = 'An error occurred when resetting your password, please try again..';
  //         }
  //         this.loading = false;
  //       });
  //
  //
  // }
  //
  // getCurrentUser(): void {
  //   this.userService.getCurrentUser().then(
  //     (res: User) => {
  //       this.user = res;
  //       this.avatarSrc = res.profile_image;
  //     })
  //     .catch(
  //       (err) => {
  //         console.log(err);
  //       });
  // }
  //
  // getLanguages(): void {
  //   this.constantsService.get().then((choices) => {
  //     this.languages = choices.languages;
  //   }).catch((error) => {
  //     this.languages = [];
  //     console.log(error);
  //   });
  // }

  ngOnInit() {
    // this.user = new User();
    // this.getLanguages();
    // this.getCurrentUser();
    // this.showNavService.set(true);
  }

}
