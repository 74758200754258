export const SUCCESS_STATUS: string = 'success';

export class SuccessResponse {
  status: string;
}

export class ErrorResponse {
  status: string;
  error: string;
}
