import { Injectable } from '@angular/core';
import {BaseService} from '../base.service';
import {IBaseService} from '../interfaces/base-interface';
import {BehaviorSubject, throwError} from 'rxjs';
import {AuthenticationService} from '../authentication.service';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Document} from '../../core/models/document.model';

@Injectable()
export class DocumentService extends BaseService<Document> {
  currentSubject: BehaviorSubject<Document> = new BehaviorSubject(new Document());

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.document/', Document);
    this.subRoute = '';
    this.is_not_archived = '?archived=false';
    // this.date_obj_keys = ['dob'];
  }

  getFileById(id: number): Promise<Document> {
    // need to move this into the base class
    const url = this.apiUrl + id + '/download/';
    const options = this.getOptions(true);

    return this._http.get(url, options)
      .toPromise()
      .then(
        (res: HttpResponse<Document>) => {
          let ret_obj: Document;
          if (res && res.ok) {
            ret_obj = res.body;
            this.current = this.toObj(ret_obj);
            this.currentUpdated.next(this.current);
          }
          return ret_obj;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }

  getPreviewById(id: number): Promise<Document> {
    // need to move this into the base class
    const url = this.apiUrl + id + '/preview/';
    const options = this.getOptions(true);

    return this._http.get(url, options)
      .toPromise()
      .then(
        (res: HttpResponse<Document>) => {
          let ret_obj: Document;
          if (res && res.ok) {
            ret_obj = res.body;
            this.current = this.toObj(ret_obj);
            this.currentUpdated.next(this.current);
          }
          return ret_obj;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }

}
