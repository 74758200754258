import {Injectable, TemplateRef} from '@angular/core';
import {Procedure} from '../../core/models/procedure.model';
import {IBaseViewService} from '../interfaces/base-view.interface';
import {NumTrial} from '../../core/models/num-trial.model';
import {ReinforcementSchedule} from '../../core/models/reinforcement-schedule.model';
import {InitialTactic} from '../../core/models/initial-tactic.model';
import {InitialTacticService} from '../../core/services/initial-tactic.service';
import {ReinforcementScheduleService} from '../../core/services/reinforcement-schedule.service';
import {NumTrialService} from '../../core/services/num-trial.service';
import {Observable, BehaviorSubject, of, throwError} from 'rxjs';
import {DataSheetService} from '../../core/services/data-sheet.service';
import {DataSheet} from '../../core/models/data-sheet.model';
import {ProcedureService} from '../../core/services/procedure.service';
import {GraphData} from '../../core/models/graph-data.model';
import {PatientService} from '../../core/services/patient.service';
import * as _ from 'lodash';
import {ProcedureStepService} from '../../core/services/procedure-step.service';
import {ProcedureStep} from '../../core/models/procedure-step.model';
import {PromptFadingStep} from '../../core/models/prompt-fading-step.model';
import {PromptFadingStepService} from '../../core/services/prompt-fading-step.service';
import {SdResponse} from '../../core/models/sd-response.model';
import {SdResponseService} from '../../core/services/sd-response.service';
import {ProcItem} from '../../core/models/proc-item.model';
import {ProcItemService} from '../../core/services/proc-item.service';
import {MasteryCriteria} from '../../core/models/mastery-criteria.model';
import {MasteryCriteriaService} from '../../core/services/mastery-criteria.service';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {SkillObjective} from '../../core/models/skill-objective.model';
import {SkillObjectiveService} from '../../core/services/skill-objective.service';
import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {ToastyService} from 'ng2-toasty';
import * as moment from 'moment';

@Injectable()
export class ProcedureViewService implements IBaseViewService<Procedure> {

  currentSubjectType: BehaviorSubject<string> = new BehaviorSubject('');
  phaseChangeDescriptionSubject: BehaviorSubject<string> = new BehaviorSubject('');

  currentSubject: BehaviorSubject<Procedure> = new BehaviorSubject(new Procedure());
  currentSubjects: BehaviorSubject<Procedure[]> = new BehaviorSubject([]);
  currentSubjectsFiltered: BehaviorSubject<Procedure[]> = new BehaviorSubject([]);
  currentNumTrials: BehaviorSubject<NumTrial[]> = new BehaviorSubject([]);
  currentReinforcements: BehaviorSubject<ReinforcementSchedule[]> = new BehaviorSubject([]);
  currentInitialTactics: BehaviorSubject<InitialTactic[]> = new BehaviorSubject([]);
  currentProcedureSteps: BehaviorSubject<ProcedureStep[]> = new BehaviorSubject([]);
  currentPromptFadingSteps: BehaviorSubject<PromptFadingStep[]> = new BehaviorSubject([]);
  currentSdResponses: BehaviorSubject<SdResponse[]> = new BehaviorSubject([]);
  currentProcItems: BehaviorSubject<ProcItem[]> = new BehaviorSubject([]);
  currentMasteryCriteriaItems: BehaviorSubject<MasteryCriteria[]> = new BehaviorSubject([]);
  promptFadingStepsToDelete: BehaviorSubject<PromptFadingStep[]> = new BehaviorSubject([]);

  updateCurrentSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  overrideDialogSizeSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  currentSavedObj: any[] = [];
  currentObjSaving = '';

  keys_to_search: string[] = ['name'];
  test_sd_data: SdResponse[];
  dialog: DialogRef;

  constructor(private reinforcementScheduleService: ReinforcementScheduleService,
              private numTrialService: NumTrialService,
              private initialTacticService: InitialTacticService,
              private dataSheetService: DataSheetService,
              private procedureService: ProcedureService,
              private patientService: PatientService,
              private procedureStepService: ProcedureStepService,
              private promptFadingStepService: PromptFadingStepService,
              private sdResponseService: SdResponseService,
              private procItemService: ProcItemService,
              private skillObjectiveService: SkillObjectiveService,
              private masteryCriteriaService: MasteryCriteriaService,
              private dialogService: DialogService,
              private toastyService: ToastyService) {
    this.test_sd_data = Array.apply(null, Array(5))
      .map((curr, idx) => new SdResponse(idx, idx, 'Test-SD-' + idx, 'code-' + idx));
  }

  // TODO: move to base service by passing in the context as a parameter instead of creating it with const that = this
  // need to do this because when processArray tries to call the function it doesn't know where it's calling from
  processArray(array, fn): Promise<any> {
    const results = [];
    const that = this;
    return array.reduce(function (p, item, curr_idx) {
      return p.then(function (val) {
        // we do this to skip the first 'promise resolve'
        if (curr_idx > 0) {
          that.currentSavedObj.push(val);
        }
        return fn.call(that, item);
      });
    }, Promise.resolve());
  }

  search(status?: string, search_text?: string): Promise<Procedure[]> {
    let promise: Promise<Procedure[]>;
    let params: any;
    if (status) {
      params = {
        status: status
      }
    }
    // if (search_text) {
    //   if (!params) {
    //     params = {};
    //   }
    // }
    const patient = this.patientService.currentPatient.getValue();
    if (!params) {
      params = {};
    }
    params['patient'] = patient.id.toString();

    promise = this.procedureService.getByParamObjPromise(params);

    return promise
      .then((filtered: Procedure[]) => {
        // check through all keys, not sure if there is an aggregate yet
        let result = [];
        if (search_text) {
          for (const item of filtered) {
            // const keys = Object.keys(item);
            for (const key of this.keys_to_search) {
              if (typeof item[key] === 'string' &&
                item[key].toLocaleLowerCase().indexOf(search_text.toLocaleLowerCase()) > -1) {
                result.push(_.cloneDeep(item));
                break;
              }
            }
          }
        } else {
          result = filtered;
        }
        this.currentSubjectsFiltered.next(result);
        return result;
      })
      .catch((err) => throwError(err).toPromise());
  }

  save(): Promise<Procedure> {
    let procedure = this.currentSubject.getValue();
    // first we fire off the prompt fading deletion
    const prompts = this.promptFadingStepsToDelete.getValue();
    if (prompts && prompts.length > 0) {
      this.processArray(prompts, this.removePromptFadingStep);
    }
    return this.procedureService.save(procedure)
      .then(
        (updated: Procedure) => {
          procedure = updated;
          this.currentSubject.next(procedure);
          this.currentSavedObj = [];
          // return procedure;
          this.currentObjSaving = 'currentProcedureSteps';
          let steps = this.currentProcedureSteps.getValue();
          if (steps && steps.length > 0) {
            for (let idx = 0; idx < steps.length; idx++) {
              steps[idx].procedure = procedure.id;
              steps[idx]._order = idx;
              if(!steps[idx].mastery_date_date) {
                steps[idx].mastery_date = null;
              }
            }
          } else {
            steps = [];
          }
          return this.processArray(steps, this.saveProcedureSteps);
        })
      .then((last_procedure_step: ProcedureStep) => {
        if (last_procedure_step) {
          const idx = this.currentSavedObj.findIndex(cso => cso.id == last_procedure_step.id);
          if (idx === -1) {
            this.currentSavedObj.push(last_procedure_step);
          }
        }
        this.currentProcedureSteps.next(this.currentSavedObj.slice());
        this.currentSavedObj = [];

        this.currentObjSaving = 'currentPromptFadingSteps';
        let steps = this.currentPromptFadingSteps.getValue();
        if (steps && steps.length > 0) {
          for (let idx = 0; idx < steps.length; idx++) {
            steps[idx].procedure = procedure.id;
            steps[idx]._order = idx;
          }
        } else {
          steps = [];
        }
        // loop through the num trials and save them
        return this.processArray(steps, this.savePromptFadingStep)
      })
      .then((last_prompt_fading_step: PromptFadingStep) => {
        if (last_prompt_fading_step) {
          const idx = this.currentSavedObj.findIndex(cso => cso.id == last_prompt_fading_step.id);
          if (idx === -1) {
            this.currentSavedObj.push(last_prompt_fading_step);
          }
        }
        this.currentPromptFadingSteps.next(this.currentSavedObj.slice());
        this.currentSavedObj = [];

        this.currentObjSaving = 'currentNumTrials';
        let num_trials = this.currentNumTrials.getValue();
        if (num_trials && num_trials.length > 0) {
          for (const num_trial of num_trials) {
            num_trial.procedure = procedure.id;
          }
        } else {
          num_trials = [];
        }
        // loop through the num trials and save them
        return this.processArray(num_trials, this.saveNumTrial)
      })
      .then(
        (last_num_trial) => {
          if (last_num_trial) {
            const idx = this.currentSavedObj.findIndex(cso => cso.id == last_num_trial.id);
            if (idx === -1) {
              this.currentSavedObj.push(last_num_trial);
            }
          }
          this.currentNumTrials.next(this.currentSavedObj.slice());
          this.currentSavedObj = [];

          this.currentObjSaving = 'currentReinforcements';
          let reinforcements = this.currentReinforcements.getValue();
          if (reinforcements && reinforcements.length > 0) {
            for (const reinforcement of reinforcements) {
              reinforcement.procedure = procedure.id;
            }
          } else {
            reinforcements = [];
          }

          // now loop through the reinforcement schedules and save them
          return this.processArray(reinforcements, this.saveReinforcementSchedule);
        })
      .then(
        (last_schedule) => {
          if (last_schedule) {
            const idx = this.currentSavedObj.findIndex(cso => cso.id == last_schedule.id);
            if (idx === -1) {
              this.currentSavedObj.push(last_schedule);
            }
          }
          this.currentReinforcements.next(this.currentSavedObj.slice());
          this.currentSavedObj = [];

          this.currentObjSaving = 'currentInitialTactics';
          let initial_tactics = this.currentInitialTactics.getValue();
          if (initial_tactics && initial_tactics.length > 0) {
            for (const initial_tactic of initial_tactics) {
              initial_tactic.procedure = procedure.id;
            }
          } else {
            initial_tactics = [];
          }
          // now loop through the initial_tactics and save them
          return this.processArray(initial_tactics, this.saveInitialTactic);
        })
      .then((last_initial_tactic: InitialTactic) => {
        if (last_initial_tactic) {
          const idx = this.currentSavedObj.findIndex(cso => cso.id == last_initial_tactic.id);
          if (idx === -1) {
            this.currentSavedObj.push(last_initial_tactic);
          }
        }
        this.currentInitialTactics.next(this.currentSavedObj.slice());
        this.currentSavedObj = [];

        this.currentObjSaving = 'currentSdResponses';
        let responses = this.currentSdResponses.getValue();
        if (responses && responses.length > 0) {
          for (let idx = 0; idx < responses.length; idx++) {
            responses[idx].procedure = procedure.id;
            responses[idx]._order = idx;
          }
        } else {
          responses = [];
        }
        // loop through the num trials and save them
        return this.processArray(responses, this.saveSdResponse)
      })
      .then((last_sd_response: SdResponse) => {
        if (last_sd_response) {
          const idx = this.currentSavedObj.findIndex(cso => cso.id == last_sd_response.id);
          if (idx === -1) {
            this.currentSavedObj.push(last_sd_response);
          }
        }
        this.currentSdResponses.next(this.currentSavedObj.slice());
        this.currentSavedObj = [];

        this.currentObjSaving = 'currentProcItems';
        let proc_items = this.currentProcItems.getValue();
        if (proc_items && proc_items.length > 0) {
          for (let idx = 0; idx < proc_items.length; idx++) {
            proc_items[idx].procedure = procedure.id;
            proc_items[idx]._order = idx;
            if(!proc_items[idx].mastery_date_date) {
              proc_items[idx].mastery_date = null;
            }
          }
        } else {
          proc_items = [];
        }
        // loop through the num trials and save them
        return this.processArray(proc_items, this.saveProcItem)
      })
      .then((last_proc_item: ProcItem) => {
        if (last_proc_item) {
          const idx = this.currentSavedObj.findIndex(cso => cso.id == last_proc_item.id);
          if (idx === -1) {
            this.currentSavedObj.push(last_proc_item);
          }
        }
        this.currentProcItems.next(this.currentSavedObj.slice());
        this.currentSavedObj = [];

        this.currentObjSaving = 'currentMasteryCriteriaItems';
        let mastery_criteria_items = this.currentMasteryCriteriaItems.getValue();
        if (mastery_criteria_items && mastery_criteria_items.length > 0) {
          for (let idx = 0; idx < mastery_criteria_items.length; idx++) {
            mastery_criteria_items[idx].procedure = procedure.id;
            mastery_criteria_items[idx]._order = idx;
          }
        } else {
          mastery_criteria_items = [];
        }
        // loop through the num trials and save them
        return this.processArray(mastery_criteria_items, this.saveMasteryCriteria)
      })
      .then((last_mastery_criteria) => {
        if (last_mastery_criteria) {
          const idx = this.currentSavedObj.findIndex(cso => cso.id == last_mastery_criteria.id);
          if (idx === -1) {
            this.currentSavedObj.push(last_mastery_criteria);
          }
        }

        this.currentMasteryCriteriaItems.next(this.currentSavedObj.slice());
        this.currentSavedObj = [];

        return procedure;
      })
      .catch((err) => {
        switch (this.currentObjSaving) {
          case 'currentProcedureSteps':
            this.currentProcedureSteps.next(this.currentSavedObj);
            break;
          case 'currentPromptFadingSteps':
            this.currentPromptFadingSteps.next(this.currentSavedObj);
            break;
          case 'currentNumTrials':
            this.currentNumTrials.next(this.currentSavedObj);
            break;
          case 'currentReinforcements':
            this.currentReinforcements.next(this.currentSavedObj);
            break;
          case 'currentInitialTactics':
            this.currentInitialTactics.next(this.currentSavedObj);
            break;
          case 'currentSdResponses':
            this.currentSdResponses.next(this.currentSavedObj);
            break;
          case 'currentProcItems':
            this.currentProcItems.next(this.currentSavedObj);
            break;
          case 'currentMasteryCriteriaItems':
            this.currentMasteryCriteriaItems.next(this.currentSavedObj);
            break;
        }
        return throwError(err).toPromise();
      });
  }

  update(): Promise<Procedure> {
    return Promise.resolve(<Procedure>{});
  }

  getSubjects(): void {
    const patient = this.patientService.currentPatient.getValue();

    this.procedureService.getByParamPromise(patient.id.toString(), 'patient')
      .then((procedures: Procedure[]) => {
        this.currentSubjects.next(procedures);
      })
      .catch((err) => {
        // TODO: need to show toasty with error or bubble error up
      });
  }

  getProcedures(patient: number): Promise<Procedure[]> {
    return this.procedureService.getByParamPromise(patient.toString(), 'patient')
      .then((procedures: Procedure[]) => {
        this.currentSubjects.next(procedures);
        return procedures;
      })
      .catch((err) => {
        // TODO: need to show toasty with error or bubble error up
        return throwError(err).toPromise();
      });
  }

  getProceduresByIds(ids: number[]): Promise<Procedure[]> {
    ids = ids.filter(i => i != null);
    const params = {};
    if (ids.length == 1) {
      params['id'] = ids[0];
    } else {
      params['id__in'] = ids;
    }
    return this.procedureService.getByParamObjPromise(params, true)
      .then((procedures: Procedure[]) => {
        this.currentSubjects.next(procedures);
        return procedures;
      })
      .catch((err) => {
        // TODO: need to show toasty with error or bubble error up
        return throwError(err).toPromise();
      });
  }

  getProceduresByIds$(ids: number[]): Observable<Procedure[]> {
    ids = ids.filter(i => i != null);
    const params = {};
    if (ids.length == 1) {
      params['id'] = ids[0];
    } else {
      params['id__in'] = ids;
    }
    return this.procedureService.getByParamObj(params, true)
      .pipe(
        map((procedures: Procedure[]) => {
        this.currentSubjects.next(procedures);
        return procedures;
      }),
      catchError((err) => {
        // TODO: need to show toasty with error or bubble error up
        return throwError(err);
      })
      );
  }

  getByPatient(id: string): Promise<Procedure[]> {
    return this.procedureService.getByParamPromise(id, 'patient')
      .then((procedures: Procedure[]) => {
        return procedures;
      })
      .catch(
        err => {
          return throwError(err).toPromise();
        });
  }


  getReinforcementSchedules(): Promise<ReinforcementSchedule[]> {
    const service_plan = this.currentSubject.getValue();
    return this.reinforcementScheduleService
      .getByParamPromise(service_plan.id.toString(), 'procedure')
      .then(
        (schedule) => {
          return schedule;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  saveReinforcementSchedule(reinforcement: ReinforcementSchedule): Promise<ReinforcementSchedule> {
    return this.reinforcementScheduleService
      .save(reinforcement)
      .then(
        (schedule) => {
          return schedule;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  removeProcedure(procedure: Procedure): Promise<boolean> {
    return this.procedureService
      .removeObj(procedure)
      .then(
        (response: boolean) => {
          return response;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  removeReinforcementSchedule(reinforcement: ReinforcementSchedule): Promise<boolean> {
    return this.reinforcementScheduleService
      .removeObj(reinforcement)
      .then(
        (response: boolean) => {
          return response;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getInitialTactics(): Promise<InitialTactic[]> {
    const service_plan = this.currentSubject.getValue();
    return this.initialTacticService
      .getByParamPromise(service_plan.id.toString(), 'procedure')
      .then(
        (schedule) => {
          return schedule;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getInitialTactics$(): Observable<InitialTactic[]> {
    const service_plan = this.currentSubject.getValue();
    return this.initialTacticService
      .getByParam(service_plan.id.toString(), 'procedure')
      .pipe(
        map((schedule) => {
          return schedule;
        }),
        catchError((err) => {
          return throwError(err).toPromise();
        }));
  }

  getAllInitialTactics$(): Observable<InitialTactic[]> {
    return this.initialTacticService.getAll()
      .pipe(
        map((initialTactics: InitialTactic[]) => {
          return initialTactics;
        }),
        catchError((err) => {
          return throwError(err).toPromise();
        }));
  }

  saveInitialTactic(initial_tactic: InitialTactic): Promise<InitialTactic> {
    return this.initialTacticService
      .save(initial_tactic)
      .then(
        (response: InitialTactic) => {
          return response;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  removeInitialTactic(initial_tactic: InitialTactic): Promise<boolean> {
    return this.initialTacticService
      .removeObj(initial_tactic)
      .then(
        (response: boolean) => {
          return response;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getPromptFadingSteps(): Promise<PromptFadingStep[]> {
    const service_plan = this.currentSubject.getValue();
    return this.promptFadingStepService
      .getByParamPromise(service_plan.id.toString(), 'procedure')
      .then(
        (steps: PromptFadingStep[]) => {
          return steps;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getPromptFadingStepsByIds$(ids: number[]): Observable<PromptFadingStep[]> {
    ids = ids.filter(i => i != null);
    const params = {};
    if (ids.length == 1) {
      params['id'] = ids[0];
    } else {
      params['id__in'] = ids;
    }
    return this.promptFadingStepService.getByParamObj(params, true)
      .pipe(
        map((prompt_steps: PromptFadingStep[]) => {
          return prompt_steps;
        }),
        catchError((err) => {
          // TODO: need to show toasty with error or bubble error up
          return throwError(err);
        })
      );
  }

  savePromptFadingStep(step: PromptFadingStep): Promise<PromptFadingStep> {
    return this.promptFadingStepService
      .save(step)
      .then(
        (response: PromptFadingStep) => {
          return response;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  removePromptFadingStep(step: PromptFadingStep): Promise<boolean> {
    return this.promptFadingStepService
      .removeObj(step)
      .then(
        (response: boolean) => {
          return response;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  removeProcedureStep(step: ProcedureStep): Promise<boolean> {
    return this.procedureStepService
      .removeObj(step)
      .then(
        (response: boolean) => {
          return response;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getNumTrials(): Promise<NumTrial[]> {
    const procedure = this.currentSubject.getValue();
    return this.numTrialService
      .getByParamPromise(procedure.id.toString(), 'procedure')
      .then(
        (schedule) => {
          return schedule;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getNumTrials$(): Observable<NumTrial[]> {
    const procedure = this.currentSubject.getValue();
    return this.numTrialService
      .getByParam(procedure.id.toString(), 'procedure')
      .pipe(
        map((schedule) => {
          return schedule;
        }));
  }

  saveNumTrial(num_trial: NumTrial): Promise<NumTrial> {
    return this.numTrialService
      .save(num_trial)
      .then(
        (trial) => {
          return trial;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  removeNumTrial(num_trial: NumTrial): Promise<boolean> {
    return this.numTrialService
      .removeObj(num_trial)
      .then(
        (response: boolean) => {
          return response;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getDataSheets(): Promise<DataSheet[]> {
    let promise: Promise<DataSheet[]>;
    if (this.dataSheetService.collection && this.dataSheetService.collection.length > 0) {
      promise = of(this.dataSheetService.collection).toPromise();
    } else {
      promise = this.dataSheetService
        .getAllPromise()
        .then(
          (data_sheets) => {
            return data_sheets;
          })
        .catch(
          (err) => {
            return throwError(err).toPromise();
          });
    }
    return promise;
  }

  getDataSheets$(): Observable<DataSheet[]> {
    let obs: Observable<DataSheet[]>;
    if (this.dataSheetService.collection && this.dataSheetService.collection.length > 0) {
      obs = of(this.dataSheetService.collection);
    } else {
      obs = this.dataSheetService
        .getAll()
        .pipe(map(
          (data_sheets) => {
            return data_sheets;
          }),
          catchError((err) => {
            return throwError(err).toPromise();
          })
        );
    }
    return obs;
  }

  getSkillObjectives$(): Observable<SkillObjective[]> {
    let obs: Observable<SkillObjective[]>;
    if (this.skillObjectiveService.collection && this.skillObjectiveService.collection.length > 0) {
      obs = of(this.skillObjectiveService.collection);
    } else {
      obs = this.skillObjectiveService
        .getAll()
        .pipe(map(
          (skill_objectives) => {
            return skill_objectives;
          }),
          catchError((err) => {
            return throwError(err).toPromise();
          })
        );
    }
    return obs;
  }

  getSkillObjectivesById$(skill_id: string, key?: string): Observable<SkillObjective[]> {
    return this.skillObjectiveService
      .getByParam(skill_id, key)
      .pipe(map(
        (skill_objectives) => {
          return skill_objectives;
        }),
        catchError((err) => {
          return throwError(err).toPromise();
        })
      );
  }

  getGraphData(): Promise<GraphData> {
    const procedure = this.currentSubject.getValue();
    return this.procedureService.getGraphData(procedure.id.toString())
      .then((data_points: GraphData) => {
        return data_points;
      })
      .catch(
        (err) => {
          if (err.status === 400) {
            if (err.error && err.error.error) {
              const errData = {
                msg: err.error.error,
                title: 'error'
              };
              this.toastyService.error(errData);
            }
          }
          return throwError(err).toPromise();
        });
  }

  getProcListHtml(): Promise<string> {
    const procedure = this.currentSubject.getValue();
    return this.procedureService.getItemsList(procedure.id.toString())
      .then((proc_list_html: string) => {
        // need to mark the html as safe in the pipe
        return proc_list_html;
      })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getRawData(): Observable<any> {
    const procedure = this.currentSubject.getValue();
    return this.procedureService.getRawData(procedure.id.toString())
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  getProcedureStepsById(id: number): Promise<ProcedureStep[]> {
    if (id == null) {
      throw new Error('No procedure found');
    }
    const params = {
      procedure: id
    };
    return this.procedureStepService.getByParamObjPromise(params)
      .then((steps: ProcedureStep[]) => {
        return steps;
      }).catch((err) => throwError(err).toPromise());
  }

  getProcedureSteps(): Promise<ProcedureStep[]> {
    const procedure = this.currentSubject.getValue();
    if (!procedure) {
      throw new Error('No procedure found');
    }
    const params = {
      procedure: procedure.id
    };
    return this.procedureStepService.getByParamObjPromise(params)
      .then((steps: ProcedureStep[]) => {
        return steps;
      }).catch((err) => throwError(err).toPromise());
  }

  saveProcedureSteps(step: ProcedureStep): Promise<ProcedureStep> {
    return this.procedureStepService.save(step)
      .then((new_step: ProcedureStep) => {
        return new_step;
      }).catch((err) => throwError(err).toPromise());
  }

  saveSdResponse(step: SdResponse): Promise<SdResponse> {
    return this.sdResponseService.save(step)
      .then((response: SdResponse) => {
        return response;
      }).catch((err) => throwError(err).toPromise());
  }

  getSdResponses(procedure?: Procedure, get_all?: boolean): Promise<SdResponse[]> {
    procedure = procedure == null ? this.currentSubject.getValue() : procedure;
    if (!procedure) {
      throw new Error('No procedure found');
    }
    const params = {
      procedure: procedure.id
    };
    params['archived'] = get_all == null ? false : get_all;
    return this.sdResponseService.getByParamObjPromise(params)
      .then((responses: SdResponse[]) => {
        return responses;
      }).catch((err) => throwError(err).toPromise());
  }

  getSdResponsesByProcedureIds(ids: number[], get_all?: boolean): Promise<SdResponse[]> {
    const params = {
      'procedure__in': ids
    };
    params['archived'] = get_all == null ? false : get_all;
    return this.sdResponseService.getByParamObjPromise(params)
      .then((sd_responses: SdResponse[]) => {
        return sd_responses;
      })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getSdResponsesByProcedureIds$(ids: number[], get_all?: boolean): Observable<SdResponse[]> {
    const params = {
      'procedure__in': ids
    };
    if (!get_all) {
      params['archived'] = false;
    }
    return this.sdResponseService.getByParamObj(params, get_all)
      .pipe(map((sd_responses: SdResponse[]) => {
        return sd_responses;
      }),
      catchError(
        (err) => {
          return throwError(err);
        })
      );
  }

  removeSdResponse(sd_response: SdResponse): Promise<boolean> {
    return this.sdResponseService
      .removeObj(sd_response)
      .then(
        (response: boolean) => {
          return response;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  saveProcItem(proc_item: ProcItem): Promise<ProcItem> {
    return this.procItemService.save(proc_item)
      .then((proc_item_resp: ProcItem) => {
        return proc_item_resp;
      }).catch((err) => throwError(err).toPromise());
  }

  getProcItems(): Promise<ProcItem[]> {
    const procedure = this.currentSubject.getValue();
    if (!procedure) {
      throw new Error('No procedure found');
    }
    const params = {
      procedure: procedure.id
    };
    return this.procItemService.getByParamObjPromise(params)
      .then((responses: ProcItem[]) => {
        return responses;
      }).catch((err) => throwError(err).toPromise());
  }

  removeProcItem(proc_item: ProcItem): Promise<boolean> {
    return this.procItemService
      .removeObj(proc_item)
      .then(
        (response: boolean) => {
          return response;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }


  saveMasteryCriteria(mastery_criteria: MasteryCriteria): Promise<MasteryCriteria> {
    return this.masteryCriteriaService.save(mastery_criteria)
      .then((mastery_criteria_resp: MasteryCriteria) => {
        return mastery_criteria_resp;
      }).catch((err) => throwError(err).toPromise());
  }

  getMasteryCriteriaItems(): Promise<MasteryCriteria[]> {
    const procedure = this.currentSubject.getValue();

    if (!procedure) {
      throw new Error('No procedure found');
    }

    const params = {
      procedure: procedure.id
    };
    return this.masteryCriteriaService.getByParamObjPromise(params)
      .then((responses: MasteryCriteria[]) => {
        let new_obj: MasteryCriteria;
        const ret_objs: MasteryCriteria[] = [];
        // TODO: find a way to push this to the base service ot to the model (or maybe a DTO)
        for (const response of responses) {
          new_obj = new MasteryCriteria();
          new_obj = new_obj.toObj(response);
          ret_objs.push(_.cloneDeep(new_obj));
        }
        return ret_objs;
      }).catch((err) => throwError(err).toPromise());
  }

  removeMasteryCriteria(mastery_criteria: MasteryCriteria): Promise<boolean> {
    return this.masteryCriteriaService
      .removeObj(mastery_criteria)
      .then(
        (response: boolean) => {
          return response;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getById(id: number): Promise<Procedure> {
    return undefined;
  }

  incrementPhaseNumber(): void {
    const to_update = this.updateCurrentSubject.getValue();
    if (to_update) {
      if (confirm('Do you want to increment the phase number?')) {
        const procedure = this.currentSubject.getValue();
        procedure.increment_phase = true;
        this.currentSubject.next(procedure);
      }
      this.updateCurrentSubject.next(false);
    }
  }

  incrementPhaseNumberDialog(dialogTemplate?: TemplateRef<any>, dialogActionTemplate?: TemplateRef<any>): Observable<any> | void {
    const to_update = this.updateCurrentSubject.getValue();
    if (to_update) {
      this.phaseChangeDescriptionSubject.next('');
      this.overrideDialogSizeSubject.next(true);
      this.dialog = this.dialogService.open({
        title: 'Phase Number',
        // content: `Would you like to implement a phase change line for these changes?`,
        content: dialogTemplate,
        // actions: [
        //   {text: 'No'},
        //   {text: 'Yes', primary: true}
        // ],
        actions: dialogActionTemplate,
        height: 250,
        minWidth: 450,
        // appendTo: dialogTemplate
      });

      return this.dialog.result.pipe(
        mergeMap((result) => {
          // defaulting observable to an empty observable so we can subscribe to all cases
          let obs: Observable<any> = of();
          console.log('result', result)
          const phaseChangeDescription = this.phaseChangeDescriptionSubject.getValue()
          console.log('phase number description text:', phaseChangeDescription)
          if (result instanceof DialogCloseResult) {
            console.log('close');
          } else {
            if (result && result.text.toLocaleLowerCase() === 'yes') {
              const procedure = this.currentSubject.getValue(),
                  timestamp = moment.utc(new Date()).format()

              // now we save the change log instead of the procedure
              const phaseLine = {
                description: phaseChangeDescription,
                timestamp: timestamp,
                procedure: procedure.id,
                type: 'PHASE_CHANGE',
                publish_status: 'published',
              }
              obs = this.procedureService.addPhaseChangeLog(phaseLine)
            }
          }
          // this.dialog = null;
          this.overrideDialogSizeSubject.next(false);
          return obs;
        })
      );
    }
  }

  public getPhaseChangeDescription() {
    const description = this.phaseChangeDescriptionSubject.getValue()
    return description && description.trim()
  }

  public closePhaseChangeDialog() {
    this.dialog.close()
  }

  public savePhaseChange() {
    const procedure = this.currentSubject.getValue(),
    timestamp = moment.utc(new Date()).format()

    const phaseChangeDescription = this.phaseChangeDescriptionSubject.getValue()
    console.log('phase number description text:', phaseChangeDescription)

    // now we save the change log instead of the procedure
    const phaseLine = {
      description: phaseChangeDescription,
      timestamp: timestamp,
      procedure: procedure.id,
      type: 'PHASE_CHANGE',
      publish_status: 'published',
    }

    return this.procedureService.addPhaseChangeLog(phaseLine)
  }

}
