import { Injectable } from '@angular/core';
import {BaseService} from '../base.service';
import {IBaseService} from '../interfaces/base-interface';
import {ContentType} from '../models/content-type.model';
import {BehaviorSubject} from 'rxjs';
import {AuthenticationService} from '../authentication.service';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ContentTypeService extends BaseService<ContentType> {
  currentSubject: BehaviorSubject<ContentType> = new BehaviorSubject(new ContentType());

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'contenttypes.contenttype/', ContentType);
    this.subRoute = '';
    this.is_not_archived = '?archived=false';
  }

}
