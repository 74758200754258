import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PatientRoutingModule} from './patient-routing.module';
import {DetailsModule} from './details/details.module';
import {PatientComponent} from './patient.component';
import {ServicePlanModule} from './service-plan/service-plan.module';
import {CoreModule} from '../core/core.module';
import {AssessmentsModule} from './assessments/assessments.module';
import {SapsModule} from './saps/saps.module';
import {CommunicationsModule} from './communications/communications.module';
import {ContactsModule} from './contacts/contacts.module';
import {ProgramSupervisionModule} from './program-supervision/program-supervision.module';
import {TabStripModule} from '@progress/kendo-angular-layout';
import {BehaviourInterventionsModule} from './behaviour-interventions/behaviour-interventions.module';
import {PatientViewService} from './patient-view.service';
import {MatTabsModule} from '@angular/material';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';
import {DocumentsModule} from './documents/documents.module';
import {SessionsModule} from './sessions/sessions.module';
import {HttpClientModule} from '@angular/common/http';
import {ReportsModule} from './reports/reports.module';
import {TrainingModule} from './training/training.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


@NgModule({
  imports: [
    CommonModule,
    PatientRoutingModule,
    DetailsModule,
    ServicePlanModule,
    AssessmentsModule,
    BehaviourInterventionsModule,
    SapsModule,
    CommunicationsModule,
    ContactsModule,
    ProgramSupervisionModule,
    ReportsModule,
    TrainingModule,
    CoreModule,
    TabStripModule,
    HttpClientModule,
    MatTabsModule,
    DateInputsModule,
    DocumentsModule,
    SessionsModule,
    BrowserAnimationsModule
  ],
  declarations: [
    PatientComponent
  ],
  providers: [
    PatientViewService
  ]
})
export class PatientModule { }
