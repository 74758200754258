import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {BaseComponent} from '../../../shared/base/base.component';
import {NgForm} from '@angular/forms';
import {TherapySession} from '../../../core/models/therapy-session.model';
import {ProcedureSession} from '../../../core/models/procedure-session.model';
import {User} from '../../../core/models/user.model';
import {Procedure} from '../../../core/models/procedure.model';
import {ProcedureStep} from '../../../core/models/procedure-step.model';
import {DailySessionViewService} from '../../../shared/services/daily-session-view.service';
import {SelectOption} from '../../../core/models/select-option.model';
import {CurrentFormService} from '../../../core/services/current-form.service';
import {DataSheet} from '../../../core/models/data-sheet.model';
import {ProcItem} from '../../../core/models/proc-item.model';
import {PromptFadingStep} from '../../../core/models/prompt-fading-step.model';
import {ProcedureService} from '../../../core/services/procedure.service';
import {DialogService} from '@progress/kendo-angular-dialog';
import {ConstantsService} from '../../../core/services/constants.service';
import {UserViewService} from '../../../shared/services/user-view.service';
import {PatientService} from '../../../core/services/patient.service';
import {ToastData, ToastOptions, ToastyService} from 'ng2-toasty';
import {UserService} from '../../../core/services/user.service';
import {ProcedureViewService} from '../../../shared/services/procedure-view.service';
import {DataSheetService} from '../../../core/services/data-sheet.service';
import {ShowNavService} from '../../../core/services/show-nav.service';
import {Subscription, throwError, of, Observable} from 'rxjs/index';
import {SessionTrial} from '../../../core/models/session-trial.model';
import {FilterViewService} from '../../../shared/services/filter-view.service';
import {Moment} from 'moment';
import {BehaviorSubject} from 'rxjs';
import * as _ from 'lodash';
import {SheetInfo} from '../../../core/models/sheet-info.model';
import {SessionData} from '../../../core/models/session-data.model';
import {CommonService} from '../../../core/shared/common/common.service';
import {BaseSessionComponent} from '../../../shared/base-session/base-session.component';
import {map, catchError} from 'rxjs/operators';

@Component({
  selector: 'app-task-analysis',
  templateUrl: './task-analysis.component.html',
  styleUrls: ['./task-analysis.component.css']
})
export class TaskAnalysisComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('taskAnalysisSessionForm') public currentForm: any;

  @Input() data_sheet_type: string;
  // dataSheetForPage: string = 'DT';
  private _data_sheets = new BehaviorSubject<any[]>([]);
  private _patient = new BehaviorSubject<any>({});
  private _sheet_info = new BehaviorSubject<any>({});
  private _is_readonly: boolean;
  private _readonly_data: any;

  cmp_name: string;
  // TODO: need to extend BaseSessionComponent instead so below and other properties are inherited
  proc_items_to_show: string[] = ['baseline', 'training'];

  getting_data: boolean = false;
  taskAnalysisSessionForm: NgForm;
  search_list: any[];
  filtered: any[];
  DELIMITER: string = '&';
  toastyId: number;
  have_proc_sessions: boolean = true;

  DEFAULT_SESSION_NUM = 3;
  selected_date: Date;
  procedure_sessions: ProcedureSession[];
  therapy_session: TherapySession;
  // patient: User;
  procedures: Procedure[];
  all_procedures: Procedure[];
  prompt_types: SelectOption[];
  stages: SelectOption[];
  dailySessionViewService: DailySessionViewService;
  currentFormService: CurrentFormService;
  daily_session_trial_options: SelectOption[];
  max_col_span: number;
  current_user: User;
  data_sheet: DataSheet;
  procedure_steps: ProcedureStep[];
  current_procedure_id: number;
  current_procedure: Procedure;
  // session_trials: Map<number, SessionTrial[]>;
  prompt_steps: PromptFadingStep[];
  proc_items: ProcItem[];
  selected_host: number;
  all_procedure_sessions: ProcedureSession[];
  selected_filter: string;
  loading: boolean;
  trial_timeout_map: Map<number, Map<string, any[]>> = new Map<number, Map<string, any[]>>();
  procedure_session_timeout_map: Map<number, any> = new Map<number, any>();
  trial_click_queue = [];
  trial_timeout: Map<number, any>;
  procedure_session_timeout: any;
  current_therapist: User;
  saving_trial: boolean;
  phase: number;
  prompt_step: number;
  training_step: number;
  stage: string;
  procedure_step_filter: number[];
  procedure_session_defaults: any[];
  phase_type_tactics_list: any[];
  phase_type_tactic: any;
  isPhaseError: boolean

  dailySessionSave: Subscription;
  dailySessionSaving: Subscription;

  // objects for readonly view
  steps: string[];
  readonly_sessions: SessionData[];
  max_trials: number;

  dailySessionsSavedSubscription: Subscription;
  therapySessionSubscription: Subscription;

  constructor(dialogService: DialogService,
              constantsService: ConstantsService,
              dailySessionViewService: DailySessionViewService,
              userViewService: UserViewService,
              private procedureService: ProcedureService,
              private patientService: PatientService,
              currentFormService: CurrentFormService,
              private showNavService: ShowNavService,
              private userService: UserService,
              private dataSheetService: DataSheetService,
              private procedureViewService: ProcedureViewService,
              toastyService: ToastyService,
              private filterViewService: FilterViewService,
              private commonService: CommonService) {
    super(constantsService, userViewService, dialogService, dailySessionViewService, currentFormService, toastyService);
    this.dailySessionViewService = dailySessionViewService;
    this.currentFormService = currentFormService;
  }

  @Input()
  set data_sheet_list(data: any[]) {
    this._data_sheets.next(data);
  }

  get data_sheets() {
    return this._data_sheets;
  }

  @Input()
  set patient(obj: any) {
    this._patient.next(obj);
  }

  get patient() {
    return this._patient;
  }

  @Input()
  set sheet_info(obj: any) {
    this._sheet_info.next(obj);
  }

  get sheet_info() {
    return this._sheet_info;
  }

  @Input()
  set is_readonly(readonly: boolean) {
    this._is_readonly = readonly;
  }

  get is_readonly() {
    return this._is_readonly;
  }

  @Input()
  set data(readonly_data: any) {
    this._readonly_data = readonly_data;
  }

  get data() {
    return this._readonly_data;
  }

  updateProcedureSession(idx: number): void {
    this.procedure_session_timeout_map.set(idx, this.procedure_sessions[idx]);

    if (this.procedure_session_timeout) {
      clearTimeout(this.procedure_session_timeout);
      this.procedure_session_timeout = null;
    }

    this.procedure_session_timeout = setTimeout(() => {
      // if (!this.is_procession) {
      this.procedure_session_timeout_map.delete(idx);
      this.processArray([[idx]], this.saveProcedureSession)
        .then(final => {
          if (this.procedure_session_timeout_map.has(idx)) {
            clearTimeout(this.procedure_session_timeout);
            this.procedure_session_timeout = null;
            this.updateProcedureSession(idx);
          }

        });
    }, this.TIMEOUT_VAL);
  }

  saveProcedureSession(i?: number): Promise<number | void> {
    if (i == null) {
      return throwError('Could not find procedure session').toPromise();
    }
    if (this.procedure_sessions[i].procedure != null) {

      // for task analysis we should only be saving one at a time
      if (this.procedure_sessions[i].procedure == null) {
        this.procedure_sessions[i].procedure = this.current_procedure_id;
      }
      if (this.procedure_sessions[i].therapy_session == null) {
        this.procedure_sessions[i].therapy_session = this.therapy_session.id;
      }
      if (this.procedure_sessions[i].perc_correct == '') {
        this.procedure_sessions[i].perc_correct = null;
      }
      if (this.procedure_sessions[i].total_trials == '') {
        this.procedure_sessions[i].total_trials = null;
      }
      // if for some reason therapy session id is still null on proc session, then abort
      if (this.procedure_sessions[i].therapy_session == null) {
        return throwError('no therapy id on procedure session').toPromise();
      }
      if (this.procedure_sessions[i]._order == null) {
        this.procedure_sessions[i]._order = i;
      }
      return this.dailySessionViewService.saveProcedureSession(this.procedure_sessions[i])
        .then((procedureSession: ProcedureSession) => {
          // in theory we should be ok as the values shold be passed by reference
          // if (this.procedure_sessions[i].id == null) {
          //   this.procedure_sessions[i].id = procedureSession.id;
          // }
          // return procedureSession.id;
          this.procedure_sessions[i] = _.merge(this.procedure_sessions[i], procedureSession);
          this.procedure_sessions[i].saving = false;
          if (this.procedure_sessions[i].perc_correct == null) {
            this.procedure_sessions[i].edit_perc_correct = false;
          }
          if (this.procedure_sessions[i].total_trials == null) {
            this.procedure_sessions[i].edit_total_trials = false;
          }
          return this.procedure_sessions[i].id;
          // for now we buffer up to the default max
        }).catch(
          (err) => {
            this.procedure_sessions[i].saving = true;

            throw err;
          });
    }
  }

  saveSession(i?: number, complete_session?: boolean): Promise<TherapySession> {
    // for task analysis we should only be saving one at a time
    if (i != null) {
      if (this.procedure_sessions[i].procedure == null) {
        this.procedure_sessions[i].procedure = this.current_procedure_id;
      }
      this.dailySessionViewService.currentProcedureSessions.next([this.procedure_sessions[i]]);
    }
    return this.dailySessionViewService.save(complete_session)
      .then((therapy_session) => {
        // in theory we should be ok as the values shold be passed by reference
        this.therapy_session = _.merge(this.therapy_session, therapy_session);
        if (this.therapy_session.id != null) {
          const saved_procedure_sessions = this.dailySessionViewService.currentProcedureSessions.getValue();
          for (const session of saved_procedure_sessions) {
            const idx = this.procedure_sessions.findIndex(ps => ps.id == session.id);
            if (idx > -1) {
              this.procedure_sessions[idx] = _.merge(this.procedure_sessions[idx] || {}, session);
              // this.procedure_sessions[idx].session_trials = this.padSessionTrials(this.procedure_sessions[idx].session_trials || []);
              const session_proc_steps_length = this.procedure_sessions[i].state_proc_steps != null ?
                this.procedure_sessions[i].state_proc_steps.length : 0;

              const blank_trials = this.getBlankSessionTrialsList(session_proc_steps_length);
              this.procedure_sessions[idx].session_trials = this.addSessionTrials(blank_trials, session.session_trials || []);
            } else if (i != null) {
              // let's assume it's new and use 'i' from the parent context
              this.procedure_sessions[i] = _.merge(this.procedure_sessions[i] || {}, session);
              // this.procedure_sessions[i].session_trials = this.padSessionTrials(this.procedure_sessions[i].session_trials || []);
              const session_proc_steps_length = this.procedure_sessions[i].state_proc_steps != null ?
                this.procedure_sessions[i].state_proc_steps.length : 0;
              const blank_trials = this.getBlankSessionTrialsList(session_proc_steps_length);
              this.procedure_sessions[i].session_trials = this.addSessionTrials(blank_trials, session.session_trials || []);
            }
          }
        }

        return therapy_session;
        // for now we buffer up to the default max
      })
      .catch((err) => {
        return throwError(err).toPromise();
      });
  }

  updateProcedureSessions(): void {
    for (let i = 0; i < this.procedure_sessions.length; i++) {
      this.procedure_sessions[i].phase = this.phase;
      this.procedure_sessions[i].training_step = this.training_step;
      this.procedure_sessions[i].prompt_step = this.prompt_step;
      this.procedure_sessions[i].stage = this.stage;
      if (this.procedure_sessions[i].procedure != null) {
        this.saveProcedureSession(i)
          .then((proc_session_id: number) => {
            if (this.isPhaseError) {
              this.isPhaseError = false;
            }
            console.log('finished saving proc session number: ' + i);
          }).catch((err) => {
            if (this.isPhaseError) {
              this.isPhaseError = false;
            }
            if (err.error) {
              for (let key in err.error) {
                this.addToast(err.error[key][0], 'error');
                if (key === 'phase') {
                  this.isPhaseError = true;
                }
              }
            }
            console.log(err);

            throw err;
          });
      }
    }
  }

  updateSession(is_trial_click?: boolean, procedure_step?: ProcedureStep,
                idx?: number): Promise<boolean | void> {
    // this.dailySessionViewService.currentSubject.next(this.therapy_session);
    this.dailySessionViewService.currentProcedureSessions.next(this.procedure_sessions);

    if (is_trial_click) {

      // setTimeout(() => {
      let promise: Promise<number | void>;
      if (this.procedure_sessions[idx].id == null) {
        promise = this.saveProcedureSession(idx);
      } else {
        promise = Promise.resolve(this.procedure_sessions[idx].id);
      }
      const proc_step_idx = this.procedure_steps.findIndex(ps => ps.id == procedure_step.id);

      return promise.then((procedure_id: number) => {
        let sub_promise: Promise<any>;
        if (this.procedure_sessions[idx].session_trials[proc_step_idx].procedure_session == null) {
          this.procedure_sessions[idx].session_trials[proc_step_idx].procedure_session = procedure_id;
        }
        // save it if we have a value, otherwise we delete it
        if (this.procedure_sessions[idx].session_trials[proc_step_idx].value == null &&
          this.procedure_sessions[idx].session_trials[proc_step_idx].id != null) {
          sub_promise = this.dailySessionViewService.deleteSessionTrial(this.procedure_sessions[proc_step_idx].session_trials[idx]);
        } else if (this.procedure_sessions[idx].session_trials[proc_step_idx].value != null) {
          console.log(this.procedure_sessions[idx].session_trials[proc_step_idx]);
          sub_promise = this.dailySessionViewService.saveSessionTrial(this.procedure_sessions[idx].session_trials[proc_step_idx]);
        } else {
          console.log(this.procedure_sessions[idx].session_trials[idx]);
          sub_promise = Promise.resolve(this.procedure_sessions[idx].session_trials[idx]);
        }
        return sub_promise;
      })
        .then((res: any) => {
          if (res.id != null) {
            this.procedure_sessions[idx].session_trials[proc_step_idx].id = res.id;
          } else {
            delete this.procedure_sessions[idx].session_trials[proc_step_idx].id;
            this.procedure_sessions[idx].session_trials[proc_step_idx] = new SessionTrial();
          }
          // now we get the procedure session
          if (this.procedure_sessions[idx].getting_procedure_id == null) {
            // setting the trial that is getting the procedure
            this.procedure_sessions[idx].getting_procedure_id = idx;
            return this.dailySessionViewService.getProcedureSessionById(this.procedure_sessions[idx].id);
          } else {
            return Promise.resolve(this.procedure_sessions[idx]);
          }
        })
        .then((procedure_session) => {
          this.procedure_sessions[idx] = Object.assign(this.procedure_sessions[idx], procedure_session);
          if (this.procedure_sessions[idx].getting_procedure_id == idx) {
            this.procedure_sessions[idx].getting_procedure_id = null;
          }
          if (this.procedure_sessions[idx].perc_correct == null) {
            this.procedure_sessions[idx].edit_perc_correct = false;
          }
          if (this.procedure_sessions[idx].total_trials == null) {
            this.procedure_sessions[idx].edit_total_trials = false;
          }
          return true;
        })
        .catch((err) => {
          throw err;
        });

      // }, 50);
    }
  }

  getBlankSessionTrialsList(num_trials: number): SessionTrial[] {
    if (num_trials == null) return [];

    let new_session_trials: SessionTrial[];

    new_session_trials = Array
      .apply(null, Array(num_trials))
      .map(() => new SessionTrial());

    return new_session_trials;
  }

  addSessionTrials(blank_trials: SessionTrial[], session_trials: SessionTrial[]) {
    // now we know the session trials, if any, are sorted by _order
    // let new_session_trials: SessionTrial[] = [];
    if (session_trials && session_trials.length > 0) {
      for (let i = 0; i < session_trials.length; i++) {
        if (session_trials[i] != null && session_trials[i]._order != null && session_trials[i]._order <= blank_trials.length) {
          blank_trials[session_trials[i]._order] = session_trials[i];
        }
      }
    }
    return blank_trials;
  }

  padSessionTrials(session_trials: SessionTrial[], num_trials?: number): SessionTrial[] {
    // now we know the session trials, if any, are sorted by _order
    let new_session_trials: SessionTrial[] = this.fillSessionTrials(session_trials);

    new_session_trials = [...new_session_trials, ...(Array
      .apply(null, Array(this.procedure_steps.length - new_session_trials.length))
      .map(() => new SessionTrial()))];

    return new_session_trials;

  }

  padProcedureSessions(procedure_sessions: ProcedureSession[]): ProcedureSession[] {
    const padded_amt = this.max_col_span > procedure_sessions.length ?
      Math.abs(this.max_col_span - procedure_sessions.length) : procedure_sessions.length % this.DEFAULT_SESSION_NUM;
    return procedure_sessions.concat(Array
      .apply(null, Array(padded_amt))
      .map(() => {
        const session = new ProcedureSession();
        session.procedure = this.current_procedure_id;
        return session;
      }));
    // }
  }

  getDataSheet(data_sheets: DataSheet[]): void {
    this.data_sheet = data_sheets.find(ds => ds.code.toLowerCase() == this.data_sheet_type.toLowerCase());
  }

  subscribeToChoices(): void {
    this.subscriptions.push(this.choicesSubject.subscribe(
      (choices: Map<string, any>) => {
        if (choices.size > 0 && choices.has('procedure')) {
          this.prompt_types = choices.get('procedure').get('prompt_type');
          this.stages = choices.get('sessionsummary').get('stage');
          this.daily_session_trial_options = choices.get('sessiontrial').get('value');
          // this.phase_types = choices['proceduresession'].get('phase_type');
        }
      }));
  }

  subscribeToCurrentUser(): void {
    const curr_user = this.userService.currentUserUpdated.getValue();
    if (curr_user) {
      this.current_user = curr_user;
      if (this.therapy_session && this.therapy_session.host == null) {
        this.therapy_session.host = this.current_user.id;
      }
    }
    this.subscriptions.push(this.userService.currentUserUpdated.subscribe(
      (current_user) => {
        this.current_user = current_user;
        if (this.therapy_session.can_edit && this.therapy_session.host == null) {
          this.therapy_session.host = this.current_user.id;
        }
      }));
  }

  trialClick(trial: SessionTrial, procedure_step_id?: number, proc_session?: ProcedureSession, index?: number, step_idx?: number): void {
    let dailySessions: Map<number, SessionTrial[]> = this.dailySessionViewService.dailySessions.getValue();
    if (dailySessions == null) {
      dailySessions = new Map<number, SessionTrial[]>();
    }
    trial.is_updated = true;
    if (!this.trial_timeout_map.has(this.procedure_sessions[index].id)) {
      this.trial_timeout_map.set(this.procedure_sessions[index].id, new Map<string, any[]>());
    }
    if (trial.count == null) {
      trial.count = 0;
    } else if (trial.count === 2) {
      trial.count = null;
    } else {
      trial.count = (trial.count + 1) % 3;
    }

    if (trial.procedure_step == null) {
      trial.procedure_step = procedure_step_id;
    }

    if (trial.count != null) {
      trial.value = this.daily_session_trial_options[trial.count].value;
    } else {
      trial.value = null;
    }
    trial._order = step_idx;

    // // here we set the timestamps to check we have saved everything
    // trial.timestamp = moment().toDate();
    // if (!trial.earliest) {
    //   this.saveTrial(trial, procedure_step, index, step_idx)
    // }

    if (!dailySessions.has(this.procedure_sessions[index].id)) {
      // if we don't have the object in the map then we create it
      dailySessions.set(this.procedure_sessions[index].id, new Array(proc_session.session_trials.length));
    }
    // TODO: need to resize the array if the user adds more trials after doing edits
    // step_idx: this is the procedure step index - this correlates to the task analysis procedure session trail index
    // because, one session is a column of the grid - unlike most of the others
    dailySessions.get(this.procedure_sessions[index].id)[step_idx] = trial;
    console.log(dailySessions.get(this.procedure_sessions[index].id));
    this.dailySessionViewService.dailySessions.next(dailySessions);
  }

  getProcedureSteps(is_new_procedure?: boolean): Promise<boolean> {
    // check if we have a new procedure session

    if (this.therapy_session.after_complete || this.therapy_session.is_complete) {
      // now we delete the after complete because we are done
      delete this.therapy_session.after_complete;
      if (!this.therapy_session.filtering) {
        this.dailySessionViewService.currentSubject.next(this.therapy_session);
      }
    }

    // in theory, we should only have one as the therapist can only edit one at a time
    if (this.current_procedure &&
      Object.keys(this.current_procedure).length > 0 &&
      (!this.therapy_session.is_complete ||
        (this.therapy_session.is_complete && this.therapy_session.filtering))) {
      // for (const procedure of this.procedures) {
      // assuming here that we still have to get the trials after this
      return this.dailySessionViewService.getProcedureSteps(this.current_procedure.id.toString(), this.therapy_session.filtering)
        .then((steps) => {
          this.procedure_steps = steps;
          if (this.procedure_sessions && this.procedure_sessions.length > 0) {
            for (const procedure_session of this.procedure_sessions) {
              // procedure_session.session_trials = this.padSessionTrials([]);

              // only need a blank session trial list here - here we use the
              // list of procedure_steps instead of the ones on the procedure_session
              const session_proc_steps_length = procedure_session.state_proc_steps != null ?
                procedure_session.state_proc_steps.length : steps != null ?
                  steps.length : 0;

              procedure_session.session_trials = this.getBlankSessionTrialsList(session_proc_steps_length);
              // procedure_session.session_trials = this.fillSessionTrials(procedure_session.num_trials);
            }
          }
          this.loading = false;
          return this.loading;
        });
      // }

    } else {
      this.loading = !!is_new_procedure;
      return Promise.resolve(this.loading);
    }

    // this.updateSession();
  }

  getProcedures(): Observable<boolean> {
    let observable: Observable<Procedure[]>;
    const procedure_ids = this.procedure_session_defaults.reduce((arr, curr) => {
      if (arr.indexOf(curr.procedure) == -1) {
        arr = [...arr, curr.procedure];
      }
      return arr;
    }, []);
    if (procedure_ids.length === 0) {
      observable = of([]);
    } else {
      observable = this.procedureViewService.getProceduresByIds$(procedure_ids);
    }

    return observable
      .pipe(
        map((procedures: Procedure[]) => {
          this.all_procedures = procedures != null ? procedures.slice() : [];
          if (this.all_procedures && this.all_procedures.length > 0) {
            // for each procedure we want to add a procedure session
            // now we have the procedures, we get the latest session instead
            // this.getNewProcedureSession();
            for (let i = 0; i < this.all_procedures.length; i++) {
              this.all_procedures[i].phase_types = this.constantsService.convertListToSelctOptions(this.all_procedures[i].valid_phase_types);
            }

            //TODO: the below is the only 3 lines that truely differ TA from the rest - could make this function a base one
            if (this.current_procedure) {
              this.phase_type_tactic = this.current_procedure.phase_type_tactic;
              this.phase_types = this.current_procedure.phase_types;
              this.getPromptSteps();
              this.getProcItems();
            }

            // this.getTherapySession(null, this.current_user ? this.current_user.id : null, null, false);

            // this.getSearchText();
          } else {
            this.loading = false;
            this.procedure_sessions = [];
          }
          return this.loading;
        }),
        catchError((err) => {
          // need to show toasty with error
          this.loading = false;
          this.procedure_sessions = [];

          throw err;
        })
      )
  }

  getNewProcedureSession(): void {
    this.setDefaults();

    this.procedure_sessions = this.padProcedureSessions([]);

    // now we check if we have to update the procedure sessions with the default values
    this.updateProcedureSessions();

    this.getProcedureSteps(true)
      .then((finished_loading) => {
        this.loading = false;
      });
  }

  /**
   * passing a null date_filter and host_id will get the latest therapy session that
   * hasn't been completed
   *
   * @param {string} date_filter
   * @param {number} host_id
   * @param {boolean} procedure_change
   */
  getTherapySession(date_filter?: string, host_id?: number, procedure_change?: boolean, is_complete?: boolean): void {

    this.dailySessionViewService.getProcedureSessionByTherapySessionAndProcedureAndDataSheet(
      this.therapy_session.id,
      this.current_procedure.id,
      this.data_sheet.id
    )
      .then((procedure_sessions) => {
        if (procedure_sessions && procedure_sessions.length > 0) {
          this.procedure_sessions = procedure_sessions.filter(ps => ps.procedure == this.current_procedure_id).slice();

          this.getProcedureSteps().then(() => {
            this.getSessionTrials();
          });
          this.setDefaults();

          if (!this.therapy_session.is_complete) {
            this.procedure_sessions = this.padProcedureSessions(this.procedure_sessions);
          }

          if (this.procedure_sessions[0]) {
            this.phase = this.procedure_sessions[0].phase;
            this.prompt_step = this.procedure_sessions[0].prompt_step;
            this.training_step = this.procedure_sessions[0].training_step;
            this.stage = this.procedure_sessions[0].stage;
          } else {
            this.phase = null;
            this.prompt_step = null;
            this.training_step = null;
            this.stage = null;
          }

          this.max_col_span = this.max_col_span > this.procedure_sessions.length ?
            this.max_col_span : this.procedure_sessions.length;
        } else if (procedure_sessions && procedure_sessions.length == 0 &&
          this.selected_host != null) {
          // this.selected_date != null && this.selected_host != null) {
          this.procedure_sessions = [];
          this.procedure_steps = [];
          this.loading = false;
        } else if (!this.therapy_session.filtering || !this.therapy_session.is_complete) {
          // this.max_col_span = this.DEFAULT_SESSION_NUM;
          this.setDefaults();

          this.getNewProcedureSession();
        } else {
          this.procedure_sessions = [];
          this.loading = false;
        }
      });
  }

  getSessionTrials(): void {
    if (this.procedure_sessions && this.procedure_sessions.length > 0) {
      let proc_session_ids = this.procedure_sessions.map(ps => ps.id);

      proc_session_ids = proc_session_ids != null ? proc_session_ids.filter(ps => ps != null) : [];
      if (proc_session_ids && proc_session_ids.length > 0) {
        this.dailySessionViewService.getSessionTrialsByProcedureSessionIds(proc_session_ids)
          .then(session_trials => {
            const grouped_sessions = _(session_trials)
              .groupBy(st => st.procedure_session)
              .value();

            const keys = Object.keys(grouped_sessions);
            for (const key of keys) {
              this.max_col_span = grouped_sessions[key][grouped_sessions[key].length - 1]._order > this.max_col_span ?
                grouped_sessions[key][grouped_sessions[key].length - 1]._order : this.max_col_span;

              let temp: SessionTrial[] = [];
              // for each session trial we then set the count to the index of the value
              // from static choices
              const trials = this.commonService.sortByProperty(grouped_sessions[key], '_order');
              for (let idx = 0; idx < trials.length; idx++) {
                trials[idx].count = this.daily_session_trial_options.findIndex(dst => dst.value == trials[idx].value);

                temp.push(_.cloneDeep(trials[idx]));
              }
              const proc_session_idx = this.procedure_sessions.findIndex(ps => ps.id === +key);
              if (proc_session_idx > -1) {
                const session_proc_steps_length = this.procedure_sessions[proc_session_idx].state_proc_steps != null ?
                  this.procedure_sessions[proc_session_idx].state_proc_steps.length : 0;

                const blank_trials = this.getBlankSessionTrialsList(session_proc_steps_length);
                this.procedure_sessions[proc_session_idx].session_trials = this.addSessionTrials(blank_trials, temp);
              }
            }
            this.loading = false;
          });
      }
    }
    this.updateSession();
  }

  currentProcedureChange(event: any): void {
    this.loading = true;
    this.max_col_span = this.DEFAULT_SESSION_NUM;
    // this.getProcedureSteps(event);
    console.log('current procedure', this.current_procedure_id);
    this.max_col_span = this.DEFAULT_SESSION_NUM;
    const procedure = this.procedures.find(p => p.id === this.current_procedure_id);
    if (procedure) {
      // this.dailySessionViewService.current
      this.current_procedure = _.cloneDeep(procedure);
      this.phase_type_tactic = this.current_procedure.phase_type_tactic;
      this.phase_types = this.current_procedure.phase_types;
      this.procedure_sessions = [];
      // now we get the procedure sessions
      this.setDefaults();

      this.getPromptSteps().then((ret_val) => {
        this.getProcItems();
        this.filterSessions(event, true);
      });
    }
  }

  getPromptSteps(procedure_sessions?: ProcedureSession[]): Promise<boolean | void> {
    let promise: Promise<boolean>;
    if (this.current_procedure && Object.keys(this.current_procedure).length > 0 && !this.current_procedure.prompt_steps) {
      // for (const procedure of this.procedures) {
      promise = this.dailySessionViewService.getPromptFadingStepsById(this.current_procedure.id.toString(), this.therapy_session.filtering)
        .then((steps) => {
          for (const step of steps) {
            step.step_display = step.step_abbr != null ? step.step_abbr : step.step;
          }
          this.prompt_steps = steps;
          return true;
        });
    } else {
      this.prompt_steps = this.current_procedure.prompt_steps;
      promise = Promise.resolve(true);
    }
    this.updateSession();
    return promise;
  }

  getProcItems(): void {
    if (this.current_procedure && Object.keys(this.current_procedure).length > 0) {
      this.dailySessionViewService.getProcItemsById(this.current_procedure.id.toString(), this.therapy_session.filtering)
        .then((proc_items) => {
          this.proc_items = proc_items.filter(s => this.proc_items_to_show.indexOf(s.stage) > -1);
        });
    }
    this.updateSession();
  }

  subscribeToDailySessionSave(): void {
    this.dailySessionSave = this.dailySessionViewService.incrementalSave().subscribe(() => {
      // empty subscription
    });
  }

  subscribeToSavingSessions(): void {
    this.dailySessionSaving = this.dailySessionViewService.savingSessions.subscribe((is_saving: boolean) => {
      // empty subscription

      if (is_saving) {
        this.saving_trial = is_saving;
        this.addToast('Saving...', 'saving');
      }
    });
  }

  subscribeTodailySessionCache(): void {
    //TODO: simplify this data structure that's returned to a list of ids instead of a map
    if (this.dailySessionsSavedSubscription) return;

    this.dailySessionsSavedSubscription = this.dailySessionViewService.dailySessionsSaved.subscribe((daily_sessions) => {
      const keys = daily_sessions.keys();
      let key = keys.next();
      while (!key.done) {
        let k = key.value;
        // now need to check each of the procedure sessions found here and in the 'future' save to update with the id or delete
        // NOTE: we don't need to do this anymore since we are disabling the UI

        const idx = this.procedure_sessions.findIndex(ps => ps.id == k);
        // const step_idx = this.procedure_steps.findIndex(ps => ps.id == );
        const ret_list = daily_sessions.get(k);
        for (let i = 0; i < ret_list.length; i++) {
          const saved = ret_list[i];
          if (this.procedure_sessions[idx]) {
            if (!this.procedure_sessions[idx].session_trials[saved._order]) {
              if (this.procedure_sessions[idx].session_trials.length < saved._order) {
                this.procedure_sessions[idx].session_trials.push(saved);
              } else {
                this.procedure_sessions[idx].session_trials[saved._order] = saved;
              }
            } else {
              this.procedure_sessions[idx].session_trials[saved._order].id = saved.id;
            }

          }
        }

        // now we just reload the procedure sessions that were saved
        this.dailySessionViewService.getProcedureSessionById(k)
          .then((procedure_session: ProcedureSession) => {
            if (idx > -1 && this.procedure_sessions[idx] != null && this.procedure_sessions[idx].id == procedure_session.id) {
              this.procedure_sessions[idx] = _.merge(this.procedure_sessions[idx], procedure_session);
            }
          });
        key = keys.next();
      }
      this.saving_trial = false;
      if (this.toastyId != null && daily_sessions.size > 0) {
        this.toastyService.clear(this.toastyId);

        this.addToast('Done', 'success');
      }
      this.toastyId = null;
    });
  }

  filterSessions(event: any, procedure_change?: boolean): void {

    // if (this.selected_host != null && this.selected_date != null) {
    //   debugger;
    let temp: Moment;
    let date: string;
    // might be able to remove this check later
    // if (!procedure_change) {
    temp = moment(this.selected_date);
    date = !temp.isValid() ? null : temp.format('YYYY-MM-DD');
    // }

    this.getTherapySession(date, this.selected_host, true);
    // console.log(this.selected_host, this.selected_date);
    // }
  }

  subscribeToUsers(): void {
    this.subscriptions.push(this.allUsersSubject.subscribe(
      (users: User[]) => {
        if (this.therapy_session && this.therapy_session.host != null && this.therapy_session.id != null) {
          this.current_therapist = users.find(ul => ul.id === this.therapy_session.host);
          if (this.current_therapist && this.therapy_session &&
            this.therapy_session.host == null && this.therapy_session.id != null) {
            this.therapy_session.host = this.current_therapist.id;
          }
        }
      }));
  }

  // procedureSelectionChange($event: any) {
  //   console.log($event);
  // }

  subscribeToTherapySession(): void {
    if (this.therapySessionSubscription) return;

    this.therapySessionSubscription = this.dailySessionViewService.currentSubject.subscribe(
      (therapy_session: TherapySession) => {
        this.procedure_steps = [];
        if (this.therapy_session && !this.therapy_session.after_complete && !this.therapy_session.is_error_finish) {
          this.therapy_session = therapy_session;
          if (this.therapy_session.is_complete && !this.therapy_session.filtering) {
            this.loading = true;
            this.saveSession(null, true)
              .then((completed) => {
                this.max_col_span = this.DEFAULT_SESSION_NUM;
                this.procedure_sessions = [];
                // for (const proc_step of this.procedure_steps) {
                //   proc_step.session_trials = [];
                // }

                if (this.therapy_session.id != null) {
                  this.getNewProcedureSession();
                }
                this.loading = false;
              })
              .catch((err) => {
                //this.loading = false;
                throw err;
              });
            return;
          }
          if (therapy_session && therapy_session.id != null) {
            if (!therapy_session.after_complete) {
              this.loading = true;

              this.therapy_session = therapy_session;
              // if (this.therapy_session.filtering) {
                this.procedure_sessions = [];
                this.procedure_steps = [];
                // this.therapy_session.filtering = false;
              // }
              // if (!this.procedure_sessions || this.procedure_sessions.length == 0) {

                this.dailySessionViewService.getProcedureSessionByTherapySessionAndDataSheet(this.therapy_session.id, this.data_sheet.id, this.therapy_session.filtering)
                  .then((procedure_sessions) => {
                    const sheet_info = this.sheet_info.getValue();

                    if (procedure_sessions && procedure_sessions.length > 0) {
                      this.setDefaults();

                      this.procedure_sessions = procedure_sessions.filter(ps => ps.procedure == this.current_procedure_id).slice();

                      this.getProcedureSteps().then(() => {
                        this.getSessionTrials();
                      });

                      if (!this.therapy_session.is_complete) {
                        this.procedure_sessions = this.padProcedureSessions(this.procedure_sessions);
                      }
                      if (this.procedure_sessions[0]) {
                        this.phase = this.procedure_sessions[0].phase;
                        this.prompt_step = this.procedure_sessions[0].prompt_step;
                        this.training_step = this.procedure_sessions[0].training_step;
                        this.stage = this.procedure_sessions[0].stage;
                      } else {
                        this.phase = null;
                        this.prompt_step = null;
                        this.training_step = null;
                        this.stage = null;
                      }
                      this.max_col_span = this.max_col_span > this.procedure_sessions.length ?
                        this.max_col_span : this.procedure_sessions.length;
                      this.loading = false;
                    } else if (procedure_sessions && procedure_sessions.length == 0 &&
                      this.selected_host != null) {
                      // this.selected_date != null && this.selected_host != null) {
                      this.procedure_sessions = [];
                      this.procedure_steps = [];
                      this.loading = false;
                    } else if (!this.therapy_session.filtering || !this.therapy_session.is_complete) {
                      // this.max_col_span = this.DEFAULT_SESSION_NUM;
                      this.setDefaults();

                      this.getNewProcedureSession();
                    } else {
                      this.procedure_sessions = [];
                      this.loading = false;
                    }
                  })
                  .catch(err => {
                    console.log(err);
                    this.loading = false;
                  });
              // } else {
              //   this.loading = false;
              // }
            }
          }
        } else if (this.therapy_session.ignore_update) {
          delete this.therapy_session.ignore_update;
          this.loading = false;
        }
        if (this.therapy_session.is_error_finish) {
          delete this.therapy_session.is_error_finish;
          this.loading = false;
        }
      });
  }

  // subscribeToSearchFilterSubject(): void {
  //   this.subscriptions.push(this.filterViewService.dataSheetSearchFilterSubject.subscribe(
  //     (filter?: string) => {
  //       if (filter !== null && filter !== undefined) {
  //         this.filterSessionsByProcedure(filter);
  //       }
  //     }));
  // }

  subscribeToDataSheets(): void {
    this.subscriptions.push(this.data_sheets.subscribe((items) => {
      // wait until we have some data_sheets
      if (items && items.length > 0) {
        this.getDataSheet(items);
        if (!this.getting_data && this.patient.getValue() && this.patient.getValue().id != null) {
          this.getting_data = true;
          this.getData();
        }
      }
    }));
  }

  setDefaults(): void {

      this.procedures = [];

      if (this.procedure_session_defaults && this.procedure_session_defaults.length > 0) {

        for (let i = 0; i < this.procedure_session_defaults.length; i++) {
          const session_default = this.procedure_session_defaults[i];
          if (this.all_procedures && this.all_procedures.length > 0) {
            const procedure = this.all_procedures.find(p => p.id == session_default.procedure);
            if (procedure) {
              if (this.procedures.length == 0 || this.procedures.findIndex(p => p.id === procedure.id) === -1) {
                procedure.prompt_step_ids = session_default.state_prompt_steps;
                procedure.proc_item_ids = session_default.state_items;
                this.procedures.push(_.cloneDeep(procedure));
              }

              // const proc_item_ids = [...this.proc_item_ids, ...session_default.state_items];
              // this.proc_item_ids = Array.from(new Set(proc_item_ids));
              //
              // if (procedure_sessions.findIndex(ps => ps.procedure == session_default.procedure &&
              //   ps.sd == session_default.sd && ps.state_group == session_default.item_group) > -1) {
              //   continue;
              // }
              // let procedure_session: ProcedureSession;
              // if (session_default.procedure_session_id != null) {
              //   procedure_session = this.procedure_sessions.find(ps => ps.id == session_default.procedure_session_id);
              // }
              //
              // if (!procedure_session) {
              //   let proc_sessions = _.filter(this.procedure_sessions, ps => ps.procedure == procedure.id);
              //   if (proc_sessions && proc_sessions.length > 0) {
              //     proc_sessions = _.filter(proc_sessions, ps => ps.prompt_step == session_default.prompt_step);
              //   }
              //   if (proc_sessions && proc_sessions.length > 0) {
              //     proc_sessions = _.filter(proc_sessions, ps => ps.sd == session_default.sd);
              //   }
              //   if (proc_sessions && proc_sessions.length > 0) {
              //     proc_sessions = _.filter(proc_sessions, ps => ps.state_proc_items &&
              //       ps.state_proc_items.some(spi => session_default.state_items.includes(spi)));
              //   }
              //   if (proc_sessions && proc_sessions.length > 0) {
              //     procedure_session = proc_sessions[0];
              //   }
              // }
              // if (!procedure_session) {
              //   procedure_session = new ProcedureSession();
              //   procedure_session.default_session_idx = i;
              //   procedure_session.procedure = procedure.id;
              // }
              // if (session_default.warnings && session_default.warnings.length > 0) {
              //   procedure_session.warning = session_default.warnings.join(', ');
              // }
              // procedure_session.phase_types = procedure.phase_types;
              // procedure_session.prompt_steps = [];
              // procedure_session.prompt_step_ids = session_default.state_prompt_steps || [];
              // procedure_session.proc_items = [];
              // procedure_session.proc_item_ids = session_default.state_items || [];
              // procedure_session.state_group = session_default.item_group;
              // procedure_session.sd_order = session_default.sd_order;
              // procedure_session['state_sds'] = session_default.state_sds;
              //
              // if (procedure_session.sd == null) {
              //   procedure_session.sd = session_default.sd;
              // }
              // if (procedure_session.phase == null) {
              //   procedure_session.phase = session_default.phase;
              // }
              // if (procedure_session.proc_item == null) {
              //   procedure_session.proc_item = session_default.item;
              // }
              // if (procedure_session.prompt_step == null) {
              //   procedure_session.prompt_step = session_default.prompt_step;
              // }
              // if (procedure_session.stage == null) {
              //   procedure_session.stage = session_default.stage;
              // }
              // // if (session_default && session_default.phase_type_tactics && 'mei' in session_default.phase_type_tactics) {
              // //   this.phase_type_tactics_list.push(session_default.phase_type_tactics['mei']);
              // // } else {
              // //   this.phase_type_tactics_list.push(session_default.phase_type_tactics || procedure.phase_type_tactic);
              // // }
              // if (!this.phase_type_tactics_map.has(procedure.id)) {
              //   this.phase_type_tactics_map.set(procedure.id, []);
              // }
              // this.phase_type_tactics_map.get(procedure.id).push(session_default.phase_type_tactics || procedure.phase_type_tactic);
              //
              // if (!procedure_session.session_trials || procedure_session.session_trials.length == 0) {
              //   const proc_item_length = this.proc_items != null ? this.proc_items.length : 1;
              //   procedure_session.session_trials = Array
              //     .apply(null, Array((this.max_row_num * proc_item_length)))
              //     .map(() => new SessionTrial());
              // }
              // if (!procedure_session.id ||
              //   (procedure_session.id && procedure_sessions.findIndex(ps => ps.id === procedure_session.id) === -1)) {
              //   procedure_sessions.push(_.cloneDeep(procedure_session));
              // }
              //
              // // updateing the procedure session in all_procedure_sesions
              // const idx = this.all_procedure_sessions.findIndex(ps => ps.id === procedure_session.id);
              // if (idx > -1) {
              //   this.all_procedure_sessions[idx] = _.merge(this.all_procedure_sessions[idx], procedure_session);
              // }
            }
          }
        }

        if (this.procedures && this.procedures.length > 0 && this.current_procedure_id == null) {
          this.current_procedure_id = this.procedures[0].id;
          this.current_procedure = this.procedures[0];
          this.phase_type_tactic = this.current_procedure.phase_type_tactic;
          this.phase_types = this.current_procedure.phase_types;
          this.getPromptSteps();
        }
      }

    if (this.current_procedure &&
      Object.keys(this.current_procedure).length > 0 &&
      this.procedure_session_defaults && this.procedure_session_defaults.length > 0) {
      const idx = this.procedure_session_defaults.findIndex(d => d.procedure == this.current_procedure.id);
      if (idx > -1) {
        if (this.procedure_session_defaults[idx].warnings != null && this.procedure_session_defaults[idx].warnings.length > 0) {
          this.current_procedure.warning = this.procedure_session_defaults[idx].warnings.join(', ');
        }
        // if (this.procedure_session_defaults[idx].phase) {
        this.phase = this.procedure_session_defaults[idx].phase;
        // }
        // if (this.procedure_session_defaults[idx].training_step) {
        this.training_step = this.procedure_session_defaults[idx].training_step;
        // }
        // if (this.procedure_session_defaults[idx].prompt_step) {
        this.prompt_step = this.procedure_session_defaults[idx].prompt_step;
        // }
        // if (this.procedure_session_defaults[idx].stage) {
        this.stage = this.procedure_session_defaults[idx].stage;
        // }
        // if (this.procedure_session_defaults[idx].steps) {

        this.procedure_step_filter = this.procedure_session_defaults[idx].steps;
        // }
        this.max_col_span = this.procedure_session_defaults[idx].num_trials;
        this.DEFAULT_SESSION_NUM = this.max_col_span;
        this.phase_type_tactics_list.push(
          this.procedure_session_defaults[idx].phase_type_tactics || this.current_procedure.phase_type_tactic
        );
      }
    }

    // check if we have and procedure sessions at all
    if (!this.procedure_session_defaults || this.procedure_session_defaults.length == 0) {
      this.have_proc_sessions = false;
    }

  }

  subscribeToSheetInfo(): void {
    this.subscriptions.push(this.sheet_info.subscribe((sheet_info: SheetInfo) => {
      if (sheet_info && Object.keys(sheet_info).length > 0) {
        const data = sheet_info.data.filter(d => d.data_sheet_code == this.data_sheet_type);
        this.procedure_session_defaults = data;
        console.log(this.procedure_session_defaults);

        this.setDefaults();
        if (!this.procedure_sessions || this.procedure_sessions.length === 0) {
          this.loading = false;
        }
      }
    }));
  }

  subscribeToPatient(): void {
    this.subscriptions.push(this.patient.subscribe((patient: User) => {
      if (!this.getting_data && patient && patient.id != null && this.data_sheet && this.data_sheet.id != null) {
        this.getting_data = true;
        this.getData();
      }
    }));
  }

  private getData(): void {
    // this.getAllClients();
    this.subscribeToCurrentUser();
    this.subscriptions.push(this.getAllUsers().subscribe((users) => {
      this.users_list = this.filterHosts(users);
    }));
  }

  processReadonlyData(): void {
    if (this.data && this.data.data) {
      this.max_trials = this.steps.length;
      for (const obj of this.data.data) {
        const new_session_data = new SessionData();
        new_session_data.date = obj.date;
        new_session_data.description = obj.description;
        new_session_data.host = obj.host;
        new_session_data.perc_correct = obj.perc_correct;
        new_session_data.ts_id = obj.ts_id;
        new_session_data.num_trials = obj.state_prescribed_trials;
        new_session_data.session_trials = [];
        if (obj.counted_for_mastery != null) {
          new_session_data.counted_for_mastery = obj.counted_for_mastery;
        }
        if (obj.trials && obj.trials.length > 0) {
          for (let i = 0; i < obj.trials.length; i++) {
            const trial = new SessionTrial();
            trial.value = obj.trials[i];
            trial._order = i;
            new_session_data.session_trials.push(_.cloneDeep(trial));
          }
        }
        // new_session_data.session_trials = this.padSessionTrials(new_session_data.session_trials, this.steps.length);
        this.readonly_sessions.push(_.cloneDeep(new_session_data));
      }
    }
    this.loading = false;
  }

  ngOnInit() {
    this.cmp_name = 'task analysis';
    this.loading = true;

    this.procedure_sessions = [];
    // this.selected_date = moment().toDate();
    this.subscriptions = [];
    this.prompt_types = [];
    this.procedure_steps = [];
    this.prompt_steps = [];
    this.search_list = [];
    this.phase_types = [];
    this.proc_items = [];
    this.phase_type_tactics_list = [];

    this.subscribeToChoices();
    if (!this.is_readonly) {
      this.max_col_span = this.DEFAULT_SESSION_NUM;
      this.therapy_session = this.dailySessionViewService.currentSubject.getValue();
      if (!this.therapy_session || this.therapy_session.id == null) {
        this.therapy_session = new TherapySession();
        this.therapy_session.date_date = moment().toDate();
      }
      if (this.therapy_session.ignore_update) {
        delete this.therapy_session.ignore_update;
      }

      this.subscribeToDataSheets();
      this.subscribeToPatient();
      this.subscribeToSheetInfo();

      this.subscribeToUsers();
      this.subscribeToDailySessionSave();
      this.subscribeTodailySessionCache();
      this.subscribeToSavingSessions();
      this.subscriptions.push(this.getProcedures()
        .pipe(
          map(res => {
            this.subscribeToTherapySession();
            return of(res);
          }),
          catchError(e => {
            this.loading = false;
            return of(false);
          })
        ).subscribe(
          null,
          err => {
            console.log(err);
          }
        )
      )
    } else {
      this.steps = this.data.all_steps;
      this.readonly_sessions = [];
      this.processReadonlyData();
    }
    this.showNavService.showButtons.next(false);
  }

  ngOnDestroy(): void {
    this.updateSession();
    this.unsubscribe();
    this.showNavService.showButtons.next(true);
    if (this.dailySessionSave) {
      // killing the incremental save for this page
      this.dailySessionViewService.killTrigger.next();
      this.dailySessionSave.unsubscribe();
    }
    if (this.dailySessionSaving) {
      this.dailySessionSaving.unsubscribe();
    }
    if (this.dailySessionsSavedSubscription) {
      this.dailySessionsSavedSubscription.unsubscribe();
    }
    if (this.therapySessionSubscription) {
      this.therapySessionSubscription.unsubscribe();
    }
  }

}
