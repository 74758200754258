import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {BaseService} from '../base.service';
import {AuthGroup} from '../models/auth-group.model';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class AuthGroupService extends BaseService<AuthGroup> {

  currentUserPermissions: BehaviorSubject<AuthGroup> = new BehaviorSubject(new AuthGroup());
  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'auth.group/', AuthGroup);
  }

  canAccessRoute(route: string): boolean {
    const perms = this.currentUserPermissions.getValue();

    if (!route || !perms) {
      return false;
    }

    return perms.exclude_tabs.indexOf(route) === -1;
  }
}
