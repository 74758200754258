import { Injectable } from '@angular/core';
import {DataSheet} from '../models/data-sheet.model';
import {IBaseService} from '../interfaces/base-interface';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {BehaviorSubject} from 'rxjs/';
import {BaseService} from '../base.service';
import {Behaviour} from '../models/behaviour.model';

@Injectable()
export class BehaviourReductionService extends BaseService<Behaviour> {
  currentSubject: BehaviorSubject<Behaviour> = new BehaviorSubject(new Behaviour());

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.behaviourreduction/', Behaviour);
    this.subRoute = '';
    this.date_obj_keys = [];
  }
}
