import {Injectable} from '@angular/core';
import {Actions, Effect, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable, timer} from 'rxjs';
import {environment as env} from '../../../../environments/environment';
import * as moment from 'moment';
import {map, switchMap, startWith, switchAll} from "rxjs/operators"

import * as ApplicationActions from './actions';

@Injectable()
export class ApplicationEffects {
  // setting the application time to 2 hours
  APPLICATION_TIMEOUT_TIME = 1000 * 60 * 60 * 2;
  // // dev setting to 10 minutes so we don't have to wait for 2 hours
  // APPLICATION_TIMEOUT_TIME = 1000 * 60 * 10;
  timeout_delta = 1000 * 60; // if it's within 1s or negative, then
  autologout_date: Date;

  action: Action;
  @Effect()
  extendApplicationTimeout$: Observable<Action> = this.actions$
  // .ofType(ROOT_EFFECTS_INIT)
  // .startWith(new ApplicationActions.LogOut())
    .pipe(switchMap((action: Action) => {
      let diff: number;
      const autologout_date = localStorage.getItem(env.session_accessor);

      if (!autologout_date) {
        this.autologout_date = moment().toDate();
        localStorage.setItem(env.session_accessor, JSON.stringify(this.autologout_date));
        diff = this.APPLICATION_TIMEOUT_TIME;
      } else {
        this.autologout_date = JSON.parse(autologout_date);
        diff = moment().diff(this.autologout_date);
      }
      if (diff > this.APPLICATION_TIMEOUT_TIME) {
        // this.autologout_date = moment().toDate();
        localStorage.removeItem(env.session_accessor);
        diff = this.timeout_delta;
      }
      this.action = action;

      return timer(diff);
    }), map(() => {
      if (this.action.type == ApplicationActions.LOG_OUT || this.action.type == ROOT_EFFECTS_INIT) {
        return new ApplicationActions.LogOut();
      } else {
        return new ApplicationActions.AutoLogOut();
      }
    }));


  constructor(private actions$: Actions) {
  }
}
