import { Injectable } from '@angular/core';
import {DataSheet} from '../models/data-sheet.model';
import {BaseService} from '../base.service';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {IBaseService} from '../interfaces/base-interface';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class DataSheetService extends BaseService<DataSheet> {
  currentSubject: BehaviorSubject<DataSheet> = new BehaviorSubject(new DataSheet);

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.datasheet/', DataSheet);
    this.subRoute = '';
    this.date_obj_keys = [];
  }

}
