import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {ProcedureViewService} from '../../shared/services/procedure-view.service';
import {UserViewService} from '../../shared/services/user-view.service';
import {PatientService} from '../../core/services/patient.service';
import {ConstantsService} from '../../core/services/constants.service';
import {DialogService} from '@progress/kendo-angular-dialog';
import {CurrentFormService} from '../../core/services/current-form.service';
import {ProcedureService} from '../../core/services/procedure.service';
import {PopupService} from '@progress/kendo-angular-popup';
import {CommunicationViewService} from '../../shared/services/communication-view.service';
import {Communication} from '../../core/models/communication.model';
import {User} from '../../core/models/user.model';
import {Note} from '../../core/models/note.model';
import {HttpErrorCodes} from '../../core/shared/enum';
import {Procedure} from '../../core/models/procedure.model';
import {ShowNavService} from '../../core/services/show-nav.service';
import {ToastyService} from 'ng2-toasty';
import { ConfirmDeleteService } from 'app/core/services/confirm-delete.service';
import * as moment from 'moment';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.css']
})
export class CommunicationsComponent extends BaseComponent implements OnInit, OnDestroy {

  currentFormService: CurrentFormService;
  communicationViewService: CommunicationViewService;
  communications: Note[];
  patient: User;
  loading: boolean;

  constructor(dialogService: DialogService,
              constantsService: ConstantsService,
              communicationViewService: CommunicationViewService,
              userViewService: UserViewService,
              private procedureService: ProcedureService,
              private patientService: PatientService,
              private popupService: PopupService,
              currentFormService: CurrentFormService,
              private showNavService: ShowNavService,
              toastyService: ToastyService,
              private confirmDeleteService: ConfirmDeleteService) {
    super(constantsService, userViewService, dialogService, communicationViewService, currentFormService, toastyService);
    this.currentFormService = currentFormService;
    this.communicationViewService = communicationViewService;
  }

  subscribeToPatient(): void {
    // this.subscriptions.push(this.patientService.getData().subscribe(
    //   (patient: User) => {
    //     this.patient = patient;
    //     this.getContacts();
    //   }));

    this.subscriptions.push(this.patientService.currentPatient.subscribe(
      (patient: User) => {
        this.patient = patient;
        if (this.patient && this.patient.id) {
          this.getCommunications();
        }
      }));
  }

  subscribeToCommunications(): void {
    this.subscriptions.push(this.communicationViewService.currentSubjects.subscribe(
      (communications: Note[]) => {
        if (communications && communications.length > 0) {
          this.communications = communications;
        }
      }));
  }

  getCommunications(): void {
    this.communicationViewService.getByPatient()
      .then((communications: Note[]) => {
        this.communications = communications;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      })
  }

  openConfirmDelete(idx: number) {
    let creator = this.all_users.find(el => el.id === this.communications[idx].created_by),
        fullname = null,
        created = moment(this.communications[idx].created).format('DD/MM/YYYY');
    if (creator != null) {
      fullname = `${creator.first_name} ${creator.last_name}`;
    } else {
      fullname = `unknown-${this.communications[idx].created_by}`;
    }

    const content = `Are you sure you want to delete communication created by ${fullname} on ${created}?`

    this.confirmDeleteService.openConfirmDialog(content)
      .subscribe(res => {
        if (res) {
          this.remove(idx);
        }
      })
  }

  remove(idx: number): void {
    if (idx == null) {
      return
    }
    // need to find out if we have to archive it or just blatently remove
    this.communicationViewService.remove(this.communications[idx])
      .then((res) => {
        this.communications.splice(idx, 1);
        this.addToast('Communication was deleted.', 'Success');
      })
      .catch((err) => {
        if (typeof err === 'object' && err.status === HttpErrorCodes.UNAUTHORIZED) {
          this.addToast('You do not have permission to remove this communication...', 'Error');
        } else {
          this.addToast('Could not delete communication, please try again...', 'Error');
        }
        throw err;
      });
  }

  subscribeToFiltered(): void {
    this.subscriptions.push(this.communicationViewService.currentSubjectsFiltered.subscribe(
      (filtered: Note[]) => {
        this.communications = filtered;
      }));
  }

  ngOnInit() {
    this.loading = true;
    this.subscriptions = [];
    this.patient = this.patientService.currentPatient.getValue();
    // if (this.patient && this.patient.id) {
    //   this.getCommunications();
    // }
    this.getChoices();
    this.subscriptions.push(this.getAllUsers().subscribe((res) => {
      this.subscribeToPatient();
      this.subscribeToCommunications();
      this.subscribeToFiltered();
    }));

    this.showNavService.showButtons.next(false);
  }

  ngOnDestroy() {
    this.unsubscribe();
    this.showNavService.showButtons.next(true);
  }

}
