import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, of, Subscription, throwError} from 'rxjs';
import {ShowNavService} from '../../../core/services/show-nav.service';
import {DialogService} from '@progress/kendo-angular-dialog';
import {ConstantsService} from '../../../core/services/constants.service';
import {UserViewService} from '../../../shared/services/user-view.service';
import {DailySessionViewService} from '../../../shared/services/daily-session-view.service';
import {CurrentFormService} from '../../../core/services/current-form.service';
import {ToastyService} from 'ng2-toasty';
import {TherapySession} from '../../../core/models/therapy-session.model';
import {ProcedureSession} from '../../../core/models/procedure-session.model';
import {DataSheet} from '../../../core/models/data-sheet.model';
import {User} from '../../../core/models/user.model';
import {Procedure} from '../../../core/models/procedure.model';
import {BaseSessionComponent} from '../../../shared/base-session/base-session.component';
import {UserService} from '../../../core/services/user.service';
import {SessionTrial} from '../../../core/models/session-trial.model';
import * as moment from 'moment';
import {FilterViewService} from '../../../shared/services/filter-view.service';
import {SheetInfo} from '../../../core/models/sheet-info.model';
import * as _ from 'lodash';
import {CommonService} from '../../../core/shared/common/common.service';
import {SessionData} from '../../../core/models/session-data.model';
import {ProcedureViewService} from '../../../shared/services/procedure-view.service';

@Component({
  selector: 'app-incidental-teaching',
  templateUrl: './incidental-teaching.component.html',
  styleUrls: ['./incidental-teaching.component.css']
})
export class IncidentalTeachingComponent extends BaseSessionComponent implements OnInit, OnDestroy {
  // constants
  DEFAULT_SESSION_NUM = 3;
  BASE_LINE_STAGE = 'baseline';

  @Input() data_sheet_type: string;
  // dataSheetForPage: string = 'DT';
  private _data_sheets = new BehaviorSubject<any[]>([]);
  private _patient = new BehaviorSubject<any>({});
  private _sheet_info = new BehaviorSubject<any>({});
  private _is_readonly: boolean;
  private _readonly_data: any;
  _showNavService: ShowNavService;
  private _userService: UserService;
  private _filterViewService: FilterViewService;
  private _commonService: CommonService;

  // objects
  DELIMITER = '&';
  data_sheet: DataSheet;
  getting_data: boolean;
  dailySessionViewService: DailySessionViewService;
  current_user: User;
  max_col_span: number;
  selected_host: number;
  saving_trial: boolean;
  procedure_session_timeout_map: Map<number, any> = new Map<number, any>();
  procedure_session_timeout: any;

  // lists
  procedures: Procedure[];
  all_procedures: Procedure[];
  procedure_sessions: ProcedureSession[];
  procedure_session_defaults: any[];
  phase_type_tactics_list: any[];

  // objects for readonly view
  readonly_sessions: SessionData[];
  max_trials: number;

  dailySessionsSavedSubscription: Subscription;
  therapySessionSubscription: Subscription;
  current_therapist: User = new User();


  constructor(dialogService: DialogService,
              constantsService: ConstantsService,
              dailySessionViewService: DailySessionViewService,
              userViewService: UserViewService,
              toastyService: ToastyService,
              showNavService: ShowNavService,
              currentFormService: CurrentFormService,
              private userService: UserService,
              private filterViewService: FilterViewService,
              private commonService: CommonService,
              procedureViewService: ProcedureViewService) {
    super(constantsService, userViewService, dialogService,
      dailySessionViewService, currentFormService, toastyService, procedureViewService);

    this.dailySessionViewService = dailySessionViewService;
    this._showNavService = showNavService;
    this._userService = userService;
    this._filterViewService = filterViewService;
    this._commonService = commonService;
  }

  @Input()
  set data_sheet_list(data: any[]) {
    this._data_sheets.next(data);
  }

  get data_sheets() {
    return this._data_sheets;
  }

  @Input()
  set patient(obj: any) {
    this._patient.next(obj);
  }

  get patient() {
    return this._patient;
  }

  @Input()
  set sheet_info(obj: any) {
    this._sheet_info.next(obj);
  }

  get sheet_info() {
    return this._sheet_info;
  }

  @Input()
  set is_readonly(readonly: boolean) {
    this._is_readonly = readonly;
  }

  get is_readonly() {
    return this._is_readonly;
  }

  @Input()
  set data(readonly_data: any) {
    this._readonly_data = readonly_data;
  }

  get data() {
    return this._readonly_data;
  }

  padSessionTrials(session_trials: SessionTrial[], num_trials?: number): SessionTrial[] {
    // for (const procedure_session of this.procedure_sessions) {
    const col_span = num_trials || this.max_col_span;
    // const max_length = this.max_col_span > session_trials.length ? this.max_col_span - session_trials.length : 0;

    if (session_trials.length <= col_span) {
      session_trials = [...session_trials, ...Array
        .apply(null, Array(col_span - session_trials.length))
        .map(() => new SessionTrial())];
    }
    return session_trials;
    // }
  }

  addTrial(): void {
    // add another trial to each session
    for (const procedure_session of this.procedure_sessions) {
      if (!procedure_session.session_trials) {
        procedure_session.session_trials = [];
      }
      procedure_session.session_trials.push(new SessionTrial());
    }
    this.max_col_span += 1;
    this.updateSession();
  }

  getDataSheet(data_sheets: DataSheet[]): void {
    this.data_sheet = data_sheets.find(ds => ds.code.toLowerCase() === this.data_sheet_type.toLowerCase());
  }

  getProcedures(): Promise<Procedure[] | void> {
    // TODO: change 'active' to active status from choices
    return this.dailySessionViewService.getProcedures(
      this.patient.getValue().id,
      this.data_sheet.id,
      false, this.therapy_session.is_complete
    )
      .then((procedures: Procedure[]) => {
        this.procedures = [];
        this.all_procedures = procedures.slice();
        if (!this.all_procedures || this.all_procedures.length === 0) {
          this.loading = false;
          this.procedure_sessions = [];
        }
        if (this.all_procedures && this.all_procedures.length > 0) {
          this.procedures = _.cloneDeep(this.all_procedures);
        }
        return this.procedures;
      })
      .catch((err) => {
        // need to show toasty with error
        this.loading = false;
        this.procedure_sessions = [];

        throw err;
      });
  }

  getPromptSteps(): Promise<any> | void {
    let promise;
    if (this.procedures && this.procedures.length > 0 &&
      !this.therapy_session.after_complete &&
      (!this.therapy_session.is_complete ||
        (this.therapy_session.is_complete && this.therapy_session.filtering))) {

      let procedure_idxs: number[] = this.procedures.map(p => p.id);
      procedure_idxs = procedure_idxs != null ? procedure_idxs.filter(ps => ps != null) : [];
      if (procedure_idxs && procedure_idxs.length > 0) {
        promise = this.dailySessionViewService.getPromptFadingStepsByProcIds(procedure_idxs)
          .then(prompt_fading_steps => {
            const grouped_steps = _(prompt_fading_steps)
              .groupBy(st => st.procedure)
              .value();
            // this.max_col_span = session_trials.length > this.max_col_span ? session_trials.length : this.max_col_span;
            const keys = Object.keys(grouped_steps);
            for (const key of keys) {
              const proc_idx = this.all_procedures.findIndex(p => p.id == +key);
              if (proc_idx > -1) {
                this.all_procedures[proc_idx].prompt_steps = grouped_steps[key];
              }
              const idxs = this._commonService.getAllIndexes(this.procedure_sessions, +key, 'procedure');
              if (idxs && idxs.length > 0) {
                for (const idx of idxs) {
                  if (idx > -1) {
                    this.procedure_sessions[idx].prompt_steps = grouped_steps[key];
                  }
                }
              }
            }
            this.procedures = _.cloneDeep(this.all_procedures);
            this.loading = false;

          });
      } else {
        this.loading = false;
      }
    } else if (this.therapy_session.after_complete || this.therapy_session.is_complete) {
      for (const procedure of this.procedures) {
        const proc_idx = this.all_procedures.findIndex(p => p.id === procedure.id);
        if (proc_idx > -1) {
          procedure.prompt_steps = this.all_procedures[proc_idx].prompt_steps;
        }
        const indexes = this._commonService.getAllIndexes(this.procedure_sessions, procedure.id, 'procedure');
        if (indexes != null && indexes.length > 0) {
          for (let i = 0; i < indexes.length; i++) {
            // const idx = this.procedure_sessions.findIndex(ps => ps.procedure == procedure.id);
            const idx = indexes[i];
            this.procedure_sessions[idx].prompt_steps = procedure.prompt_steps || [];
          }
        }
        this.procedures = _.cloneDeep(this.all_procedures);
        // // assuming each of these come back in order
        // // TODO: think of a better algo to set the loading flag
        // const procedure_idx = this.procedures.findIndex(p => p.id == procedure.id);
        // if (procedure_idx != null && procedure_idx == this.procedures.length - 1) {
        //   this.loading = false;
        // }
      }
    }
    // return this.updateSession();
  }

  getProcItems(): Promise<any> | void {
    if (this.procedures && this.procedures.length > 0 &&
      !this.therapy_session.after_complete &&
      (!this.therapy_session.is_complete ||
        (this.therapy_session.is_complete && this.therapy_session.filtering))) {
      let procedure_idxs: number[] = this.procedures.map(p => p.id);
      procedure_idxs = procedure_idxs != null ? procedure_idxs.filter(ps => ps != null) : [];
      if (procedure_idxs && procedure_idxs.length > 0) {
        this.dailySessionViewService.getProcItemsByProcedureIds$(procedure_idxs)
          .subscribe(proc_items => {

            const grouped_items = _(proc_items)
              .groupBy(st => st.procedure)
              .value();

            const keys = Object.keys(grouped_items);
            for (const key of keys) {
              let proc_idx = this.all_procedures.findIndex(p => p.id == +key);
              if (proc_idx > -1) {
                this.all_procedures[proc_idx].proc_items = grouped_items[key].slice();
              }

              proc_idx = this.procedures.findIndex(p => p.id == +key);

              if (proc_idx > -1 && this.procedures[proc_idx] != null) {
                this.procedures[proc_idx].proc_items = grouped_items[key].slice();
                if (!this.therapy_session.is_complete) {
                  this.procedures[proc_idx].proc_items = this.procedures[proc_idx].proc_items.filter(
                    s => this.proc_items_to_show.indexOf(s.stage) > -1
                  );
                }
                const idxs = this._commonService.getAllIndexes(this.procedure_sessions, +key, 'procedure');

                if (idxs && idxs.length > 0) {
                  for (const idx of idxs) {
                    if (idx > -1) {
                      this.procedure_sessions[idx].proc_items = this.procedures[proc_idx].proc_items.slice();
                    }
                  }
                }
              }
            }
            // this.all_procedures = _.cloneDeep(this.procedures);
            this.loading = false;
          }, err => {
            throw err;
          });
      } else {
        this.loading = false;
      }
    } else if (this.therapy_session.after_complete || this.therapy_session.is_complete) {
      for (const procedure of this.procedures) {
        const proc_idx = this.all_procedures.findIndex(p => p.id == procedure.id);
        if (proc_idx > -1) {
          procedure.proc_items = this.all_procedures[proc_idx].proc_items;
        }
        if (!this.therapy_session.is_complete) {
          procedure.proc_items = procedure.proc_items.filter(s => this.proc_items_to_show.indexOf(s.stage) > -1);
        }
        const indexes = this._commonService.getAllIndexes(this.procedure_sessions, procedure.id, 'procedure');
        if (indexes != null && indexes.length > 0) {
          for (let i = 0; i < indexes.length; i++) {
            // const idx = this.procedure_sessions.findIndex(ps => ps.procedure == procedure.id);
            const idx = indexes[i];
            this.procedure_sessions[idx].proc_items = procedure.proc_items;
          }
        }
        // // assuming each of these come back in order
        // // TODO: think of a better algo to set the loading flag
        // const procedure_idx = this.procedures.findIndex(p => p.id == procedure.id);
        // if (procedure_idx != null && procedure_idx == this.procedures.length - 1) {
        //   this.loading = false;
        // }
      }
      // now we delete the after complete because we are done
      delete this.therapy_session.after_complete;
      if (!this.therapy_session.filtering) {
        this.dailySessionViewService.currentSubject.next(this.therapy_session);
      }
    }
    // return this.updateSession();
  }

  getSessionTrials(): void {
    if (this.procedure_sessions && this.procedure_sessions.length > 0) {
      let proc_session_ids = this.procedure_sessions.map(ps => ps.id);
      proc_session_ids = proc_session_ids != null ? proc_session_ids.filter(ps => ps != null) : [];
      if (proc_session_ids && proc_session_ids.length > 0) {
        this.dailySessionViewService.getSessionTrialsByProcedureSessionIds(proc_session_ids)
          .then(session_trials => {
            const grouped_sessions = _(session_trials)
              .groupBy(st => st.procedure_session)
              .value();

            const keys = Object.keys(grouped_sessions);
            for (const key of keys) {
              this.max_col_span = grouped_sessions[key][grouped_sessions[key].length - 1]._order > this.max_col_span ?
                grouped_sessions[key][grouped_sessions[key].length - 1]._order : this.max_col_span;

              const trials = this._commonService.sortByProperty(grouped_sessions[key], '_order');

              let temp: SessionTrial[] = [];
              for (let idx = 0; idx < trials.length; idx++) {
                trials[idx].count = this.daily_session_trial_options.findIndex(dst => dst.value == trials[idx].value);
                if (trials[idx]._order > temp.length) {
                  temp = temp.concat(Array
                    .apply(null, Array(trials[idx]._order - temp.length))
                    .map(() => new SessionTrial()));
                }
                temp.push(_.cloneDeep(trials[idx]));
              }
              const proc_session_idx = this.procedure_sessions.findIndex(ps => ps.id === +key);
              if (proc_session_idx > -1) {
                this.procedure_sessions[proc_session_idx].session_trials = temp.slice();
                this.procedure_sessions[proc_session_idx].session_trials = this.padSessionTrials(
                  this.procedure_sessions[proc_session_idx].session_trials,
                  this.procedure_sessions[proc_session_idx].num_trials
                );
              }
              if (this.procedure_sessions[proc_session_idx] != null &&
                this.procedure_sessions[proc_session_idx].session_trials.length > this.max_col_span) {
                this.max_col_span = this.procedure_sessions[proc_session_idx].session_trials.length;
              }
            }
            this.loading = false;

          });
      } else {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }

    this.updateSession();
  }

  // private getDataEntrySheet(procedure_sessions: ProcedureSession[]) {
  //   if (this.procedure_sessions.length <= this.sd_responses.length) {
  //     // jus tin case we don't have any
  //     const new_procedure_sessions = Array
  //       .apply(null, Array((this.sd_responses.length)))
  //       .map(() => new ProcedureSession());
  //     for (let i = 0; i < this.sd_responses.length; i++) {
  //       new_procedure_sessions[i].sd = this.sd_responses[i].id;
  //       new_procedure_sessions[i].procedure = this.current_procedure_id;
  //       new_procedure_sessions[i].session_trials = this.padSessionTrials([]);
  //     }
  //   }
  //   for (let i = 0; i < procedure_sessions.length; i++) {
  //     if (procedure_sessions[i]._order == i) {
  //       this.procedure_sessions[i] = _.cloneDeep(procedure_sessions[i]);
  //     }
  //   }
  // }

  getNewProcedureSession(): void {
    this.setDefaults();
    // now we get the list of prompt steps
    this.getPromptSteps();
    this.getProcItems();
    // this.updateProcedureSessions();

    this.loading = false;
  }

  updateSession(is_trial_click?: boolean, procedure?: ProcedureSession, idx?: number,
                procedure_session_idx?: number): Promise<boolean | void> {
    // don't need this anymore
    // this.dailySessionViewService.currentSubject.next(this.therapy_session);
    this.dailySessionViewService.currentProcedureSessions.next(this.procedure_sessions);
    if (is_trial_click) {

      // setTimeout(() => {
      let promise: Promise<number | void>;
      if (procedure.id == null) {
        promise = this.saveProcedureSession(procedure_session_idx);
      } else {
        promise = Promise.resolve(this.procedure_sessions[procedure_session_idx].id);
      }
      return promise.then((procedure_id: number) => {
        let sub_promise: Promise<any>;
        if (procedure.session_trials[idx].procedure_session == null) {
          procedure.session_trials[idx].procedure_session = procedure_id;
        }
        // save it if we have a value, otherwise we delete it
        if (procedure.session_trials[idx].value == null && procedure.session_trials[idx].id != null) {
          sub_promise = this.dailySessionViewService.deleteSessionTrial(procedure.session_trials[idx]);
        } else if (procedure.session_trials[idx].value != null) {
          sub_promise = this.dailySessionViewService.saveSessionTrial(procedure.session_trials[idx]);
        } else {
          sub_promise = Promise.resolve(procedure.session_trials[idx]);
        }
        return sub_promise;
      })
        .then((res: any) => {
          // because the return values could either be a session tiral or a success code
          // for deleting the object successfully, then need to check if we have the id
          // if we don't then it should've been deleted
          if (res.id != null) {
            procedure.session_trials[idx].id = res.id;
          } else {
            delete procedure.session_trials[idx].id;
          }

          // now we get the procedure session
          if (procedure.getting_procedure_id == null) {
            // setting the trial that is getting the procedure
            procedure.getting_procedure_id = idx;
            return this.dailySessionViewService.getProcedureSessionById(procedure.id);
          } else {
            return Promise.resolve(procedure);
          }
        })
        .then((procedure_session) => {
          procedure = Object.assign(procedure, procedure_session);
          if (procedure.getting_procedure_id == idx) {
            procedure.getting_procedure_id = null;
          }
          if (procedure.perc_correct == null) {
            procedure.edit_perc_correct = false;
          }
          if (procedure.total_trials == null) {
            procedure.edit_total_trials = false;
          }

          return true;
        })
        .catch((err) => {
          throw err;
        });
      // }, 10);
    } else {
      return of(true).toPromise();
    }
  }

  updateProcedureSession(idx: number, phase_input_valid?: boolean, $event?: any): void {
    if ($event === null) {
      this.addToast('Invalid format. Use format "xh (and/or) xm (and/or) xs" where "x" is a number of hours, minutes and seconds. Or just put the number of seconds.', 'error', null, 10000);
      return;
    }
    // checking if phase is null, ot it's a blank string cause the input type is text
    const procedure: Procedure = this.all_procedures.find(p => p.id == this.procedure_sessions[idx].procedure);
    if (procedure && Object.keys(procedure).length > 0) {
      this.procedure_sessions[idx].phase_types = procedure.phase_types;
      this.procedure_sessions[idx].proc_items = procedure.proc_items;
      this.procedure_sessions[idx].prompt_steps = procedure.prompt_steps;
    }
    if (this.procedure_sessions[idx].phase_type == null) {
      return
    }
    if (phase_input_valid != null && !phase_input_valid) {
      return
    }
    this.procedure_session_timeout_map.set(idx, this.procedure_sessions[idx]);

    if (this.procedure_session_timeout) {
      clearTimeout(this.procedure_session_timeout);
      this.procedure_session_timeout = null;
    }

    this.procedure_session_timeout = setTimeout(() => {
      // if (!this.is_procession) {
      this.procedure_session_timeout_map.delete(idx);
      this.processArray([[idx]], this.saveProcedureSession)
        .then(final => {
          if (this.procedure_sessions[idx].isPhaseError) {
            delete this.procedure_sessions[idx].isPhaseError;
          }
          if (this.procedure_session_timeout_map.has(idx)) {
            clearTimeout(this.procedure_session_timeout);
            this.procedure_session_timeout = null;
            this.updateProcedureSession(idx);
          }

        }).catch((err) => {
          if (this.procedure_sessions[idx].isPhaseError) {
            delete this.procedure_sessions[idx].isPhaseError;
          }
          if (err.error) {
            for (let key in err.error) {
              this.addToast(err.error[key][0], 'error');
              if (key === 'phase') {
                this.procedure_sessions[idx].isPhaseError = true;
              }
            }
          }
          console.log(err);

          throw err;
        });
    }, 0);
  }

  saveProcedureSession(idx: number): Promise<number | void> {
    if (this.procedure_sessions[idx].procedure != null) {
      this.procedure_sessions[idx].saving = true;
      if (this.procedure_sessions[idx].therapy_session == null) {
        this.procedure_sessions[idx].therapy_session = this.therapy_session.id;
      }
      if (this.procedure_sessions[idx].perc_correct == '') {
        this.procedure_sessions[idx].perc_correct = null;
      }
      if (this.procedure_sessions[idx].total_trials == '') {
        this.procedure_sessions[idx].total_trials = null;
      }
      // if for some reason therapy session id is still null on proc session, then abort
      if (this.procedure_sessions[idx].therapy_session == null) {
        return throwError('no therapy id on procedure session').toPromise();
      }
      if (this.procedure_sessions[idx]._order == null) {
        this.procedure_sessions[idx]._order = idx;
      }
      return this.dailySessionViewService.saveProcedureSession(this.procedure_sessions[idx])
        .then((procedureSession: ProcedureSession) => {
          // in theory we should be ok as the values shold be passed by reference
          if (this.procedure_sessions[idx].default_session_idx != null) {
            this.setTherapySessionStateId(
              this.procedure_session_defaults[this.procedure_sessions[idx].default_session_idx],
              procedureSession.id
            );
            delete this.procedure_sessions[idx].default_session_idx;
          }
          // this.procedure_sessions[idx].id = procedureSession.id;
          this.procedure_sessions[idx] = _.merge(this.procedure_sessions[idx], procedureSession);

          if (this.procedure_sessions[idx] != null) {
            this.procedure_sessions[idx].saving = false;
            if (this.procedure_sessions[idx].perc_correct == null) {
              this.procedure_sessions[idx].edit_perc_correct = false;
            }
            if (this.procedure_sessions[idx].total_trials == null) {
              this.procedure_sessions[idx].edit_total_trials = false;
            }
          } else {
            console.log('The procedure session is null');
          }

          return this.procedure_sessions[idx].id;
          // for now we buffer up to the default max
        })
        .catch(
          (err) => {
            this.procedure_sessions[idx].saving = false;

            throw err;
          });
    }

  }

  saveSession(complete_session?: boolean): Promise<TherapySession> {
    this.dailySessionViewService.currentProcedureSessions.next(this.procedure_sessions);
    return this.dailySessionViewService.save(complete_session)
      .then((therapy_session) => {
        // in theory we should be ok as the values shold be passed by reference
        this.therapy_session = _.merge(this.therapy_session, therapy_session);
        const saved_procedure_sessions = this.dailySessionViewService.currentProcedureSessions.getValue();
        if (saved_procedure_sessions && saved_procedure_sessions.length > 0) {
          for (const idx in this.procedure_sessions) {
            this.procedure_sessions[idx] = _.merge(this.procedure_sessions[idx], saved_procedure_sessions[idx]);
            this.procedure_sessions[idx].session_trials = this.padSessionTrials(this.procedure_sessions[idx].session_trials,
              this.procedure_sessions[idx].num_trials);
          }
        }

        // for now we buffer up to the default max
        return therapy_session;
      })
      .catch((err) => {
        return throwError(err).toPromise();
      });
  }

  trialClick(trial: SessionTrial, procedure_session?: ProcedureSession, index?: number, procedure_session_idx?: number): void {
    let promise: Promise<number | void>;
    if (!procedure_session.id && !this.saving_proc_session.has(procedure_session_idx)) {

      promise = this.saveProcedureSession(procedure_session_idx);
      this.saving_proc_session.set(procedure_session_idx, new BehaviorSubject<number>(null));
    } else if (!procedure_session.id && this.saving_proc_session.has(procedure_session_idx)) {
      promise = this.saving_proc_session.get(procedure_session_idx).toPromise();
    } else {
      promise = Promise.resolve(procedure_session.id);
    }

    promise.then((id: number) => {
      procedure_session.id = id;

      let dailySessions: Map<number, SessionTrial[]> = this.dailySessionViewService.dailySessions.getValue();
      if (dailySessions == null) {
        dailySessions = new Map<number, SessionTrial[]>();
      }

      // if (!this.trial_timeout_map.has(procedure_session.id)) {
      //   this.trial_timeout_map.set(procedure_session.id, new Map<number, any[]>());
      // }
      if (trial.count == null) {
        trial.count = 0;
      } else if (trial.count === 2) {
        trial.count = null;
      } else if (trial.count !== -1) {
        trial.count = (trial.count + 1) % 3;
      }

      if (trial.count != null && trial.count > -1) {
        trial.value = this.daily_session_trial_options[trial.count].value;
      } else if (trial.count == null) {
        trial.value = null;
      }
      trial._order = index;

      // // here we set the timestamps to check we have saved everything
      // trial.timestamp = moment().toDate();
      // if (!trial.earliest) {
      //   this.saveTrial(trial, procedure_session, index, procedure_session_idx)
      // }

      if (!dailySessions.has(procedure_session.id)) {
        // if we don't have the object in the map then we create it
        dailySessions.set(procedure_session.id, new Array(procedure_session.session_trials.length));
      }
      //TODO: need to resize the array if the user adds more trials after doing edits
      dailySessions.get(procedure_session.id)[index] = trial;
      this.dailySessionViewService.dailySessions.next(dailySessions);
    });
  }

  private setProcedureSessionDropdowns() {
    if (this.procedure_sessions && this.procedure_sessions.length > 0) {
      for (const procedure_session of this.procedure_sessions) {
        const proc_idx = this.all_procedures.findIndex(p => p.id == procedure_session.procedure);
        if (proc_idx > -1) {
          procedure_session.prompt_steps = this.all_procedures[proc_idx].prompt_steps;
          procedure_session.proc_items = this.all_procedures[proc_idx].proc_items;
        }
      }
    }
  }

  setDefaults(): void {
    const procedure_sessions: ProcedureSession[] = [];
    // now we loop over all the defaults we have found
    if (this.procedure_session_defaults && this.procedure_session_defaults.length > 0) {
      this.procedures = [];

      this.max_col_span = this.procedure_session_defaults[0].num_trials > this.max_col_span ?
        this.procedure_session_defaults[0].num_trials : this.max_col_span;
      this.DEFAULT_SESSION_NUM = this.max_col_span;
        for (let i = 0; i < this.procedure_session_defaults.length; i++) {
          const session_default = this.procedure_session_defaults[i];
          if (this.all_procedures && this.all_procedures.length > 0) {
            const procedure = this.all_procedures.find(p => p.id == session_default.procedure);
            if (procedure) {
              this.procedures.push(_.cloneDeep(procedure));
              let procedure_session: ProcedureSession;
              if (session_default.procedure_session_id != null) {
                procedure_session = this.procedure_sessions.find(ps => ps.id == session_default.procedure_session_id);
              } else {
                // so now we try and get it based on the order
                procedure_session = this.procedure_sessions.find(ps => ps._order == i);
              }

              if (!procedure_session) {
                let proc_sessions = _.filter(this.procedure_sessions, ps => ps.procedure == procedure.id);
                if (proc_sessions && proc_sessions.length > 0) {
                  proc_sessions = _.filter(proc_sessions, ps => ps.proc_item == session_default.item)
                }
                if (proc_sessions && proc_sessions.length > 0) {
                  proc_sessions = _.filter(proc_sessions, ps => ps.prompt_step == session_default.prompt_step)
                }
                if (proc_sessions && proc_sessions.length > 0) {
                  proc_sessions = _.filter(proc_sessions, ps => ps.phase == session_default.phase);
                }
                if (proc_sessions && proc_sessions.length > 0) {
                  proc_sessions = _.filter(proc_sessions, ps => ps.phase_type == session_default.phase_type)
                }
                if (proc_sessions && proc_sessions.length > 0) {
                  procedure_session = proc_sessions[0];
                }
              }
              if (!procedure_session) {
                if (this.therapy_session.filtering) {
                  // we wanna skip any sessions that weren't saved
                  continue;
                }
                procedure_session = new ProcedureSession();
                procedure_session.procedure = procedure.id;
                procedure_session.default_session_idx = i;
              }
              if (session_default.warnings && session_default.warnings.length > 0) {
                procedure_session.warning = session_default.warnings.join(', ');
              }
              procedure_session.phase_types = procedure.phase_types;
              procedure_session.prompt_steps = [];
              procedure_session.proc_items = [];

              if (procedure_session.phase == null) {
                procedure_session.phase = session_default.phase;
              }
              if (procedure_session.proc_item == null) {
                procedure_session.proc_item = session_default.item;
              }
              if (procedure_session.phase_type == null) {
                procedure_session.phase_type = session_default.phase_type == null ?
                'incidental' :
                session_default.phase_type;
              }
              if (procedure_session.prompt_step == null) {
                procedure_session.prompt_step = session_default.prompt_step;
              }
              if (procedure_session.stage == null) {
                procedure_session.stage = session_default.stage;
              }
              if (procedure_session.num_trials == null) {
                procedure_session.num_trials = session_default.num_trials;
              }
              procedure_session.phase_type_tactics = session_default.phase_type_tactics;
              // we get the pahse_type_tactics object from the session defaults (meta from therapy session), if that doesn't exist
              // this means we have a historic object, so need to get it from the procedure instead
              this.phase_type_tactics_list.push(session_default.phase_type_tactics || procedure.phase_type_tactic);
              if (!procedure_session.session_trials || procedure_session.session_trials.length == 0) {
                procedure_session.session_trials =
                  this.padSessionTrials([], procedure_session.num_trials || this.DEFAULT_SESSION_NUM);
              }
              procedure_sessions.push(_.cloneDeep(procedure_session));
            }
          }
        }
        this.procedure_sessions = procedure_sessions.slice();
      // }
    }
  }

  subscribeToSheetInfo(): void {
    this.subscriptions.push(this.sheet_info.subscribe((sheet_info: SheetInfo) => {
      if (sheet_info && Object.keys(sheet_info).length > 0) {
        const data = sheet_info.data.filter(d => d.data_sheet_code == this.data_sheet_type);
        this.procedure_session_defaults = data.slice();
        this.setDefaults();
      }
    }));
  }

  // subscriptions
  subscribeToDataSheets(): void {
    this.subscriptions.push(this.data_sheets.subscribe((items) => {
      // wait until we have some data_sheets
      if (items && items.length > 0) {
        this.getDataSheet(items);
        if (!this.getting_data && this.patient.getValue() && this.patient.getValue().id != null) {
          this.getting_data = true;
          this.getData();
        }
      }
    }));
  }

  subscribeToPatient(): void {
    this.subscriptions.push(this.patient.subscribe((patient: User) => {
      if (!this.getting_data && patient && patient.id != null && this.data_sheet && this.data_sheet.id != null) {
        this.getting_data = true;
        this.getData();
      }
    }));
  }

  subscribeToCurrentUser(): void {
    const curr_user = this._userService.currentUserUpdated.getValue();
    if (curr_user) {
      this.current_user = curr_user;
      if (this.therapy_session && this.therapy_session.host == null) {
        this.therapy_session.host = this.current_user.id;
      }

    }
    this.subscriptions.push(this._userService.currentUserUpdated.subscribe(
      (current_user) => {
        this.current_user = current_user;
        if (this.therapy_session.can_edit && this.therapy_session.host == null) {
          this.therapy_session.host = this.current_user.id;
        }
        // this.selected_host = this.current_user.id
      }));
  }

  subscribeToUsers(): void {
    this.subscriptions.push(this.allUsersSubject.subscribe(
      (users: User[]) => {
        if (this.therapy_session && this.therapy_session.host != null && this.therapy_session.id != null) {
          this.current_therapist = users.find(ul => ul.id === this.therapy_session.host);
          if (this.current_therapist && this.therapy_session &&
            this.therapy_session.host == null && this.therapy_session.id != null) {
            this.therapy_session.host = this.current_therapist.id;
          }
        }
      }));
  }

  subscribeToSessionCache(): void {
    // TODO: simplify this data structure that's returned to a list of ids instead of a map
    if (this.dailySessionsSavedSubscription) return;

    this.dailySessionsSavedSubscription = this.dailySessionViewService.dailySessionsSaved.subscribe((sessions) => {
      const keys = sessions.keys();
      let key = keys.next();
      while (!key.done) {
        let k = key.value;
        // now need to check each of the procedure sessions found here and in the 'future' save to update with the id or delete
        // NOTE: we don't need to do this anymore since we are disabling the UI

        const idx = this.procedure_sessions.findIndex(ps => ps.id == k);
        const ret_list = sessions.get(k);
        for (let i = 0; i < ret_list.length; i++) {
          const saved = ret_list[i];
          if (this.procedure_sessions[idx]) {
            this.procedure_sessions[idx].session_trials[saved._order].id = saved.id;
          }
        }

        // now we just reload the procedure sessions that were saved
        this.dailySessionViewService.getProcedureSessionById(k)
          .then((procedure_session: ProcedureSession) => {
            if (idx > -1) {
              this.procedure_sessions[idx] = _.merge(this.procedure_sessions[idx], procedure_session);
            }
          });
        key = keys.next();
      }
      // now let's save the therapy_session if we need to
      if (this.therapy_session.ignore_update) {
        this.dailySessionViewService.saveTherapySession(this.therapy_session)
          .then((therapy_session) => {
            const ignore_update = this.therapy_session.ignore_update;
            this.therapy_session = _.merge(this.therapy_session, therapy_session);
            this.therapy_session.ignore_update = ignore_update;
            this.dailySessionViewService.currentSubject.next(this.therapy_session);
          });
      }
      this.saving_trial = false;
      if (this.toastyId != null && sessions.size > 0) {
        this.toastyService.clear(this.toastyId);
        this.addToast('Done', 'success');
      }
      this.toastyId = null;
    });
  }

  subscribeToTherapySession(): void {
    if (this.therapySessionSubscription) return;

    this.therapySessionSubscription = this.dailySessionViewService.currentSubject.subscribe(
      (therapy_session: TherapySession) => {
        if (this.therapy_session && !this.therapy_session.after_complete && !this.therapy_session.ignore_update && !this.therapy_session.is_error_finish) {
          this.therapy_session = therapy_session;
          if (this.therapy_session.is_complete && !this.therapy_session.filtering) {
            this.loading = true;
            this.saveSession(true).then(
              (completed) => {
                this.procedure_sessions = [];
                this.max_col_span = this.DEFAULT_SESSION_NUM;
                this.getNewProcedureSession();
                this.loading = false;
              })
              .catch((err) => {
                //this.loading = false;
                throw err;
              });
            return;
          }
          if (therapy_session && therapy_session.id != null) {
            if (!therapy_session.after_complete) {
              this.loading = true;

              this.therapy_session = therapy_session;
              // if (this.therapy_session.filtering) {
              this.procedures = [];
              this.procedure_sessions = [];
              this.proc_items = [];
                // this.all_procedures = [];
                // this.therapy_session.filtering = false;
              // }
              // if (!this.procedure_sessions || this.procedure_sessions.length == 0) {

                this.getProcedures().then((procedures) => {

                  return this.dailySessionViewService.getProcedureSessionByTherapySessionAndDataSheet(
                    this.therapy_session.id,
                    this.data_sheet.id,
                    this.therapy_session.filtering
                  );
                })
                  .then((procedure_sessions) => {
                    if (procedure_sessions && procedure_sessions.length > 0) {

                      for (let i = 0; i < procedure_sessions.length; i++) {
                        const procedure: Procedure = this.procedures.find(p => p.id == procedure_sessions[i].procedure);
                        if (procedure && Object.keys(procedure).length > 0) {
                          procedure_sessions[i].phase_types = procedure.phase_types;
                        }
                      }
                      this.procedure_sessions = procedure_sessions.slice();

                      if (this.therapy_session.state_sheet_meta && this.therapy_session.state_sheet_meta.data) {
                        const data = this.therapy_session.state_sheet_meta.data
                          .filter(d => d.data_sheet_code == this.data_sheet_type);
                        this.procedure_session_defaults = data.slice();
                      } else {
                        this.procedure_session_defaults = null;
                      }

                      this.setDefaults();

                      this.getPromptSteps();
                      this.getProcItems();

                      this.getSessionTrials();

                    } else if (procedure_sessions && procedure_sessions.length === 0 &&
                      this.selected_host != null) {
                      // this.selected_date != null && this.selected_host != null) {
                      this.procedure_sessions = [];

                      this.loading = false;
                    } else if (!this.therapy_session.filtering || !this.therapy_session.is_complete) {
                      this.getNewProcedureSession();
                    } else {
                      this.procedure_sessions = [];
                      this.loading = false;
                    }
                  })
                  .catch(err => {
                    console.log(err);

                    this.setProcedureSessionDropdowns();
                    this.loading = false;
                  });
              // } else {
              //   this.setProcedureSessionDropdowns();
              //   this.loading = false;
              // }

            }
          }
        }
        else if (this.therapy_session.ignore_update) {
          delete this.therapy_session.ignore_update;
          this.loading = false;
          // this.therapy_session = _.merge(this.therapy_session, therapy_session);
        }
        if (this.therapy_session.is_error_finish) {
          delete this.therapy_session.is_error_finish;
          this.loading = false;
        }
      });
  }

  private getData(): void {
    this.subscribeToCurrentUser();
    this.userViewService.getUsersPromise().then(
      (users) => {
        this.users_list = this.filterHosts(users);
        this.getProcedures().then((procedure_sessions) => {
          // for some reason at this point, procedure_sessions is empty

          // this.subscribeToSearchFilterSubject();
          this.getting_data = false;
        });
      });
  }

  processReadonlyData(): void {
    if (this.data && this.data.data) {
      this.max_trials = this.data.max_trials;
      for (const obj of this.data.data) {
        const new_session_data = new SessionData();
        new_session_data.date = obj.date;
        new_session_data.description = obj.description;
        new_session_data.host = obj.host;
        new_session_data.perc_correct = obj.perc_correct;
        new_session_data.num_trials = obj.state_prescribed_trials;
        new_session_data.session_trials = [];
        if (obj.counted_for_mastery != null) {
          new_session_data.counted_for_mastery = obj.counted_for_mastery;
        }
        if (obj.trials && obj.trials.length > 0) {
          for (let i = 0; i < obj.trials.length; i++) {
            const trial = new SessionTrial();
            trial.value = obj.trials[i].value;
            trial._order = i;
            new_session_data.session_trials.push(_.cloneDeep(trial));
          }
        }
        new_session_data.session_trials = this.padSessionTrials(new_session_data.session_trials, new_session_data.num_trials);
        this.readonly_sessions.push(_.cloneDeep(new_session_data));
      }
    }
    this.loading = false;
  }

  ngOnInit() {
    this.cmp_name = 'incidental teaching';
    this.loading = true;
    this.procedure_sessions = [];
    // this.selected_date = moment().toDate();
    this.subscriptions = [];
    this.prompt_types = [];
    this.phase_types = [];
    this.phase_type_tactics_list = [];
    this.saving_proc_session = new Map<number, BehaviorSubject<number>>();

    this.subscribeToChoices();

    if (!this.is_readonly) {
      this.max_col_span = this.DEFAULT_SESSION_NUM;
      this.therapy_session = this.dailySessionViewService.currentSubject.getValue();
      if (!this.therapy_session || this.therapy_session.id == null) {
        this.therapy_session = new TherapySession();
        this.therapy_session.date_date = moment().toDate();
      }
      if (this.therapy_session.ignore_update) {
        delete this.therapy_session.ignore_update;
      }

      this.saving_trial = false;
      // this.getInitialData();
      this.subscribeToDataSheets();
      this.subscribeToPatient();
      this.subscribeToSheetInfo();

      this.subscribeToTherapySession();
      this.subscribeToUsers();
      this.subscribeToSessionSave();
      this.subscribeToSessionCache();
      this.subscribeToSavingSessions();

    } else {
      this.readonly_sessions = [];
      this.processReadonlyData();
    }
    this._showNavService.showButtons.next(false);
  }

  ngOnDestroy(): void {
    this.saving_trial = false;
    this.unsubscribe();
    this._showNavService.showButtons.next(true);
    if (this.sessionSave) {
      // killing the incremental save for this page
      this.dailySessionViewService.killTrigger.next();
      this.sessionSave.unsubscribe();
    }
    if (this.sessionSaving) {
      this.sessionSaving.unsubscribe();
    }
    if (this.dailySessionsSavedSubscription) {
      this.dailySessionsSavedSubscription.unsubscribe();
    }
    if (this.therapySessionSubscription) {
      this.therapySessionSubscription.unsubscribe();
    }
  }

}
