import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {Observable, of} from 'rxjs';
import * as StackTraceParser from 'error-stack-parser';
import {environment as env} from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(
    private injector: Injector,
  ) { }

  log(error, userName?: string) {
    // Log the error to the console
    console.error(error);
    // Send error to server
    const errorToSend = this.addContextInfo(error, userName);
    return FakeHttpService.post(errorToSend);
  }

  addContextInfo(error, userName) {
    // You can include context details here (usually coming from other services: UserService...)
    const name = error.name || null;
    const appId = env.app;
    const user = userName;
    const time = new Date().getTime();
    const id = user != null ? `${appId}-${user}-${time}` : `${appId}-${time}`;
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const status = error.status || null;
    const message = error.message || error.toString();
    const stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);

    const errorWithContext = {name, appId, user, time, id, url, status, message, stack};
    return errorWithContext;
  }
}

class SentryService {
  static post(error): Observable<any> {
    console.log('Error sent to the server: ', error);
    // return this.httpClient.post();
    return of({});
  }
}

class FakeHttpService {
  static post(error): Observable<any> {
    console.log('Error sent to the server: ', error);
    return of(error);
  }
}
