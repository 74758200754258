import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getPatientName'
})
export class GetPatientNamePipe implements PipeTransform {

  transform(value: any): any {
    if (!value) {
      return;
    }

    return !value || !value.last_name || value.length === 0 ? '' : value.first_name + ' ' + value.last_name[0];

  }

}
