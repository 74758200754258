import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssessmentsComponent } from './assessments.component';
import {CoreModule} from '../../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IntlModule} from '@progress/kendo-angular-intl';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    IntlModule,
    DateInputsModule,
    DropDownsModule,
    DialogModule
  ],
  declarations: [
    AssessmentsComponent
  ],
  exports: [
    AssessmentsComponent
  ]
})
export class AssessmentsModule { }
