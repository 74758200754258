import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { environment as env } from '../../../environments/environment';
import {AuthenticationService} from '../authentication.service';
import {StartupService} from '../shared/startup/startup.service';
import {AuthGroupService} from '../services/auth-group.service';
import {DeviceDetectorService} from '../device-detector/device-detector.service';

@Injectable()
export class AccessGuard implements CanActivate {
  defaultExpiry = env.expires_within;
  constructor (
    private authenticationService: AuthenticationService,
    private router: Router,
    private startupService: StartupService,
    private authGroupService: AuthGroupService,
    private deviceService: DeviceDetectorService
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<any> | boolean {
    const requiresLogin = next.data.requiresLogin || false;
    const token = this.authenticationService.getToken();

    // TODO: upgrade the version of angular to atleast 7.4.X before proceeding
    console.log('this.deviceService', this.deviceService, this.deviceService.isMobile())
    if (this.deviceService.isMobile()) {
      this.router.navigate(['/mobile-coming-soon'])
      return
    }

    if (requiresLogin) {
      if (token && (!this.authenticationService.isExpired(token) ||
          !this.authenticationService.expiresWithin(this.defaultExpiry))) {
        // this.router.navigate(['/login']);
        return this.startupService.load().then(() => {
          if (!this.authGroupService.canAccessRoute(next.url[0].path)) {
            console.log(next);
            this.router.navigate(['/error']);
            return false;
          }
          if (this.router.url === '/patient') {
            console.log(this.router);
            this.router.navigate(['/patients']);
          }
          return true;
        })
          .catch(
            (err) => {
              // log the error
              // just incase there is a different kind of error
              this.router.navigate(['/login']);
              return;
            });
      } else {
        // logout regardless if we successfully logour of the api or not
        // the logout service should take care of deleting the key
        // this.operatorService.logout().then((res) => {
        //   this.router.navigate(['/login']);
        // })
        //   .catch(
        //     (err) => {
        //       console.log(err);
        //       this.router.navigate(['/login']);
        //     });
        this.router.navigate(['/login']);
        return;
      }
    } else {
      return true;
    }
  }
}
