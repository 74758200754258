export class VerifyPassword {
  newPassword: string;
  verifyPassword: string;

  constructor(
    newPassword?: string,
    verifyPassword?: string
  ) {
    this.newPassword = newPassword || '';
    this.verifyPassword = verifyPassword || '';
  }
}
