import {Directive, ElementRef, HostListener, Inject, Input, Renderer2} from '@angular/core';
import {DocumentService} from '../services/document.service';
import {Document} from '../models/document.model';
import {DocumentViewService} from '../../shared/services/document-view.service';
import {CommonService} from '../shared/common/common.service';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {ConstantsService} from '../services/constants.service';
import {DOCUMENT} from '@angular/common';

@Directive({
  selector: '[appDownloadLink]'
})
export class DownloadLinkDirective {
  @Input() linkFile: any;
  private DEFAULT_TITLE = 'Error';

  constructor(public el: ElementRef,
              private documentViewService: DocumentViewService,
              private commonSerivce: CommonService,
              private toastyService: ToastyService) {
  }

  @HostListener('click') onClick() {
    console.log(this.linkFile);
    this.documentViewService.getFileById(this.linkFile.id)
      .then((documentObj: Document) => {
        let fileBlob, fileURL;
        if (documentObj.file_content) {
          fileBlob = this.commonSerivce.b64toBlob(documentObj.file_content, documentObj.filetype);
          fileURL = URL.createObjectURL(fileBlob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = fileURL;
          a.download = documentObj.filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      })
      .catch((err) => {
        // TODO: need to display why we couldn't download the file
        this.addToast('Could not download file, please try again later', 'error');
        throw err;
      })
  }

  getToastOptions(message: string, title: string): ToastOptions {
    title = title != null ? title : this.DEFAULT_TITLE;

    return {
      title: title,
      msg: message,
      showClose: true,
      timeout: 5000,
      theme: 'default'
    };
  }

  // TODO: move to base component
  addToast(message: string, title: string) {
    // Or create the instance of ToastOptions
    const toastOptions = this.getToastOptions(message, title);
    // // Add see all possible types in one shot
    // this.toastyService.info(toastOptions);
    // this.toastyService.success(toastOptions);
    // this.toastyService.wait(toastOptions);
    // this.toastyService.error(toastOptions);
    // this.toastyService.warning(toastOptions);

    switch (title.toLowerCase()) {
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
    }
  }
}
