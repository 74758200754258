import {ErrorHandler, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ErrorRoutingModule} from './routing/errors-routing.module';
import {RouterModule} from '@angular/router';
import {ErrorsComponent} from './component/errors.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ServerErrorsInterceptor} from './server-errors-interceptor/server-errors.interceptor';
import {AuthInterceptor} from '../interceptors/auth.interceptor';
import {ErrorsHandler} from './handler/errors-handler';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ErrorRoutingModule,
  ],
  declarations: [
    ErrorsComponent
  ],
  providers: [
    // TODO: need to add in the ServersInterceptor, need to figure out why this isn't loading
    // {
    //   provide: ErrorHandler,
    //   useClass: ErrorsHandler,
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ServerErrorsInterceptor,
    //   multi: true
    // },{
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true,
    // }
  ]
})
export class ErrorsModule { }
