export class Domain {
  id: number;
  created: string;
  updated: string;
  _order: number;
  archived: boolean;
  name: string;
  short_description: string;
  created_by: number;
  last_updated_by: number;
  ltga: number;
}
