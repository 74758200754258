import {Base} from './base.model';

export class Document extends Base<Document> {
  id: number;
  patient: number;
  type: string;
  name: string;
  description: string;
  file: File;
  object_id: number;
  content_type: number;
  archived: boolean;
  created_by: number;
  last_updated_by: number;
  file_content: string;
  filetype: string;
  filename: string;
  data_type: string;

  constructor() {
    super(Document);
    this.required_fields = [
      'name',
      'file',
      'publish_status'
    ];
  }
}
