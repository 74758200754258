import {Base} from './base.model';
import {Document} from './document.model';

export class Assessment extends Base<Assessment> {
  id: number;
  patient: number;
  host: number;
  created: string;
  updated: string;
  archived: false;
  date: string;
  date_completed: string;
  name: string;
  type: string;
  status: string;
  next_due: string;
  created_by: number;
  last_updated_by: number;
  note: string;
  edit: boolean;
  date_date: Date;
  date_completed_date: Date;
  next_due_date: Date;
  document: Document;
  documents: Document[];

  constructor() {
    super(Assessment);
  }
}
