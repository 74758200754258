export class DataPoint {
  date: string;
  phase: number;
  value: number;
  name: string;
  converted_date: Date;
  label: string;
}

export class GraphData {
  x_label: string;
  y_label: string;
  multiple: boolean;
  data: DataPoint[];
  data_multiple: {[name: string]: DataPoint[]};
}
