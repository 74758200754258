export class Session {
  id: number;
  procedure: number;
  host: number;
  created: string;
  updated: string;
  created_by: number;
  last_updated_by: number;
  archived: boolean;
  date: string;
  phase: number;
  item: string;
  tactic: string;
  num_prompted: number;
  num_independent: number;
  num_error: number;
  num_total: number;
  percent_correct: number;
  percent_independent: number;
  edit: boolean;
  date_date: Date;
}
