import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PromptStepsComponent} from './prompt-steps/prompt-steps.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {CoreModule} from '../core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IntlModule} from '@progress/kendo-angular-intl';
import {SharedModule} from '../../shared/shared.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MaskedTextBoxModule} from '@progress/kendo-angular-inputs';
import {DurationTrialComponent} from './duration-trial/duration-trial.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    IntlModule,
    DropDownsModule,
    SharedModule,
    MaskedTextBoxModule,
    MatTooltipModule,
  ],
  declarations: [
    PromptStepsComponent,
    DurationTrialComponent
  ],
  exports: [
    PromptStepsComponent,
    DurationTrialComponent
  ]
})
export class ComponentsModule {
}
