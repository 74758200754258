// import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
// import {BaseComponent} from '../../shared/base/base.component';
// import {DialogCloseResult, DialogService} from '@progress/kendo-angular-dialog';
// import {ConstantsService} from '../../core/services/constants.service';
// import {ProcedureService} from '../../core/services/procedure.service';
// import {User} from '../../core/models/user.model';
// import {PatientService} from '../../core/services/patient.service';
// import {Procedure} from '../../core/models/procedure.model';
// import {ProcedureViewService} from '../../shared/services/procedure-view.service';
// import {UserViewService} from '../../shared/services/user-view.service';
// import {GraphData} from '../../core/models/graph-data.model';
// import { groupBy, aggregateBy } from '@progress/kendo-data-query';
// import {PopupRef, PopupService} from '@progress/kendo-angular-popup';
// import {CurrentFormService} from '../../core/services/current-form.service';
// import {SelectOption} from '../../core/models/select-option.model';
// import {Layout, Path, Text} from '@progress/kendo-drawing';
// import {ShowNavService} from '../../core/services/show-nav.service';
// import {ToastyService} from 'ng2-toasty';
// import {HttpErrorCodes} from '../../core/shared/enum';
// import * as moment from 'moment';
// import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
// import {AddSapComponent} from '../../shared/add-sap/add-sap.component';
// import {PatientDetails} from '../../core/models/patient-details.model';
// import {MultipleExampleInstructionComponent} from '../sessions/multiple-example-instruction/multiple-example-instruction.component';
// import {DiscreteTrialComponent} from '../sessions/discrete-trial/discrete-trial.component';
// import {IncidentalTeachingComponent} from '../sessions/incidental-teaching/incidental-teaching.component';
// import {TaskAnalysisComponent} from '../sessions/task-analysis/task-analysis.component';
// import {Point, Rect} from '@progress/kendo-drawing/geometry';
// import {DurationComponent} from '../sessions/duration/duration.component';
// import {DataSheet} from '../../core/models/data-sheet.model';
// import {Margin} from '@progress/kendo-angular-charts';
// import { ConfirmDeleteService } from 'app/core/services/confirm-delete.service';
// import { throwError } from 'rxjs';
// // import 'libs/vue-app/cs.min';
// // import 'libs/vue-app/cs-components.min';
// import 'libs/vue-app/cs-component.umd.min';
// declare var jQuery: any;
// declare const $: any;

// @Component({
//   selector: 'app-saps',
//   templateUrl: './saps.component.html',
//   styleUrls: ['./saps.component.css']
// })
// export class SapsComponent extends BaseComponent implements OnInit, OnDestroy {
//   private colors: string[] = ['#d9d9d9', '#7030a0', '#0070c0', '#00b050', '#0E6251',
//                                 '#D7BDE2', '#F2D7D5', '#F9E79F', '#D6EAF8', '#F5CBA7',
//                                 '#E57373', '#4E342E', '#F57C00', '#DAF7A6', '#95A5A6',
//                                 '#BFC9CA', '#117864', '#F9E79F', '#F9E79F', '#73C6B6'];
//   private DEFAULT_COLOR = '#4890e2';

//   @ViewChild('appendKendoModalDialog', { read: ViewContainerRef }) public appendKendoModalDialog: any;

//   private _data_sheet: DataSheet;

//   private set data_sheet(data_sheet){
//     this._data_sheet = data_sheet;
//   }

//   private get data_sheet() {
//     return this._data_sheet;
//   }

//   patient: User;
//   patient_details: PatientDetails;
//   procedures: Procedure[];
//   procedureViewService: ProcedureViewService;
//   currentFormService: CurrentFormService;
//   statuses: SelectOption[];
//   graph_multiple_choices: SelectOption[];
//   teaching_formats: SelectOption[];
//   graph_choice: string;
//   graph_data: GraphData;
//   program_meta: any;
//   all_data_sheets: DataSheet[];
//   defaultStatusItem: {
//     text: string,
//     value: number
//   } = {
//     text: 'Filter by status...',
//     value: null
//   };

//   raw_data_popups = {
//     dt: DiscreteTrialComponent,
//     task_analysis: TaskAnalysisComponent,
//     incidental: IncidentalTeachingComponent,
//     mei: MultipleExampleInstructionComponent,
//     dur: DurationComponent
//   };
//   it_types = ['duration', 'frequency'];

//   html_to_display: SafeHtml;
//   series: any;
//   x_label: string;
//   y_label: string;
//   loading: boolean;
//   override: boolean;

//   margin: Margin;
//   width_multiplier = 130; // the amount to multiply the margin by to move the graph
//   width: number;
//   defaultWidth;

//   refreshToken: number = 0;

//   private popupRef: PopupRef;
//   constructor(
//       dialogService: DialogService,
//       constantsService: ConstantsService,
//       procedureViewService: ProcedureViewService,
//       userViewService: UserViewService,
//       private procedureService: ProcedureService,
//       private patientService: PatientService,
//       private popupService: PopupService,
//       currentFormService: CurrentFormService,
//       private showNavService: ShowNavService,
//       toastyService: ToastyService,
//       private sanitizer: DomSanitizer,
//       private confirmDeleteService: ConfirmDeleteService
//   ) {
//     super(constantsService, userViewService, dialogService, procedureViewService, currentFormService, toastyService);
//     this.procedureViewService = procedureViewService;
//     this.currentFormService = currentFormService;
//   }

//   subscribeToPatient(): void {
//     this.subscriptions.push(this.patientService.currentPatient.subscribe(
//       (patient: User) => {
//         this.patient = patient;
//         // if (this.patient && this.patient.id != null) {
//         //   this.getProcedures();
//         // }
//       }));
//   }

//   subscribeToPatientDetails(): void {
//     this.subscriptions.push(this.patientService.currentPatientDetails.subscribe(
//       (patient_details: PatientDetails) => {
//         this.patient_details = patient_details;
//       }));
//   }

//   updateChart($event): void {
//     if ($event !== null && this.graph_data && Object.keys(this.graph_data).length > 0) {
//       this.processData($event);
//     }
//   }

//   processData(key?: string): void {
//     this.width = window.innerWidth * 0.8;

//     this.x_label = this.graph_data.x_label;
//     this.y_label = this.graph_data.y_label;
//     if (this.graph_data && this.graph_data.data && this.graph_data.data.length > 0) {
//       const data = this.graph_data.data.filter(d => d.value != null);
//       data.forEach((obj) => {
//         obj.date = 'Phase ' + obj.phase + ' ' + moment(obj.converted_date).format('DD/MM/YYYY');
//         obj.value = parseFloat(obj.value.toFixed(2));
//       });
//       // first we check the data if we have an MEI graph to plot - if the name is different for any of them, we have MEI
//       let have_mei = false;
//       let have_duration = false;
//       let name = '';
//       if (this.data_sheet != null) {
//         have_mei = this.data_sheet.code.toLowerCase() === 'mei';
//         have_duration = this.data_sheet.code.toLowerCase() === 'dur';
//       } else {
//         for (const point of data) {
//           if (!name) {
//             // first name in list
//             name = point.name.toLowerCase();
//           } else if (name !== point.name.toLowerCase()) {
//             have_mei = true;
//             break;
//           }
//         }
//       }
//       // const series = groupBy(this.graph_data.data, [{ field: 'name' }, { field: 'phase' }]);
//       // this.series = series[0]; // right now let's take the first set
//       // this.series = groupBy(data, [{ field: 'phase', aggregates: [{field: 'date', aggregate: 'max'},
//       // {field: 'value', aggregate: 'max'}] }]);
//       if (!have_mei) {
//         this.series = groupBy(data, [
//           {field: 'phase', aggregates: [{field: 'label', aggregate: 'max'}]}
//           ]);

//         for (let i = 0; i < this.series.length; i++) {
//           this.series[i]['color'] = this.DEFAULT_COLOR;
//         }
//       } else {
//         this.series = groupBy(data, [
//           {field: 'phase', aggregates: [{field: 'label', aggregate: 'max'}]},
//           {field: 'name', aggregates: [{field: 'label', aggregate: 'max'}]}
//         ]);
//         // for (let i = 0; i < this.series.length; i++) {
//         //   this.series[i]['color'] = this.DEFAULT_COLOR;
//         // }
//         console.log(this.series);
//         if (this.series && this.series[0] && this.series[0]['items']) {
//           this.series = this.series[0]['items'];
//           for (let i = 0; i < this.series.length; i++) {
//             if (i < this.colors.length) {
//               this.series[i]['color'] = this.colors[i];
//             } else {
//               this.series[i]['color'] = this.DEFAULT_COLOR;
//             }
//             if (this.series[i].items && this.series[i].items.hasOwnProperty('length') && this.series[i].items.length > 0) {
//               this.series[i].date = this.series[i].items[0].date;
//             }
//           }
//         } else {
//           this.series = [];
//         }
//         console.log(this.series);
//       }
//     } else if (this.graph_data.multiple && this.graph_data.data_multiple && Object.keys(this.graph_data.data_multiple).length > 0) {
//       this.graph_multiple_choices = Object.keys(this.graph_data.data_multiple).map(val => {
//         return new SelectOption(val, val);
//       });

//       this.graph_choice = key == null ? this.graph_multiple_choices[0].value : key;
//       const data = this.graph_data.data_multiple[this.graph_choice].filter(d => d.value != null);
//       data.forEach((obj) => {
//         obj.date = 'Phase ' + obj.phase + ' ' + moment(obj.converted_date).format('DD/MM/YYYY');
//         obj.value = parseFloat(obj.value.toFixed(2));
//       });
//       this.series = groupBy(data, [{field: 'phase', aggregates: [{field: 'label', aggregate: 'max'}]}]);
//       for (let i = 0; i < this.series.length; i++) {
//         this.series[i]['color'] = this.DEFAULT_COLOR;
//       }
//     } else {
//       this.series = [];
//     }

//   }

//   showGraph(idx: number, template: TemplateRef<any>): void {
//     this.data_sheet = null;

//     if (this.procedures && this.procedures[idx] != null) {
//       this.procedureViewService.currentSubject.next(this.procedures[idx]);
//       this.data_sheet = this.all_data_sheets != null && this.all_data_sheets.length > 0 ?
//         this.all_data_sheets.find(ds => ds.id == this.procedures[idx].data_sheet) :
//         null;

//       this.procedureViewService.getGraphData()
//         .then(
//           (graph_data: GraphData) => {
//             this.graph_data = graph_data;
//             this.processData();

//             const dialog = this.dialogService.open({
//               title: this.procedures[idx].name,
//               content: template,
//               minWidth: 1000,
//               actions: [],
//               appendTo: this.appendKendoModalDialog
//             });
//             dialog.result.subscribe((result) => {
//               if (result instanceof DialogCloseResult) {
//                 console.log('close');
//                 // clear the select list since we may/may not need it
//                 this.graph_multiple_choices = [];
//                 this.graph_choice = undefined;
//               } else {
//                 console.log('action', result);
//               }
//               document.body.style.overflow = '';
//             });
//         })
//     }
//     document.body.style.overflow = 'hidden';

//   }

//   isDT(procedure): boolean {
//     const data_sheet = this.all_data_sheets != null && this.all_data_sheets.length > 0 ?
//       this.all_data_sheets.find(ds => ds.id === procedure.data_sheet) :
//       null;

//     return data_sheet && data_sheet.code === 'DT'
//   }

//   processDTData(key?: string): void {
//     this.x_label = this.graph_data.x_label;
//     this.y_label = this.graph_data.y_label;

//     if (this.graph_data && this.graph_data.data && this.graph_data.data.length > 0) {
//       const data = this.graph_data.data.filter(d => d.value != null);

//       const categories = [];
//       data.forEach(obj => {
//         if (categories.indexOf(obj.date) < 0) {
//           categories.push(obj.date);
//         }
//       });

//       let series: any[] = groupBy(data, [
//         {field: 'item_names'},
//         {field: 'date'}
//       ]);

//       series = series.map((obj, index) => {
//         return {
//           name: obj.value,
//           items: obj.items.map(dateItem => {
//             return {
//               date: dateItem.value,
//               value: parseFloat(aggregateBy(dateItem.items, [{field: 'value', aggregate: 'average'}]).value.average.toFixed(2)),
//               items: dateItem.items.map(valueItem => {
//                 valueItem.value = parseFloat(valueItem.value.toFixed(2));
//                 return valueItem;
//               })
//             };
//           }),
//           color: this.colors[index % this.colors.length]
//         }
//       });

//       series.forEach((obj, index) => {
//         if (index === series.length - 1) {
//           return;
//         }

//         obj.items.forEach((item) => {
//           if (item.overlappingChecked) {
//             return;
//           }
//           item.overlappingChecked = true;

//           const items = item.items;

//           for (let i = index + 1; i < series.length; i++) {

//             const sameItem = series[i].items.find(item1 => item1.date === item.date && item1.value === item.value);
//             if (sameItem) {
//               sameItem.overlappingChecked = true;

//               sameItem.items.forEach(dt => {
//                 items.push(dt);
//               });

//               sameItem.items = items;
//             }
//           }
//         });
//       });

//       series.unshift({
//         color: 'transparent',
//         items: categories.map(category => {
//           return {
//             date: category,
//             value: null
//           }
//         })
//       });

//       this.series = series;
//     } else {
//       this.series = [];
//     }
//   }

//   showDTGraph(idx: number, template: TemplateRef<any>): void {
//     this.data_sheet = null;

//     if (this.procedures && this.procedures[idx] != null) {
//       this.procedureViewService.currentSubject.next(this.procedures[idx]);
//       this.data_sheet = this.all_data_sheets != null && this.all_data_sheets.length > 0 ?
//         this.all_data_sheets.find(ds => ds.id === this.procedures[idx].data_sheet) :
//         null;

//       this.procedureViewService.getGraphData()
//         .then(
//           (graph_data: GraphData) => {
//             this.graph_data = graph_data;
//             this.processDTData();

//             const dialog = this.dialogService.open({
//               title: this.procedures[idx].name,
//               content: template,
//               minWidth: 1000,
//               actions: [],
//               appendTo: this.appendKendoModalDialog
//             });
//             dialog.result.subscribe((result) => {
//               if (result instanceof DialogCloseResult) {
//                 console.log('close');
//                 // clear the select list since we may/may not need it
//                 this.graph_multiple_choices = [];
//                 this.graph_choice = undefined;
//               } else {
//                 console.log('action', result);
//               }
//               document.body.style.overflow = '';
//             });
//           })
//     }
//     document.body.style.overflow = 'hidden';

//   }

//   showItemsList(idx: number, template: TemplateRef<any>): void {
//     if (this.procedures && this.procedures[idx] != null) {
//       this.procedureViewService.currentSubject.next(this.procedures[idx]);
//       this.procedureViewService.getProcListHtml()
//         .then(
//           (html: string) => {
//             this.html_to_display = this.sanitizer.bypassSecurityTrustHtml(html);
//             const dialog = this.dialogService.open({
//               title: this.procedures[idx].name,
//               content: template,
//               minWidth: 400,
//               actions: [],
//               appendTo: this.appendKendoModalDialog
//             });
//             dialog.result.subscribe((result) => {
//               if (result instanceof DialogCloseResult) {
//                 console.log('close');
//                 this.html_to_display = null;
//               } else {
//                 console.log('action', result);
//               }
//               document.body.style.overflow = '';
//             });
//         })
//     }
//     document.body.style.overflow = 'hidden';

//   }

//   openConfirmDelete(obj: Procedure) {
//     const content = `Are you sure you want to delete procedure ${obj.name}?`
//     this.confirmDeleteService.openConfirmDialog(content)
//       .subscribe(res => {
//         if (res) {
//           this.remove(obj);
//         }
//       })
//   }

//   remove(obj: Procedure): void {
//     this.loading = true;
//     // need to find out if we have to archive it or just blatently remove
//     this.procedureViewService.removeProcedure(obj)
//       .then((res) => {
//         this.loading = false;
//         this.addToast('Procedure was removed from client.', 'Success');

//         this.refreshProcedures()
//       })
//       .catch((err) => {
//         if (typeof err === 'object' && err.status === HttpErrorCodes.UNAUTHORIZED) {
//           this.addToast('You do not have permission to remove this procedure...', 'Error');
//         } else {
//           this.addToast('Could not remove procedure from client, please try again...', 'Error');
//         }
//         this.loading = false;
//         throw err;
//       });
//   }

//   public onRender(args: any): void {
//     const chart = args.sender;
//     const valueAxis = chart.findAxisByName('value');
//     const categoryAxis = chart.findAxisByName('category');
//     const valueRange = valueAxis.range();
//     const valueSlot = valueAxis.slot(valueRange.min, valueRange.max);

//     const series = [];
//     if (this.series.length > 0) {
//       for (let i = 0; i < this.series.length; i++) {
//         let idx = -1;
//         // now we have to check each of the items (if we are in the case where we have multiple points)
//         if (this.series[i].date != null){
//           idx = series.findIndex(s => this.series[i].date == s.date);
//         } else {
//           idx = series.findIndex(s => this.series[i].aggregates.label.max == s.aggregates.label.max);
//         }
//         if (idx == -1) {
//           series.push(this.series[i]);
//         }
//       }
//     }
//     if (series.length > 1) {
//       const width = series.length * this.width_multiplier;
//       const defaultWidth = window.innerWidth * 0.8;
//       this.width = width < defaultWidth ? defaultWidth : width;
//       let previous_x_value_limit = 0;
//       for (let idx = 0; idx < series.length; idx++) {
//         const max = series[idx].aggregates.label.max;
//         const categorySlot = categoryAxis.slot(max).bottomRight();
//         const phase_title = series[idx].items[0].phase_title;
//         let offset = (phase_title.length / 2) * 3.8;
//         let layout = null;

//         let x_value = 0;
//         // if (previous_x_value_limit != null) {
//         if (categorySlot.x - previous_x_value_limit < phase_title.length*5) {

//           layout = new Layout(new Rect([previous_x_value_limit, 0], [categorySlot.x - previous_x_value_limit, 0]), {
//             spacing: 3
//           });
//           const parts = phase_title.split(' ');
//           // let count = 1;
//           for (let i = 0; i < parts.length; i++) {
//             layout.append(new Text(parts[i], []));
//             // count++;
//           }

//           layout.reflow();
//         } else {
//           offset = (phase_title.length / 2) * 5;

//           x_value = previous_x_value_limit + (categorySlot.x - previous_x_value_limit) / 2 - offset;
//         }
//         // } else {
//         //   const new_offset = series.length > 3 ? -offset/2 : offset;
//         //   x_value = (categorySlot.x / series.length) * (idx + 1) - new_offset;
//         // }
//         previous_x_value_limit = categorySlot.x;
//         // if (idx == series.length - 1) {
//         //   x_value = categorySlot.x - (categorySlot.x / (series.length * 2)) - offset;
//         // }
//         const title = new Text(phase_title, new Point(x_value, 0));
//           // .moveTo(categorySlot.x / 2, valueSlot.origin.x / 2);
//         const path = new Path({ stroke: { width: 3, color: '#000', dashType: 'dot' } })
//           .moveTo(categorySlot.x, valueSlot.origin.x + 50)
//           .lineTo(categorySlot.x, valueSlot.bottomRight().y);

//         chart.surface.draw(path);
//         if (layout) {
//           chart.surface.draw(layout);
//         } else {
//           chart.surface.draw(title);
//         }
//       }
//     } else if (series.length == 1) {
//       const max = this.series[0].aggregates.label.max;
//       const categorySlot = categoryAxis.slot(max).bottomRight();
//         const phase_title = this.series[0].items[0].phase_title;
//         // get padding to move the title to the left since it will be off centre:
//       const offset = (phase_title.length / 2) * 3.8;
//         const title = new Text(phase_title, new Point(categorySlot.x/2 - offset, 0));
//         // .moveTo(categorySlot.x / 2, valueSlot.origin.x / 2);

//         chart.surface.draw(title);
//     }

//   }

//   subscribeToChoices(): void {
//     this.subscriptions.push(this.choicesSubject.subscribe(
//       (choices: Map<string, any>) => {
//         if (choices.size > 0 && choices.has('procedure')) {
//           this.statuses = choices.get('procedure').get('status');
//           this.teaching_formats = choices.get('procedure').get('teaching_format');
//           this.program_meta = choices.get('patient').get('program_meta');
//         }
//       }));
//   }

//   subscribeToFiltered(): void {
//     this.subscriptions.push(this.procedureViewService.currentSubjectsFiltered.subscribe(
//       (filtered: Procedure[]) => {
//         this.procedures = filtered;
//       }));
//   }

//   subscribeToProcedures(): void {
//     // we should have a patient at this point, if we don't then something is very wrong
//     this.subscriptions.push(this.procedureViewService.currentSubjects.subscribe(
//       procedures => {
//         if (this.filter_status) {
//           this.filter(true)
//             .then(res => {
//               this.procedures = res;
//             })
//         } else {
//           this.procedures = procedures;
//         }
//       }));
//   }

//   // getProcedures(): void {
//   //   this.procedureViewService.getProcedures(this.patient.id)
//   //     .then((procedures: Procedure[]) => {
//   //       this.procedures = procedures;
//   //       this.loading = false;
//   //     })
//   //     .catch((err) => {
//   //       // TODO: need to show toasty with error
//   //       this.loading = false;
//   //       throw err;
//   //   });
//   // }

//   getProcedure(id: number): Promise<Procedure> {
//     return this.procedureViewService.getProceduresByIds([id])
//     .then((procedures: Procedure[]) => {
//       return procedures && procedures.length ? procedures[0] : null;
//     })
//     .catch((err) => {
//       // TODO: need to show toasty with error or bubble error up
//       return throwError(err).toPromise();
//     });
//   }

//   printProcedureById(id: number, actionTemplate: TemplateRef<any>): void {
//     this.getProcedure(id).then((procedure: Procedure) => {
//       this.printProcedure(procedure, actionTemplate)
//     })
//   }

//   editProcedureById(
//     id: number,
//     event?: any,
//     actionTemplate?: TemplateRef<any>,
//   ): void {
//     this.getProcedure(id).then((procedure: Procedure) => {
//       this.editProcedure(procedure, event, actionTemplate)
//     })
//   }

//   deleteProcedureById(
//     id: number,
//   ): void {
//     this.getProcedure(id).then((procedure: Procedure) => {
//       this.openConfirmDelete(procedure)
//     })
//   }

//   refreshProcedures(): void {
//     this.refreshToken = (new Date()).getTime()
//     console.log('refresh procedure list', this.refreshToken)
//   }

//   printProcedure(obj: Procedure, actionTemplate: TemplateRef<any>): void {
//     // this.procedureViewService.currentSubject.next(this.procedures[i]);
//     // this.printingService.print(elem);

//     this.procedureViewService.currentSubject.next(obj);
//     // need to add assets to the front of logo
//     const img_src = 'assets/' + this.program_meta[this.patient_details.program]['logo'];
//     const dialog = this.dialogService.open({
//       title: 'Print Preview',
//       content: AddSapComponent,
//       minWidth: 1000,
//       actions: actionTemplate,
//       appendTo: this.appendKendoModalDialog
//     });
//     dialog.content.instance.can_edit = false;
//     dialog.content.instance.is_print_view = true;
//     dialog.content.instance.img_src = img_src;
//     dialog.result.subscribe((result) => {
//       if (result instanceof DialogCloseResult) {
//         console.log('close');
//       } else {
//         console.log('action', result);
//       }
//       document.body.style.overflow = '';
//     });

//     document.body.style.overflow = 'hidden';

//   }

//   getRawData(idx: number, actionTemplate: TemplateRef<any>): void {
//     if (idx == null) {
//       return;
//     }
//     this.procedureViewService.currentSubject.next(this.procedures[idx]);
//     let component;
//     if (this.procedures[idx].teaching_format && this.procedures[idx].teaching_format.indexOf('dt') > -1) {
//       component = this.raw_data_popups['dt'];
//     } else {
//       const data_sheet = this.all_data_sheets != null && this.all_data_sheets.length > 0 ?
//         this.all_data_sheets.find(ds => ds.id == this.procedures[idx].data_sheet) :
//         null;
//       if (this.procedures[idx].teaching_format.indexOf('incidental') > -1) {
//         // now need to check if the data_sheet is either duration or frequency
//         if (data_sheet != null && data_sheet.code != null && data_sheet.code.toLowerCase() in this.raw_data_popups) {
//           component = this.raw_data_popups[data_sheet.code.toLowerCase()];
//         } else {
//           component = this.raw_data_popups[this.procedures[idx].teaching_format];
//         }
//       } else {
//         component = this.raw_data_popups[this.procedures[idx].teaching_format];
//       }
//     }
//     this.procedureViewService.getRawData()
//       .subscribe((data: object) => {
//         console.log(data);
//         this.dialog = this.dialogService.open({
//           title: this.procedures[idx].name,
//           content: component,
//           width: 500,
//           minWidth: 300,
//           actions: actionTemplate,
//           appendTo: this.appendKendoModalDialog
//         });
//         this.dialog.content.instance.is_readonly = true;
//         this.dialog.content.instance.data = data;
//         this.dialog.result.subscribe((result) => {
//           if (result instanceof DialogCloseResult) {
//             console.log('close');
//           } else {
//             console.log('action', result);
//           }
//           document.body.style.overflow = '';
//         });
//       },
//         (err) => console.log(err)
//       );
//     document.body.style.overflow = 'hidden';
//   }

//   subscribeToDataSheets(): void {
//     this.subscriptions.push(this.procedureViewService.getDataSheets$()
//       .subscribe(
//         (data_sheets: DataSheet[]) => {
//           this.all_data_sheets = data_sheets;
//         }, (err) => {
//           console.log(err);
//           // TODO: inform user
//         })
//     );
//   }

//   /**
//    * editProcedure function to add/edit a procedure
//    *
//    * @param obj
//    * @param event
//    * @param {TemplateRef<any>} actionTemplate
//    */
//   editProcedure(
//       obj?: any,
//       event?: any,
//       actionTemplate?: TemplateRef<any>,
//   ): void {
//     this.current_modal_obj = obj;

//     this.procedureViewService.currentSubject.next(obj)

//     if (event) {
//       event.preventDefault();
//     }
//     const jq_height = jQuery(window).height();
//     const height = jq_height < window.innerHeight ? jq_height : window.innerHeight;
//     const options = {
//       content: AddSapComponent,
//       actions: actionTemplate,
//       height: height
//     };

//     this.dialog = this.dialogService.open(options)
//     this.dialog.result.subscribe((result) => {
//       if (event) {
//         event.preventDefault();
//       }
//       if (result instanceof DialogCloseResult) {
//         console.log('close');
//         this.procedureViewService.currentSubjectType.next(null);
//         // now force
//       } else {
//         // TODO check if below is obsolete since we use
//         // custom actions
//         console.log('redundant save')
//         console.log('action', result);
//         if (!result['primary']) {
//           this.procedureViewService.currentSubject.next(null);
//         } else {
//           console.log('used to save some stuff here, but it is commented out now')
//         }
//       }
//       // this.result = JSON.stringify(result);
//     });
//     document.body.style.overflow = 'hidden';
//   }

//   public saveProcedure(dialogTemplate?: TemplateRef<any>, dialogActionTemplate?: TemplateRef<any>) {
//     this.current_form = this.currentFormService.currentSubject.getValue();

//     console.log('saving from the custom function')
//     this.current_form.ngSubmit.emit();
//     this.current_form.submitted = true;
//     if (this.current_form.valid) {
//       this.saveProcedures(dialogTemplate, dialogActionTemplate);
//     }
//     // need to clear the overflow
//     document.body.style.overflow = '';
//   }

//   public incrementPhaseNumberSave(dialogTemplate?: TemplateRef<any>, dialogActionTemplate?: TemplateRef<any>) {
//     if (this.procedureViewService.incrementPhaseNumberDialog) {
//       const incrObs = this.procedureViewService.incrementPhaseNumberDialog(dialogTemplate, dialogActionTemplate);
//       if (incrObs) {
//         incrObs.subscribe(res => {
//           console.log(res);
//           if (res) {
//             const message = `Successully saved ${res.name ? res.name : res.constructor.name}`;

//             this.addToast(message, 'Success');
//           }
//         });
//       }
//     }
//   }

//   private saveProcedures(dialogTemplate?: TemplateRef<any>, dialogActionTemplate?: TemplateRef<any>): void{
//     this.dialog.content._component.loading = true;
//     // TODO: add save
//     // this.loading = true;
//     this.procedureViewService.save()
//         .then((res) => {
//           // this.dialog.close();
//           this.addToast('Save completed successfully', 'success');
//           this.refreshProcedures()

//           // this.procedureViewService.getSubjects();
//           this.dialog.content._component.loading = false;
//           // force the subscriber to refresh the list
//           this.close();

//           this.incrementPhaseNumberSave(dialogTemplate, dialogActionTemplate);

//           this.refreshSubjects.next(true);
//         })
//         .catch((err) => {
//           // TODO:: need to show error
//           console.log(err);
//           this.dialog.content._component.loading = false;
//           this.currentFormService.currentErrorMessage.next(err);

//           if (err && typeof err === 'object' && (!err.status ||
//               err.status === HttpErrorCodes.SERVER_ERROR || err.status == HttpErrorCodes.BAD_REQUEST)) {

//             if (err.non_field_errors) {
//               // take the first error message
//               const error_message = err.non_field_errors[0];
//               this.currentFormService.currentNonFieldErrorMessage.next(error_message);
//             } else if (err.error) {
//               const errors = err.error;
//               if (typeof errors == 'string' || errors.detail) {
//                 // striping out inner html from error message
//                 const div = document.createElement('div');
//                 div.innerHTML = errors.detail || errors;
//                 const text = div.textContent || div.innerText || '';
//                 this.addToast(text, 'error');
//               } else {
//                 if (err.error.error) {
//                   this.addToast(err.error.error, 'error');
//                 } else {
//                   const keys = Object.keys(errors);
//                   if (keys.length > 0) {
//                     const key = keys[0];
//                     // err object comes back in the form of { <field>: [<message>]}
//                     const message = key + ': ' + errors[key][0];
//                     this.addToast(message, 'error');
//                   }
//                 }
//               }

//             } else {
//               // take the first error key
//               const keys = Object.keys(err);
//               if (keys.length > 0) {
//                 const key = keys[0];
//                 // err object comes back in the form of { <field>: [<message>]}
//                 const message = key + ': ' + err[key][0];
//                 this.addToast(message, 'error');
//               }
//             }
//           } else {
//             this.addToast('Could not add the selected user, please try again later', 'error');
//           }

//           throw err;
//         });
//   }

//   public updatePhaseChangeDescription(event: any) {
//     console.log('event', event.target.value)
//     this.procedureViewService.phaseChangeDescriptionSubject.next(event.target.value)
//   }

//   public getPhaseChangeDescription() {
//     return this.procedureViewService.getPhaseChangeDescription();
//   }

//   public closePhaseChangeDialog() {
//     this.procedureViewService.closePhaseChangeDialog();
//   }

//   public savePhaseChange() {
//     return this.procedureViewService.savePhaseChange().toPromise().then(() => {
//       this.closePhaseChangeDialog()
//     })
//   }

//   ngOnInit() {
//     // default width
//     this.margin = {top: 100, bottom: 0, left: 0, right: 0};
//     // this.loading = true;
//     this.subscriptions = [];
//     this.graph_multiple_choices = [];
//     this.publish_statuses = [];
//     this.graph_data = new GraphData();
//     this.all_data_sheets = [];
//     this.getChoices();
//     // this.subscriptions.push(this.getAllClients().subscribe((users) => users));
//     this.subscribeToPatient();
//     this.subscribeToPatientDetails();
//     this.subscribeToChoices();
//     this.subscribeToFiltered();
//     this.subscribeToProcedures();
//     this.subscribeToDataSheets();
//     this.showNavService.showButtons.next(false);
//   }

//   ngOnDestroy(): void {
//     this.unsubscribe();
//     this.showNavService.showButtons.next(true);
//   }

// }


import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {DialogCloseResult, DialogService} from '@progress/kendo-angular-dialog';
import {ConstantsService} from '../../core/services/constants.service';
import {ProcedureService} from '../../core/services/procedure.service';
import {User} from '../../core/models/user.model';
import {PatientService} from '../../core/services/patient.service';
import {Procedure} from '../../core/models/procedure.model';
import {ProcedureViewService} from '../../shared/services/procedure-view.service';
import {UserViewService} from '../../shared/services/user-view.service';
import {GraphData} from '../../core/models/graph-data.model';
import { groupBy, aggregateBy } from '@progress/kendo-data-query';
import {PopupRef, PopupService} from '@progress/kendo-angular-popup';
import {CurrentFormService} from '../../core/services/current-form.service';
import {SelectOption} from '../../core/models/select-option.model';
import {Layout, Path, Text} from '@progress/kendo-drawing';
import {ShowNavService} from '../../core/services/show-nav.service';
import {ToastyService} from 'ng2-toasty';
import {HttpErrorCodes} from '../../core/shared/enum';
import * as moment from 'moment';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {AddSapComponent} from '../../shared/add-sap/add-sap.component';
import {PatientDetails} from '../../core/models/patient-details.model';
import {MultipleExampleInstructionComponent} from '../sessions/multiple-example-instruction/multiple-example-instruction.component';
import {DiscreteTrialComponent} from '../sessions/discrete-trial/discrete-trial.component';
import {IncidentalTeachingComponent} from '../sessions/incidental-teaching/incidental-teaching.component';
import {TaskAnalysisComponent} from '../sessions/task-analysis/task-analysis.component';
import {Point, Rect} from '@progress/kendo-drawing/geometry';
import {DurationComponent} from '../sessions/duration/duration.component';
import {DataSheet} from '../../core/models/data-sheet.model';
import {Margin} from '@progress/kendo-angular-charts';
import { ConfirmDeleteService } from 'app/core/services/confirm-delete.service';
import { throwError } from 'rxjs';
// import 'libs/vue-app/cs.min';
// import 'libs/vue-app/cs-components.min';
// import 'libs/vue-app/cs-component.umd.min';
declare var jQuery: any;
declare const $: any;

@Component({
  selector: 'app-saps',
  templateUrl: './saps.component.html',
  styleUrls: ['./saps.component.css']
})
export class SapsComponent extends BaseComponent implements OnInit, OnDestroy {
  private colors: string[] = ['#d9d9d9', '#7030a0', '#0070c0', '#00b050', '#0E6251',
                                '#D7BDE2', '#F2D7D5', '#F9E79F', '#D6EAF8', '#F5CBA7',
                                '#E57373', '#4E342E', '#F57C00', '#DAF7A6', '#95A5A6',
                                '#BFC9CA', '#117864', '#F9E79F', '#F9E79F', '#73C6B6'];
  private DEFAULT_COLOR = '#4890e2';

  @ViewChild('appendKendoModalDialog', { read: ViewContainerRef }) public appendKendoModalDialog: any;

  private _data_sheet: DataSheet;

  private set data_sheet(data_sheet){
    this._data_sheet = data_sheet;
  }

  private get data_sheet() {
    return this._data_sheet;
  }

  patient: User;
  patient_details: PatientDetails;
  procedures: Procedure[];
  procedureViewService: ProcedureViewService;
  currentFormService: CurrentFormService;
  statuses: SelectOption[];
  graph_multiple_choices: SelectOption[];
  teaching_formats: SelectOption[];
  graph_choice: string;
  graph_data: GraphData;
  program_meta: any;
  all_data_sheets: DataSheet[];
  defaultStatusItem: {
    text: string,
    value: number
  } = {
    text: 'Filter by status...',
    value: null
  };

  raw_data_popups = {
    dt: DiscreteTrialComponent,
    task_analysis: TaskAnalysisComponent,
    incidental: IncidentalTeachingComponent,
    mei: MultipleExampleInstructionComponent,
    dur: DurationComponent
  };
  it_types = ['duration', 'frequency'];

  html_to_display: SafeHtml;
  series: any;
  x_label: string;
  y_label: string;
  loading: boolean;
  override: boolean;

  margin: Margin;
  width_multiplier = 130; // the amount to multiply the margin by to move the graph
  width: number;
  defaultWidth;

  refreshToken: number = 0;

  private popupRef: PopupRef;
  constructor(
      dialogService: DialogService,
      constantsService: ConstantsService,
      procedureViewService: ProcedureViewService,
      userViewService: UserViewService,
      private procedureService: ProcedureService,
      private patientService: PatientService,
      private popupService: PopupService,
      currentFormService: CurrentFormService,
      private showNavService: ShowNavService,
      toastyService: ToastyService,
      private sanitizer: DomSanitizer,
      private confirmDeleteService: ConfirmDeleteService
  ) {
    super(constantsService, userViewService, dialogService, procedureViewService, currentFormService, toastyService);
    this.procedureViewService = procedureViewService;
    this.currentFormService = currentFormService;
  }

  subscribeToPatient(): void {
    this.subscriptions.push(this.patientService.currentPatient.subscribe(
      (patient: User) => {
        this.patient = patient;
        // if (this.patient && this.patient.id != null) {
        //   this.getProcedures();
        // }
      }));
  }

  subscribeToPatientDetails(): void {
    this.subscriptions.push(this.patientService.currentPatientDetails.subscribe(
      (patient_details: PatientDetails) => {
        this.patient_details = patient_details;
      }));
  }

  updateChart($event): void {
    if ($event !== null && this.graph_data && Object.keys(this.graph_data).length > 0) {
      this.processData($event);
    }
  }

  processData(key?: string): void {
    this.width = window.innerWidth * 0.8;

    this.x_label = this.graph_data.x_label;
    this.y_label = this.graph_data.y_label;
    if (this.graph_data && this.graph_data.data && this.graph_data.data.length > 0) {
      const data = this.graph_data.data.filter(d => d.value != null);
      data.forEach((obj) => {
        obj.date = 'Phase ' + obj.phase + ' ' + moment(obj.converted_date).format('DD/MM/YYYY');
        obj.value = parseFloat(obj.value.toFixed(2));
      });
      // first we check the data if we have an MEI graph to plot - if the name is different for any of them, we have MEI
      let have_mei = false;
      let have_duration = false;
      let name = '';
      if (this.data_sheet != null) {
        have_mei = this.data_sheet.code.toLowerCase() === 'mei';
        have_duration = this.data_sheet.code.toLowerCase() === 'dur';
      } else {
        for (const point of data) {
          if (!name) {
            // first name in list
            name = point.name.toLowerCase();
          } else if (name !== point.name.toLowerCase()) {
            have_mei = true;
            break;
          }
        }
      }
      // const series = groupBy(this.graph_data.data, [{ field: 'name' }, { field: 'phase' }]);
      // this.series = series[0]; // right now let's take the first set
      // this.series = groupBy(data, [{ field: 'phase', aggregates: [{field: 'date', aggregate: 'max'},
      // {field: 'value', aggregate: 'max'}] }]);
      if (!have_mei) {
        this.series = groupBy(data, [
          {field: 'phase', aggregates: [{field: 'label', aggregate: 'max'}]}
          ]);

        for (let i = 0; i < this.series.length; i++) {
          this.series[i]['color'] = this.DEFAULT_COLOR;
        }
      } else {
        this.series = groupBy(data, [
          {field: 'phase', aggregates: [{field: 'label', aggregate: 'max'}]},
          {field: 'name', aggregates: [{field: 'label', aggregate: 'max'}]}
        ]);
        // for (let i = 0; i < this.series.length; i++) {
        //   this.series[i]['color'] = this.DEFAULT_COLOR;
        // }
        console.log(this.series);
        if (this.series && this.series[0] && this.series[0]['items']) {
          this.series = this.series[0]['items'];
          for (let i = 0; i < this.series.length; i++) {
            if (i < this.colors.length) {
              this.series[i]['color'] = this.colors[i];
            } else {
              this.series[i]['color'] = this.DEFAULT_COLOR;
            }
            if (this.series[i].items && this.series[i].items.hasOwnProperty('length') && this.series[i].items.length > 0) {
              this.series[i].date = this.series[i].items[0].date;
            }
          }
        } else {
          this.series = [];
        }
        console.log(this.series);
      }
    } else if (this.graph_data.multiple && this.graph_data.data_multiple && Object.keys(this.graph_data.data_multiple).length > 0) {
      this.graph_multiple_choices = Object.keys(this.graph_data.data_multiple).map(val => {
        return new SelectOption(val, val);
      });

      this.graph_choice = key == null ? this.graph_multiple_choices[0].value : key;
      const data = this.graph_data.data_multiple[this.graph_choice].filter(d => d.value != null);
      data.forEach((obj) => {
        obj.date = 'Phase ' + obj.phase + ' ' + moment(obj.converted_date).format('DD/MM/YYYY');
        obj.value = parseFloat(obj.value.toFixed(2));
      });
      this.series = groupBy(data, [{field: 'phase', aggregates: [{field: 'label', aggregate: 'max'}]}]);
      for (let i = 0; i < this.series.length; i++) {
        this.series[i]['color'] = this.DEFAULT_COLOR;
      }
    } else {
      this.series = [];
    }

  }

  showGraph(idx: number, template: TemplateRef<any>): void {
    this.data_sheet = null;

    if (this.procedures && this.procedures[idx] != null) {
      this.procedureViewService.currentSubject.next(this.procedures[idx]);
      this.data_sheet = this.all_data_sheets != null && this.all_data_sheets.length > 0 ?
        this.all_data_sheets.find(ds => ds.id == this.procedures[idx].data_sheet) :
        null;

      this.procedureViewService.getGraphData()
        .then(
          (graph_data: GraphData) => {
            this.graph_data = graph_data;
            this.processData();

            const dialog = this.dialogService.open({
              title: this.procedures[idx].name,
              content: template,
              minWidth: 1000,
              actions: [],
              appendTo: this.appendKendoModalDialog
            });
            dialog.result.subscribe((result) => {
              if (result instanceof DialogCloseResult) {
                console.log('close');
                // clear the select list since we may/may not need it
                this.graph_multiple_choices = [];
                this.graph_choice = undefined;
              } else {
                console.log('action', result);
              }
              document.body.style.overflow = '';
            });
        })
    }
    document.body.style.overflow = 'hidden';

  }

  isDT(procedure): boolean {
    const data_sheet = this.all_data_sheets != null && this.all_data_sheets.length > 0 ?
      this.all_data_sheets.find(ds => ds.id === procedure.data_sheet) :
      null;

    return data_sheet && data_sheet.code === 'DT'
  }

  processDTData(key?: string): void {
    this.x_label = this.graph_data.x_label;
    this.y_label = this.graph_data.y_label;

    if (this.graph_data && this.graph_data.data && this.graph_data.data.length > 0) {
      const data = this.graph_data.data.filter(d => d.value != null);

      const categories = [];
      data.forEach(obj => {
        if (categories.indexOf(obj.date) < 0) {
          categories.push(obj.date);
        }
      });

      let series: any[] = groupBy(data, [
        {field: 'item_names'},
        {field: 'date'}
      ]);

      series = series.map((obj, index) => {
        return {
          name: obj.value,
          items: obj.items.map(dateItem => {
            return {
              date: dateItem.value,
              value: parseFloat(aggregateBy(dateItem.items, [{field: 'value', aggregate: 'average'}]).value.average.toFixed(2)),
              items: dateItem.items.map(valueItem => {
                valueItem.value = parseFloat(valueItem.value.toFixed(2));
                return valueItem;
              })
            };
          }),
          color: this.colors[index % this.colors.length]
        }
      });

      series.forEach((obj, index) => {
        if (index === series.length - 1) {
          return;
        }

        obj.items.forEach((item) => {
          if (item.overlappingChecked) {
            return;
          }
          item.overlappingChecked = true;

          const items = item.items;

          for (let i = index + 1; i < series.length; i++) {

            const sameItem = series[i].items.find(item1 => item1.date === item.date && item1.value === item.value);
            if (sameItem) {
              sameItem.overlappingChecked = true;

              sameItem.items.forEach(dt => {
                items.push(dt);
              });

              sameItem.items = items;
            }
          }
        });
      });

      series.unshift({
        color: 'transparent',
        items: categories.map(category => {
          return {
            date: category,
            value: null
          }
        })
      });

      this.series = series;
    } else {
      this.series = [];
    }
  }

  showDTGraph(idx: number, template: TemplateRef<any>): void {
    this.data_sheet = null;

    if (this.procedures && this.procedures[idx] != null) {
      this.procedureViewService.currentSubject.next(this.procedures[idx]);
      this.data_sheet = this.all_data_sheets != null && this.all_data_sheets.length > 0 ?
        this.all_data_sheets.find(ds => ds.id === this.procedures[idx].data_sheet) :
        null;

      this.procedureViewService.getGraphData()
        .then(
          (graph_data: GraphData) => {
            this.graph_data = graph_data;
            this.processDTData();

            const dialog = this.dialogService.open({
              title: this.procedures[idx].name,
              content: template,
              minWidth: 1000,
              actions: [],
              appendTo: this.appendKendoModalDialog
            });
            dialog.result.subscribe((result) => {
              if (result instanceof DialogCloseResult) {
                console.log('close');
                // clear the select list since we may/may not need it
                this.graph_multiple_choices = [];
                this.graph_choice = undefined;
              } else {
                console.log('action', result);
              }
              document.body.style.overflow = '';
            });
          })
    }
    document.body.style.overflow = 'hidden';

  }

  showItemsList(idx: number, template: TemplateRef<any>): void {
    if (this.procedures && this.procedures[idx] != null) {
      this.procedureViewService.currentSubject.next(this.procedures[idx]);
      this.procedureViewService.getProcListHtml()
        .then(
          (html: string) => {
            this.html_to_display = this.sanitizer.bypassSecurityTrustHtml(html);
            const dialog = this.dialogService.open({
              title: this.procedures[idx].name,
              content: template,
              minWidth: 400,
              actions: [],
              appendTo: this.appendKendoModalDialog
            });
            dialog.result.subscribe((result) => {
              if (result instanceof DialogCloseResult) {
                console.log('close');
                this.html_to_display = null;
              } else {
                console.log('action', result);
              }
              document.body.style.overflow = '';
            });
        })
    }
    document.body.style.overflow = 'hidden';

  }

  openConfirmDelete(obj: Procedure) {
    const content = `Are you sure you want to delete procedure ${obj.name}?`
    this.confirmDeleteService.openConfirmDialog(content)
      .subscribe(res => {
        if (res) {
          this.remove(obj);
        }
      })
  }

  remove(obj: Procedure): void {
    this.loading = true;
    // need to find out if we have to archive it or just blatently remove
    this.procedureViewService.removeProcedure(obj)
      .then((res) => {
        this.loading = false;
        this.addToast('Procedure was removed from client.', 'Success');

        this.refreshProcedures()
      })
      .catch((err) => {
        if (typeof err === 'object' && err.status === HttpErrorCodes.UNAUTHORIZED) {
          this.addToast('You do not have permission to remove this procedure...', 'Error');
        } else {
          this.addToast('Could not remove procedure from client, please try again...', 'Error');
        }
        this.loading = false;
        throw err;
      });
  }

  public onRender(args: any): void {
    const chart = args.sender;
    const valueAxis = chart.findAxisByName('value');
    const categoryAxis = chart.findAxisByName('category');
    const valueRange = valueAxis.range();
    const valueSlot = valueAxis.slot(valueRange.min, valueRange.max);

    const series = [];
    if (this.series.length > 0) {
      for (let i = 0; i < this.series.length; i++) {
        let idx = -1;
        // now we have to check each of the items (if we are in the case where we have multiple points)
        if (this.series[i].date != null){
          idx = series.findIndex(s => this.series[i].date == s.date);
        } else {
          idx = series.findIndex(s => this.series[i].aggregates.label.max == s.aggregates.label.max);
        }
        if (idx == -1) {
          series.push(this.series[i]);
        }
      }
    }
    if (series.length > 1) {
      const width = series.length * this.width_multiplier;
      const defaultWidth = window.innerWidth * 0.8;
      this.width = width < defaultWidth ? defaultWidth : width;
      let previous_x_value_limit = 0;
      for (let idx = 0; idx < series.length; idx++) {
        const max = series[idx].aggregates.label.max;
        const categorySlot = categoryAxis.slot(max).bottomRight();
        const phase_title = series[idx].items[0].phase_title;
        let offset = (phase_title.length / 2) * 3.8;
        let layout = null;

        let x_value = 0;
        // if (previous_x_value_limit != null) {
        if (categorySlot.x - previous_x_value_limit < phase_title.length*5) {

          layout = new Layout(new Rect([previous_x_value_limit, 0], [categorySlot.x - previous_x_value_limit, 0]), {
            spacing: 3
          });
          const parts = phase_title.split(' ');
          // let count = 1;
          for (let i = 0; i < parts.length; i++) {
            layout.append(new Text(parts[i], []));
            // count++;
          }

          layout.reflow();
        } else {
          offset = (phase_title.length / 2) * 5;

          x_value = previous_x_value_limit + (categorySlot.x - previous_x_value_limit) / 2 - offset;
        }
        // } else {
        //   const new_offset = series.length > 3 ? -offset/2 : offset;
        //   x_value = (categorySlot.x / series.length) * (idx + 1) - new_offset;
        // }
        previous_x_value_limit = categorySlot.x;
        // if (idx == series.length - 1) {
        //   x_value = categorySlot.x - (categorySlot.x / (series.length * 2)) - offset;
        // }
        const title = new Text(phase_title, new Point(x_value, 0));
          // .moveTo(categorySlot.x / 2, valueSlot.origin.x / 2);
        const path = new Path({ stroke: { width: 3, color: '#000', dashType: 'dot' } })
          .moveTo(categorySlot.x, valueSlot.origin.x + 50)
          .lineTo(categorySlot.x, valueSlot.bottomRight().y);

        chart.surface.draw(path);
        if (layout) {
          chart.surface.draw(layout);
        } else {
          chart.surface.draw(title);
        }
      }
    } else if (series.length == 1) {
      const max = this.series[0].aggregates.label.max;
      const categorySlot = categoryAxis.slot(max).bottomRight();
        const phase_title = this.series[0].items[0].phase_title;
        // get padding to move the title to the left since it will be off centre:
      const offset = (phase_title.length / 2) * 3.8;
        const title = new Text(phase_title, new Point(categorySlot.x/2 - offset, 0));
        // .moveTo(categorySlot.x / 2, valueSlot.origin.x / 2);

        chart.surface.draw(title);
    }

  }

  subscribeToChoices(): void {
    this.subscriptions.push(this.choicesSubject.subscribe(
      (choices: Map<string, any>) => {
        if (choices.size > 0 && choices.has('procedure')) {
          this.statuses = choices.get('procedure').get('status');
          this.teaching_formats = choices.get('procedure').get('teaching_format');
          this.program_meta = choices.get('patient').get('program_meta');
        }
      }));
  }

  subscribeToFiltered(): void {
    this.subscriptions.push(this.procedureViewService.currentSubjectsFiltered.subscribe(
      (filtered: Procedure[]) => {
        this.procedures = filtered;
      }));
  }

  subscribeToProcedures(): void {
    // we should have a patient at this point, if we don't then something is very wrong
    this.subscriptions.push(this.procedureViewService.currentSubjects.subscribe(
      procedures => {
        if (this.filter_status) {
          this.filter(true)
            .then(res => {
              this.procedures = res;
            })
        } else {
          this.procedures = procedures;
        }
      }));
  }

  // getProcedures(): void {
  //   this.procedureViewService.getProcedures(this.patient.id)
  //     .then((procedures: Procedure[]) => {
  //       this.procedures = procedures;
  //       this.loading = false;
  //     })
  //     .catch((err) => {
  //       // TODO: need to show toasty with error
  //       this.loading = false;
  //       throw err;
  //   });
  // }

  getProcedure(id: number): Promise<Procedure> {
    return this.procedureViewService.getProceduresByIds([id])
    .then((procedures: Procedure[]) => {
      return procedures && procedures.length ? procedures[0] : null;
    })
    .catch((err) => {
      // TODO: need to show toasty with error or bubble error up
      return throwError(err).toPromise();
    });
  }

  printProcedureById(id: number, actionTemplate: TemplateRef<any>): void {
    this.getProcedure(id).then((procedure: Procedure) => {
      this.printProcedure(procedure, actionTemplate)
    })
  }

  editProcedureById(
    id: number,
    event?: any,
    actionTemplate?: TemplateRef<any>,
  ): void {
    this.getProcedure(id).then((procedure: Procedure) => {
      this.editProcedure(procedure, event, actionTemplate)
    })
  }

  deleteProcedureById(
    id: number,
  ): void {
    this.getProcedure(id).then((procedure: Procedure) => {
      this.openConfirmDelete(procedure)
    })
  }

  refreshProcedures(): void {
    this.refreshToken = (new Date()).getTime()
    console.log('refresh procedure list', this.refreshToken)
  }

  printProcedure(obj: Procedure, actionTemplate: TemplateRef<any>): void {
    // this.procedureViewService.currentSubject.next(this.procedures[i]);
    // this.printingService.print(elem);

    this.procedureViewService.currentSubject.next(obj);
    // need to add assets to the front of logo
    const img_src = 'assets/' + this.program_meta[this.patient_details.program]['logo'];
    const dialog = this.dialogService.open({
      title: 'Print Preview',
      content: AddSapComponent,
      minWidth: 1000,
      actions: actionTemplate,
      appendTo: this.appendKendoModalDialog
    });
    dialog.content.instance.can_edit = false;
    dialog.content.instance.is_print_view = true;
    dialog.content.instance.img_src = img_src;
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log('close');
      } else {
        console.log('action', result);
      }
      document.body.style.overflow = '';
    });

    document.body.style.overflow = 'hidden';

  }

  getRawData(idx: number, actionTemplate: TemplateRef<any>): void {
    if (idx == null) {
      return;
    }
    this.procedureViewService.currentSubject.next(this.procedures[idx]);
    let component;
    if (this.procedures[idx].teaching_format && this.procedures[idx].teaching_format.indexOf('dt') > -1) {
      component = this.raw_data_popups['dt'];
    } else {
      const data_sheet = this.all_data_sheets != null && this.all_data_sheets.length > 0 ?
        this.all_data_sheets.find(ds => ds.id == this.procedures[idx].data_sheet) :
        null;
      if (this.procedures[idx].teaching_format.indexOf('incidental') > -1) {
        // now need to check if the data_sheet is either duration or frequency
        if (data_sheet != null && data_sheet.code != null && data_sheet.code.toLowerCase() in this.raw_data_popups) {
          component = this.raw_data_popups[data_sheet.code.toLowerCase()];
        } else {
          component = this.raw_data_popups[this.procedures[idx].teaching_format];
        }
      } else {
        component = this.raw_data_popups[this.procedures[idx].teaching_format];
      }
    }
    this.procedureViewService.getRawData()
      .subscribe((data: object) => {
        console.log(data);
        this.dialog = this.dialogService.open({
          title: this.procedures[idx].name,
          content: component,
          width: 500,
          minWidth: 300,
          actions: actionTemplate,
          appendTo: this.appendKendoModalDialog
        });
        this.dialog.content.instance.is_readonly = true;
        this.dialog.content.instance.data = data;
        this.dialog.result.subscribe((result) => {
          if (result instanceof DialogCloseResult) {
            console.log('close');
          } else {
            console.log('action', result);
          }
          document.body.style.overflow = '';
        });
      },
        (err) => console.log(err)
      );
    document.body.style.overflow = 'hidden';
  }

  subscribeToDataSheets(): void {
    this.subscriptions.push(this.procedureViewService.getDataSheets$()
      .subscribe(
        (data_sheets: DataSheet[]) => {
          this.all_data_sheets = data_sheets;
        }, (err) => {
          console.log(err);
          // TODO: inform user
        })
    );
  }

  /**
   * editProcedure function to add/edit a procedure
   *
   * @param obj
   * @param event
   * @param {TemplateRef<any>} actionTemplate
   */
  editProcedure(
      obj?: any,
      event?: any,
      actionTemplate?: TemplateRef<any>,
  ): void {
    this.current_modal_obj = obj;

    this.procedureViewService.currentSubject.next(obj)

    if (event) {
      event.preventDefault();
    }
    const jq_height = jQuery(window).height();
    const height = jq_height < window.innerHeight ? jq_height : window.innerHeight;
    const options = {
      content: AddSapComponent,
      actions: actionTemplate,
      height: height
    };

    this.dialog = this.dialogService.open(options)
    this.dialog.result.subscribe((result) => {
      if (event) {
        event.preventDefault();
      }
      if (result instanceof DialogCloseResult) {
        console.log('close');
        this.procedureViewService.currentSubjectType.next(null);
        // now force
      } else {
        // TODO check if below is obsolete since we use
        // custom actions
        console.log('redundant save')
        console.log('action', result);
        if (!result['primary']) {
          this.procedureViewService.currentSubject.next(null);
        } else {
          console.log('used to save some stuff here, but it is commented out now')
        }
      }
      // this.result = JSON.stringify(result);
    });
    document.body.style.overflow = 'hidden';
  }

  public saveProcedure(dialogTemplate?: TemplateRef<any>, dialogActionTemplate?: TemplateRef<any>) {
    this.current_form = this.currentFormService.currentSubject.getValue();

    console.log('saving from the custom function')
    this.current_form.ngSubmit.emit();
    this.current_form.submitted = true;
    if (this.current_form.valid) {
      this.saveProcedures(dialogTemplate, dialogActionTemplate);
    }
    // need to clear the overflow
    document.body.style.overflow = '';
  }

  public incrementPhaseNumberSave(dialogTemplate?: TemplateRef<any>, dialogActionTemplate?: TemplateRef<any>) {
    if (this.procedureViewService.incrementPhaseNumberDialog) {
      const incrObs = this.procedureViewService.incrementPhaseNumberDialog(dialogTemplate, dialogActionTemplate);
      if (incrObs) {
        incrObs.subscribe(res => {
          console.log(res);
          if (res) {
            const message = `Successully saved ${res.name ? res.name : res.constructor.name}`;

            this.addToast(message, 'Success');
          }
        });
      }
    }
  }

  private saveProcedures(dialogTemplate?: TemplateRef<any>, dialogActionTemplate?: TemplateRef<any>): void{
    this.dialog.content._component.loading = true;
    // TODO: add save
    // this.loading = true;
    this.procedureViewService.save()
        .then((res) => {
          // this.dialog.close();
          this.addToast('Save completed successfully', 'success');
          this.refreshProcedures()

          // this.procedureViewService.getSubjects();
          this.dialog.content._component.loading = false;
          // force the subscriber to refresh the list
          this.close();

          this.incrementPhaseNumberSave(dialogTemplate, dialogActionTemplate);

          this.refreshSubjects.next(true);
        })
        .catch((err) => {
          // TODO:: need to show error
          console.log(err);
          this.dialog.content._component.loading = false;
          this.currentFormService.currentErrorMessage.next(err);

          if (err && typeof err === 'object' && (!err.status ||
              err.status === HttpErrorCodes.SERVER_ERROR || err.status == HttpErrorCodes.BAD_REQUEST)) {

            if (err.non_field_errors) {
              // take the first error message
              const error_message = err.non_field_errors[0];
              this.currentFormService.currentNonFieldErrorMessage.next(error_message);
            } else if (err.error) {
              const errors = err.error;
              if (typeof errors == 'string' || errors.detail) {
                // striping out inner html from error message
                const div = document.createElement('div');
                div.innerHTML = errors.detail || errors;
                const text = div.textContent || div.innerText || '';
                this.addToast(text, 'error');
              } else {
                if (err.error.error) {
                  this.addToast(err.error.error, 'error');
                } else {
                  const keys = Object.keys(errors);
                  if (keys.length > 0) {
                    const key = keys[0];
                    // err object comes back in the form of { <field>: [<message>]}
                    const message = key + ': ' + errors[key][0];
                    this.addToast(message, 'error');
                  }
                }
              }

            } else {
              // take the first error key
              const keys = Object.keys(err);
              if (keys.length > 0) {
                const key = keys[0];
                // err object comes back in the form of { <field>: [<message>]}
                const message = key + ': ' + err[key][0];
                this.addToast(message, 'error');
              }
            }
          } else {
            this.addToast('Could not add the selected user, please try again later', 'error');
          }

          throw err;
        });
  }

  public updatePhaseChangeDescription(event: any) {
    console.log('event', event.target.value)
    this.procedureViewService.phaseChangeDescriptionSubject.next(event.target.value)
  }

  public getPhaseChangeDescription() {
    return this.procedureViewService.getPhaseChangeDescription();
  }

  public closePhaseChangeDialog() {
    this.procedureViewService.closePhaseChangeDialog();
  }

  public savePhaseChange() {
    return this.procedureViewService.savePhaseChange().toPromise().then(() => {
      this.closePhaseChangeDialog()
    })
  }

  ngOnInit() {
    // default width
    this.margin = {top: 100, bottom: 0, left: 0, right: 0};
    // this.loading = true;
    this.subscriptions = [];
    this.graph_multiple_choices = [];
    this.publish_statuses = [];
    this.graph_data = new GraphData();
    this.all_data_sheets = [];
    this.getChoices();
    // this.subscriptions.push(this.getAllClients().subscribe((users) => users));
    this.subscribeToPatient();
    this.subscribeToPatientDetails();
    this.subscribeToChoices();
    this.subscribeToFiltered();
    this.subscribeToProcedures();
    this.subscribeToDataSheets();
    this.showNavService.showButtons.next(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.showNavService.showButtons.next(true);
  }

}
