import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {SkillObjective} from '../models/skill-objective.model';
import {BaseService} from '../base.service';
import {environment as env} from '../../../environments/environment';
// import {Observable} from 'rxjs/Observable';
import {Observable} from 'rxjs';
@Injectable()
export class SkillObjectiveService extends BaseService<SkillObjective> {

  skillObjectives: SkillObjective[];
  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.skillobjective/', SkillObjective);
    this.subRoute = '';
    this.date_obj_keys = [];
    this.skillObjectives = []
  }

}
