import { Injectable } from '@angular/core';
import {BaseService} from '../base.service';
import {IBaseService} from '../interfaces/base-interface';
import {ProcedureStep} from '../models/procedure-step.model';
import {AuthenticationService} from '../authentication.service';
import { HttpClient} from '@angular/common/http';
// import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {BehaviorSubject} from 'rxjs';
@Injectable()
export class ProcedureStepService extends BaseService<ProcedureStep> {

  currentSubject: BehaviorSubject<ProcedureStep> = new BehaviorSubject(new ProcedureStep());
  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.procedurestep/', ProcedureStep);
    this.subRoute = '';
    this.graph_route = '';
    this.date_obj_keys = ['mastery_date'];
  }

}
