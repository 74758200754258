import {AfterViewChecked, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DocumentViewService} from '../services/document-view.service';
import {Document} from '../../core/models/document.model';
import {PatientService} from '../../core/services/patient.service';
import {User} from '../../core/models/user.model';
import {ConstantsService} from '../../core/services/constants.service';
import {NgForm} from '@angular/forms';
import {AppValidationMessages} from '../app.messages';
import {CurrentFormService} from '../../core/services/current-form.service';
import {SelectOption} from '../../core/models/select-option.model';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.css']
})
export class AddDocumentComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  @ViewChild('addDocumentForm') public currentForm: any;
  addDocumentForm: NgForm;

  document: Document;
  patient: User;
  choices: Map<string, any>;
  parent: any;
  publish_statuses: SelectOption[];
  document_data_types: SelectOption[];
  formErrors = {
    document_name: '',
    file: ''
  };
  newFilename: string = '';

  constructor(private documentViewService: DocumentViewService,
              private patientService: PatientService,
              private constantsService: ConstantsService,
              private currentFormService: CurrentFormService) { }

  private updateValue(): void {
    this.documentViewService.currentSubject.next(this.document);
  }

  updateDetails(): void {
    this.updateValue();
  }

  getChoices(): void {
    if (!this.constantsService.choices || this.constantsService.choices.size === 0) {
      this.constantsService.getChoices().then(
        (choices: Map<string, any>) => {
          this.choices = choices;
          this.publish_statuses = choices.get('global').get('publish_status');
          if (choices.size > 0 && choices.has('document')) {
            this.document_data_types = choices.get('document').get('data_type');
          }
        });
    } else {
      this.choices = this.constantsService.choices;
      this.publish_statuses = this.choices.get('global').get('publish_status');
      this.document_data_types = this.choices.get('document').get('data_type');
    }
  }

  formChange(): void {
    if (this.currentForm === this.addDocumentForm) {
      return;
    }

    this.addDocumentForm = this.currentForm;
    if (this.addDocumentForm) {
      this.addDocumentForm.valueChanges
        .subscribe(data => this.onValueChanged());
    }
  }

  onValueChanged() {
    if (!this.addDocumentForm) {
      return;
    }
    const form = this.addDocumentForm.form;

    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }
  }

  submitForm() {
    // just a dummy function for the moment
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = this.currentForm.form.get(field);

      if (control && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }
  }

  ngAfterViewChecked(): void {
    this.formChange();
  }


  ngOnDestroy(): void {
    this.updateValue();
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.updateValue();
  }

  ngOnInit() {
    this.publish_statuses = [];

    this.document = this.documentViewService.currentSubject.getValue();
    this.patient = this.patientService.currentPatient.getValue();

    this.getChoices();

    const document_type = this.documentViewService.currentSubjectType.getValue();
    if (!this.document) {
      this.document = new Document();

    }
    this.document.type = !this.document.type ? document_type : this.document.type;
    this.document.patient = !this.document.patient ? this.patient.id : this.document.patient;
    this.currentFormService.currentSubject.next(this.currentForm);
  }

  uploadFile($event: any): void {
    // let's assume we can only have one file at the moment:
    if ($event.target && $event.target.files && $event.target.files.length > 0) {
      this.document.file = $event.target.files[0]; // take the first one
      this.newFilename = $event.target.files[0].name;
    }
  }
}
