import { Injectable } from '@angular/core';
import {Session} from '../models/session.model';
import {BaseService} from '../base.service';
import { HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {IBaseService} from '../interfaces/base-interface';
// import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class SessionService extends BaseService<Session> {
  currentSubject: BehaviorSubject<Session> = new BehaviorSubject(new Session());

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.sessionsummary/', Session);
    this.subRoute = '';
    this.date_obj_keys = ['date'];
  }

}
