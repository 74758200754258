import {AfterViewChecked, Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Assessment} from '../../core/models/assessment.model';
import {FormBuilder, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {PatientService} from '../../core/services/patient.service';
import {CurrentFormService} from '../../core/services/current-form.service';
import {ConstantsService} from '../../core/services/constants.service';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {ServicePlanViewService} from '../services/service-plan-view.service';
import {User} from '../../core/models/user.model';
import {Subscription} from 'rxjs/index';
import {AppValidationMessages} from '../app.messages';
import {SelectOption} from '../../core/models/select-option.model';
import {UserViewService} from '../services/user-view.service';
import {AssessmentViewService} from '../services/assessment-view.service';
import {DocumentViewService} from '../services/document-view.service';
import {Document} from '../../core/models/document.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-add-assessment',
  templateUrl: './add-assessment.component.html',
  styleUrls: ['./add-assessment.component.css']
})
export class AddAssessmentComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {
  @ViewChild('addAssessmentForm') public currentForm: any;
  @ViewChild('newDocumentFile') public newDocumentFile: ElementRef;
  addAssessmentForm: NgForm;

  private DEFAULT_TITLE = 'Error';
  subscriptions: Subscription[];
  patient: User;
  choices: Map<string, any>;
  assessment_types: SelectOption[];
  assessment_status: SelectOption[];
  hosts: User[];
  error_message: string = '';
  publish_statuses: SelectOption[];
  document_data_types: SelectOption[];
  document: Document;
  inline_backups: Map<string, any[]>;
  document_type = 'assessment';

  assessment: Assessment;
  formErrors = {
    name: '',
    conducted_by: '',
    type: '',
    date: '',
    date_completed: '',
    status: '',
    next_due: ''
  };

  obj_remove_functions = {
    documents: this.documentViewService.remove$
  };

  // public addAssessmentForm: FormGroup;

  constructor(private documentViewService: DocumentViewService,
              private patientService: PatientService,
              private servicePlanViewService: ServicePlanViewService,
              private constantsService: ConstantsService,
              private toastyService: ToastyService,
              private currentFormService: CurrentFormService,
              private userViewService: UserViewService,
              private assessmentViewService: AssessmentViewService) {

  }

  updateAssessment(): void {
    this.assessmentViewService.currentSubject.next(this.assessment);
  }

  subscribeToPatient(): void {
    this.subscriptions.push(this.patientService.currentPatient.subscribe(
      (patient: User) => {
        this.patient = patient;
        if (this.patient && this.patient.id != null) {
          // this.getServicePlans();
          // and add the patient id to the procedure regardless - as it should be the same
          // this.procedure.patient = patient.id;
          this.assessment.patient = patient.id;
        }
      }));
  }

  getChoices(): void {
    if (!this.constantsService.choices || this.constantsService.choices.size === 0) {
      this.constantsService.getChoices().then(
        (choices: Map<string, any>) => {
          this.choices = choices;
          this.assessment_types = this.choices.get('assessment').get('type');
          this.assessment_status = this.choices.get('assessment').get('status');
          this.publish_statuses = this.choices.get('global').get('publish_status');
          this.document_data_types = this.constantsService.choices.get('document').get('data_type');
        });
    } else {

      this.choices = this.constantsService.choices;
      this.assessment_types = this.choices.get('assessment').get('type');
      this.assessment_status = this.choices.get('assessment').get('status');
      this.publish_statuses = this.choices.get('global').get('publish_status');
      this.document_data_types = this.constantsService.choices.get('document').get('data_type');
    }
  }

  getHosts(): void {
    // if (!this.userViewService.collection || this.userService.collection.length === 0) {
    this.userViewService.get_hosts().subscribe(
      (users) => {
        this.hosts = users;
      })
    // } else {
    //   this.users_list = this.userService.collection;
    // }
  }

  unsubscribe(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  formChange(): void {
    if (this.currentForm === this.addAssessmentForm) {
      return;
    }

    this.addAssessmentForm = this.currentForm;
    if (this.addAssessmentForm) {
      this.addAssessmentForm.valueChanges
        .subscribe(data => this.onValueChanged());
    }
  }

  uploadFile($event: any, idx?: number): void {
    // let's assume we can only have one file at the moment:
    if ($event.target && $event.target.files && $event.target.files.length > 0) {
      if (idx != null) {
        this.assessment.documents[idx].file = $event.target.files[0]; // take the first one
      } else {
        this.document.file = $event.target.files[0]; // take the first one
      }
    }
  }

  onValueChanged() {
    if (!this.addAssessmentForm) {
      return;
    }
    const form = this.addAssessmentForm.form;

    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }
  }

  submitForm() {
    // just a dummy function for the moment
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = this.addAssessmentForm.form.get(field);

      if (control && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }
  }


  private updateValue(): void {
    this.documentViewService.currentSubject.next(this.document);
  }

  private getDocuments() {
    if (this.assessment && this.assessment.id != null) {
      this.subscriptions.push(this.documentViewService.getByObject$(this.assessment.id, null, this.document_type)
      // this.subscriptions.push(this.documentViewService.getByObject$(this.assessment.id)
        .subscribe((documents: Document[]) => {
          this.assessment.documents = documents;
          this.inline_backups.set('documents', _.cloneDeep(documents));
        })
      );
    } else if (this.assessment) {
      this.assessment.documents = [];
    }
  }

  updateDetails(): void {
    this.updateValue();
  }

  ngAfterViewChecked(): void {
    this.formChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.updateProcedure();
  }

  ngOnInit() {
    this.subscriptions = [];
    this.hosts = [];
    this.publish_statuses = [];

    this.assessment = this.assessmentViewService.currentSubject.getValue();
    if (!this.assessment) {
      this.assessment = new Assessment();
      // this.addAssessmentForm = this.formBuilder.group({
      //   name: [null, Validators.required],
      //   type: [null, Validators.required],
      //   host: [null, Validators.required],
      //   date: [null],
      //   date_completed: [null],
      //   status: [null],
      //   next_due: [null]
      // });
    }
    // else {
    //   this.addAssessmentForm = this.formBuilder.group({
    //     name: [this.assessment.name, Validators.required],
    //     type: [this.assessment.type, Validators.required],
    //     host: [this.assessment.host, Validators.required],
    //     date: [this.assessment.date_date],
    //     date_completed: [this.assessment.date_completed_date],
    //     status: [this.assessment.status],
    //     next_due: [this.assessment.next_due_date]
    //   });
    // }
    this.subscribeToPatient();
    this.getChoices();
    this.getHosts();
    this.getDocuments();
    this.currentFormService.currentSubject.next(this.currentForm);
    // this.document = this.documentViewService.currentSubject.getValue();
    this.document = new Document();
    this.document.type = this.document_type;
    this.patient = this.patientService.currentPatient.getValue();
    this.inline_backups = new Map<string, any[]>();

    this.getChoices();

    // let's get the documents now, if we have anything
    const document_type = this.documentViewService.currentSubjectType.getValue();
    // if (!this.document) {
    //   this.document = new Document();
    //
    // }
    this.document.type = !this.document.type ? document_type : this.document.type;
    this.document.patient = !this.document.patient ? this.patient.id : this.document.patient;
    this.currentFormService.currentSubject.next(this.currentForm);

    // this.getNumTrials();
    // this.getChoices();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.currentFormService.currentSubject.next(null);
  }

  addDocument(): void {
    // TODO: need to save document
    if (!this.assessment) this.assessment = new Assessment(); // just incase
    if (!this.assessment.documents) this.assessment.documents = [];
    if (!this.document.name || !this.document.file) return;

    this.document._order = this.assessment.documents.length;
    this.assessment.documents.push(_.cloneDeep(this.document));
    this.document = new Document();
    this.document.type = this.document_type;
    this.document.patient = this.patient.id;
    this.newDocumentFile.nativeElement.value = '';
    this.updateAssessment();
  }

  edit(obj: any, idx?: number, list?: string): void {
    if (!obj.edit && list != null && idx != null && this.inline_backups.has(list)) {
      this.inline_backups.get(list)[idx] = _.cloneDeep(obj);
      this.assessment[list][idx].edit = !this.assessment[list][idx].edit;
    } else if (!!obj.edit && list != null && idx != null) {
      // here we are canceling the edit if we are editing
      // have to update the list cause we are changing the hash by copying the object back
      this.assessment[list][idx] = _.cloneDeep(this.inline_backups.get(list)[idx]);
      this.assessment[list][idx].edit = false;
    } else {
      this.assessment[list][idx].edit = !this.assessment[list][idx].edit;
    }
  }

  updateOrderOfList(list: string): void {
    if (this.assessment[list]) {
      for (let i = 0; i < this.assessment[list].length; i++) {
        this.assessment[list][i]['_order'] = i;
      }
    }
  }

  removeObj(obj: object, remove_key: string, list: string, title: string, second_key?: string) {
    if (remove_key && this.obj_remove_functions[remove_key]) {
      let idx = this.assessment[list].findIndex(l => l.id == obj['id']);
      if (obj && obj['id']) {
        // these should be observables now
        this.subscriptions.push(this.obj_remove_functions[remove_key].call(this.documentViewService, obj)
          .subscribe((response) => {
            if (response) {
              this.assessment[list].splice(idx, 1);
              this.updateOrderOfList(list);

            }
            this.addToast('Successfully deleted ' + title, 'success');
          }, (err) => {
            this.addToast('Could not delete ' + title + ', please try again later...', 'Error');
          }));
      } else {
        if (second_key != null) {
          idx = this.assessment[list].findIndex(l => l._order == obj[second_key]);
        }
        this.assessment[list].splice(idx, 1);
        this.updateOrderOfList(list);
      }
      if (this.inline_backups.has(list) && this.inline_backups.get(list) != null && this.inline_backups.get(list)[idx] != null) {
        this.inline_backups.get(list).splice(idx, 1);
      }
    }
  }

  addToast(message: string, title: string) {
    // Or create the instance of ToastOptions
    const toastOptions = this.getToastOptions(message, title);
    // // Add see all possible types in one shot
    // this.toastyService.info(toastOptions);
    // this.toastyService.success(toastOptions);
    // this.toastyService.wait(toastOptions);
    // this.toastyService.error(toastOptions);
    // this.toastyService.warning(toastOptions);

    switch (title.toLowerCase()) {
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
    }
  }

  getToastOptions(message: string, title: string): ToastOptions {
    title = title != null ? title : this.DEFAULT_TITLE;

    return {
      title: title,
      msg: message,
      showClose: true,
      timeout: 5000,
      theme: 'default'
    };
  }

  saveDocument(idx: number): void {
    if (!this.assessment || !this.assessment.id) {
      // this.addToast('Could not save the schedule before the Procedure, please save the procedure first', 'Error');
      this.assessment.documents[idx].edit = false;
      return;
    }
    this.assessment.documents[idx].object_id = this.assessment.id;
    this.documentViewService.currentSubject.next(this.assessment.documents[idx]);
    this.documentViewService.save$()
      .subscribe(
        (document: Document) => {
          document.edit = false;
          this.assessment.documents[idx] = _.merge(this.assessment.documents[idx], document);
          const documents = this.inline_backups.get('documents');
          documents[idx] = _.cloneDeep(document);
          this.inline_backups.set('documents', _.cloneDeep(documents));
          this.addToast('Successfully saved document', 'success');
        },
        (err) => {
          this.addToast('Could not save document, please try again later...', 'Error');
        });
  }
}
