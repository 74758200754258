import {Base} from './base.model';

export class PermissionModel extends Base<PermissionModel> {
  detail: string;
  prefix: string;
  method: string;
  custom_action: string;
  is_allowed: boolean;

  constructor() {
    super(PermissionModel);
  }
}
