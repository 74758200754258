import {AfterViewChecked, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ProcedureViewService} from '../services/procedure-view.service';
import {PatientService} from '../../core/services/patient.service';
import {SkillObjectiveService} from '../../core/services/skill-objective.service';
import {ConstantsService} from '../../core/services/constants.service';
import {ServicePlanViewService} from '../services/service-plan-view.service';
import {InitialTactic} from '../../core/models/initial-tactic.model';
import {SelectOption} from '../../core/models/select-option.model';
import {Procedure} from '../../core/models/procedure.model';
import {User} from '../../core/models/user.model';
import {Subscription} from "rxjs/index"
import {SessionService} from '../../core/services/session.service';
import {SessionViewService} from '../services/session-view.service';
import {Session} from '../../core/models/session.model';
import {UserViewService} from '../services/user-view.service';
import {HttpErrorCodes} from '../../core/shared/enum';
import {Assessment} from '../../core/models/assessment.model';
import {ToastOptions, ToastyConfig, ToastyService} from 'ng2-toasty';
import {NgForm} from '@angular/forms';
import {CurrentFormService} from '../../core/services/current-form.service';
import {AppValidationMessages} from '../app.messages';

@Component({
  selector: 'app-add-session',
  templateUrl: './add-session.component.html',
  styleUrls: ['./add-session.component.css']
})
export class AddSessionComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {

  private DEFAULT_TITLE = 'Error';
  @ViewChild('addSessionsForm') public currentForm: any;
  addSessionsForm: NgForm;

  phases: SelectOption[] = [];
  tactics: SelectOption[] = [];
  procedures: Procedure[];
  subscriptions: Subscription[];
  sessions: Session[];
  patient: User;
  choices: Map<string, any>;
  session: Session;
  users_list: User[];
  all_users: User[];
  procedure: Procedure;
  procedure_title: string;

  formErrors = {};

  constructor(private patientService: PatientService,
              private procedureViewService: ProcedureViewService,
              private sessionViewService: SessionViewService,
              private skillObjectiveService: SkillObjectiveService,
              private constantsService: ConstantsService,
              private userViewService: UserViewService,
              private toastyService: ToastyService,
              private toastyConfig: ToastyConfig,
              private currentFormService: CurrentFormService) {

  }

  getToastOptions(message: string, title: string): ToastOptions {
    title = title != null ? title : this.DEFAULT_TITLE;

    return {
      title: title,
      msg: message,
      showClose: true,
      timeout: 5000,
      theme: 'default'
    };
  }

  addToast(message: string, title: string) {
    // Or create the instance of ToastOptions
    const toastOptions = this.getToastOptions(message, title);
    // // Add see all possible types in one shot
    // this.toastyService.info(toastOptions);
    // this.toastyService.success(toastOptions);
    // this.toastyService.wait(toastOptions);
    // this.toastyService.error(toastOptions);
    // this.toastyService.warning(toastOptions);

    switch (title.toLowerCase()) {
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
    }
  }

  save(idx: number): void {
    const session = this.sessions[idx];
    if (session && (session.procedure != null || (this.procedure && this.procedure.id != null))) {
      if (session.procedure == null) {
        session.procedure = this.procedure.id;
      }
      this.sessionViewService.currentSubject.next(session);
      this.sessionViewService.save()
        .then((res: Session) => {
          this.sessions[idx] = res;
          this.sessionViewService.currentSubject.next(null);
          this.addToast('Session was saved.', 'Success');
        })
        .catch((err) => {
          this.addToast('Could not save session, please try again...', 'Error');
          this.sessionViewService.currentSubject.next(null);

          throw err;
        });
    }
  }

  add(): void {
    if (this.session && (this.session.procedure != null || (this.procedure && this.procedure.id != null))) {
      if (this.session.procedure == null) {
        this.session.procedure = this.procedure.id;
      }
      this.sessionViewService.currentSubject.next(this.session);
      this.sessionViewService.save()
        .then((res: Assessment) => {
          this.sessions.push(this.session);
          this.session = null;
          this.session = new Session();
          this.sessionViewService.currentSubject.next(null);
          this.addToast('Session was saved.', 'Success');
        })
        .catch((err) => {
          this.addToast('Could not save session, please try again...', 'Error');
          this.sessionViewService.currentSubject.next(null);

          throw err;
        });
    }
  }

  edit(idx: number): void {
    this.sessions[idx].edit = !this.sessions[idx].edit;
  }


  remove(idx: number): void {
    if (idx == null) {
      return
    }
    // need to find out if we have to archive it or just blatently remove
    this.sessionViewService.removeSession(this.sessions[idx])
      .then((res) => {
        this.sessions.splice(idx, 1);
        this.addToast('Session was deleted.', 'Success');
      })
      .catch((err) => {
        if (typeof err === 'object' && err.status === HttpErrorCodes.UNAUTHORIZED) {
          this.addToast('You do not have permission to remove this session...', 'Error');
        } else {
          this.addToast('Could not delete session, please try again...', 'Error');
        }

        throw err;
      });
  }


  getProcedures(): void {
    // we should have a patient at this point, if we don't then something is very wrong
    this.procedureViewService.getByPatient(this.patient.id.toString())
      .then((procedures: Procedure[]) => {
        this.procedures = procedures;
      })
      .catch(
        err => {
          console.log(err);

          throw err;
        })
  }

  getSessions(): void {
    // we should have a patient at this point, if we don't then something is very wrong
    this.sessionViewService.getByProcedure(this.procedure.id.toString())
      .then((sessions: Session[]) => {
        this.sessions = sessions;
      })
      .catch(
        err => {
          console.log(err);

          throw err;
        })
  }

  subscribeToPatient(): void {
    this.subscriptions.push(this.patientService.currentPatient.subscribe(
      (patient: User) => {
        this.patient = patient;
        if (this.patient && this.patient.id != null) {
          this.getProcedures();
        }
      }));
  }

  getChoices(): void {
    if (!this.constantsService.choices || this.constantsService.choices.size === 0) {
      this.constantsService.getChoices().then(
        (choices: Map<string, any>) => {
          this.choices = choices;
          this.phases = this.choices.get('initialtactic').get('phase');
          this.tactics = this.choices.get('initialtactic').get('tactic');
        });
    } else {
      this.choices = this.constantsService.choices;
      this.phases = this.choices.get('initialtactic').get('phase');
      this.tactics = this.choices.get('initialtactic').get('tactic');
    }
  }

  getHosts(): void {
    // if (!this.userViewService.collection || this.userService.collection.length === 0) {
    this.userViewService.get_hosts().subscribe(
      (users) => {
        this.users_list = users;
      })
    // } else {
    //   this.users_list = this.userService.collection;
    // }
  }

  // getAllHosts(): void {
  //   // if (!this.userViewService.collection || this.userService.collection.length === 0) {
  //   this.userViewService.getAllUsers().then(
  //     (users) => {
  //       this.all_users = users;
  //     })
  //   // } else {
  //   //   this.users_list = this.userService.collection;
  //   // }
  // }

  unsubscribe(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  formChange(): void {
    if (this.currentForm === this.addSessionsForm) {
      return;
    }

    this.addSessionsForm = this.currentForm;
    if (this.addSessionsForm) {
      this.addSessionsForm.valueChanges
        .subscribe(data => this.onValueChanged());
    }
  }

  onValueChanged() {
    if (!this.addSessionsForm) {
      return;
    }
    const form = this.addSessionsForm.form;

    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }
    // this.skill_objectives_error = !!(!this.service_plan.skill_objectives_display ||
    //   (this.service_plan.skill_objectives_display && this.service_plan.skill_objectives_display.length === 0));
  }

  submitForm() {
    // just a dummy function for the moment
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = this.currentForm.form.get(field);

      if (control && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }

    // this.skill_objectives_error = !!(!this.service_plan.skill_objectives_display ||
    //   (this.service_plan.skill_objectives_display && this.service_plan.skill_objectives_display.length === 0));
  }

  ngAfterViewChecked(): void {
    this.formChange();
  }

  ngOnInit() {
    this.users_list = [];
    this.subscriptions = [];
    this.sessions = [];
    this.session = new Session();
    this.procedure = this.procedureViewService.currentSubject.getValue();
    if (this.procedure && this.procedure.id != null) {
      this.getSessions();
    }
    this.subscribeToPatient();
    this.getChoices();
    this.getHosts();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.procedureViewService.currentSubject.next(null);
    this.procedure_title = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

}
