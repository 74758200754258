import {Base} from './base.model';
import {SessionTrial} from './session-trial.model';

export class ProcedureStep extends Base<ProcedureStep>  {
  _order: number;
  step: string;
  procedure: number;
  mastery_date: string;
  mastery_date_date: Date;
  stage: string;
  session_trials: SessionTrial[];

  constructor() {
    super(ProcedureStep);

  }
}
