import {Component, OnInit} from '@angular/core';
import {UserService} from '../core/services/user.service';
import {PatientService} from '../core/services/patient.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {PatientDetails} from '../core/models/patient-details.model';
import {Observable} from 'rxjs';
import {User} from '../core/models/user.model';
import {BaseComponent} from '../shared/base/base.component';
import {PatientViewService} from './patient-view.service';
import {GlobalService} from '../core/services/global.service';
import {Location} from '@angular/common';
import {ConstantsService} from '../core/services/constants.service';
import {ShowNavService} from '../core/services/show-nav.service';
import {AuthGroupService} from '../core/services/auth-group.service';
import {AuthGroup} from '../core/models/auth-group.model';
import {UserViewService} from '../shared/services/user-view.service';
import {CurrentFormService} from '../core/services/current-form.service';
import {switchMap} from "rxjs/operators"

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css']
})
export class PatientComponent extends BaseComponent implements OnInit {
  patient_id: string;
  patient: User;
  patient_details: PatientDetails;
  showButtons: boolean;

  auth_groups: AuthGroup[];
  supervisor: any;
  sbt: any;
  carer1: number;
  carer2: number;
  carer3: number;
  tab_links: any[] = [];
  default_tab_links = [
    {label: 'Details', path: 'details', disabled: false},
    {label: 'Assessments', path: 'assessments', disabled: false},
    {label: 'Documents', path: 'documents', disabled: false},
    {label: 'Program Plan', path: 'program-plan', disabled: false},
    {label: 'SAPs', path: 'procedures', disabled: false},
    {label: 'Data Entry', path: 'data-entry', disabled: false},
    // { label: 'Discrete Trial', path: 'discrete-trial', disabled: false },
    // { label: 'Task Analysis', path: 'task-analysis', disabled: false },
    // { label: 'Behaviour Interventions', path: 'behaviour-interventions', disabled: false },
    // { label: 'Training', path: 'training', disabled: true },
    // { label: 'Program Supervision', path: 'program-supervision', disabled: true },
    {label: 'Contacts', path: 'contacts', disabled: false},
    {label: 'Comms Book', path: 'communications', disabled: false},
  ];

  constructor(private userService: UserService,
              constantsService: ConstantsService,
              userViewService: UserViewService,
              private patientService: PatientService,
              private route: ActivatedRoute,
              private patientViewService: PatientViewService,
              private globalService: GlobalService,
              private location: Location,
              private authGroupService: AuthGroupService,
              private showNavService: ShowNavService,
              currentFormService: CurrentFormService,
              private router: Router) {
    super(constantsService, userViewService, null, null, currentFormService);
  }

  getPatient(id: number): Promise<void> {
    return this.userService.getById(id).then(res => {
      let promise: Promise<any>;

      this.patientService.currentPatient.next(res);
      this.patient = res;
      const supervisors = res.parents_repr.filter(pr => pr.relationship_type === 'PS');
      const sbts = res.parents_repr.filter(pr => pr.relationship_type === 'SBT');

      const parents = res.parents_repr.filter(pr => pr.relationship_type === 'PARENT' && pr.relationship_type_description.toLowerCase() == 'carer');
      if (parents[0]) {
        this.carer1 = parents[0].user;
      }
      if (parents[1]) {
        this.carer2 = parents[1].user;
      }
      // for (const parent of parents) {
      //   const user = this.users_list.filter(ul => ul.id === parent.user);
      //   if (user && user.length > 0) {
      //     this.carer1 = user[0];
      //     this.carer2 = user[1];
      //   }
      // }
      if (supervisors && supervisors.length > 0) {
        this.supervisor = supervisors[0];
      }
      if (sbts && sbts.length > 0) {
        this.sbt = sbts[0];
      }
      if (res.detail_cls != null) {
        promise = this.patientService.getByParamPromise(this.patient_id, 'user');
      } else {
        promise = Promise.resolve({});
      }
      return promise;
    }).then((res) => {
      if (res && res.length > 0) {
        this.patient_details = res[0];
      } else {
        this.patient_details = new PatientDetails();
      }
      this.patientService.currentPatientDetails.next(this.patient_details);
    });
  }

  getAuthGroups(): void {
    if (!this.authGroupService.collection || this.authGroupService.collection.length === 0) {
      this.authGroupService.getAllPromise().then(
        (auth_groups) => {
          this.auth_groups = auth_groups;
        })
    } else {
      this.auth_groups = this.authGroupService.collection;
    }
  }

  subscribeToTitle(): void {
    this.subscriptions.push(this.patientViewService.currentTitle.subscribe(
      (title) => {
        this.title = title;
      }));
  }

  subscribeToShowButtons(): void {
    this.subscriptions.push(this.showNavService.showButtons.subscribe(
      (is_shown) => {
        this.showButtons = is_shown;
      }));
  }

  save(): void {
    this.globalService.save('');
  }

  cancel(): void {
    this.location.back();
  }

  filterTabs(): void {
    const permissions = this.authGroupService.currentUserPermissions.getValue();
    this.tab_links = this.default_tab_links.filter(dtl => permissions.exclude_tabs.indexOf(dtl.path) === -1)
  }

  subscribeToUserPermissions(): void {
    this.subscriptions.push(this.authGroupService.currentUserPermissions.subscribe(
      (perms) => {
        this.filterTabs();
      }
    ));
  }

  ngOnInit() {
    this.showButtons = true;
    this.users_list = [];
    this.subscriptions = [];
    this.patient = new User();
    this.showNavService.set(true);
    this.subscriptions.push(this.getAllClients().subscribe((users) => users));
    this.getAuthGroups();
    this.subscribeToTitle();
    this.subscribeToShowButtons();
    this.subscribeToUserPermissions();

    this.route.paramMap
      .pipe(switchMap((params: ParamMap) => {
        this.patient_id = params.get('id');
        if (this.patient_id == null) {
          this.router.navigate(['/patients']);
        }
        this.patientService.currentPatientId.next(+this.patient_id);
        console.log(this.patient_id);
        return this.getPatient(+this.patient_id);
      }))
      .subscribe(contact => {
        // just a dummy return function since it doesn't need to really do anything
        return contact;
      });

  }

}
