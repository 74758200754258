import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getProcName'
})
export class GetProcNamePipe implements PipeTransform {

  transform(value: any, args?: any[]): string {
    if (!value || !args || !args.length) {
      return;
    }

    let ret_val: string;
    ret_val = args.find(a => a.id === value.procedure)['name'];
    return ret_val;

  }

}
