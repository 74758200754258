import { Injectable } from '@angular/core';
import {BaseService} from '../base.service';
import {IBaseService} from '../interfaces/base-interface';
import {PromptFadingStep} from '../models/prompt-fading-step.model';
import {AuthenticationService} from '../authentication.service';
import { HttpClient} from '@angular/common/http';
// import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {BehaviorSubject} from 'rxjs';
@Injectable()
export class PromptFadingStepService extends BaseService<PromptFadingStep> {
  currentSubject: BehaviorSubject<PromptFadingStep> = new BehaviorSubject(new PromptFadingStep());

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.promptfadingstep/', PromptFadingStep);
    this.subRoute = '';
    this.date_obj_keys = [];
    this.graph_route = '';
  }

}
