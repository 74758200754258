import {Base} from './base.model';
import {SelectOption} from './select-option.model';

export class InitialTactic extends Base<InitialTactic> {
  id: number;
  created: string;
  updated: string;
  _order: number;
  archived: boolean;
  phase: string;
  tactic: string;
  chaining_type: string;
  created_by: number;
  last_updated_by: number;
  procedure: number;
  edit: boolean;
  tactics: SelectOption[];
  description: string;
  show_prompt_description: boolean;
  prompt_description: string;

  constructor() {
    super(InitialTactic);
  }
}
