import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TokenService} from '../core/services/token.service';
import {AuthenticationService} from '../core/authentication.service';
import {Router} from '@angular/router';
import {Login} from '../core/models/login.model';
import {ShowNavService} from '../core/services/show-nav.service';
import {environment as env} from 'environments/environment';
import {SelectOption} from '../core/models/select-option.model';
// import 'libs/vue-app/cs-comp.min';
// import 'libs/vue-app/cs-comp.common';
// import 'libs/vue-app/cs.vendors~cs.min.min';
// import 'libs/vue-app/cs-comp.css';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  // loginForm: FormGroup;
  loading: boolean = false;
  error: string = '';
  isDev: boolean;
  testObject: SelectOption;
  @Input() loginUser: Login = new Login;
  @Output() showNavChange = new EventEmitter<boolean>();

  constructor(private tokenService: TokenService,
              private authenticationService: AuthenticationService,
              private showNavService: ShowNavService,
              private router: Router) {
  }

  login(): void {
    if (this.loginUser.username && this.loginUser.password) {
      this.loading = true;
      this.error = '';
      this.tokenService.obtain(this.loginUser.username, this.loginUser.password)
        .then((res) => {
          // need to do something on successful login

          console.log(res);
          // this.loading = false;
          // add the id when it's worked out what's going on
          this.router.navigate(['/patients'])
            .then((nav_res) => {
              console.log(nav_res)
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {

          this.loading = false;
          // console.log(error);
          if (typeof error === 'string') {
            if (error === 'invalid_grant') {
              this.error = 'Username or password was incorrect, please try again...';
            } else {
              this.error = 'Something went wrong, please contact your Systems Administrator.';
            }
          } else {
            const err = error;
            if (err.error && err.error.error_description) {
              this.error = err.error.error_description;
            } else {
              // a different error occurred
              this.error = 'System error occurred, please contact your system administrator';
            }
          }

          throw error;
        });
    }
  }

  throwClientError() {
    // throwing error that the error handler should catch
    // the below object is purposefully not set so we can catch the error and redirect/show error msg
    this.testObject.value = 'foo';
  }

  clickEventHappened(data) {
    console.log('returned from Vue app', data)
    console.log('arguments', arguments)
  }

  ngOnInit() {
    this.isDev = !env.production && window.location.href.indexOf('localhost') > -1;
    if (this.authenticationService.isAuthenticated()) {
      // this.operatorService.login().then(() => {
      //   this.router.navigate(['/operator']);
      // }).catch((err) => {
      //   //TODO: do something on error
      //   console.log(err);
      //   // if (err && err.statusText && err.statusText.toLowerCase().indexOf('server') > -1) {
      //   // }
      //   this.router.navigate(['/operator']);
      //
      // });
      this.router.navigate(['/patients']);
    }
    this.showNavService.set(false);
  }

  ngOnDestroy() {
    this.showNavService.set(true);
  }
}
