export class ExpiryModel {
  date_added: Date;
  expires_in: number;

  constructor(
    date_added?: Date,
    expires_in?: number
  ) {
    this.date_added = date_added;
    this.expires_in = expires_in;
  }
}
