import {Injectable} from '@angular/core';
import {ParentDetailService} from '../../core/services/parent-detail.service';
import {User} from '../../core/models/user.model';
import {PatientService} from '../../core/services/patient.service';
import {UserService} from '../../core/services/user.service';
import {ParentDetail} from '../../core/models/parent-detail.model';
import {throwError, BehaviorSubject} from 'rxjs';
import {AuthGroupService} from '../../core/services/auth-group.service';
import {IBaseViewService} from '../interfaces/base-view.interface';

@Injectable()
export class ParentDetailViewService implements IBaseViewService<User> {

  currentSubjectType: BehaviorSubject<string> = new BehaviorSubject('');
  currentSubject: BehaviorSubject<User> = new BehaviorSubject(new User());
  currentSubjects: BehaviorSubject<User[]> = new BehaviorSubject([]);

  DEFAULT_GROUP = 'PARENT';
  PROMISE_STATUS_SUCCESS = 'success';

  constructor(private parentDetailService: ParentDetailService,
              private patientService: PatientService,
              private userService: UserService,
              private authGroupService: AuthGroupService) {

  }

  search(status?: string, search_text?: string): Promise<User[]> {
    return undefined;
  }

  getSubjects(): Promise<User[] | void> {
    const patient = this.patientService.currentPatient.getValue();
    // we should have a patient at this point, if we don't then something is very wrong
    return this.userService.getByParamPromise(patient.id.toString(), 'patient')
      .then(contacts => {
        this.currentSubjects.next(contacts);
        return contacts;
      })
      .catch(
        err => {
          console.log(err);
        })
  }

  // don't need this, just need to define it
  getByPatient(id: string): Promise<User[]> {
    return undefined;
  }

  save(): Promise<User> {
    let parent = this.currentSubject.getValue();
    return this.saveParent(parent).then(
      (res) => {
        parent = this.userService.newUser.getValue();
        this.currentSubject.next(parent);
        return this.getSubjects();
      }).then(() => {
      return parent;
    })
      .catch(err => throwError(err).toPromise());
  }

  update(): Promise<boolean> {
    let parent = this.userService.newUser.getValue();
    return this.saveParent(parent).then(
      (res) => {
        parent = this.userService.newUser.getValue();
        return true;
      })
      .catch(err => throwError(err).toPromise());
  }

  getDetails(parent: User): Promise<ParentDetail> {
    if (!parent) {
      // if we don't have a patient then we have a problem
      throw new Error('User was not found or was null');
    }
    return this.parentDetailService.getByParamPromise(parent.id.toString(), 'user')
      .then((res: ParentDetail[]) => {
        let detail: ParentDetail;
        if (res && res.length > 0) {
          detail = res[0];
        }
        return detail;
      });
  }

  saveParent(parent: User): Promise<ParentDetail> {
    const patient = this.patientService.currentPatient.getValue();
    let promise: Promise<User>;
    if (!patient) {
      // if we don't have a patient then we have a problem
      throw new Error('Patient was not created');
    }
    if (!parent.centre) {
      parent.centre = patient.centre;
    }
    if (!parent.id) {
      promise = this.userService.addContact(parent, patient.id);
    } else {
      promise = this.userService.save(parent);
    }
    // first we save the parent
    let saved_user: User;
    return promise
      .then(
        (res) => {
          if (!res && !res.id) {
            throw new Error('User did not save correctly');
          }
          saved_user = res;
          this.userService.newUser.next(res);
          // next we save or get the detail
          return this.parentDetailService.getByParamPromise(res.id.toString(), 'user');
        }).then((res: ParentDetail[]) => {
        const saved_detail = res[0];
        let detail = this.parentDetailService.currentParentDetail.getValue();
        if (!detail) {
          detail = new ParentDetail();
        }
        if (saved_detail && saved_detail.id != null) {
          saved_detail.dob = detail.dob;
          saved_detail.dob_date = detail.dob_date;
          saved_detail.relationship_desc = detail.relationship_desc || 'Parent';
          if (parent.id) {
            saved_detail.relationship_descs = detail.relationship_descs;
            const index = detail.relationship_descs.findIndex(el => el.to_user_id === patient.id);
            if (index !== -1) {
              saved_detail.relationship_descs[index].relationship_desc = detail.relationship_desc;
            }
          } else {
            saved_detail.relationship_descs = [{
              to_user_id: patient.id,
              relationship_desc: saved_detail.relationship_desc
            }]
          }
          // we add in the missing details from the saved object and reassign to the
          // detail object so we can save it
          detail = saved_detail;
        } else {
          detail.user = saved_user.id;
        }

        return this.parentDetailService.save(detail);
      })
      .then(
        (res) => {
          return res;
        });
  }

  assignRole(parent: User): Promise<boolean> {
    // should have auth groups now
    let group: number;
    let promise: Promise<any>;
    const result = this.authGroupService.collection.filter(c => c.user_type === this.DEFAULT_GROUP);
    if (result && result.length > 0) {
      group = result[0].id;
      promise = this.userService.assignRole(parent.id, group);
    } else {
      promise = Promise.resolve(null);
    }
    return promise
      .then((res) => {
        return !!res && res.status === this.PROMISE_STATUS_SUCCESS;
      });
  }

  assignChild(parent: User): Promise<boolean> {
    const patient = this.patientService.currentPatient.getValue();
    if (!patient) {
      // if we don't have a patient then we have a problem
      throw new Error('Patient was not created');
    }
    return this.userService.assignChild(parent.id, patient.id, this.DEFAULT_GROUP)
      .then((res) => {
        return !!res && res['status'] === this.PROMISE_STATUS_SUCCESS;
      });
  }

  getById(id: number): Promise<User> {
    return undefined;
  }

}
