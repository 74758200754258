import {Component, HostBinding, HostListener, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {TokenService} from './core/services/token.service';
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import {ShowNavService} from './core/services/show-nav.service';
import {Subscription} from 'rxjs';
import {User} from './core/models/user.model';
import {UserService} from './core/services/user.service';
import {UserViewService} from './shared/services/user-view.service';

import {Store} from '@ngrx/store';
import * as fromRoot from './core/state/reducers';
import * as ApplicationActions from './core/state/application/actions';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {AuthenticationService} from './core/authentication.service';
import {DailySessionViewService} from './shared/services/daily-session-view.service';
import {TherapySession} from './core/models/therapy-session.model';
import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {Procedure} from './core/models/procedure.model';
import {ConstantsService} from './core/services/constants.service';
import {SelectOption} from './core/models/select-option.model';
import {environment as env} from '../environments/environment';
import {ProcedureViewService} from './shared/services/procedure-view.service';
import { ConfirmDeleteService } from './core/services/confirm-delete.service';

declare var jQuery: any;
declare const $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  ignoreRoutes = ['/restpassword'];
  showNav: boolean;
  navIsOpen: boolean;
  subscriptions: Subscription[] = [];
  logged_in_user: User;
  isLoggedIn: Subscription;
  DEFAULT_TITLE = 'Reminder';
  dialog: DialogRef;
  is_logout: boolean = false;
  therapy_session: TherapySession;
  exclude_types = ['patient', 'parent'];
  user_types: SelectOption[];
  warn_types: string[];
  override: boolean;
  isStaging: boolean;
  loading: boolean;
  @ViewChild('appendKendoDialog', { read: ViewContainerRef }) public appendKendoDialog: any;

  @ViewChild('sideNav', {read: ViewContainerRef}) public sideNav: any;

  public constructor(private showNavService: ShowNavService,
                     private tokenService: TokenService,
                     private router: Router,
                     private userService: UserService,
                     private userViewService: UserViewService,
                     private store: Store<fromRoot.State>,
                     private toastyService: ToastyService,
                     private authenticationService: AuthenticationService,
                     private dailySessionViewService: DailySessionViewService,
                     private dialogService: DialogService,
                     private confirmDeleteService: ConfirmDeleteService,
                     private constantsService: ConstantsService,
                     private procedureViewService: ProcedureViewService) {
    this.router.events.subscribe((e : RouterEvent) => {
      this.navigationInterceptor(e);
    })
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (this.navIsOpen) {
      this.navToggle(event);
      this.navIsOpen = false;
    }
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true
    }
    if (event instanceof NavigationEnd) {
      this.loading = false
    }
    if (event instanceof NavigationCancel) {
      this.loading = false
    }
    if (event instanceof NavigationError) {
      this.loading = false
    }
  }

  private userLogout(): void {
    this.tokenService.remove();
    this.store.dispatch(new ApplicationActions.LogOut());
    const autologout_date = localStorage.getItem(env.session_accessor);
    if (autologout_date) {
      localStorage.removeItem(env.session_accessor);
    }
    if (this.isLoggedIn) {
      this.isLoggedIn.unsubscribe();
    }
    this.userService.currentSubject.next(null);
    this.router.navigate(['/login']);
  }

  logout(event: any): void {
    event.preventDefault();
    let promise: Promise<TherapySession>;

    if (this.therapy_session && this.therapy_session.id != null && this.therapy_session.host == this.logged_in_user.id) {
      if (confirm('The current therapy session has not been marked as complete.')) {
        this.userLogout();
      }
    } else {
      this.userLogout();
    }
  }

  openNav(event: any): void {
    event.stopPropagation();
    this.navIsOpen = true;
    // document.getElementById('sidenav').style.left = '0px';
    this.sideNav.style.display = 'block';
  }

  navToggle(event: any): void {
    event.stopPropagation();
    this.navIsOpen = !this.navIsOpen;
    // document.getElementById('sidenav').style.left = '0px';
    // $('.sidenav').style.display = 'block';
    jQuery('body').toggleClass('disabled');
    jQuery('.dropdown-nav').fadeToggle();
    jQuery('.bodyOverlay').fadeToggle();
    jQuery('.iconbarGroup').toggle();
    jQuery('.closeIcon').toggle();
    jQuery('.toggle-hamburger').addClass('open');
  }

  closeNav(event: any): void {
    if (this.navIsOpen) {
      // document.getElementById('sidenav').style.left = '-250px';
      this.sideNav.style.display = 'none';
      this.navIsOpen = false;
    }
  }

  subscribeToLoggedInUser(): void {
    const logged_in_user = this.userService.currentUserUpdated.getValue();
    if (!logged_in_user || !logged_in_user.id) {
      //   this.userService.getCurrentUser()
      //     .then(
      //       (user) => {
      //         this.logged_in_user = user;
      //   });
      // } else {
      this.subscriptions.push(this.userService.currentUserUpdated.subscribe(
        (user: User) => {
          this.logged_in_user = user;
        }));

      if (env.production) {
        this.isLoggedIn = this.tokenService.$isLoggedIn.subscribe((is_logged_in) => {
          if (!is_logged_in.isLoggedIn && is_logged_in.autoLogout && !this.dialog
            && this.warn_types && this.warn_types.length > 0 && this.warn_types.indexOf(this.logged_in_user.user_type) > -1) {
            this.is_logout = true;
            this.dialog = this.dialogService.open({
              title: 'Reminder',
              content: 'Remember to logout after the session is complete.',
              actions: [
                { text: 'Ok', primary: true }
              ],
              width: 100,
              height: 200,
              minWidth: 100,
              appendTo: this.appendKendoDialog
            });

            this.dialog.result.subscribe((result) => {
              if (result instanceof DialogCloseResult) {
                console.log('close');
              } else {
                console.log('action', result);
              }
              this.store.dispatch(new ApplicationActions.LogIn());
              this.dialog = null;
              this.is_logout = false;

              // this.result = JSON.stringify(result);
            });
          } else if (!is_logged_in.isLoggedIn && !is_logged_in.autoLogout && this.authenticationService.getToken()) {
            this.store.dispatch(new ApplicationActions.LogIn());
          } else if (!is_logged_in.isLoggedIn && !is_logged_in.autoLogout) {
            //TODO: need to figure out what to do here
            console.log('user logged out');
          }
        });
      }
    }
  }

  getToastOptions(message: string, title: string): ToastOptions {
    title = title != null ? title : this.DEFAULT_TITLE;

    return {
      title: title,
      msg: message,
      showClose: true,
      timeout: 5000,
      theme: 'default'
    };
  }

  addToast(message: string, title: string) {
    // Or create the instance of ToastOptions
    const toastOptions = this.getToastOptions(message, title);
    // // Add see all possible types in one shot
    // this.toastyService.info(toastOptions);
    // this.toastyService.success(toastOptions);
    // this.toastyService.wait(toastOptions);
    // this.toastyService.error(toastOptions);
    // this.toastyService.warning(toastOptions);

    switch (title.toLowerCase()) {
      case 'reminder':
        this.toastyService.info(toastOptions);
        break;
    }
  }

  // getNavValue(): Observable<boolean> {
  //   return this.showNavService.showNavSubject.asObservable();
  // }

  private filterUserTypes(): void {
    if (this.user_types && this.user_types.length > 0) {
      const filtered = this.user_types.filter(ut => this.exclude_types.indexOf(ut.value.toLowerCase()) === -1);
      this.warn_types = filtered.map((obj) => {
        return obj.value;
      });
    }
  }

  getChoices(): Promise<boolean> {

    if (!this.constantsService.choices || this.constantsService.choices.size === 0) {
      return this.constantsService.getChoices().then(
        (choices: Map<string, any>) => {
          if (choices && choices.size > 0) {
            console.log(choices);
            this.user_types = choices.get('global').get('relationship_type');
            this.filterUserTypes();
            return true;
          }

        });
    } else {
      this.user_types = this.constantsService.choices.get('global').get('relationship_type');
      this.filterUserTypes();
      return Promise.resolve(true);
    }
  }

  subscribeToTherapySession(): void {
    this.subscriptions.push(this.dailySessionViewService.currentSubject.subscribe(
      (therapy_session: TherapySession) => {

        this.therapy_session = therapy_session;
      }));
  }

  private subscribeToDialogOverride() {
    this.subscriptions.push(this.procedureViewService.overrideDialogSizeSubject.subscribe(
      (override: boolean) => {
        this.override = override;
      }));
  }

  private subscribeConfirmDeleteDialog() {
    this.subscriptions.push(this.confirmDeleteService.overrideDialogSizeSubject.subscribe(
      (override: boolean) => {
        this.override = override;
      }));
  }

  ngOnInit(): void {
    this.navIsOpen = false;
    this.tokenService.$isLoggedIn = this.store.select(fromRoot.selectIsLoggedIn);
    this.user_types = [];
    this.warn_types = [];
    this.isStaging = env.sentry_environment === 'staging';

    this.subscribeToLoggedInUser();
    this.subscribeToTherapySession();
    this.subscribeToDialogOverride();
    this.subscribeConfirmDeleteDialog();
    this.getChoices();

    const therapy_session = this.dailySessionViewService.currentSubject.getValue();
    if (!therapy_session) {

      this.dailySessionViewService.getTherapySession();
    }
    this.subscriptions.push(this.showNavService.showNavUpdated.subscribe(
      (showNav) => {
        setTimeout(() => {
          this.showNav = showNav;
        });
      }
    ));
    this.subscriptions.push(this.userViewService.autoRefresh().subscribe(
      (res) => {

      },
      (err) => {
        const url = this.router.url;
        if (this.ignoreRoutes.indexOf(url) === -1) {
          // if we error then need to go login again
          // need to show the user that the session has expired
          this.tokenService.remove();
          this.router.navigate(['/login']);
        }

      }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
    if (this.isLoggedIn) {
      this.isLoggedIn.unsubscribe();
    }
  }
}
