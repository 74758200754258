import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SessionData} from '../../models/session-data.model';
import {SessionTrial} from '../../models/session-trial.model';
import {ProcedureSession} from '../../models/procedure-session.model';
import {CommonService} from '../../shared/common/common.service';

@Component({
  selector: 'app-duration-trial',
  templateUrl: './duration-trial.component.html',
  styleUrls: ['./duration-trial.component.css']
})
export class DurationTrialComponent implements OnInit {
  @Input() TrialCompType: string;

  @Input() session: ProcedureSession;
  @Input() session_idx: number;
  @Input() trial_idx: number;
  @Input() trial: SessionTrial;
  @Input() title: string;
  @Input() is_readonly: boolean;
  @Input() required: boolean;
  @Input('session_field') field: string;

  @Output('changeDuration') changeDuration = new EventEmitter();

  @ViewChild('trialInputField') public trialInputField: any;

  // hmmused if we go back to the kendo-maskedtextbox
  // mask = '99m90s';
  RADIX = 10;
  editting = false;
  displayVal = '';
  // regex = /((?<sec>[0-9]{1,2})s|(?<min>[0-9]{1,2})(m( (?<sec2>[0-9]{1,2})s)?)?)/g;
  regex = /^([0-9]+h)?[\s]?([0-9]+m)?[\s]?([0-9]+s+$|^[0-9]+$)?/;
  testValue = /^((\d+h+)?(\d+m+)?(\d+s+)?)$|^\d+$/;
  MAX_SECS = 60;
  model: any;
  model_backup: any;
  isValid: boolean;

  readonly_sessions: SessionData[];

  constructor(private cdRef: ChangeDetectorRef, private commonService: CommonService) { }


  private getMatchedValues(val: string){
    let hours = 0, mins = 0, secs = 0;
    val = val.replace(/ /g, '');
    if (!this.testValue.test(val)) {
      return {hours: null, mins: null, secs: null};
    }
    const matches = val.match(this.regex);
    const hour_matched = matches[1];
    const min_matched = matches[2];
    const sec_matched = matches[3];
    if (hour_matched == null && min_matched == null && sec_matched == null) {
      return {hours: null, mins: null, secs: null};
    }
    if (hour_matched != null && hour_matched.includes('h')){
      hours += parseInt(hour_matched.split('h')[0] || '0', this.RADIX);
    }
    if (min_matched != null && min_matched.includes('m')){
      mins += parseInt(min_matched.split('m')[0] || '0', this.RADIX);
    }
    if (sec_matched != null && sec_matched.includes('s')) {
      secs += parseInt(sec_matched.split('s')[0] || '0', this.RADIX)
    } else if (sec_matched != null) {
      // if we got here then the user must've entered a value without a unit
      secs += this.commonService.TryParseInt(sec_matched, 0);
    }
    return {hours: hours, mins: mins, secs: secs};
  }

  // get the total duration in seconds
  private getTotalDuration(): number {
    let totalDuration = 0;
    if (this.session != null && this.session.session_trials != null) {
      const session_trials = this.session.session_trials;

      if (session_trials && session_trials.length > 0) {
        let hours = 0, mins = 0, secs = 0;
        for(const trial of session_trials) {
          if (trial.value != null && typeof trial.value == 'string'){
            const ret_val = this.getMatchedValues(trial.value.replace(/ /g, ''));
            hours += ret_val.hours;
            mins += ret_val.mins;
            secs += ret_val.secs;
          }
        }
        totalDuration = (hours * 3600) + (mins * 60) + secs;
      }
    }
    return totalDuration;
  }

  // get the total duration in seconds
  private getMeanDuration(): number {
    let meanDuration = 0;
    if (this.session != null && this.session.session_trials != null) {
      const session_trials = this.session.session_trials;

      if (session_trials && session_trials.length > 0) {
        let hours = 0, mins = 0, secs = 0;
        let count = 0;
        for(const trial of session_trials) {
          if (trial.value != null && typeof trial.value == 'string'){
            const ret_val = this.getMatchedValues(trial.value);
            hours += ret_val.hours;
            mins += ret_val.mins;
            secs += ret_val.secs;
            count++;
          }
        }
        meanDuration = ((hours * 3600) + (mins * 60) + secs) / count;
      }
    }
    return meanDuration;
  }

  private getDurationString(val: string | number): string {
    if (!val) return null;
    let hours = 0, mins = 0, secs = 0;

    if (typeof val === 'string') {
      const ret_val = this.getMatchedValues(val);
      hours = ret_val.hours;
      mins = ret_val.mins;
      secs = ret_val.secs;
    } else if(typeof val === 'number') {
      secs = val;
    } else {
      // TODO: show error that the duration couldn't be parsed
      console.log('Note: duration could not be parsed');
      return '';
    }

    if (hours == null && mins == null && secs == null) {
      return null;
    }

    // now we check if we have gone over 60s or/and 60m
    if (mins != null && mins >= this.MAX_SECS) {
      hours += Math.floor(mins / this.MAX_SECS);
      mins = mins % this.MAX_SECS;
    }
    if (secs != null && secs >= this.MAX_SECS) {
      if (secs >= this.MAX_SECS * this.MAX_SECS) {
        hours += Math.floor(secs / (this.MAX_SECS * this.MAX_SECS));
        secs = secs % (this.MAX_SECS * this.MAX_SECS);
        if (secs >= this.MAX_SECS) {
          mins += Math.floor(secs / this.MAX_SECS);
          secs = secs % this.MAX_SECS;
        }
      } else {
        mins += Math.floor(secs / this.MAX_SECS);
        secs = secs % this.MAX_SECS;
      }
    }

    let ret_str = '';
    if (hours > 0) {
      ret_str = `${hours}h`;
    }
    if (mins > 0) {
      ret_str = ret_str ?
        `${ret_str} ${mins}m` :
        `${mins}m`;
    }
    if (secs > 0) {
      ret_str = ret_str ?
        `${ret_str} ${secs}s` :
        `${secs}s`;
    } else if (secs === 0) {
      ret_str = ret_str ?
        `${ret_str}` :
        `0s`;
    }
    return ret_str;
  }

  ngOnInit() {
    this.model = this.field == null ?
      this.trial.value :
      this.session[this.field] || this.session[`calc_${this.field}`];
    if (this.trial && this.trial.value) {
      if (!this.field) {
        this.displayVal = this.getDurationString(this.trial.value);
      } else {
        this.displayVal = this.getDurationString(this.session[this.field]);
      }
      this.trial.value = this.displayVal;
      if (!this.field) {
        this.handleBlur(null, true);
      }
    }
    this.model_backup = this.model && this.getDurationString(this.model.replace(/ /g, ''));
    this.isValid = !!this.model;
  }

  handleBlur($event: any, firstLoad?: boolean) {
    this.displayVal = this.model && this.getDurationString(this.model.replace(/ /g, ''));

    if (this.displayVal === this.model_backup) {
      if (this.session[`edit_${this.field}`]) {
        this.session[`edit_${this.field}`] = false;
      }
      this.editting = false;
      this.model = this.model_backup;
      return;
    }

    if (this.displayVal == null) {
      this.changeDuration.emit(null);
      this.isValid = false;
      return;
    }

    let ret_val = {};
    if (this.trial != null) {
      this.trial.value = this.displayVal;
      this.trial.count = -1; // this tells the trialClick function in BaseSessionComponent, this is not using the traditional click version of data entry
      const totalDuration = this.getTotalDuration();
      const totalDurationString = this.getDurationString(totalDuration);
      const meanDuration = this.getMeanDuration();
      const meanDurationString = this.getDurationString(meanDuration);
      ret_val = {
        trial: this.trial,
        trial_idx: this.trial_idx,
        total_duration: totalDurationString,
        mean_duration: meanDurationString
      };
    } else if (this.field != null) {
      this.session[this.field] = this.displayVal;
    }
    ret_val['procedure_session'] = this.session;
    ret_val['procedure_session_idx'] = this.session_idx;
    ret_val['first_load'] = firstLoad;

    this.changeDuration.emit(ret_val);
    this.displayVal ?
      this.model = this.model_backup = this.displayVal :
      this.model = this.model_backup = this.session[`calc_${this.field}`];
    this.isValid = true;
    this.editting = false;
    if (this.session[`edit_${this.field}`]) {
      this.session[`edit_${this.field}`] = false;
    }
  }

  handleClick($event: any) {
    // if we are in readonly mode, don't do anything
    if (this.is_readonly) return;
    this.editting = true;
    this.cdRef.detectChanges();
    this.trialInputField.nativeElement.focus();
  }

}
