import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getValue'
})

/**
 * This pipe returns any list that can be found from an object
 * does not retrieve from Maps or hashes, can obly be in the format:
 * {
 *     key1: [any, any],
 *     key2: [any],
 *     ...
 * }
 */
export class GetValuePipe implements PipeTransform {

  transform(obj: any, key?: any): any {
    if (!obj || key == null || !(key in obj)) return [];
    const ret_obj = obj[key];
    if (ret_obj == null || ret_obj.constructor.name.toLocaleLowerCase() !== 'array') {
      return [];
    }
    return ret_obj;
  }

}
