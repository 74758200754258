export class SkillObjective {
  id: number;
  created: string;
  updated: string;
  _order: number;
  archived: boolean;
  tag: string;
  program: string;
  objective: string;
  created_by: number;
  last_updated_by: number;
  skill: number;
  display_name: string;
  name: string;
  domain_name: string;
  is_in_procedure: boolean;
  first: boolean;
  ltga_name: string;
}
