import { Injectable } from '@angular/core';
import {BaseService} from '../base.service';
import {Note} from '../models/note.model';
import { HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';

@Injectable()
export class NoteService extends BaseService<Note> {

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.note/', Note);

    this.subRoute = '';
    this.date_obj_keys = ['created'];
  }

}
