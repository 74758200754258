import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contains'
})
export class ContainsPipe implements PipeTransform {

  transform(value: any, args?: any[], prop?: string): any {
    if (!value || !args || args.length === 0) {
      return;
    }

    let ret_val: any;
    if (value === Object(value)) {
      // if passed value is an object, use prop
      ret_val = args.find(a => a[prop] == value[prop]);
      // doing == cause we might have a string and need to check against a number
    } else {
      // else look for value directly - we might be looking for a string
      // check the first value of args to see if it's primitives or not
      if (args[0] === Object(args[0])) {
        ret_val = args.find(a => a[prop] == value);
      } else {
        ret_val = args.find(a => a === value);
      }
    }

    return !!ret_val;

  }

}
