import { Injectable } from '@angular/core';
import {BaseService} from '../base.service';
import {AuthenticationService} from '../authentication.service';
import {User} from '../models/user.model';
import {PatientDetails} from '../models/patient-details.model';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import {HttpClient} from '@angular/common/http';
@Injectable()
export class PatientService extends BaseService<PatientDetails> {

  currentPatient: BehaviorSubject<User> = new BehaviorSubject(new User());
  currentPatientId: BehaviorSubject<number> = new BehaviorSubject(null);
  currentPatientDetails: BehaviorSubject<PatientDetails> = new BehaviorSubject(new PatientDetails());
  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.patientdetail/', PatientDetails);
    this.subRoute = '';
    this.date_obj_keys = ['dob', 'enrollment_date'];
  }

  public getData(): Observable<User> {
    return this.currentPatient.asObservable();
  }

}
