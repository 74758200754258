import { Injectable } from '@angular/core';
import {ReinforcementSchedule} from '../models/reinforcement-schedule.model';
import {BaseService} from '../base.service';
import { HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {IBaseService} from '../interfaces/base-interface';
// import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {BehaviorSubject} from 'rxjs';
@Injectable()
export class ReinforcementScheduleService extends BaseService<ReinforcementSchedule> {
  currentSubject: BehaviorSubject<ReinforcementSchedule>;

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.reinforcementschedule/', ReinforcementSchedule);
  }

}
