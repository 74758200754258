import {Base} from './base.model';

export class SkillObjectiveM2M extends Base<SkillObjectiveM2M> {
  id: number;
  domain_id: number;
  domain_name: string;
  objective: string;
  name: string;
  skill_objective: number;
  skill_objective_display: string;
  show_full_desc: boolean;
  ltga_id: number;
  ltga_name: string;

  constructor() {
    super(SkillObjectiveM2M);
  }
}
