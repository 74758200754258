import {Injectable} from '@angular/core'
import {BaseService} from '../base.service'
import {AuthenticationService} from '../authentication.service'
import {UserService} from './user.service'
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http'
import {Auth} from '../models/auth.model'
import {environment as env} from '../../../environments/environment'
import {Observable, interval, throwError} from 'rxjs'
import {switchMap} from 'rxjs/operators'
import * as fromRoot from '../state/reducers'
import {Store} from '@ngrx/store'
import * as ApplicationActions from '../state/application/actions'

@Injectable()
export class TokenService extends BaseService<Auth> {
    auth_api: string = env.auth_api
    payload: object
    getToken: boolean = false
    defaultExpiry: number = env.default_expiry
    $isLoggedIn: Observable<any>

    // allow the refresh to happen 30 seconds before the actual timeout of the token
    private intevalTime = env.refresh_token_timeout - 30

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private store: Store<fromRoot.State>
    ) {
        super(authenticationService, http, '', Auth)
    }

    remove(): void {
        const t = this.authenticationService.getToken()
        if (t) {
            this.authenticationService.clearToken()
        }
        this.authenticationService.clearCookie()
    }

    obtain(username: string, password: string): Promise<Auth> {
        // clearing cookie
        this.authenticationService.clearCookie()
        this.authenticationService.clearToken()
        this.payload = {
            username: username,
            password: password,
            grant_type: 'password'
        }
        this.getToken = true
        const body = new URLSearchParams()
        body.append('username', username)
        body.append('password', password)
        body.append('grant_type', 'password')

        let ret_token: Auth
        return this.http.post<Auth>(this.auth_api, body.toString(), {headers: env.auth_headers}).toPromise()
            .then((res: Auth) => {
                const token = res
                let promise
                ret_token = token
                if (token) {
                    this.store.dispatch(new ApplicationActions.LogIn())

                    this.authenticationService.set(token)
                    promise = this.userService.getCurrentUser()
                } else {
                    promise = Promise.resolve()
                }
                this.getToken = false
                return promise
            }).then(
                (user) => {
                    this.getToken = false
                    if (user) {
                        // do something with user
                    }
                    return ret_token
                })
            .catch(
                (error: any) => {
                    this.getToken = false
                    return throwError(error).toPromise()
                })
    }

    refresh(): Promise<Auth> {
        this.authenticationService.clearCookie()
        const t = this.authenticationService.getToken()
        if (!t) {
            return Promise.reject('no token found')
        } else if (this.authenticationService.isRefreshExpired(t)) {
            return Promise.reject('refresh token has expired')
        }
        this.getToken = true

        let ret_token: Auth
        const body = new URLSearchParams()
        body.append('client_id', env.client_id)
        body.append('client_secret', env.client_secret)
        body.append('grant_type', 'refresh_token')
        body.append('refresh_token', t.refresh_token)
        return this.http.post<Auth>(this.auth_api, body.toString(), {headers: env.auth_headers})
            .toPromise()
            .then((res: Auth) => {
                const token = res
                let promise
                ret_token = token
                if (token) {
                    this.authenticationService.set(token)
                    promise = this.userService.getCurrentUser()
                } else {
                    promise = Promise.resolve()
                }
                this.getToken = false
                return promise
            }).then(
                (user) => {
                    if (user) {
                        // do something with user
                    }
                    this.getToken = false
                    return ret_token
                })
            .catch(
                (error: any) => {
                    this.getToken = false
                    return throwError(error.error || 'Server Error').toPromise()
                })
    }

    autoRefresh(): Observable<Auth> {
        return interval(this.intevalTime).pipe(switchMap(() => {
            const t = this.authenticationService.getToken()
            if (!t) {
                return Promise.reject('no token found')
            } else if (this.authenticationService.isRefreshExpired(t)) {
                return Promise.reject('refresh token has expired')
            } else if (!this.authenticationService.accessExpiresWithin(this.defaultExpiry)) {
                return Promise.resolve(t)
            }
            this.getToken = true
            let ret_token: Auth
            const body = new URLSearchParams()
            body.append('client_id', env.client_id)
            body.append('client_secret', env.client_secret)
            body.append('grant_type', 'refresh_token')
            body.append('refresh_token', t.refresh_token)
            return this.http.post<Auth>(this.auth_api, body.toString(), {headers: env.auth_headers})
                .toPromise()
                .then((res: Auth) => {
                    const token = res
                    let promise
                    ret_token = token
                    if (token) {

                        this.authenticationService.set(token)
                        promise = this.userService.getCurrentUser()
                    } else {
                        promise = Promise.resolve()
                    }
                    this.getToken = false
                    return promise
                }).then(
                    (user) => {
                        if (user) {
                            // do something with user
                        }
                        this.getToken = false
                        return ret_token
                    })
                .catch(
                    (error: any) => {
                        this.getToken = false
                        return throwError(error.error || 'Server Error').toPromise()
                    })
        }))
    }


}
