import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {BaseService} from '../base.service';
import {ParentDetail} from '../models/parent-detail.model';
import {BehaviorSubject} from 'rxjs';
import {User} from '../models/user.model';
import {IBaseService} from '../interfaces/base-interface';

@Injectable()
export class ParentDetailService extends BaseService<ParentDetail> {
  // TODO: need to change the usage of currentParentDetail to currentSubject
  currentSubject: BehaviorSubject<ParentDetail>;
  // currentParent: BehaviorSubject<User> = new BehaviorSubject(new User());
  currentParentDetail: BehaviorSubject<ParentDetail> = new BehaviorSubject(new ParentDetail());
  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.parentdetail/', ParentDetail);
    this.subRoute = '';
    this.date_obj_keys = ['dob'];
  }

}
