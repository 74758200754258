import { Injectable } from '@angular/core';
import {BaseService} from '../base.service';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {Assessment} from '../models/assessment.model';

@Injectable()
export class AssessmentService extends BaseService<Assessment> {

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.assessment/', Assessment);
    this.subRoute = '';
    this.date_obj_keys = ['date', 'date_completed', 'next_due'];
  }

}
