import {Base} from './base.model';
import {SessionTrial} from './session-trial.model';

export class PromptFadingStep extends Base<PromptFadingStep> {
  id: number;
  _order: number;
  step: string;
  procedure: number;
  step_abbr: string;
  edit: boolean;
  step_display: string;
  mouseentered: boolean;
  session_trials: SessionTrial[];
  constructor() {
    super(PromptFadingStep);
    // this.step = '';
    // this.step_abbr = '';

  }
}
