export class Skill {
  id: number;
  created: string;
  updated: string;
  _order: number;
  archived: boolean;
  name: string;
  created_by: number;
  last_updated_by: number;
  domain: number;
}
