import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterClients'
})
export class FilterClientsPipe implements PipeTransform {

  transform(clients, name?: string, regions?: string[], staff?: number[]) {
    let result = clients;

    if (name) {
      const text = name.trim().toLowerCase().replace(/ +/g, ' ');
      const data = text.split(' ');
      switch (data.length) {
        case 1: {
          result = result.filter(val => val['first_name'].toLowerCase().includes(data[0]) || val['last_name'].toLowerCase().includes(data[0]));
          break;
        };
        case 2: {
          result = result.filter(val => (val['first_name'].toLowerCase() == data[0] && val['last_name'].toLowerCase() == data[1]) || (val['first_name'].toLowerCase() == data[1] && val['last_name'].toLowerCase() == data[0]));
          break;
        };
      };
    }

    if (regions.length) {
      result = result.filter(val => regions.includes(val.region));
    }

    if (staff.length) {
      result = result.filter(val => {
        return staff.some(item => {
          return val.parents_repr.some(el => el.user === +item);
        })
      })
    }

    return result;
  }
}
