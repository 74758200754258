import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { BaseComponent } from '../../shared/base/base.component';
import { ToastyConfig, ToastyService } from 'ng2-toasty';
import { GlobalService } from '../../core/services/global.service';
import { CurrentFormService } from '../../core/services/current-form.service';
import { UserViewService } from '../../shared/services/user-view.service';
import { ConstantsService } from '../../core/services/constants.service';
import { AssessmentService } from '../../core/services/assessment.service';
import { PatientService } from '../../core/services/patient.service';
import { ShowNavService } from '../../core/services/show-nav.service';
import { User } from '../../core/models/user.model';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { SelectOption } from '../../core/models/select-option.model';
import { Assessment } from '../../core/models/assessment.model';
import { DocumentService } from '../../core/services/document.service';
import { Document } from '../../core/models/document.model';
import { DocumentViewService } from '../../shared/services/document-view.service';
import { HttpErrorCodes } from '../../core/shared/enum';
import { ConfirmDeleteService } from 'app/core/services/confirm-delete.service';
import { PreviewDocumentComponent } from '../../shared/preview-document/preview-document.component';
import { CommonService } from '../../core/shared/common/common.service';

declare var jQuery: any;

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent extends BaseComponent implements OnInit, OnDestroy {

  documents: Document[];
  patient: User;
  dialogService: DialogService;
  document_types: SelectOption[];
  document_data_types: SelectOption[];
  document_type: string = 'report';
  loading: boolean;
  failed: boolean;
  public documentViewService: DocumentViewService;

  constructor(dialogService: DialogService,
    private patientService: PatientService,
    toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    constantsService: ConstantsService,
    private commonSerivce: CommonService,
    private globalService: GlobalService,
    userViewService: UserViewService,
    private documentService: DocumentService,
    private showNavService: ShowNavService,
    currentFormService: CurrentFormService,
    documentViewService: DocumentViewService,
    private confirmDeleteService: ConfirmDeleteService) {
    super(constantsService, userViewService, dialogService, documentViewService, currentFormService, toastyService);
    this.documentViewService = documentViewService;

  }

  addToast(message: string, title: string) {
    // Or create the instance of ToastOptions
    const toastOptions = this.getToastOptions(message, title);
    // // Add see all possible types in one shot
    // this.toastyService.info(toastOptions);
    // this.toastyService.success(toastOptions);
    // this.toastyService.wait(toastOptions);
    // this.toastyService.error(toastOptions);
    // this.toastyService.warning(toastOptions);

    switch (title.toLowerCase()) {
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
    }
  }

  getDocuments(): void {
    // only get report type documents now
    // this.documentService.getByParamObjPromise({patient: this.patient.id.toString()})
    this.loading = true
    this.failed = false
    this.documentService.getByParamObjPromise({ patient: this.patient.id.toString(), type: this.document_type })
      .then((documents: Document[]) => {
        // const doc_list = [];
        // for (const doc of documents) {
        //   if (doc.type != 'comm_book' && doc.type != 'consent_form' && doc.type != 'enrollment_form') {
        //     doc_list.push(doc);
        //   }
        // }
        // this.documents = doc_list;
        this.documents = documents;
        this.loading = false;
      })
      .catch((err) => {
        // TODO: show alert that something happened
        console.log(err);
        // throw err;
        this.loading = false
        this.failed = true
        this.addToast('Failed to load documents. Please try again.', 'Error');
      })
  }

  openConfirmDelete(idx: number) {
    const content = `Are you sure you want to delete document ${this.documents[idx].name}?`
    this.confirmDeleteService.openConfirmDialog(content)
      .subscribe(res => {
        if (res) {
          this.remove(idx);
        }
      })
  }

  openPreview(document) {

    this.documentViewService.currentSubject.next(document);

    const jq_height = jQuery(window).height();
    const height = jq_height < window.innerHeight ? jq_height : window.innerHeight;
    this.dialogService.open({
      title: document.filename,
      content: PreviewDocumentComponent,
      height
    });
  }

  remove(idx: number): void {
    if (idx == null) {
      return
    }
    // need to find out if we have to archive it or just blatently remove
    this.documentService.removeObj(this.documents[idx])
      .then((res) => {
        this.documents.splice(idx, 1);
        this.addToast('Document was removed from patient.', 'Success');
      })
      .catch((err) => {
        if (typeof err === 'object' && err.status === HttpErrorCodes.UNAUTHORIZED) {
          this.addToast('You do not have permission to remove this document...', 'Error');
        } else {
          this.addToast('Could not remove document from patient, please try again...', 'Error');
        }
        throw err;
      });
  }


  subscribeToPatient(): void {
    this.subscriptions.push(this.patientService.currentPatient.subscribe(
      (patient: User) => {
        this.patient = patient;
        // checking for != null as this checks for null and undefined
        if (this.patient && this.patient.id != null) {
          this.getDocuments();
        }
        // if (this.patient.dob_date) {
        //   // now we get the calculated age
        //   const now = moment();
        //   const age = moment(this.patient.dob_date);
        //   const diff = now.diff(age, 'years');
        //   this.patient.calculated_age = diff.toString();
        // }
      }, (err) => {
        this.loading = false;
      }));
  }

  subscribeToChoices(): void {
    this.subscriptions.push(this.choicesSubject.subscribe(
      (choices: Map<string, any>) => {
        if (choices.size > 0 && choices.has('document')) {
          this.document_types = choices.get('document').get('type');
          this.document_data_types = choices.get('document').get('data_type');
        }
        // do something with assessments
      }, (err) => {
        this.loading = false;
      }));
  }

  subscribeToRefreshSubjects(): void {
    this.subscriptions.push(this.refreshSubjects.subscribe(
      (refresh: boolean) => {
        if (refresh) {
          this.loading = true;
          this.getDocuments();
        }
      }, (err) => {
        this.loading = false;
      }));
  }

  ngOnInit() {
    this.loading = true;
    this.subscriptions = [];
    this.publish_statuses = [];
    this.subscribeToPatient();
    this.getChoices();
    this.getHosts();
    this.subscribeToChoices();
    this.subscribeToRefreshSubjects();
    this.showNavService.showButtons.next(false);
  }

  ngOnDestroy() {
    this.unsubscribe();
    this.showNavService.showButtons.next(true);
  }

}
