import {Base} from './base.model';
import {PatientDetails} from './patient-details.model';

export class UserRelationship {
  relationship_type: string;
  relationship_type_description: string;
  user: number;
}

export class User extends Base<User> {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  date_joined: string;
  is_active: boolean;
  created: string;
  updated: string;
  archived: boolean;
  user_type: string;
  gender: string;
  created_by: number;
  last_updated_by: number;
  centre: number;
  groups: number[];
  guardian_ids: string[];
  parents_repr: UserRelationship[];
  children_repr: UserRelationship[];
  has_alert_fields: boolean;
  detail_cls: string;
  country: string;
  phone: string;
  mobile: string;
  postcode: string;
  state: string;
  street: string;
  suburb: string;
  enrollment_date: string;
  program_supervisor: User;
  sbt: User;
  dob: string;
  dob_date: Date;
  age: number;
  can_invite: boolean;
  can_login: boolean;
  region: number;
  update_user: boolean;
  is_hidden: boolean;
  program: number;
  invite_sent: boolean;
  update_child_link: boolean;

  // helper for UI
  relationship_type: string;
  relationship_type_desc: string;
  display_name: string;
  // TODO: need to change PatientDetails to ClientDetails
  details: PatientDetails;

  constructor(
    id?: number,
    username?: string,
    email?: string,
    groups?: number[],
    profile_image?: string,
    location?: string,
    description?: string,
    default_language?: string,
    languages?: string[],
    role?: string,
    first_name?: string,
    last_name?: string,
    password?: string
  ) {
    super(User);

    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.username = username;
    this.email = email;
    this.groups = groups;
    this.password = password;

    //this.setDisplayName();
  }

  setDisplayName() {
    this.display_name = this.last_name ? this.last_name + ', ' + this.first_name : '';
  }
}
