import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getText'
})
export class GetTextPipe implements PipeTransform {

  transform(value: any, args?: any[], prop?: string, text_prop?: string): any {
    if (!value || !args || args.length === 0) {
      return;
    }

    let ret_val: any;
    if (value === Object(value)) {
      // if passed value is an object or an array, use prop
      if (!value.hasOwnProperty('length')) {
        ret_val = args.find(a => a[prop] == value[prop]);
      } else {
        // we get there if we have an array
        ret_val = {};
        let ret_str: string = '';
        for (let i = 0; i < value.length; i++) {
          const val = prop == null ?
            args.find(a => a == value[i]) :
            args.find(a => a[prop] == value[i]);
          if (val) {
            ret_str += (prop == null ? val : val[prop]) + ', ';
          }
        }
        ret_str = ret_str.trim().slice(0, -1);
        const key = text_prop == null ? 'text' : text_prop;
        ret_val[key] = ret_str;
      }

      // doing == cause we might have a string and need to check against a number
    } else {
      // else look for value directly - we might be looking for a string
      // check the first value of args to see if it's primitives or not
      if (args[0] === Object(args[0])) {
        ret_val = args.find(a => a[prop] == value);
      } else {
        ret_val = args.find(a => a === value);
      }
    }

    return !ret_val ? '' : !text_prop ? ret_val.text : ret_val[text_prop];
  }

}
