import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]'
})
export class NumbersOnlyDirective {
  @Input() numOnly: any;
  // private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
  private regex_with_period: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
  private num_only_regex: RegExp = new RegExp(/^-?[0-9]+$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', '-' ];

  constructor(private el: ElementRef) { }

  @HostListener('keydown', [ '$event' ])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    let regex: RegExp;
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    regex = this.numOnly ? this.num_only_regex : this.regex_with_period;

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(regex)) {
      event.preventDefault();
    }
  }
}
