import {ErrorHandler, ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {INITIALIZER, ModuleOptions, OPTIONS} from './tokens';
import {ErrorsHandler} from '../../errors/handler/errors-handler';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {SentryErrorInterceptor} from '../../interceptors/sentry.interceptor';
import {init} from '@sentry/browser';

/**
 * Initializer function to setup sentry logging.
 *
 * @param - The module options
 * @returns - A promise for waiting to be resolved
 */
export function initializer(options: ModuleOptions): void {
  // configure sentry's browser library
  if (options.enabled) {
    // show report dialog
    if (options.dialog) {
      options.sentry.beforeSend = event => {
        if (event.exception) {
          console.log(event.exception);
          // showReportDialog(
          //   typeof options.dialog === 'object' ? options.dialog : undefined,
          // )
        }
        return event
      }
    }

    init(options.sentry)
  }
}

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: []
})
export class SentryModule {
  public static forRoot(options: ModuleOptions): ModuleWithProviders {
    return {
      ngModule: SentryModule,
      providers: [
        {
          provide: OPTIONS,
          useValue: options,
        },
        {
          provide: INITIALIZER,
          useFactory: initializer,
          deps: [OPTIONS],
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SentryErrorInterceptor,
          deps: [OPTIONS, INITIALIZER],
          multi: true,
        },
      ],
    }
  }
}
