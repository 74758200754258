import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getProcItem'
})
export class GetProcItemPipe implements PipeTransform {

  transform(value: any, args?: any[]): string {
    if (!value || !args || !args.length) {
      return;
    }

    let ret_val: string;
    ret_val = args.find(a => a.item === value.proc_item)['_item_name'];
    return ret_val;

  }

}
