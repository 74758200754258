import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserService} from './services/user.service';
import {TokenService} from './services/token.service';
import {AccessGuard} from './guards/access.guard';
import {AuthenticationService} from './authentication.service';
import {PatientService} from './services/patient.service';
import {ConstantsService} from './services/constants.service';
import {ShowNavService} from './services/show-nav.service';
import {StartupModule} from './shared/startup/startup.module';
import {GetFullNamePipe} from './pipes/get-full-name.pipe';
import {GlobalService} from './services/global.service';
import {AssessmentService} from './services/assessment.service';
import {ServicePlanService} from './services/service-plan.service';
import {ParentDetailService} from './services/parent-detail.service';
import {AuthGroupService} from './services/auth-group.service';
import {ConfirmDeleteService} from './services/confirm-delete.service';
import {GetTextPipe} from './pipes/get-text.pipe';
import {ToastyModule} from 'ng2-toasty';
import {ProcedureService} from './services/procedure.service';
import {DomainService} from './services/domain.service';
import {SkillService} from './services/skill.service';
import {SkillObjectiveService} from './services/skill-objective.service';
import {NoteService} from './services/note.service';
import {ReinforcementScheduleService} from './services/reinforcement-schedule.service';
import {NumTrialService} from './services/num-trial.service';
import {InitialTacticService} from './services/initial-tactic.service';
import {DataSheetService} from './services/data-sheet.service';
import {ShortenTextPipe} from './pipes/shorten-text.pipe';
import {GetListNamesPipe} from './pipes/get-list-names.pipe';
import {CommunicationService} from './services/communication.service';
import {SessionService} from './services/session.service';
import {GetPatientNamePipe} from './pipes/get-patient-name.pipe';
import {CurrentFormService} from './services/current-form.service';
import {DocumentService} from './services/document.service';
import {ProcedureStepService} from './services/procedure-step.service';
import {PromptFadingStepService} from './services/prompt-fading-step.service';
import {ProcedureSessionService} from './services/procedure-session.service';
import {TherapySessionService} from './services/therapy-session.service';
import {SessionTrialService} from './services/session-trial.service';
import {PatientDetailService} from './services/patient-detail.service';
import {BehaviourReductionService} from './services/behaviour-reduction.service';
import {DownloadLinkDirective} from './directives/download-link.directive';
import {CommonService} from './shared/common/common.service';
import {ContentTypeService} from './services/content-type.service';
import {NumbersOnlyDirective} from './directives/numbers-only.directive';
import {SdResponseService} from './services/sd-response.service';
import {ProcItemService} from './services/proc-item.service';
import {MasteryCriteriaService} from './services/mastery-criteria.service';
import {ContainsPipe} from './pipes/contains.pipe';
import {KeysPipe} from './pipes/keys.pipe';
import {CheckNextPipe} from './pipes/check-next.pipe';
import {RangePipe} from './pipes/range.pipe';
import {HttpClientModule} from '@angular/common/http';
import {MustMatchDirective} from './directives/must-match.directive';
import {PromptStepsComponent} from './components/prompt-steps/prompt-steps.component';
import {GetSubListPipe} from './pipes/get-sub-list.pipe';
import {GetObjectPipe} from './pipes/get-object.pipe';
import {GetProcNamePipe} from './pipes/get-proc-name.pipe';
import {GetProcItemPipe} from './pipes/get-proc-item.pipe';
import {DurationTrialComponent} from './components/duration-trial/duration-trial.component';
import {GetValuePipe} from './pipes/get-value.pipe';
import {ToFormatPipe} from './pipes/to-format.pipe';
import {GetObjectFromArrayPipe} from './pipes/get-object-from-array.pipe';
import {ErrorsComponent} from './errors/component/errors.component';
import {ErrorsModule} from './errors/errors.module';
import {ShowHidePasswordDirective} from './directives/show-hide-password.directive';
import {FilterClientsPipe} from './pipes/filter-clients.pipe';
import {CutMultilineTextDirective} from './directives/cut-multiline-text.directive';
import {SortByDatePipe} from './pipes/sort-by-date.pipe';
import {DeviceDetectorModule} from './device-detector/device-detector.module';

// import {RequiredDirective, RequiredValidatorDirective} from './directives/required.directive';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StartupModule,
        ErrorsModule,
        ToastyModule.forRoot(),
        DeviceDetectorModule
    ],
    declarations: [
        GetFullNamePipe,
        GetTextPipe,
        ShortenTextPipe,
        GetListNamesPipe,
        GetPatientNamePipe,
        DownloadLinkDirective,
        NumbersOnlyDirective,
        ContainsPipe,
        KeysPipe,
        CheckNextPipe,
        RangePipe,
        MustMatchDirective,
        ShowHidePasswordDirective,
        CutMultilineTextDirective,
        GetSubListPipe,
        GetObjectPipe,
        GetValuePipe,
        ToFormatPipe,
        GetObjectFromArrayPipe,
        FilterClientsPipe,
        GetProcNamePipe,
        GetProcItemPipe,
        SortByDatePipe
        // RequiredDirective
    ],
    providers: [
        UserService,
        TokenService,
        PatientService,
        AccessGuard,
        AuthenticationService,
        ConstantsService,
        ShowNavService,
        GlobalService,
        AssessmentService,
        ServicePlanService,
        ParentDetailService,
        AuthGroupService,
        ProcedureService,
        DomainService,
        SkillService,
        SkillObjectiveService,
        NoteService,
        InitialTacticService,
        ReinforcementScheduleService,
        NumTrialService,
        DataSheetService,
        CommunicationService,
        SessionService,
        CurrentFormService,
        DocumentService,
        ProcedureStepService,
        PromptFadingStepService,
        ProcedureSessionService,
        TherapySessionService,
        SessionTrialService,
        PatientDetailService,
        BehaviourReductionService,
        CommonService,
        ContentTypeService,
        SdResponseService,
        ProcItemService,
        MasteryCriteriaService,
        ConfirmDeleteService,
    ],
    exports: [
        GetFullNamePipe,
        GetTextPipe,
        ToastyModule,
        ShortenTextPipe,
        GetListNamesPipe,
        GetPatientNamePipe,
        DownloadLinkDirective,
        NumbersOnlyDirective,
        ContainsPipe,
        KeysPipe,
        CheckNextPipe,
        RangePipe,
        MustMatchDirective,
        GetSubListPipe,
        GetObjectPipe,
        GetValuePipe,
        ToFormatPipe,
        GetObjectFromArrayPipe,
        GetProcNamePipe,
        GetProcItemPipe,
        ShowHidePasswordDirective,
        FilterClientsPipe,
        CutMultilineTextDirective,
        SortByDatePipe
    ]
})
export class CoreModule {
}
