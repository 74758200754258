import {Behaviour} from './behaviour.model';
import {BaseDetail} from './base-detail.model';
import {Document} from './document.model'

export class PatientDetails extends BaseDetail {
  allergies: string;
  comm_profile: any[];
  comm_profile_desc: string;
  diagnosis: string;
  enrollment_date: string;
  enrollment_date_date: Date;
  goals: string;
  medical_information: string;
  potential_reinforcers: string;
  safety_information: any[];
  safety_information_desc: string;
  siblings: string;
  status: string;
  toileting: string;
  toileting_desc: string;
  behaviours: Behaviour[];
  dob: string;
  dob_date: Date;
  age: number;
  client_id: string;
  is_lead: boolean;
  last_updated_by: number;
  enrollment_form: Document;
  consent_form: Document;
  user: number;
  program: string;
  lives_with: string;
  school: string;
}
