import {Directive, ElementRef, Renderer2} from '@angular/core';
import {Renderer3} from '@angular/core/src/render3/interfaces/renderer';

@Directive({
  selector: '[appShowHidePassword]'
})
export class ShowHidePasswordDirective {
  private _shown = false;
  protected _hidePasswordClass: string[] = ['fa', 'fa-eye-slash'];
  protected _showPasswordClass: string[] = ['fa', 'fa-eye'];
  protected _showHideIcon: HTMLElement;

  constructor(private el: ElementRef, public renderer: Renderer2) {
    this.setup();
  }

  toggle(span: HTMLElement) {
    this._shown = !this._shown;
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      // span.innerHTML = 'Hide password';
      this._showHideIcon.classList.remove(...this._showPasswordClass);
      this._showHideIcon.classList.add(...this._hidePasswordClass);
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      // span.innerHTML = 'Show password';
      this._showHideIcon.classList.remove(...this._hidePasswordClass);
      this._showHideIcon.classList.add(...this._showPasswordClass);
    }
  }

  setup() {
    if (!this.el) return;
    const parent = this.el.nativeElement.parentNode;
    const span = document.createElement('span');
    span.classList.add('show-password-wrapper');
    // span.innerHTML = `Show password`;
    this._showHideIcon = document.createElement('i');
    this._showHideIcon.classList.add(...this._showPasswordClass);
    span.appendChild(this._showHideIcon);
    span.addEventListener('click', (event) => {
      this.toggle(span);
    });
    this.renderer.setAttribute(span, 'data-cy', 'show-hide-password');
    parent.appendChild(span);
  }
}
