import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {PatientService} from '../../core/services/patient.service';
import {UserService} from '../../core/services/user.service';
import {ConstantsService} from '../../core/services/constants.service';
import {GlobalService} from '../../core/services/global.service';
import {AssessmentService} from '../../core/services/assessment.service';
import {Assessment} from '../../core/models/assessment.model';
import {User} from '../../core/models/user.model';
import {SelectOption} from '../../core/models/select-option.model';
import {UserViewService} from '../../shared/services/user-view.service';
import {ToastyConfig, ToastyService} from 'ng2-toasty';
import {DialogService} from '@progress/kendo-angular-dialog';
import {HttpErrorCodes} from '../../core/shared/enum';
import {CurrentFormService} from '../../core/services/current-form.service';
import {ShowNavService} from '../../core/services/show-nav.service';
import {Note} from '../../core/models/note.model';
import {AssessmentViewService} from '../../shared/services/assessment-view.service';
import {DocumentViewService} from '../../shared/services/document-view.service';
import {Document} from '../../core/models/document.model';
import {ContentTypeService} from '../../core/services/content-type.service';
import { ConfirmDeleteService } from 'app/core/services/confirm-delete.service';

@Component({
  selector: 'app-assessments',
  templateUrl: './assessments.component.html',
  styleUrls: ['./assessments.component.css']
})
export class AssessmentsComponent extends BaseComponent implements OnInit, OnDestroy {

  assessments: Assessment[];
  patient: User;
  // new_assessment: Assessment;
  assessment_types: SelectOption[];
  assessment_status: SelectOption[];
  dialogService: DialogService;
  add_assessment: boolean = false;
  assessmentViewService: AssessmentViewService;
  loadingDocs: boolean[];

  loading: boolean;

  constructor(dialogService: DialogService,
              private patientService: PatientService,
              toastyService: ToastyService,
              private toastyConfig: ToastyConfig,
              constantsService: ConstantsService,
              private globalService: GlobalService,
              userViewService: UserViewService,
              private assessmentService: AssessmentService,
              private showNavService: ShowNavService,
              currentFormService: CurrentFormService,
              assessmentViewService: AssessmentViewService,
              public documentViewService: DocumentViewService,
              public contentTypeService: ContentTypeService,
              private confirmDeleteService: ConfirmDeleteService) {
    super(constantsService, userViewService, dialogService, assessmentViewService,
      currentFormService, toastyService, contentTypeService);
    this.assessmentViewService = assessmentViewService;
  }

  addToast(message: string, title: string) {
    // Or create the instance of ToastOptions
    const toastOptions = this.getToastOptions(message, title);
    // // Add see all possible types in one shot
    // this.toastyService.info(toastOptions);
    // this.toastyService.success(toastOptions);
    // this.toastyService.wait(toastOptions);
    // this.toastyService.error(toastOptions);
    // this.toastyService.warning(toastOptions);

    switch (title.toLowerCase()) {
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
    }
  }

  save(idx: number): void {
    console.log(idx);
    if (this.assessments && this.assessments[idx].name) {
      // this.new_assessment.patient = this.patient.id;
      this.assessmentService.save(this.assessments[idx])
        .then((res: Assessment) => {
          this.assessments[idx] = res;

          this.addToast('Success', 'Assessment was saved.');
        })
        .catch((err) => {
          this.addToast('Error', 'Could not save assessment, please try again...');
          throw err;
        });

    }
  }

  openConfirmDelete(idx: number) {
    const content = `Are you sure you want to delete assessment ${this.assessments[idx].name}?`
    this.confirmDeleteService.openConfirmDialog(content)
      .subscribe(res => {
        if (res) {
          this.remove(idx);
        }
      })
  }

  remove(idx: number): void {
    if (idx == null) {
      return
    }
    // need to find out if we have to archive it or just blatently remove
    this.assessmentService.removeObj(this.assessments[idx])
      .then((res) => {
        this.assessments.splice(idx, 1);
        this.addToast('Assessment was deleted.', 'Success');
      })
      .catch((err) => {
        if (typeof err === 'object' && err.status === HttpErrorCodes.UNAUTHORIZED) {
          this.addToast('You do not have permission to remove this assessment...', 'Error');
        } else {
          this.addToast('Could not delete assessment, please try again...', 'Error');
        }
        throw err;
      });
  }

  getAssessments(): void {
    this.assessmentService.getByParamPromise(this.patient.id.toString(), 'patient')
      .then((assessments: Assessment[]) => {
        this.assessments = assessments;
        this.loading = false;
      })
      .catch((err) => {
        // TODO: show alert that something happened
        console.log(err);
        this.loading = false;
        throw err;
      })
  }

  subscribeToPatient(): void {
    this.subscriptions.push(this.patientService.currentPatient.subscribe(
      (patient: User) => {
        this.patient = patient;
        // checking for != null as this checks for null and undefined
        if (this.patient && this.patient.id != null) {
          this.getAssessments();
        }
        // if (this.patient.dob_date) {
        //   // now we get the calculated age
        //   const now = moment();
        //   const age = moment(this.patient.dob_date);
        //   const diff = now.diff(age, 'years');
        //   this.patient.calculated_age = diff.toString();
        // }
      }, (err) => {
        this.loading = false;
        // TODO: maybe should go back to the list of patients, not sure
      }));
  }

  subscribeToChoices(): void {
    this.subscriptions.push(this.choicesSubject.subscribe(
      (choices: Map<string, any>) => {
        if (choices && choices.has('assessment')) {
          this.assessment_types = choices.get('assessment').get('type');
          this.assessment_status = choices.get('assessment').get('status');
          this.publish_statuses = choices.get('global').get('publish_status');
        }
      }, (err) => {
        this.loading = false;
      }));
  }

  subscribeToFiltered(): void {
    this.subscriptions.push(this.assessmentViewService.currentSubjectsFiltered.subscribe(
      (filtered: Assessment[]) => {
        this.assessments = filtered;
      }, (err) => {
        this.loading = false;
      }));
  }

  subscribeToAssessments(): void {
    this.subscriptions.push(this.assessmentViewService.currentSubjects.subscribe(
      (filtered: Assessment[]) => {
        this.assessments = filtered;
      }, (err) => {
        this.loading = false;
      }));
  }

  subscribeToRefreshSubjects(): void {
    this.subscriptions.push(this.refreshSubjects.subscribe(
      (refresh: boolean) => {
        if (refresh) {
          this.loading = true;
          this.getAssessments();
        }
      }, (err) => {
        this.loading = false;
      }));
  }

  ngOnInit() {
    this.loading = true;
    // this.new_assessment = new Assessment();
    this.subscriptions = [];
    this.document_type = 'assessment';
    this.loadingDocs = [];
    this.publish_statuses = [];
    this.subscribeToPatient();
    this.getChoices();
    this.getHosts();
    this.subscriptions.push(this.getAllUsers().subscribe((users) => users));
    this.subscribeToChoices();
    this.subscribeToFiltered();
    this.subscribeToAssessments();
    this.subscribeToRefreshSubjects();
    this.showNavService.showButtons.next(false);
  }

  ngOnDestroy() {
    this.unsubscribe();
    this.showNavService.showButtons.next(true);
  }


}
