import {SkillObjective} from './skill-objective.model';
import {Base} from './base.model';
import {SkillObjectiveM2M} from './skill-objective-m2m.model';


export class ServicePlan extends Base<ServicePlan> {
  id: number;
  patient: number;
  date_6m_check: string;
  date_12m_check: string;
  created: string;
  updated: string;
  archived: boolean;
  name: string;
  date_implemented: string;
  date_implemented_date: Date;
  therapist_hpw: string;
  sbt_hpw: string;
  ps_hpm: string;
  slp_hpw: string;
  goals: string;
  status: string;
  created_by: number;
  last_updated_by: number;
  skill_objectives: number[];
  developed_domains: number[];
  focus_domains: number[];
  assessments: number[];
  skill_objectives_display: SkillObjective[];
  skill_objectives_m2m: SkillObjectiveM2M[];
  user_can_send_for_review: boolean;
  user_can_mark_reviewed: boolean;
  approval_status_display: string;
  approval_status: string;

  constructor() {
    super(ServicePlan);
  }
}
