import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-program-supervision',
  templateUrl: './program-supervision.component.html',
  styleUrls: ['./program-supervision.component.css']
})
export class ProgramSupervisionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
