
import {BehaviorSubject, Observable} from 'rxjs';
import {Procedure} from '../../core/models/procedure.model';
import {TemplateRef, ViewContainerRef} from '@angular/core';

export abstract class IBaseViewService<T> {
  currentSubject: BehaviorSubject<any>;
  currentSubjects: BehaviorSubject<T[]>;
  currentSubjectType: BehaviorSubject<string>;
  currentTitle?: BehaviorSubject<string>;
  currentSubjectParent?: BehaviorSubject<any>;
  abstract save(): Promise<any>;
  abstract update(): Promise<any>;
  abstract getByPatient(id: string): Promise<T[]>;
  abstract getSubjects(): void;
  abstract search?(status?: string, search_text?: string): Promise<T[]>;
  abstract searchObservable?(status: string, search_text: string): Observable<T[]>;
  abstract getById(id: number): Promise<T>;
  // abstract incrementPhaseNumber?(): void;
  abstract incrementPhaseNumberDialog?(dialogTemplate?: TemplateRef<any>):  Observable<any> | void;
}
