import { Injectable } from '@angular/core';
import {IBaseService} from '../interfaces/base-interface';
import {BaseService} from '../base.service';
import {BehaviorSubject} from 'rxjs';
import { HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {InitialTactic} from '../models/initial-tactic.model';

@Injectable()
export class InitialTacticService extends BaseService<InitialTactic> {
  currentSubject: BehaviorSubject<InitialTactic>;

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.initialtactic/', InitialTactic);
  }

}
