import { Injectable } from '@angular/core';
import {BaseService} from '../base.service';
import {TherapySession} from '../models/therapy-session.model';
import {AuthenticationService} from '../authentication.service';
import { HttpClient} from '@angular/common/http';

@Injectable()
export class TherapySessionService extends BaseService<TherapySession> {

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.therapysession/', TherapySession);
    this.subRoute = '';
    this.date_obj_keys = ['date'];
  }

}
