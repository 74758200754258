import {
  AfterViewChecked,
  Component, HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ProcedureViewService} from '../services/procedure-view.service';
import {PatientService} from '../../core/services/patient.service';
import {Procedure} from '../../core/models/procedure.model';
import {User} from '../../core/models/user.model';
import {BehaviorSubject, Subscription} from 'rxjs/index'
import {ServicePlanViewService} from '../services/service-plan-view.service';
import {ServicePlan} from '../../core/models/service-plan.model';
import {SkillObjectiveService} from '../../core/services/skill-objective.service';
import {SkillObjective} from '../../core/models/skill-objective.model';
import {ConstantsService} from '../../core/services/constants.service';
import {SelectOption} from '../../core/models/select-option.model';
import {ReinforcementSchedule} from '../../core/models/reinforcement-schedule.model';
import {InitialTactic} from '../../core/models/initial-tactic.model';
import {NumTrial} from '../../core/models/num-trial.model';
import {DataSheet} from '../../core/models/data-sheet.model';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {NgForm} from '@angular/forms';
import {AppValidationMessages} from '../app.messages';
import {CurrentFormService} from '../../core/services/current-form.service';
import {ProcedureStep} from '../../core/models/procedure-step.model';
import {PromptFadingStep} from '../../core/models/prompt-fading-step.model';
import {DragDropEvent} from '@progress/kendo-angular-sortable';
import {groupBy} from '@progress/kendo-data-query';
import {SdResponse} from '../../core/models/sd-response.model';
import {ProcItem} from '../../core/models/proc-item.model';
import {MasteryCriteria} from '../../core/models/mastery-criteria.model';
import {CommonService} from '../../core/shared/common/common.service';
import * as _ from 'lodash';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-add-sap',
  templateUrl: './add-sap.component.html',
  styleUrls: ['./add-sap.component.css']
})

export class AddSapComponent implements OnInit, OnDestroy, OnChanges, AfterViewChecked {

  @Input()
  set can_edit(data: boolean) {
    this._can_edit = data;
  }

  get can_edit() {
    return this._can_edit;
  }

  @Input()
  set is_print_view(data: boolean) {
    this._is_print_view = data;
  }

  get is_print_view() {
    return this._is_print_view;
  }

  @Input()
  set img_src(data: string) {
    this._img_src = data;
  }

  get img_src() {
    return this._img_src;
  }

  @ViewChild('skillObjectiveList') public skillObjectiveList: any;
  @ViewChild('st_objective') public st_objective: any;
  @ViewChild('procedureForm') public currentForm: any;
  @ViewChild('numTrialAddType') public numTrialAddType: any;
  @ViewChild('numTrialAddTrials') public numTrialAddTrials: any;
  @ViewChild('promptFadingStepForm') public promptFadingStepForm: any;
  @ViewChild('sdResponseForm') public sdResponseForm: any;
  @ViewChild('procItemForm') public procItemForm: any;
  @ViewChild('masteryCriteriaForm') public masteryCriteriaForm: any;
  @ViewChild('procedureStepForm') public procedureStepForm: any;
  @ViewChild('reinforcement_schedule_type') public reinforcement_schedule_type: any;
  @ViewChild('reinforcementScheduleForm') public reinforcementScheduleForm: any;
  @ViewChild('dataSheetDropdown') public dataSheetDropdown: any;
  @ViewChild('servicePlanList') public servicePlanList: any;
  procedureForm: NgForm;

  private DEFAULT_TITLE = 'Error';
  private _can_edit: boolean;
  private _is_print_view: boolean;
  private _img_src: string;
  private current_data_sheet: any;
  title: string;
  default_img_src = 'assets/img/procedure.png';
  TEACHING_FORMAT_PROCEDURE_STEPS = 'task_analysis';
  INITIAL_TACTIC_CRITERION = 'errorless_criterion';
  INITIAL_TACTIC_SESSION = 'errorless_session';

  codes_to_ignore = ['IT', 'DUR', 'FREQ'];
  sheet_codes_to_show_accuracy = ['DUR'];
  incidental_training_ds_id: number;
  ignore_ids: number[];
  subscriptions: Subscription[];
  patient: User;
  procedure: Procedure;
  procedure_backup: Procedure;
  service_plans: ServicePlan[];
  skill_objectives: SkillObjective[];
  skill_objectives_filtered: SkillObjective[];
  loading = false;
  choices: Map<any, any>;
  all_choices: Map<string, any> = new Map<string, any>();
  statuses: SelectOption[] = [];
  teaching_formats: SelectOption[] = [];
  phases: SelectOption[] = [];
  tactics: SelectOption[] = [];
  reinforcement_types: SelectOption[] = [];
  reinforcement_types_customised: SelectOption[] = [];
  reinforcement_schedules: SelectOption[] = [];
  num_trial_types: SelectOption[] = [];
  prompt_types: SelectOption[] = [];
  chaining_types: SelectOption[] = [];
  stages: SelectOption[] = [];
  mastery_criteria_phase_types: SelectOption[] = [];
  untrained_steps: SelectOption[] = [];
  proc_item_stages: SelectOption[] = [];
  procedure_step_stages: SelectOption[] = [];
  teaching_format_chaining_types: Map<string, SelectOption[]> = new Map<string, any[]>();
  teaching_format_mapping: Map<any, any>;
  mastery_field_mapping: Map<string, string[]>;
  initial_tactic_desc: Map<string, string>;
  reinforcement_schedule: ReinforcementSchedule;
  initial_tactic: InitialTactic;
  initial_tactic_description = '';
  initial_tactic_descriptions: string[] = [];
  num_trial: NumTrial;
  data_sheets: any[];
  all_data_sheets: DataSheet[];
  reinforcements: ReinforcementSchedule[];
  reinforcements_touched: boolean;
  reinforcements_backup: ReinforcementSchedule[];
  initial_tactics: InitialTactic[];
  initial_tactics_backup: InitialTactic[];
  num_trials: NumTrial[];
  num_trials_backup: NumTrial[];
  prompt_fading_steps: PromptFadingStep[];
  prompt_fading_steps_backup: PromptFadingStep[];
  prompt_fading_step: PromptFadingStep;
  prompt_fading_steps_to_delete: PromptFadingStep[];
  baseline_text: string;

  procedure_steps: ProcedureStep[];
  procedure_steps_backup: ProcedureStep[];
  procedure_step: ProcedureStep;
  sd_response: SdResponse;
  sd_responses: SdResponse[];
  sd_responses_backup: SdResponse[];
  proc_item: ProcItem;
  proc_items: ProcItem[];
  proc_items_backup: ProcItem[];
  publish_statuses: SelectOption[];
  mastery_criteria: MasteryCriteria;
  mastery_criteria_items_backup: MasteryCriteria[];
  mastery_criteria_items: MasteryCriteria[];
  // mastery_criteria_required: string[];
  mastery_criteria_allowed: string[];
  data_sheet_mastery_mapping: any;
  mastery_criteria_accuracy_calc_fields: SelectOption[];
  dynamic_field_options: any = {};

  // data collection objects
  dataCollectionMethods: SelectOption[];
  dataCollectionMapping: any;
  dataCollectionReverseMapping: any;

  gettingDataSheets$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  gettingDataSheetsSubscription: Subscription;

  inline_backups: Map<string, any[]>;

  accuracy_tooltip_description = 'This field is in seconds for the current phase / data sheet selection';

  has_name = false;
  reinforcements_error = false;
  initial_tactics_error = false;
  num_trials_error = false;
  error_message = '';
  formErrors = {
    service_plan: '',
    skill_objective: '',
    st_objective: '',
    name: '',
    status: '',
    materials: '',
    procedure: '',
    sd: '',
    correct_response: '',
    baseline: '',
    training_format: '',
    mastery_criteria: '',
    num_active_targets: '',
    data_sheet: '',
    code: '',
    prompt_type: '',
    num_pretr_to_move: '',
    num_tr_for_pretr_move: '',
    // mastery_date: ''
  };

  // skill_objectives_error: boolean = false;

  obj_remove_functions = {
    num_trial: this.procedureViewService.removeNumTrial,
    schedule: this.procedureViewService.removeReinforcementSchedule,
    tactic: this.procedureViewService.removeInitialTactic,
    prompt_fading_step: this.procedureViewService.removePromptFadingStep,
    procedure_step: this.procedureViewService.removeProcedureStep,
    sd_response: this.procedureViewService.removeSdResponse,
    proc_item: this.procedureViewService.removeProcItem,
    mastery_criteria: this.procedureViewService.removeMasteryCriteria
  };

  constructor(private patientService: PatientService,
              private procedureViewService: ProcedureViewService,
              private servicePlanViewService: ServicePlanViewService,
              private skillObjectiveService: SkillObjectiveService,
              private constantsService: ConstantsService,
              private toastyService: ToastyService,
              private currentFormService: CurrentFormService,
              private commonService: CommonService) {
  }

  private set data_sheet(data_sheet){
    this.current_data_sheet = data_sheet;
  }

  private get data_sheet() {
    return this.current_data_sheet;
  }

  private getDataSheetMasteryMapping() {
    let dynamic_field_options = {};
    if (this.data_sheet_mastery_mapping != null) {
      // data_sheet = this.getCurrentDataSheet();
      if (this.data_sheet != null && this.data_sheet.code in this.data_sheet_mastery_mapping) {
        const field_options = this.data_sheet_mastery_mapping[this.data_sheet.code].field_options;
        // now we want to add each of the keys in the field_options to the dynamic fields so we can use it in whichever dropdowns we wnt
        if (field_options != null) {
          // TODO: if something goes wrong, try reverting back to the old way of getting the keys first
          // const keys = Object.keys()
          for (const key in field_options) {
            // NOTE: make sure to remember to get the list to convert from the choices key in field_options[field]
            if (field_options[key] != null) {
              dynamic_field_options[key] = this.constantsService.convertListToSelctOptions(field_options[key].choices);
            }
          }
        }
      }
    }
    return dynamic_field_options;
  }

  public showAccuracyTooltip(data_sheet_id: number) {
    if (data_sheet_id == null) return false;

    return this.current_data_sheet != null && this.sheet_codes_to_show_accuracy.find(sc => this.current_data_sheet.code.toLowerCase() === sc.toLowerCase());
  }

  public onTypeChange(value) {
    this.reinforcement_schedule.types = value;
  }

  public valueNormalizer = (text$: Observable<string>) => text$.pipe(
    map((text: string) => {
      // search for matching item to avoid duplicates

      // search in values
      const matchingValue: any = this.reinforcement_schedule.types.find((item: any) => {
        // return item['text'].toLowerCase() === text.toLowerCase();
        // since each item in types should just be a string - i.e. the key of the SelectOption array
        return item.toLowerCase() === text.toLowerCase();
      });

      if (matchingValue) {
        return matchingValue; // return the already selected matching value and the component will remove it
      }

      // search in data
      const matchingItem: any = this.reinforcement_types.find((item: any) => {
        return item['text'].toLowerCase() === text.toLowerCase();
      });

      if (matchingItem) {
        return matchingItem;
      } else {
        return new SelectOption(text, text);
      }
    }));

  checkForChanges(): void {
    const keys = ['procedure', 'num_trials', 'reinforcements', 'initial_tactics', 'prompt_fading_steps', 'procedure_steps', 'sd_responses', 'proc_items', 'mastery_criteria_items'];
    let toUpdate = false;
    if (this.procedure.id != null) {
      for (const key of keys) {
        if (this[key + '_backup'] && !_.isEqual(this[key], this[key + '_backup'])) {
          toUpdate = true;
          break;
        }
      }
    }
    this.procedureViewService.updateCurrentSubject.next(toUpdate);
  }

  updateProcedure(): void {
    this.checkForChanges();

    this.procedureViewService.currentSubject.next(this.procedure);
    this.procedureViewService.currentNumTrials.next(this.num_trials);
    this.procedureViewService.currentReinforcements.next(this.reinforcements);
    this.procedureViewService.currentInitialTactics.next(this.initial_tactics);
    this.procedureViewService.currentPromptFadingSteps.next(this.prompt_fading_steps);
    this.procedureViewService.currentProcedureSteps.next(this.procedure_steps);
    this.procedureViewService.currentSdResponses.next(this.sd_responses);
    this.procedureViewService.currentProcItems.next(this.proc_items);
    this.procedureViewService.currentMasteryCriteriaItems.next(this.mastery_criteria_items);
    this.procedureViewService.promptFadingStepsToDelete.next(this.prompt_fading_steps_to_delete);
  }

  updateObjects(): void {
    // first start with the sap
    const procedure = this.procedureViewService.currentSubject.getValue();
    if (procedure && procedure.id) {
      this.procedure.id = procedure.id;
    }
    const mapping = {
      'currentNumTrials': 'num_trials',
      'currentReinforcements': 'reinforcements',
      'currentInitialTactics': 'initial_tactics',
      'currentPromptFadingSteps': 'prompt_fading_steps',
      'currentProcedureSteps': 'procedure_steps',
      'currentSdResponses': 'sd_responses',
      'currentProcItems': 'proc_items',
      'currentMasteryCriteriaItems': 'mastery_criteria_items'
    };
    // TODO: think of a better way to do this
    const keys = Object.keys(mapping);
    let listObj: any[];

    for (const key of keys) {
      if (this.procedureViewService[key]) {
        listObj = this.procedureViewService[key].getValue();
        this.updateIds(listObj, mapping[key]);
      }
    }
    this.updateProcedure();
  }

  updateIds(listObj: any[], thisKey): void {
    if (!this[thisKey] || this[thisKey].length == 0 || !listObj || listObj.length == 0 || this[thisKey].length < listObj.length) {
      return
    }
    for (let i = 0; i < listObj.length; i++) {
      if (listObj[i] && listObj[i].id != null) {
        this[thisKey][i].id = listObj[i].id;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateProcedure();
  }

  unsubscribe(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  subscribeToPatient(): void {
    this.subscriptions.push(this.patientService.currentPatient.subscribe(
      (patient: User) => {
        this.patient = patient;
        if (this.patient && this.patient.id != null) {
          this.getServicePlans();
          // and add the patient id to the procedure regardless - as it should be the same
          this.procedure.patient = patient.id;
        }
      }));
  }

  subscribeToSap(): void {
      this.subscriptions.push(this.procedureViewService.currentSubject.subscribe(
          (procedure: Procedure) => {
              if (procedure && Object.keys(procedure).length) {
                  this.procedure = _.merge(this.procedure, procedure)
              }
          }
      ))
  }

  subscribeToErrorMessage(): void {
    this.subscriptions.push(this.currentFormService.currentErrorMessage.subscribe(
      (message: string) => {
        // this.error_message = message;
        if (message && typeof message === 'string') {
          this.addToast(message, 'Error');
        }
      }));
  }

  subscribeToNonFieldErrorMessage(): void {
    this.subscriptions.push(this.currentFormService.currentNonFieldErrorMessage.subscribe(
      (message: string) => {
        // this.error_message = message;
        if (message && typeof message === 'string') {
          this.error_message = message;
          this.addToast(message, 'Error');
        }
        // now we update objects with ids that we have
        this.updateObjects();
      }));
  }

  private subscribeToGettingDataSheets() {
    this.gettingDataSheetsSubscription = this.gettingDataSheets$.subscribe(res => {
      if (res) {
        this.teachingFormatChange(null);
        if (this.gettingDataSheetsSubscription) {
          this.gettingDataSheetsSubscription.unsubscribe();
        }
      }
    });
  }

  getServicePlans(): void {
    // we should have a patient at this point, if we don't then something is very wrong
    this.servicePlanViewService.getByPatient(this.patient.id.toString())
      .then(plans => {
        this.service_plans = plans;
        if (this.procedure && this.procedure.id != null) {
          this.servicePlanChange(this.procedure.service_plan);
        }
      })
      .catch(
        err => {
          console.log(err);

          throw err;
        })
  }

  selectionChange(event: any): any {
    // if nothing is selected then stop propagation by returning false
    if (!event) {
      return false;
    }
  }

  getFilteredObjectives(id: number): SkillObjective[] {
    const new_list = [];
    if (this.service_plans && this.service_plans.length > 0 && id != null) {
      // do type coercion because Kendo likes to return stuff as strings
      const service_plan = this.service_plans.find(sp => sp.id == id);
      if (service_plan) {
        for (const objective of service_plan.skill_objectives) {
          const skill = this.skill_objectives.find(so => so.id === objective);
          if (skill) {
            // setting it before and after so it's not kept in the cache
            skill.is_in_procedure = true;
            new_list.push(_.cloneDeep(skill));
            skill.is_in_procedure = false;
          }
        }
      }
    }
    return new_list;
  }

  getGroupedObjectives(): SkillObjective[] {
    let filtered: SkillObjective[] = [];
    const grouped = groupBy(this.skill_objectives, [{field: 'domain_name'}]);
    for (const group of grouped) {
      if (group['items'] && group['items'].length > 0) {
        group['items'][0]['first'] = true;
        filtered = filtered.concat(group['items'].slice())
      }
    }
    return filtered;
  }

  servicePlanChange(event: any): void {
    if (event && event != null) {
      this.skill_objectives_filtered = [];
      if (this.skillObjectiveList) {
        this.skillObjectiveList.reset();
      }

      const new_list = this.getFilteredObjectives(event);
      this.skill_objectives_filtered = new_list.slice();
      const grouped = groupBy(this.skill_objectives, [{field: 'domain_name'}]);
      this.skill_objectives_filtered = this.skill_objectives_filtered.concat(this.getGroupedObjectives());
    }
    this.updateProcedure();
  }

  onFocusDropDown(comboBoxDropDown: any, toggle: boolean): void {
    if (comboBoxDropDown != null) {
      setTimeout(() => {
        if (!comboBoxDropDown.isOpen) {
          comboBoxDropDown.toggle(toggle);
        }
      }, 100);
    }
  }

  servicePlanOnFocus(event: any): void {
    if (this.servicePlanList != null) {
      this.onFocusDropDown(this.servicePlanList, true)
    }
  }

  skillObjectiveOnFocus(event: any): void {
    if (this.skillObjectiveList != null) {
      this.onFocusDropDown(this.skillObjectiveList, true)
    }
  }

  onBlurEvent(event: any): void {
    if (this.skillObjectiveList != null) {
      this.onFocusDropDown(this.skillObjectiveList, false)
    }
  }

  getSkillObjectives(): void {
    this.skill_objectives = [];
    //// NOTE: Taking out the caching for now
    if (this.skillObjectiveService.collection && this.skillObjectiveService.collection.length > 0) {
      this.skill_objectives = _.cloneDeep(this.skillObjectiveService.collection);
      for (const objective of this.skill_objectives) {
        objective.name = objective.tag + ' ' + objective.program;
      }
      // now we fill the filtered objectives on first load
      const new_list = this.getFilteredObjectives(this.procedure.service_plan);
      this.skill_objectives_filtered = new_list.slice();
      this.skill_objectives_filtered = this.skill_objectives_filtered.concat(this.getGroupedObjectives());
    } else {
      this.procedureViewService.getSkillObjectives$()
        .subscribe(
          (skill_objectives) => {
            this.skill_objectives = skill_objectives;
            for (const objective of this.skill_objectives) {
              objective.name = objective.tag + ' ' + objective.program;
            }
            // now we fill the filtered objectives on first load
            const new_list = this.getFilteredObjectives(this.procedure.service_plan);
            this.skill_objectives_filtered = new_list.slice();
            this.skill_objectives_filtered = this.skill_objectives_filtered.concat(this.getGroupedObjectives());
            // this.skill_objectives_filtered = this.skill_objectives_filtered.concat(this.skill_objectives.slice());
            this.loading = false;
          }, (err) => {
            // uh-oh, something went wrong, inform the user
            this.skill_objectives = [];
            this.loading = false;
          });
    }
  }

  getChoices(): Promise<boolean> {

    if (!this.constantsService.choices || this.constantsService.choices.size === 0) {
      return this.constantsService.getChoices().then(
        (choices: Map<string, any>) => {

          this.choices = choices.get('procedure');
          this.all_choices = choices;
          this.statuses = this.choices.get('status');
          this.prompt_types = this.choices.get('prompt_type');
          // this.baseline = this.choices.get('baseline');
          this.reinforcement_schedules = this.choices.get('reinforcement_schedule');
          this.reinforcement_types = this.choices.get('reinforcement_type');
          this.teaching_formats = this.choices.get('teaching_format');
          this.phases = choices.get('initialtactic').get('phase');
          this.tactics = choices.get('initialtactic').get('tactic');
          this.chaining_types = choices.get('initialtactic').get('chaining_type');
          this.num_trial_types = choices.get('numtrial').get('type');
          this.stages = choices.get('sessionsummary').get('stage');
          this.mastery_criteria_phase_types = choices.get('masterycriteria').get('phase_type');
          this.teaching_format_mapping = choices.get('procedure').get('teaching_format_mapping');
          this.proc_item_stages = choices.get('procitem').get('stage');
          this.mastery_field_mapping = choices.get('procedure').get('mastery_field_mapping');
          this.data_sheet_mastery_mapping = this.choices.get('data_sheet_mastery_mapping');
          this.procedure_step_stages = choices.get('procedurestep').get('stage');
          this.initial_tactic_desc = choices.get('initialtactic').get('description');

          this.dataCollectionMethods = choices.get('global').get('data_collection_methods')
          this.dataCollectionMapping = choices.get('global').get('data_collection_methods_mapping')
          this.dataCollectionReverseMapping = choices.get('global').get('data_collection_methods_mapping_reverse')

          this.publish_statuses = choices.get('global').get('publish_status');

          if (this.mastery_criteria_items && this.mastery_criteria_items.length > 0) {
            this.getMasteryAllowedRequired();
          }

          // now we get the tactic list for each of the initial_tactics
          this.getTacticsForList();

          this.setDataSheets()

          return true;
        });
    } else {
      this.all_choices = this.constantsService.choices;
      this.choices = this.all_choices.get('procedure');

      this.statuses = this.choices.get('status');
      this.prompt_types = this.choices.get('prompt_type');
      // this.baseline = this.choices.get('baseline');
      this.reinforcement_schedules = this.choices.get('reinforcement_schedule');
      this.reinforcement_types = this.choices.get('reinforcement_type');
      this.teaching_formats = this.choices.get('teaching_format');
      this.phases = this.all_choices.get('initialtactic').get('phase');
      this.tactics = this.all_choices.get('initialtactic').get('tactic');
      this.chaining_types = this.all_choices.get('initialtactic').get('chaining_type');
      this.num_trial_types = this.all_choices.get('numtrial').get('type');
      this.stages = this.all_choices.get('sessionsummary').get('stage');
      this.mastery_criteria_phase_types = this.all_choices.get('masterycriteria').get('phase_type');
      this.teaching_format_mapping = this.all_choices.get('procedure').get('teaching_format_mapping');
      this.proc_item_stages = this.all_choices.get('procitem').get('stage');
      this.mastery_field_mapping = this.all_choices.get('procedure').get('mastery_field_mapping');
      this.data_sheet_mastery_mapping = this.choices.get('data_sheet_mastery_mapping');

      this.procedure_step_stages = this.all_choices.get('procedurestep').get('stage');
      this.initial_tactic_desc = this.all_choices.get('initialtactic').get('description');

      this.dataCollectionMethods = this.all_choices.get('global').get('data_collection_methods')
      this.dataCollectionMapping = this.all_choices.get('global').get('data_collection_methods_mapping')
      this.dataCollectionReverseMapping = this.all_choices.get('global').get('data_collection_methods_mapping_reverse')

      this.publish_statuses = this.all_choices.get('global').get('publish_status');

      if (this.mastery_criteria_items && this.mastery_criteria_items.length > 0) {
        this.getMasteryAllowedRequired();
      }

      // now we get the tactic list for each of the initial_tactics
      this.getTacticsForList();

      this.setDataSheets()

      return Promise.resolve(true);
    }
  }

  setDataSheets() {
    this.data_sheets = []
    const keys = Object.keys(this.dataCollectionMapping)
    for (const key of keys) {
      this.data_sheets.push({
        id: this.dataCollectionMapping[key].data_sheet_id,
        code: this.dataCollectionMapping[key].data_sheet_code
      })
    }
  }

  ignoreDatasheetId(id: number): boolean {
    return this.ignore_ids.findIndex(i => i == id) > -1;
  }

  getNumTrials(): void {
    this.procedureViewService.getNumTrials$()
      .subscribe(
        (num_trials) => {
          this.num_trials = num_trials;
          this.num_trials_backup = _.cloneDeep(num_trials);
          this.updateProcedure();
        }, (err) => {
          console.log(err);
          // TODO: inform user
        });
  }

  getInitialTactics(): void {
    this.procedureViewService.getInitialTactics$()
      .subscribe(
        (initial_tactics: InitialTactic[]) => {
          this.initial_tactics = initial_tactics;
          this.initial_tactics_backup = _.cloneDeep(initial_tactics);
          if (initial_tactics && initial_tactics.length > 0) {
            this.initial_tactic_descriptions = new Array(initial_tactics.length);
          }
          this.getTacticsForList();

          this.updateProcedure();
        }, (err) => {
          console.log(err);
          // TODO: inform user
        });
  }

  getReinforcementSchedules(): void {
    this.procedureViewService.getReinforcementSchedules()
      .then(
        (reinforcements: ReinforcementSchedule[]) => {
          this.reinforcements = reinforcements;
          this.reinforcements_backup = _.cloneDeep(reinforcements);
          this.updateProcedure();

          // Check for custom reinforcement types
          let choice;
          for (choice in this.reinforcement_types) {
            this.reinforcement_types_customised.push(this.reinforcement_types[choice])
          }

          // let reinforcement;
          for (const reinforcement of this.reinforcements) {
            // let r_type;
            for (const r_type of reinforcement['types']) {
              const custom_reinforcement_type = reinforcement['types'][r_type];
              const selection_option = new SelectOption(custom_reinforcement_type, custom_reinforcement_type);

              let value_match = 0;
              let re_type;
              for (re_type in this.reinforcement_types_customised) {
                if (this.reinforcement_types_customised[re_type].value === selection_option.value) {
                  value_match++;
                }
              }
              if (value_match === 0) {
                this.reinforcement_types_customised.push(selection_option);
              }
            }
          }
        })
      .catch(
        (err) => {
          console.log(err);
          // TODO: inform user

          throw err;
        });
  }

  getPromptFadingSteps(): void {
    this.procedureViewService.getPromptFadingSteps()
      .then(
        (steps: PromptFadingStep[]) => {
          this.prompt_fading_steps = steps;
          this.prompt_fading_steps_backup = _.cloneDeep(steps);
          this.updateProcedure();
        })
      .catch(
        (err) => {
          console.log(err);
          // TODO: inform user

          throw err;
        });
  }

  getProcedureSteps(): void {
    this.procedureViewService.getProcedureSteps()
      .then(
        (steps: ProcedureStep[]) => {
          this.procedure_steps = steps;
          this.procedure_steps_backup = _.cloneDeep(steps);
          this.updateProcedure();
          // this.loading = false;
        })
      .catch(
        (err) => {
          console.log(err);
          this.loading = false;
          // TODO: inform user

          throw err;
        });
  }

  getSdResponses(): void {
    this.procedureViewService.getSdResponses()
      .then(
        (responses: SdResponse[]) => {
          this.sd_responses = responses;
          this.sd_responses_backup = _.cloneDeep(responses);
          this.updateProcedure();
          // this.loading = false;
        })
      .catch(
        (err) => {
          console.log(err);
          this.loading = false;
          // TODO: inform user

          throw err;
        });
  }

  getProcItems(): void {
    this.procedureViewService.getProcItems()
      .then(
        (proc_items: ProcItem[]) => {
          this.proc_items = proc_items;
          this.proc_items_backup = _.cloneDeep(proc_items);
          this.updateProcedure();
          this.loading = false;
        })
      .catch(
        (err) => {
          console.log(err);
          this.loading = false;
          // TODO: inform user

          throw err;
        });
  }

  getMasteryAllowedRequired(): void {
    for (let idx = 0; idx < this.mastery_criteria_items.length; idx++) {
      this.phaseTypeChange(this.mastery_criteria_items[idx].phase_type, idx);
    }
  }

  getMasteryCriteriaItems(): void {
    this.procedureViewService.getMasteryCriteriaItems()
      .then(
        (mastery_criteria_items: MasteryCriteria[]) => {
          this.mastery_criteria_items = mastery_criteria_items;
          this.mastery_criteria_items_backup = mastery_criteria_items;
          if (this.mastery_field_mapping && Object.keys(this.mastery_field_mapping).length > 0) {
            this.getMasteryAllowedRequired();
          }
          const arr = Array.apply(null, Array(this.mastery_criteria_items.length))
            .map(() => new MasteryCriteria());
          this.inline_backups.set('mastery_criteria_items', arr);
          this.updateProcedure();
          // this.loading = false;
        })
      .catch(
        (err) => {
          console.log(err);
          this.loading = false;
          // TODO: inform user

          throw err;
        });
  }

  edit(obj: any, obj_type?: string, idx?: number, list?: string): void {
    if (!obj.edit && list != null && idx != null && this.inline_backups.has(list)) {
      this.inline_backups.get(list)[idx] = _.cloneDeep(obj);
      obj.edit = !obj.edit;
    } else if (!!obj.edit && list != null && idx != null) {
      // here we are canceling the edit if we are editing
      // have to update the list cause we are changing the hash by copying the object back
      this[list][idx] = _.cloneDeep(this.inline_backups.get(list)[idx]);
    } else {
      obj.edit = !obj.edit;
    }

    if (obj_type != null && obj_type == 'initial_tactic' && idx != null) {
      // checking if the taching format chaining types map is empty, we need this for the tactic list so we call 'teaching format change' to fill it
      if (!this.teaching_format_chaining_types || this.teaching_format_chaining_types.size == 0) {
        // if we don't have the teaching format_chaining_types, let's get the default tactics:
        this.initial_tactics[idx].tactics = this.all_choices.get('initialtactic').get('tactic');
      } else {
        // else we have TA and so we treat it as a chaining type change
        this.chainingTypeChange(this.initial_tactics[idx].chaining_type, idx);
      }
      // this.updateTeachingFormatRelatedObjs('initial_tactics');
    }
  }

  saveReinformentSchedule(idx: number): void {
    if (!this.procedure || !this.procedure.id) {
      this.addToast('Could not save the schedule before the Procedure, please save the procedure first', 'Error');
      return;
    }
    this.reinforcements[idx].procedure = this.procedure.id;
    this.procedureViewService.saveReinforcementSchedule(this.reinforcements[idx])
      .then(
        (reinforcement: ReinforcementSchedule) => {
          this.reinforcements[idx] = reinforcement;
          this.addToast('Successfully saved Schedule', 'success');
        })
      .catch(
        (err) => {
          this.addToast('Could not save schedule, please try again later...', 'Error');

          throw err;
        });
  }

  savePromptFadingStep(idx: number): void {
    if (!this.procedure || !this.procedure.id) {
      // this.addToast('Could not save the schedule before the Procedure, please save the procedure first', 'Error');
      this.prompt_fading_steps[idx].edit = false;
      return;
    }
    this.prompt_fading_steps[idx].procedure = this.procedure.id;
    this.procedureViewService.savePromptFadingStep(this.prompt_fading_steps[idx])
      .then(
        (step: PromptFadingStep) => {
          step.edit = false;
          this.prompt_fading_steps[idx] = step;
          this.addToast('Successfully saved step', 'success');
        })
      .catch(
        (err) => {
          this.addToast('Could not save step, please try again later...', 'Error');

          throw err;
        });
  }

  saveSdResponse(idx: number): void {
    if (!this.procedure || !this.procedure.id) {
      // this.addToast('Could not save the schedule before the Procedure, please save the procedure first', 'Error');
      this.sd_responses[idx].edit = false;
      return;
    }
    this.sd_responses[idx].procedure = this.procedure.id;
    this.procedureViewService.saveSdResponse(this.sd_responses[idx])
      .then(
        (step: SdResponse) => {
          step.edit = false;
          this.sd_responses[idx] = step;
          this.addToast('Successfully saved sd response', 'success');
        })
      .catch(
        (err) => {
          this.addToast('Could not save sd response, please try again later...', 'Error');

          throw err;
        });
  }

  saveProcItem(idx: number): void {
    if (!this.procedure || !this.procedure.id) {
      // this.addToast('Could not save the schedule before the Procedure, please save the procedure first', 'Error');
      this.proc_items[idx].edit = false;
      return;
    }
    this.proc_items[idx].procedure = this.procedure.id;
    if(!this.proc_items[idx].mastery_date_date) {
      this.proc_items[idx].mastery_date = null;
    }
    this.procedureViewService.saveProcItem(this.proc_items[idx])
      .then(
        (proc_item: ProcItem) => {
          proc_item.edit = false;
          this.proc_items[idx] = proc_item;
          this.addToast('Successfully saved procedure item', 'success');
        })
      .catch(
        (err) => {
          this.addToast('Could not save procedure item, please try again later...', 'Error');

          throw err;
        });
  }

  saveProcedureStep(idx: number): void {
    if (!this.procedure || !this.procedure.id) {
      // this.addToast('Could not save the schedule before the Procedure, please save the procedure first', 'Error');
      this.procedure_steps[idx].edit = false;
      return;
    }
    this.procedure_steps[idx].procedure = this.procedure.id;
    if(!this.procedure_steps[idx].mastery_date_date) {
      this.procedure_steps[idx].mastery_date = null;
    }
    this.procedureViewService.saveProcedureSteps(this.procedure_steps[idx])
      .then(
        (step: ProcedureStep) => {
          step.edit = false;
          this.procedure_steps[idx] = step;
          this.addToast('Successfully saved step', 'success');
        })
      .catch(
        (err) => {
          this.addToast('Could not save step, please try again later...', 'Error');

          throw err;
        });
  }

  saveMasteryCriteria(idx: number): void {
    if (!this.procedure || !this.procedure.id) {
      const procedure = this.procedureViewService.currentSubject.getValue();
      if (!procedure || !procedure.id) {
        this.mastery_criteria_items[idx].edit = false;
        return;
      } else {
        this.procedure.id = procedure.id;
      }
    }
    this.mastery_criteria_items[idx].procedure = this.procedure.id;
    this.procedureViewService.saveMasteryCriteria(this.mastery_criteria_items[idx])
      .then(
        (mastery_criteria: MasteryCriteria) => {
          mastery_criteria.edit = false;
          this.mastery_criteria_items[idx] = _.merge(this.mastery_criteria_items[idx], mastery_criteria);
          this.addToast('Successfully saved mastery criteria', 'success');
        })
      .catch(
        (err) => {
          if (err.error) {
            for (let key in err.error) {
              this.addToast(`${key}: ${err.error[key][0]}`, 'error');
            }
          }
          //this.addToast('Could not save mastery criteria, please try again later...', 'Error');
          throw err;
        });
  }

  addToast(message: string, title: string) {
    // Or create the instance of ToastOptions
    const toastOptions = this.getToastOptions(message, title);
    // // Add see all possible types in one shot
    // this.toastyService.info(toastOptions);
    // this.toastyService.success(toastOptions);
    // this.toastyService.wait(toastOptions);
    // this.toastyService.error(toastOptions);
    // this.toastyService.warning(toastOptions);

    switch (title.toLowerCase()) {
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
    }
  }

  getToastOptions(message: string, title: string): ToastOptions {
    title = title != null ? title : this.DEFAULT_TITLE;

    return {
      title: title,
      msg: message,
      showClose: true,
      timeout: 5000,
      theme: 'default'
    };
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    // console.log(event);
    if (event.key === 'Enter') {
      // console.log('Entering');

      // event.preventDefault();
    }

  }

  skillObjectiveChange(event: any): void {
    if (event && event != null) {
      // first we look at the program (service) plan objectives_m2m, if we don't find it, then we go to the list
      const service_plan = this.service_plans.find(sp => sp.id == this.procedure.service_plan);
      const skill_objective_m2m = service_plan.skill_objectives_m2m.find(som => som.skill_objective == event);
      // should be the id of the objective
      const skill_objective = this.skill_objectives_filtered.find(so => so.id == event);
      if (skill_objective && (this.st_objective.pristine || !this.st_objective.value.trim())) {
        this.procedure.st_objective = skill_objective_m2m != null ? skill_objective_m2m.objective : skill_objective.objective;
        this.procedure.code = skill_objective.tag;
        const name_ctrl = this.procedureForm.form.get('name');
        if (!this.has_name && name_ctrl && name_ctrl.pristine) {
          this.procedure.name = skill_objective.program;
        }
      }
    }
    this.updateProcedure();
  }

  addReinforcement(f: NgForm) {
    // f.reset();
    if (!this.reinforcement_schedule.schedule_text || !this.reinforcement_schedule.types_text) {
      this.reinforcement_schedule.is_submitted = true;
      f.ngSubmit.emit();
      return;
    }
    let choice;
    for (choice in this.reinforcement_types) {
      this.reinforcement_types_customised.push(this.reinforcement_types[choice])
    }
    let r_type;
    for (r_type in this.reinforcement_schedule['types']) {
      const custom_reinforcement_type = this.reinforcement_schedule['types'][r_type];
      const selection_option = new SelectOption(custom_reinforcement_type, custom_reinforcement_type);

      let value_match = 0;
      let re_type;
      for (re_type in this.reinforcement_types_customised) {
        if (this.reinforcement_types_customised[re_type].value === selection_option.value) {
          value_match++;
        }
      }
      if (value_match === 0) {
        this.reinforcement_types_customised.push(selection_option);
      }
    }
    this.reinforcements.push(_.cloneDeep(this.reinforcement_schedule));
    this.updateProcedure();
    this.reinforcement_schedule = new ReinforcementSchedule();
    f.reset();
    this.reinforcement_schedule.is_submitted = false;
  }

  addTrial(f: NgForm): void {
    if (!this.num_trial.type || this.num_trial.trials == null) {
      f.ngSubmit.emit();
      return;
    }
    this.num_trials.push(_.cloneDeep(this.num_trial));
    this.updateProcedure();
    this.num_trial = new NumTrial();
  }

  addTactic(f: NgForm): void {
    if (!this.initial_tactic.phase) {
      f.ngSubmit.emit();
      return;
    }
    // adding the current list of tactics to the tactic object so we can display the values in the UI
    this.initial_tactic.tactics = this.tactics;
    this.initial_tactics.push(_.cloneDeep(this.initial_tactic));
    this.updateProcedure();
    this.initial_tactic = new InitialTactic();
    this.initial_tactic_descriptions.push('');
  }

  addPromptFadingStep(): void {
    if (!this.prompt_fading_step.step) {
      this.promptFadingStepForm.ngSubmit.emit();
      return;
    }
    this.prompt_fading_step._order = this.prompt_fading_steps.length;
    this.prompt_fading_steps.push(_.cloneDeep(this.prompt_fading_step));
    this.updateProcedure();
    this.prompt_fading_step = new PromptFadingStep();
  }

  addSdResponse(): void {
    if (!this.sd_response.sd || !this.sd_response.correct_response) {
      this.sdResponseForm.ngSubmit.emit();
      return;
    }
    this.sd_response._order = this.sd_responses.length;
    this.sd_responses.push(_.cloneDeep(this.sd_response));
    this.updateProcedure();
    this.sd_response = new SdResponse();
  }

  addProcedureStep(): void {
    if (!this.procedure_step.step) {
      this.procedureStepForm.ngSubmit.emit();
      return;
    }
    this.procedure_step._order = this.procedure_steps.length;
    this.procedure_steps.push(_.cloneDeep(this.procedure_step));
    this.updateProcedure();
    this.procedure_step = new ProcedureStep();
  }

  addProcItem(): void {
    if (!this.proc_item.name || !this.proc_item.stage) {
      this.procItemForm.ngSubmit.emit();
      return;
    }
    this.proc_item._order = this.proc_items.length;
    this.proc_items.push(_.cloneDeep(this.proc_item));
    this.updateProcedure();
    this.proc_item = new ProcItem();
  }

  addMasteryCriteriaItem(): void {
    if (this.masteryCriteriaForm.invalid) {
      this.masteryCriteriaForm.ngSubmit.emit();
      return;
    }
    this.mastery_criteria._order = this.mastery_criteria_items.length;
    this.mastery_criteria_items.push(_.cloneDeep(this.mastery_criteria));
    if (!this.inline_backups.has('mastery_criteria_items') || !this.inline_backups.get('mastery_criteria_items')) {
      this.inline_backups.set('mastery_criteria_items', []);
    }
    this.inline_backups.get('mastery_criteria_items').push(new MasteryCriteria());
    this.updateProcedure();
    this.mastery_criteria = new MasteryCriteria();
  }

  saveTactic(idx: number): void {
    // if (!this.procedure || !this.procedure.id) {
    //   this.addToast('Could not save tactic before the Procedure, please save the procedure first', 'Error');
    //   return;
    // }

    this.initial_tactics[idx].procedure = this.procedure.id;
    this.initial_tactics[idx].edit = false;
    this.updateTeachingFormatRelatedObjs('initial_tactics');
    this.updateProcedure();
  }

  saveNumTrial(idx: number): void {
    if (!this.procedure || !this.procedure.id) {
      this.addToast('Could not save the trial before the Procedure, please save the procedure first', 'Error');
      return;
    }
    this.num_trials[idx].procedure = this.procedure.id;
    this.procedureViewService.saveNumTrial(this.num_trials[idx])
      .then(
        (num_trial: NumTrial) => {
          this.num_trials[idx] = num_trial;
          this.addToast('Successfully saved trial', 'success');
        })
      .catch(
        (err) => {
          this.addToast('Could not save trial, please try again later...', 'Error');

          throw err;
        });
  }

  phaseTypeChange(event: any, idx?: number) {
    if (!event) {
      return;
    }
    // TODO:
    // first we need to get the data_sheet, if it exists in the data_sheet_mastery, then we need to use that code from the data_sheet
    // otherwise we need to get it from the phase_type
    // const data_sheet = this.getCurrentDataSheet();

    // first we get the current data sheet, since we need to check if it's in the data_sheet_mastery_mapping
    const phase_type = this.data_sheet != null && this.data_sheet.code in this.data_sheet_mastery_mapping ?
      this.data_sheet.code : event;
    // we get the mastery field options from this.mastery_field_mapping
    const new_mastery_field_mapping = phase_type in this.data_sheet_mastery_mapping ?
      this.data_sheet_mastery_mapping[phase_type].fields :
      this.mastery_field_mapping[phase_type];
    // TODO: need to check if this is ok, especially with DT as it can have different phases
    this.mastery_criteria_allowed = new_mastery_field_mapping['allowed'];
    if (idx != null) {

      this.mastery_criteria_items[idx].mastery_criteria_allowed = [];
      this.mastery_criteria_items[idx].mastery_criteria_required = [];
      if (new_mastery_field_mapping && Object.keys(new_mastery_field_mapping).length > 0) {
        this.mastery_criteria_items[idx].mastery_criteria_allowed = new_mastery_field_mapping['allowed'];
        this.mastery_criteria_items[idx].mastery_criteria_required = new_mastery_field_mapping['required'];
      }
    }
    this.mastery_criteria.mastery_criteria_allowed = [];
    this.mastery_criteria.mastery_criteria_required = [];
    if (new_mastery_field_mapping && Object.keys(new_mastery_field_mapping).length > 0) {
      this.mastery_criteria.mastery_criteria_allowed = new_mastery_field_mapping['allowed'];
      this.mastery_criteria.mastery_criteria_required = new_mastery_field_mapping['required'];
    }
  }

  teachingFormatChange(event: any): void {
    if (this.procedure.id != null && this.procedure.teaching_format != null &&
      this.initial_tactics && this.initial_tactics.length > 0 && event != null) {
      return;
    }
    let new_values;

    if (this.teaching_format_mapping &&
      this.procedure.teaching_format != null &&
      Object.keys(this.teaching_format_mapping).length > 0) {
      new_values = this.teaching_format_mapping[this.procedure.teaching_format];
      // now data_sheets is an array instead of a single string
      if (event != null) {
        // only clear the tactic if the UI is calling this function
        // clearing initial tactic object

        this.initial_tactic = new InitialTactic();

        // clearing all fields that are affected by teaching format when a new tactic is selected
        this.initial_tactics = [];
        this.num_trials = [];
        this.mastery_criteria_items = [];
        this.procedure.untrained_steps_proc = null;
        this.untrained_steps = [];
        this.prompt_fading_steps = [];
        this.procedure.prompt_type = null;
      }

      if (new_values != null) {
        this.teaching_format_chaining_types = this.commonService.objectToMap(new_values.chaining) as Map<string, any[]>;
        if (this.teaching_format_chaining_types.size !== 0) {
          this.tactics = [];
        } else {
          // this.tactics = this.constantsService.convertListToSelctOptions(new_values.initial_tactics);
          if (new_values['initial_tactics'] && new_values['initial_tactics'].length > 0) {
            this.tactics = this.constantsService.convertListToSelctOptions(new_values['initial_tactics']);
          } else {
            this.tactics = this.all_choices.get('initialtactic').get('tactic');
          }
        }

        this.phases = this.constantsService.convertListToSelctOptions(new_values.phase_types);
        this.mastery_criteria_phase_types = this.constantsService.convertListToSelctOptions(new_values.phase_types);
        this.num_trial_types = this.constantsService.convertListToSelctOptions(new_values.phase_types);
        this.untrained_steps = this.constantsService.convertListToSelctOptions(new_values.untrained_steps);

        // updating baseline
        if ((this.baseline_text != null && this.procedure.baseline != null && this.baseline_text == this.procedure.baseline && event != null) ||
          this.procedure.baseline == null || !this.procedure.baseline.trim()) {
          this.procedure.baseline = new_values.baseline_text;
          this.baseline_text = new_values.baseline_text;
        }
      } else {
        // default values
        this.tactics = this.all_choices.get('initialtactic').get('tactic');
        this.phases = this.all_choices.get('initialtactic').get('phase');
        this.mastery_criteria_phase_types = this.all_choices.get('masterycriteria').get('phase_type');
        this.num_trial_types = this.all_choices.get('numtrial').get('type');
        this.untrained_steps = [];
      }

      if ((!this.untrained_steps || this.untrained_steps.length == 0) && this.procedure.untrained_steps_proc != null) {
        this.procedure.untrained_steps_proc = null;
      }
    }
  }

  chainingTypeChange(event: any, idx?: number, tacticForm?: NgForm): void {
    // event should hold the value of the dropdown
    if (this.teaching_format_chaining_types &&
      (event != null || idx != null) &&
      this.teaching_format_chaining_types.size > 0) {
      const new_values = this.teaching_format_mapping[this.procedure.teaching_format];
      const tactics = this.teaching_format_chaining_types.get(event);

      if (tactics && tactics.length > 0) {
        if (idx != null) {
          this.initial_tactics[idx].tactics = this.constantsService.convertListToSelctOptions(tactics);
        } else {
          this.tactics = this.constantsService.convertListToSelctOptions(tactics);
        }
      } else {
        if (idx != null) {
          setTimeout(() => {
            this.initial_tactics[idx].tactic = '';
            this.initial_tactics[idx].tactics = [];
          });
        } else {
          this.tactics = [];
          this.initial_tactic.tactic = '';
        }
      }
    }
  }

  descriptionChanged(idx?: number): void {
    this.initial_tactic_description = this.initial_tactic.description;
  }

  tacticsChange(event: any, idx?: number, tacticForm?: NgForm, prev?: string): void {
    // event should hold the value of the dropdown
    if (this.initial_tactic_desc != null &&
      (event != null || idx != null)) {
      let key: string;
      if (idx != null) {
        if (this.initial_tactics[idx].chaining_type != null) {
          key = [...[this.initial_tactics[idx].chaining_type], ...[event]].join('_');
        } else {
          key = event.toString();
        }
      } else if (idx == null) {
        if (this.initial_tactic.chaining_type != null) {
          key = [...[this.initial_tactic.chaining_type], ...[event]].join('_');
        } else {
          key = event.toString();
        }
      } else {
        return;
      }

      const desc = this.initial_tactic_desc[key];
      if (idx != null) {
        if (!this.initial_tactics[idx].description || !this.initial_tactics[idx].description.trim()) {
          this.initial_tactics[idx].description = desc;
        }
      } else {
        if (!this.initial_tactic_description) {
          this.initial_tactic.description = desc;
        }
      }
    }
  }


  public onDragEnd(src: number, e: DragDropEvent, list_name: string): void {
    // Not logging due to the large number of events
    if (this[list_name]) {
      for (let i = 0; i < this[list_name].length; i++) {
        this[list_name][i]['_order'] = i;
        this[list_name][i]['mouseentered'] = false;
      }
    }
  }

  public onDragStart(src: number, e: DragDropEvent, list_name: string): void {
    // Not logging due to the large number of events
    if (this[list_name]) {
      this[list_name][e.index]['mouseentered'] = true;
    }
  }

  updateOrderOfList(list: string): void {
    if (this[list]) {
      for (let i = 0; i < this[list].length; i++) {
        this[list][i]['_order'] = i;
      }
    }
  }

  hasTactic(tactic: string) {
    if (!tactic) {
      return;
    }
    return this.initial_tactics && this.initial_tactics.length > 0 && this.initial_tactics.find(it => it.tactic == tactic) != null;
  }

  /**
   * function to show and hide columns in the mastery criteria table =
   *
   * @param property - string - name of the property that we need to check in the mastery_criteria object
   * @param idx      - int    - index of the mastery criteria that we are checking
   */
  masteryPropIsAllowed(property: string, idx?: number) {
    if (!property) {
      return;
    }
    if (idx != null && !this.is_print_view) {
      if (this.mastery_criteria_items[idx].mastery_criteria_allowed &&
        this.mastery_criteria_items[idx].mastery_criteria_allowed.length == 0) {
        return true;
      }
      const ret_val = this.mastery_criteria_items[idx].mastery_criteria_allowed && this.mastery_criteria_items[idx].mastery_criteria_allowed.length > 0 && this.mastery_criteria_items[idx].mastery_criteria_allowed.find(mca => mca == property) != null;
      return ret_val;
    } else if (this.is_print_view) {
      if (this.mastery_criteria_allowed && this.mastery_criteria_allowed.length == 0) {
        return true;
      }
      const ret_val = this.mastery_criteria_allowed && this.mastery_criteria_allowed.length > 0 && this.mastery_criteria_allowed.find(mca => mca == property) != null;
      return ret_val;
    } else {
      if (this.mastery_criteria.mastery_criteria_allowed && this.mastery_criteria.mastery_criteria_allowed.length == 0) {
        return true;
      }
      return this.mastery_criteria.mastery_criteria_allowed && this.mastery_criteria.mastery_criteria_allowed.length > 0 &&
        this.mastery_criteria.mastery_criteria_allowed.find(mca => mca == property) != null;
    }
  }

  masteryPropIsRequired(property: string, idx?: number) {
    if (!property) {
      return;
    }
    if (idx != null) {
      if (this.mastery_criteria_items[idx].mastery_criteria_required && this.mastery_criteria_items[idx].mastery_criteria_required.length == 0) {
        return true;
      }
      const ret_val = this.mastery_criteria_items[idx].mastery_criteria_required && this.mastery_criteria_items[idx].mastery_criteria_required.length > 0 && this.mastery_criteria_items[idx].mastery_criteria_required.find(mca => mca == property) != null;
      return ret_val;
    } else {
      if (this.mastery_criteria.mastery_criteria_required && this.mastery_criteria.mastery_criteria_required.length == 0) {
        return true;
      }
      return this.mastery_criteria.mastery_criteria_required && this.mastery_criteria.mastery_criteria_required.length > 0 && this.mastery_criteria.mastery_criteria_required.find(mca => mca == property) != null;
    }
  }

  private getTacticsForList() {
    const no_chaining_types = !this.teaching_format_chaining_types || this.teaching_format_chaining_types.size == 0;
    if (this.all_choices && this.all_choices.size > 0) {
      for (let idx = 0; idx < this.initial_tactics.length; idx++) {
        if (no_chaining_types) {
          this.initial_tactics[idx].tactics = this.all_choices.get('initialtactic').get('tactic');
        } else {
          this.chainingTypeChange(this.initial_tactics[idx].chaining_type, idx);
        }
      }
    }
  }

  updateTeachingFormatRelatedObjs(list: string): void {
    // hide the invalid initial tactics message
    if (list == 'initial_tactics' && this.initial_tactics && this.initial_tactics.length == 0) {
      // now we re-update the lists for teaching formats if the tactics list is cleared:
      this.teachingFormatChange(null);
    }
    if (list == 'initial_tactics' && !this.hasTactic(this.INITIAL_TACTIC_SESSION)) {
      // need to update initial tactic
    }
    if (list == 'initial_tactics' && !this.hasTactic(this.INITIAL_TACTIC_CRITERION)) {
      this.procedure.prompt_type = null;
      if (this.prompt_fading_steps && this.prompt_fading_steps.length > 0) {
        if (!this.prompt_fading_steps_to_delete) {
          this.prompt_fading_steps_to_delete = [];
        }
        for (let i = 0; i < this.prompt_fading_steps.length; i++) {
          if (this.prompt_fading_steps[i].id != null) {
            this.prompt_fading_steps_to_delete.push(this.prompt_fading_steps[i]);
          }
        }
        this.prompt_fading_steps = [].slice();
      }
    }
  }

  removeObj(obj: object, remove_key: string, list: string, title: string, second_key?: string) {
    if (remove_key && this.obj_remove_functions[remove_key]) {
      let idx = this[list].findIndex(l => l.id == obj['id']);
      if (this.procedure.id != null && obj && obj['id']) {
        this.obj_remove_functions[remove_key].call(this.procedureViewService, obj)
          .then((response) => {
            if (response) {
              this[list].splice(idx, 1);
              this.updateOrderOfList(list);

              this.updateTeachingFormatRelatedObjs(list);
            }
            this.addToast('Successfully deleted ' + title, 'success');
          })
          .catch((err) => {
            this.addToast('Could not delete ' + title + ', please try again later...', 'Error');

            throw err;
          })
      } else {
        if (second_key != null) {
          idx = this[list].findIndex(l => l._order == obj[second_key]);
        }
        this[list].splice(idx, 1);
        this.updateOrderOfList(list);
      }
      if (this.inline_backups.has(list) && this.inline_backups.get(list) != null && this.inline_backups.get(list)[idx] != null) {
        this.inline_backups.get(list).splice(idx, 1);
      }
      this.updateTeachingFormatRelatedObjs(list);
    }
  }

  dataCollectionMethodChange($event: any) {
    this.updateProcedure();
    // this.current_data_sheet = this.all_data_sheets.find(ds => ds.id == $event);
    // update the teaching format based on the datasheet
    this.updateTeachingFormat()
    // reset the mastery criteria form if we change the data sheet
    this.masteryCriteriaForm.reset();
    this.mastery_criteria = new MasteryCriteria();
    this.dynamic_field_options = this.getDataSheetMasteryMapping()
  }

  updateTeachingFormat() {
    console.log('updating teaching format')
    // first we get the keys of the teaching format and loop over the mapping till we find one with the datasheet we want
    const dataCollectionMethodMapping = this.dataCollectionMapping[this.procedure.data_collection_method]
    if (dataCollectionMethodMapping) {
      this.procedure.teaching_format = dataCollectionMethodMapping.teaching_format
      this.procedure.data_sheet = dataCollectionMethodMapping.data_sheet_id
      this.data_sheet = {
        id: dataCollectionMethodMapping.data_sheet_id,
        code: dataCollectionMethodMapping.data_sheet_code
      }
    }

    // this.procedure.teaching_format = 'fooooooo'
    this.teachingFormatChange(null)
  }

  setDataSheetAndCollectionMapping() {
    if (this.dataCollectionReverseMapping && this.procedure.teaching_format != null && this.procedure.data_sheet != null) {
      this.procedure.data_collection_method = this.dataCollectionReverseMapping[`${this.procedure.teaching_format}-${this.procedure.data_sheet}`]
    }
  }

  setIgnoreIds() {
    this.ignore_ids = this.commonService.getAllIds(this.data_sheets, this.codes_to_ignore, 'code')
  }

  submitSubForm(form: NgForm): void {
    form['is_submitted'] = true;
    if (form.valid) {
      form.reset();
      form['is_submitted'] = false;
    }
  }

  formChange(): void {
    if (this.currentForm === this.procedureForm) {
      return;
    }

    this.procedureForm = this.currentForm;
    if (this.procedureForm) {
      this.procedureForm.valueChanges
        .subscribe(data => this.onValueChanged());
    }
  }

  onValueChanged() {
    if (!this.procedureForm) {
      return;
    }
    const form = this.procedureForm.form;

    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }
    this.reinforcements_error = !!(!this.reinforcements ||
      (this.reinforcements && this.reinforcements.length === 0));
    this.initial_tactics_error = !!(!this.initial_tactics ||
      (this.initial_tactics && this.initial_tactics.length === 0));
    this.num_trials_error = !!(!this.num_trials ||
      (this.num_trials && this.num_trials.length === 0));
  }

  submitForm() {
    // just a dummy function for the moment
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const control = this.currentForm.form.get(field);

      if (control && !control.valid) {
        const messages = AppValidationMessages.errorMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] = messages[key];
        }
      }
    }
    this.reinforcements_error = !!(!this.reinforcements ||
      (this.reinforcements && this.reinforcements.length === 0));
    this.initial_tactics_error = !!(!this.initial_tactics ||
      (this.initial_tactics && this.initial_tactics.length === 0));
    this.num_trials_error = !!(!this.num_trials ||
      (this.num_trials && this.num_trials.length === 0));
  }

  handleBlur($event: any, idx?: number) {
    if (!$event.target.value || $event.target.value < 2) {
      if (idx != null) {
        if (this.mastery_criteria_items[idx].min_trials_per_person) {
          this.mastery_criteria_items[idx].min_trials_per_person = null;
        }
      } else {
        if (this.mastery_criteria.min_trials_per_person) {
          this.mastery_criteria.min_trials_per_person = null;
        }
      }
    }
  }

  ngAfterViewChecked(): void {
    this.formChange();
  }

  ngOnInit() {
    this.loading = true;
    this.subscriptions = [];
    this.service_plans = [];
    this.phases = [];
    this.tactics = [];
    this.reinforcements = [];
    this.reinforcement_types = [];
    this.reinforcement_types_customised = [];
    this.initial_tactics = [];
    this.num_trials = [];
    this.num_trial_types = [];
    this.prompt_fading_steps = [];
    this.procedure_steps = [];
    this.sd_responses = [];
    this.proc_items = [];
    this.reinforcements_touched = false;
    // this.mastery_criteria_allowed = [];
    // this.mastery_criteria_required = [];
    this.publish_statuses = [];
    this.prompt_types = [];
    this.mastery_criteria_items = [];
    this.baseline_text = '';
    this.inline_backups = new Map<string, any[]>();
    this.initial_tactic_desc = new Map<string, string>();
    this.prompt_fading_steps_to_delete = [];
    this.ignore_ids = [];
    this.procedure = _.cloneDeep(this.procedureViewService.currentSubject.getValue());
    this.reinforcement_schedule = new ReinforcementSchedule();
    this.initial_tactic = new InitialTactic();
    this.num_trial = new NumTrial();
    this.prompt_fading_step = new PromptFadingStep();
    this.procedure_step = new ProcedureStep();
    this.sd_response = new SdResponse();
    this.proc_item = new ProcItem();
    this.mastery_criteria = new MasteryCriteria();
    this.mastery_criteria_allowed = [];
    this.mastery_criteria_accuracy_calc_fields = [];
    if (!this.procedure) {
      this.procedure = new Procedure();
    }
    this.baseline_text = this.procedure.baseline;
    this.title = this.is_print_view ? this.procedure.name : 'Procedure';

    if (!this.img_src) {
      this.img_src = this.default_img_src;
    }
    this.getChoices().then(() => {
      if (this.procedure.id == null) {
        this.loading = false;
      } else {
        this.procedure_backup = _.cloneDeep(this.procedure);
        if (this.procedure && this.procedure.id != null && this.procedure.can_edit != null) {
          this.can_edit = this.procedure.can_edit;
        }
        if (this.is_print_view) {
          this.can_edit = false;
        }
        this.has_name = this.procedure.name != null;

        this.getNumTrials();
        this.getInitialTactics();
        this.getReinforcementSchedules();
        this.getPromptFadingSteps();
        this.getProcedureSteps();
        this.getSdResponses();
        this.getProcItems();
        this.getMasteryCriteriaItems();
      }

      // this.getDataSheets();
      this.setDataSheetAndCollectionMapping()
      this.updateTeachingFormat()
      this.setIgnoreIds()
      this.dynamic_field_options = this.getDataSheetMasteryMapping();

    });

    this.subscribeToPatient();
    this.subscribeToSap();
    this.getSkillObjectives();
    this.subscribeToNonFieldErrorMessage();
    this.subscribeToGettingDataSheets();

    this.currentFormService.currentSubject.next(this.currentForm);
  }

  ngOnDestroy(): void {
    this.updateProcedure();
    this.unsubscribe();
    this.currentFormService.currentSubject.next(null);

    // this.is_print_view = false;
    // this.can_edit = false;
  }

}
