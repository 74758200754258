import { Component, ElementRef, OnInit } from '@angular/core';
import { DocumentViewService } from '../services/document-view.service';
import { Document } from '../../core/models/document.model';
import { CommonService } from '../../core/shared/common/common.service';

@Component({
  selector: 'app-preview-document',
  templateUrl: './preview-document.component.html',
  styleUrls: ['./preview-document.component.css']
})
export class PreviewDocumentComponent implements OnInit {

  document: Document;
  loading: boolean;
  invalidFile: boolean = false;
  isPDF = false;

  constructor(private documentViewService: DocumentViewService, private commonSerivce: CommonService, private hostElement: ElementRef,) { }

  generateBlobURL() {
    const filenameParts = this.document.filename.split('.')
    const ext = filenameParts[filenameParts.length - 1]
    const allowedExts = ['txt', 'jpg', 'jpeg', 'png', 'pdf']
    const officeExts = ['ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx']

    this.isPDF = false;

    if (allowedExts.includes(ext)) {
      if (this.document.file_content) {
        const fileBlob = this.commonSerivce.b64toBlob(this.document.file_content, this.document.filetype);
        const blobURL = URL.createObjectURL(fileBlob);

        const iframe = this.hostElement.nativeElement.querySelector('iframe');
        iframe.src = blobURL;

        if (ext === 'pdf') {
          setTimeout(() => {
            this.isPDF = true;
          }, 1000);
        }

        return
      }
    } else if (officeExts.includes(ext)) {
      if (this.document.file) {
        const iframe = this.hostElement.nativeElement.querySelector('iframe');
        iframe.src = 'https://docs.google.com/viewerng/viewer?embedded=true&url=' + this.document.file;
  
        return
      }
    }
    
    this.invalidFile = true
  }

  ngOnInit() {
    this.document = this.documentViewService.currentSubject.getValue()
    this.loading = true
    this.documentViewService.getPreviewById(this.document.id)
      .then((documentObj: Document) => {
        this.document = documentObj
        this.loading = false
        this.generateBlobURL()
      })
      .catch((err) => {
        this.loading = false
        // this.addToast('No file to preview', 'error');
        this.invalidFile = true
        throw err;
      })
  }
}
