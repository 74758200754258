import { Injectable } from '@angular/core';
import {ToastData, ToastOptions, ToastyService} from 'ng2-toasty';

@Injectable()
export class ToastService {

  toastyId: number;
  DEFAULT_TITLE = 'Error';

  constructor(private toastyService: ToastyService) {
  }

  private getToastOptions(message: string, title: string, theme?: string, timeout?: number, callback?: any): ToastOptions {
    title = title != null ? title : this.DEFAULT_TITLE;
    timeout = timeout != null ? timeout : 5000;
    theme = theme != null ? theme : 'default';

    return {
      title: title,
      msg: message,
      showClose: true,
      timeout: timeout,
      theme: theme,
      onAdd: (toast: ToastData) => {
        console.log('Toast ' + this.toastyId + ' has been added!');
        this.toastyId = toast.id;
      },
      onRemove: callback
    };
  }

  public addToast(message: string, title: string, theme?: string, timeout?: number, callback?: any) {
    const toastOptions = this.getToastOptions(message, title, theme, timeout, callback);

    switch (title.toLowerCase()) {
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
      case 'saving':
        // toastOptions
        this.toastyService.wait(toastOptions);
        break;
    }

  }
}
