import {Base} from './base.model';

export class Behaviour extends Base<Behaviour> {
  name: string;
  hypothesised_function: string;
  measurement: string;
  patient: number;
  edit: boolean;

  constructor() {
    super(Behaviour)
  }
}
