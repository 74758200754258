import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CurrentFormService {

  currentSubject: BehaviorSubject<any> = new BehaviorSubject({});
  currentErrorMessage: BehaviorSubject<any> = new BehaviorSubject({});
  currentNonFieldErrorMessage: BehaviorSubject<string> = new BehaviorSubject('');
  constructor() { }

}
