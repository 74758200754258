import {Base} from './base.model';
import {SheetInfo} from './sheet-info.model';

export class TherapySession extends Base<TherapySession> {
  id: number;
  is_complete: boolean;
  host: number;
  patient: number;
  location: string;
  date: string;
  date_date: Date;
  hours: string;
  in_data_sheet: boolean;
  after_complete: boolean;
  filtering: boolean;
  after_filter_complete: boolean;
  ignore_update: boolean;
  clear_filter: boolean;
  state_sheet_meta: SheetInfo;
  is_error_finish?: boolean;
  constructor() {
    super(TherapySession);
  }
}
