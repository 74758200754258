import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseSessionComponent} from '../../../shared/base-session/base-session.component';
import {UserService} from '../../../core/services/user.service';
import {DialogService} from '@progress/kendo-angular-dialog';
import {PatientService} from '../../../core/services/patient.service';
import {ToastyService} from 'ng2-toasty';
import {ConstantsService} from '../../../core/services/constants.service';
import {UserViewService} from '../../../shared/services/user-view.service';
import {ProcedureService} from '../../../core/services/procedure.service';
import {FilterViewService} from '../../../shared/services/filter-view.service';
import {ShowNavService} from '../../../core/services/show-nav.service';
import {DailySessionViewService} from '../../../shared/services/daily-session-view.service';
import {CurrentFormService} from '../../../core/services/current-form.service';
import {DataSheetService} from '../../../core/services/data-sheet.service';
import {BehaviorSubject, combineLatest, of, Subscription, throwError} from 'rxjs';
import {TherapySession} from '../../../core/models/therapy-session.model';
import {Procedure} from '../../../core/models/procedure.model';
import {SelectOption} from '../../../core/models/select-option.model';
import {ProcedureSession} from '../../../core/models/procedure-session.model';
import {User} from '../../../core/models/user.model';
import {DataSheet} from '../../../core/models/data-sheet.model';
import * as moment from 'moment';
import {Moment} from 'moment';
import {SessionTrial} from '../../../core/models/session-trial.model';
import {ProcedureViewService} from '../../../shared/services/procedure-view.service';
import {ProcItem} from '../../../core/models/proc-item.model';
import {SdResponse} from '../../../core/models/sd-response.model';
import * as _ from 'lodash';
import {PromptFadingStep} from '../../../core/models/prompt-fading-step.model';
import {SheetInfo} from '../../../core/models/sheet-info.model';
import {CommonService} from '../../../core/shared/common/common.service';
import {SessionData} from '../../../core/models/session-data.model';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-multiple-example-instruction',
  templateUrl: './multiple-example-instruction.component.html',
  styleUrls: ['./multiple-example-instruction.component.css']
})
export class MultipleExampleInstructionComponent extends BaseSessionComponent implements OnInit, OnDestroy {
  @Input() data_sheet_type: string;
  private _data_sheets = new BehaviorSubject<any[]>([]);
  private _patient = new BehaviorSubject<any>({});
  private _sheet_info = new BehaviorSubject<any>({});
  private _is_readonly: boolean;
  private _readonly_data: any;
  private _userViewService: UserViewService;

  getting_data = false;
  filtered: any[];
  DELIMITER = '&';
  MAX_NUM_ITEMS = 4; // max number of items is 4
  DEFAULT_SESSION_NUM = 5; // max number of trials per item (as a requirement, there are 5 trials per item)
  procedure_sessions: ProcedureSession[];
  all_procedure_sessions: ProcedureSession[];
  procedures: Procedure[];
  dailySessionViewService: DailySessionViewService;
  currentFormService: CurrentFormService;
  _procedureViewService: ProcedureViewService;
  stages: SelectOption[];
  prompt_types: SelectOption[];
  daily_session_trial_options: SelectOption[];
  current_user: User;
  data_sheet: DataSheet;
  selected_host: number;
  loading: boolean;
  procedure_session_timeout_map: Map<number, any> = new Map<number, any>();
  procedure_session_timeout: any;
  current_therapist: User = new User();
  saving_trial: boolean;
  current_procedure_id: number;
  current_procedure: Procedure;
  all_procedures: Procedure[];
  prompt_steps: PromptFadingStep[];
  proc_items: ProcItem[];
  all_proc_items: ProcItem[];
  sd_responses: SdResponse[];
  selected_date: Date;
  session_trial_index: number[];
  phase: number;
  prompt_step: number;
  stage: string;
  procedure_session_defaults: any[];
  groups: SelectOption[];
  current_group: string;
  proc_item_ids: number[];
  phase_type_tactics_map: Map<number, any[]>;
  dailySessionsSavedSubscription: Subscription;
  therapySessionSubscription: Subscription;
  isPhaseError: boolean;

  // saving_proc_session: Map<number, BehaviorSubject<number>>;

  // objects for readonly view
  item_groups: string[];
  readonly_sessions: Map<string, any[]>; // need to keep track of the items index, since different sessions might have different items

  constructor(dialogService: DialogService,
              constantsService: ConstantsService,
              dailySessionViewService: DailySessionViewService,
              userViewService: UserViewService,
              private procedureService: ProcedureService,
              private patientService: PatientService,
              currentFormService: CurrentFormService,
              private showNavService: ShowNavService,
              private userService: UserService,
              private dataSheetService: DataSheetService,
              toastyService: ToastyService,
              private filterViewService: FilterViewService,
              procedureViewService: ProcedureViewService,
              private commonService: CommonService) {
    super(constantsService, userViewService, dialogService, dailySessionViewService,
      currentFormService, toastyService, procedureViewService);
    this.dailySessionViewService = dailySessionViewService;
    this.currentFormService = currentFormService;
    this._userViewService = userViewService;
    this._procedureViewService = procedureViewService;
  }

  @Input()
  set data_sheet_list(data: any[]) {
    this._data_sheets.next(data);
  }

  get data_sheets() {
    return this._data_sheets;
  }

  @Input()
  set patient(obj: any) {
    this._patient.next(obj);
  }

  get patient() {
    return this._patient;
  }

  @Input()
  set sheet_info(obj: any) {
    this._sheet_info.next(obj);
  }

  get sheet_info() {
    return this._sheet_info;
  }

  @Input()
  set is_readonly(readonly: boolean) {
    this._is_readonly = readonly;
  }

  get is_readonly() {
    return this._is_readonly;
  }

  @Input()
  set data(readonly_data: any) {
    this._readonly_data = readonly_data;
  }

  get data() {
    return this._readonly_data;
  }

  filterSessionsByProcedure($event: string) {
    let date: string;
    let host: number;
    this.loading = true;
    this.max_row_num = this.DEFAULT_SESSION_NUM;
    if ($event) {
      this.max_row_num = this.DEFAULT_SESSION_NUM;

      const param_vals = $event.split(this.DELIMITER);
      host = +param_vals[0];
      date = param_vals[1];
      // this.getTherapySession(date, host);
    } else {
      // we show the latest one
      // this.getTherapySession(date, host);
      this.loading = false;
    }
  }

  filterSessions(event: any, procedure_change?: boolean): void {

    // if (this.selected_host != null && this.selected_date != null) {
    //   debugger;
    let temp: Moment;
    let date: string;
    // might be able to remove this check later
    // if (!procedure_change) {
    temp = moment(this.selected_date);
    date = !temp.isValid() ? null : temp.format('YYYY-MM-DD');
    // }

    this.getTherapySession(date, this.selected_host, true);
    // console.log(this.selected_host, this.selected_date);
    // }
  }

  updateGlobalSessionValues() {
    if (this.procedure_sessions && this.procedure_sessions.length > 0) {
      const procedure_session = this.procedure_sessions[0];
      this.phase = procedure_session.phase;
      this.prompt_step = procedure_session.prompt_step;
      this.stage = procedure_session.stage;
    }
  }

  clearGlobalSessionValues(): void {
    this.prompt_step = null;
    this.stage = null;
    this.phase = null;
    this.current_group = null;
  }

  private sessionFilter(group: any): void {
    // this.procedure_sessions = [];
    if (this.all_proc_items && this.all_proc_items.length > 0) {
      this.proc_items = this.all_proc_items.filter(pi => pi.group == group)
        .filter(pi => this.proc_item_ids.includes(pi.id));
      this.proc_items = _.take(this.proc_items, this.MAX_NUM_ITEMS);
      const proc_item_ids = _.map(this.proc_items, 'id');
      console.log(this.all_proc_items);
      // const procedure_sessions: ProcedureSession[] = [];
      this.procedure_sessions = this.all_procedure_sessions
        .filter(ps => ps.state_group == group && ps.procedure == this.current_procedure.id);
      // if (!this.procedure_sessions || this.procedure_sessions.length == 0) {
      // estting initial session_trials array:

      for (const proc_session of this.procedure_sessions) {
        // if (!proc_session.session_trials || proc_session.session_trials.length == 0) {
        proc_session.session_trials = this.padSessionTrials([]);
        // } else {
        //   proc_session.session_trials = this.padSessionTrials(proc_session.session_trials);
        // }
      }
    }

  }

  private getTrainingOrBaselineSessions(): ProcedureSession[] {
    return this.procedure_sessions.reduce((accum, curr) => {
      if (curr.stage === 'training' || curr.stage === 'baseline') {
        accum.push(curr);
      }
      return accum;
    }, []);
  }

  private getMeiDataEntrySheet(procedure_sessions: ProcedureSession[]) {
    if (this.procedure_sessions.length < this.sd_responses.length) {
      // jus tin case we don't have any
      const new_procedure_sessions = Array
        .apply(null, Array((this.sd_responses.length)))
        .map(() => new ProcedureSession());
      for (let i = 0; i < this.sd_responses.length; i++) {
        new_procedure_sessions[i].sd = this.sd_responses[i].id;
        new_procedure_sessions[i].procedure = this.current_procedure_id;
        new_procedure_sessions[i].session_trials = this.padSessionTrials([]);
      }
      this.procedure_sessions = _.cloneDeep(new_procedure_sessions);
    }
    for (let i = 0; i < procedure_sessions.length; i++) {
      if (procedure_sessions[i]._order == i) {
        this.procedure_sessions[i] = _.cloneDeep(procedure_sessions[i]);
      }
    }
  }

  filterSessionsByGroup(event: any): void {
    if (event) {
      this.sessionFilter(event);
      this.orderProceduresBySds();

      if (!this.therapy_session.is_complete) {
        this.getNewProcedureSessionList();
      }
      this.getSessionTrials();
      this.updateGlobalSessionValues();
    }
  }

  getNewProcedureSessionList(): void {
    const procedure_sessions: ProcedureSession[] = [];
    for (const sd_response of this.sd_responses) {
      const ids = this.commonService.getAllIds(this.procedure_sessions, [sd_response.id.toString()], 'sd');
      // now we get the proc session default
      const proc_default = this.procedure_session_defaults.find(
        psd =>
          psd.item_group == this.current_group);

      if (!ids || ids.length === 0) {
        const procedure_session = new ProcedureSession();
        procedure_session.procedure = this.current_procedure_id;
        procedure_session.phase_types = this.current_procedure.phase_types;
        procedure_session.phase = proc_default != null ? proc_default.phase : this.phase;
        this.phase = proc_default != null ? proc_default.phase : this.phase;

        procedure_session.prompt_step = proc_default != null ? proc_default.prompt_step : this.prompt_step;
        this.prompt_step = proc_default != null ? proc_default.prompt_step : this.prompt_step;

        procedure_session.state_group = this.current_group;
        procedure_session.stage = proc_default != null ? proc_default.stage : this.stage;
        this.stage = proc_default != null ? proc_default.stage : this.stage;

        procedure_session.prompt_steps = proc_default != null ? proc_default.state_prompt_steps : [];
        procedure_session.proc_items = [];
        const proc_item_length = this.proc_items != null ? this.proc_items.length : 1;

        procedure_session.session_trials = Array
          .apply(null, Array((this.max_row_num * proc_item_length)))
          .map(() => new SessionTrial());
        procedure_session.sd_responses = this.sd_responses;
        if (!procedure_session.sd) {
          procedure_session.sd = sd_response.id;
        }
        procedure_sessions.push(_.cloneDeep(procedure_session));
      }
    }
    if (procedure_sessions && procedure_sessions.length > 0) {
      this.procedure_sessions = [...this.procedure_sessions, ...procedure_sessions.slice()];
    }
    if (this.procedure_sessions == null) {
      this.procedure_sessions = [];
    }

    this.updateGlobalSessionValues();
  }

  currentProcedureChange(event?: any): void {
    this.loading = true;
    this.max_row_num = this.DEFAULT_SESSION_NUM;
    // this.getProcedureSteps(event);
    console.log('current procedure', this.current_procedure_id);
    const procedure = this.procedures.find(p => p.id === this.current_procedure_id);
    if (procedure) {
      this.current_procedure = _.cloneDeep(procedure);

      this.proc_item_ids = this.current_procedure.proc_item_ids;
      // this.dailySessionViewService.current
      this.phase_types = this.current_procedure.phase_types;
      this.prompt_steps = this.current_procedure.prompt_steps;
      this.setDefaults();

      // now we set our historical ones
      this.getMeiDataEntrySheet(this.procedure_sessions);

      // now we get the procedure sessions
      this.getSdsForProcedure(true)
        .then(val => {
          this.orderProceduresBySds();

          // this.getMeiDataEntrySheet();

          // should be done here instead of within the 'getSdsForProcedure' function
          this.getPromptSteps(this.procedure_sessions);
          this.getProcItems();
          this.filterSessionsByGroup(this.current_group);

          // and then add the other options from the procedure session here
          // now we add the values for phase/proc_item/etc
          if (this.procedure_sessions.length > 0) {
            this.phase = this.procedure_sessions[0].phase;
          }
          if (this.procedure_sessions.length > 0) {
            this.prompt_step = this.procedure_sessions[0].prompt_step;
          }
          if (this.procedure_sessions.length > 0) {
            this.stage = this.procedure_sessions[0].stage;
          }
          this.loading = !!val;

        })
        .catch(err => {
          console.log(err);
        });
      // this.getPromptSteps();
      // this.getProcItems();
      this.filterSessions(event, true);
    } else{
      this.clearGlobalSessionValues();
      this.procedure_sessions = [];
      throw Error('Procedure was not found');
    }
  }

  trialClick(trial: SessionTrial, procedure_session?: ProcedureSession, trial_idx?: number,
             proc_item_idx?: number, proc_sesh_idx?: number): void {
    // if the procedure session doesn't exist yet, then add it
    // function to save procedure returns the id
    let promise: Promise<number | void>;
    if (!procedure_session.id && !this.saving_proc_session.has(proc_sesh_idx)) {

      if (procedure_session.state_group) {
        procedure_session.state_group = this.current_group;
      }
      promise = this.saveProcedureSession(proc_sesh_idx);
      this.saving_proc_session.set(proc_sesh_idx, new BehaviorSubject<number>(null));
    } else if (!procedure_session.id && this.saving_proc_session.has(proc_sesh_idx)) {
      promise = this.saving_proc_session.get(proc_sesh_idx).toPromise();
    } else {
      promise = Promise.resolve(procedure_session.id);
    }

    promise.then((id: number) => {
      procedure_session.id = id;

      if (this.saving_proc_session.has(proc_sesh_idx)) {
        this.saving_proc_session.delete(proc_sesh_idx);
      }
      // this.procedure_sessions[proc_sesh_idx].id = id;
      let dailySessions: Map<number, SessionTrial[]> = this.dailySessionViewService.dailySessions.getValue();
      if (dailySessions == null) {
        dailySessions = new Map<number, SessionTrial[]>();
      }

      // if (!this.trial_timeout_map.has(procedure_session.id)) {
      //   this.trial_timeout_map.set(procedure_session.id, new Map<number, any[]>());
      // }
      if (trial.count == null) {
        trial.count = 0;
      } else if (trial.count === 2) {
        trial.count = null;
      } else {
        trial.count = (trial.count + 1) % 3;
      }

      if (trial.count != null) {
        trial.value = this.daily_session_trial_options[trial.count].value;
      } else {
        trial.value = null;
      }
      if (proc_item_idx != null) {
        // because of how the session trials are laid out, each of the items have 5 rows
        // so the index is based on the procedure item and there can be a max of 5 rows for each - then the trial is based on it's idx
        // this is taken from the list of numbers in session_trial_index (integer from 0 - 5)
        trial._order = (proc_item_idx * this.max_row_num) + trial_idx;
        if (this.proc_items && this.proc_items.length > 0) {
          trial.proc_item = this.proc_items[proc_item_idx].id;
        }
      }

      // // here we set the timestamps to check we have saved everything
      // trial.timestamp = moment().toDate();
      // if (!trial.earliest) {
      //   this.saveTrial(trial, procedure_session, index, procedure_session_idx)
      // }

      if (!dailySessions.has(procedure_session.id)) {
        // if we don't have the object in the map then we create it
        dailySessions.set(procedure_session.id, new Array(this.proc_items.length * this.max_row_num));
      }

      // TODO: need to resize the array if the user adds more trials after doing edits
      dailySessions.get(procedure_session.id)[(proc_item_idx * this.max_row_num) + trial_idx] = trial;
      this.dailySessionViewService.dailySessions.next(dailySessions);
    });
  }

  getDataSheet(data_sheets: DataSheet[]): void {
    this.data_sheet = data_sheets.find(ds => ds.code.toLowerCase() == this.data_sheet_type.toLowerCase());
  }

  getProcedures(): Promise<ProcedureSession[] | void> {
    // TODO: change 'active' to active status from choices
    // TODO: remove the 'get_test' param to get the proper data
    return this.dailySessionViewService.getProcedures(this.patient.getValue().id, this.data_sheet.id)
      .then((procedures: Procedure[]) => {
        this.procedures = [];
        this.all_procedures = procedures != null ? procedures.slice() : [];
        if (this.all_procedures && this.all_procedures.length > 0) {
          for (let i = 0; i < this.all_procedures.length; i++) {
            this.all_procedures[i].phase_types = this.constantsService.convertListToSelctOptions(this.all_procedures[i].valid_phase_types);
          }
          // this.procedures = _.cloneDeep(this.all_procedures);

          this.current_procedure_id = this.all_procedures[0].id;
          this.current_procedure = this.all_procedures[0];
          this.phase_types = this.current_procedure.phase_types;

          // this.getPromptSteps();
          // this.getProcItems();
          this.currentProcedureChange();
          this.loading = false;
        } else {
          this.loading = false;
          this.procedure_sessions = [];
        }
        // this.getProcItems();
        return this.procedure_sessions;
      })
      .catch((err) => {
        // need to show toasty with error
        this.loading = false;
        this.procedure_sessions = [];
        throw err;
      });
  }

  getNewProcedureSession(after_complete?: boolean): void {
    this.setDefaults();
    this.getSdsForProcedure(after_complete)
      .then(val => {
        // this.getMeiDataEntrySheet();
        this.orderProceduresBySds();

        // should be done here instead of within the 'getSdsForProcedure' function
        this.getPromptSteps(this.procedure_sessions);
        this.getProcItems();
        // and then add the other options from the procedure session here
        // now we add the values for phase/proc_item/etc
        if (this.procedure_sessions.length > 0) {
          this.phase = this.procedure_sessions[0].phase;
        }
        if (this.procedure_sessions.length > 0) {
          this.prompt_step = this.procedure_sessions[0].prompt_step;
        }
        if (this.procedure_sessions.length > 0) {
          this.stage = this.procedure_sessions[0].stage;
        }
        this.loading = !!val;
      })
      .catch(err => {
        console.log(err);
      });
  }

  getSdsForProcedure(after_complete?: boolean): Promise<any> {
    let promise: Promise<any>;
    if (!this.procedures || !this.current_procedure) {
      this.loading = false;
      promise = of(null).toPromise();
      return promise
    }
    if (!after_complete) {
      let procedure_idxs: number[] = this.procedures.map(p => p.id);

      procedure_idxs = procedure_idxs != null ? procedure_idxs.filter(ps => ps != null) : [];
      if (procedure_idxs && procedure_idxs.length > 0) {
        promise = this._procedureViewService.getSdResponsesByProcedureIds(procedure_idxs)
          .then(sd_responses => {
            const grouped_responses = _(sd_responses)
              .groupBy(st => st.procedure)
              .value();

            const keys = Object.keys(grouped_responses);
            for (const key of keys) {
              let proc_idx = this.all_procedures.findIndex(p => p.id == +key);

              if (proc_idx > -1) {
                this.all_procedures[proc_idx].sd_responses = grouped_responses[key].slice();
              }

              proc_idx = this.procedures.findIndex(p => p.id == +key);

              if (proc_idx > -1) {
                this.procedures[proc_idx].sd_responses = grouped_responses[key].slice();
                if (this.current_procedure_id == this.procedures[proc_idx].id) {
                  this.sd_responses = this.procedures[proc_idx].sd_responses.slice();

                  if (this.procedure_sessions == null || this.procedure_sessions.length == 0) {
                    const procedure_sessions: ProcedureSession[] = [];

                    for (const sd_response of this.sd_responses) {
                      // TODO: add the below in when we have data coming from the backend

                      const procedure_session = new ProcedureSession();
                      procedure_session.procedure = this.current_procedure_id;
                      procedure_session.phase_types = this.current_procedure.phase_types;
                      procedure_session.prompt_steps = [];
                      procedure_session.proc_items = [];
                      const proc_tem_length= this.proc_items != null ? this.proc_items.length : 0;
                      procedure_session.session_trials = Array
                        .apply(null, Array((this.max_row_num * proc_tem_length)))
                        .map(() => new SessionTrial());
                      if (procedure_session.session_trials == null) {
                        procedure_session.session_trials = [];
                      }
                      // procedure_session.sd_responses = sd_responses;
                      if (!procedure_session.sd) {
                        procedure_session.sd = sd_response.id;
                      }
                      procedure_sessions.push(_.cloneDeep(procedure_session));
                    }
                    this.procedure_sessions = procedure_sessions.slice();
                  }
                  // this.getPromptSteps(this.procedure_sessions);
                  // this.getProcItems();
                }
              }
            }
            return false;
          });
      } else {
        promise = of(false).toPromise();
      }
    } else {
      this.sd_responses = this.current_procedure.sd_responses || []; // get the sd_responses from the current procedure or an empty list
      if (this.procedure_sessions == null || this.procedure_sessions.length == 0) {
        const procedure_sessions: ProcedureSession[] = [];
        for (const sd_response of this.sd_responses) {
          const procedure_session = new ProcedureSession();
          procedure_session.procedure = this.current_procedure_id;
          procedure_session.phase_types = this.current_procedure.phase_types;
          procedure_session.prompt_steps = [];
          procedure_session.proc_items = [];
          const proc_tem_length = this.proc_items != null ? this.proc_items.length : 0;

          procedure_session.session_trials = Array
            .apply(null, Array((this.max_row_num * proc_tem_length)))
            .map(() => new SessionTrial());
          if (procedure_session.session_trials == null) {
            procedure_session.session_trials = [];
          }
          procedure_session.sd_responses = this.sd_responses;
          if (!procedure_session.sd) {
            procedure_session.sd = sd_response.id;
          }
          procedure_sessions.push(_.cloneDeep(procedure_session));
        }
        this.procedure_sessions = procedure_sessions.slice();
      }
      // else {
      //   for (let i = 0; i < this.procedure_sessions.length; i++) {
      //     this.procedure_sessions[i].sd_responses = this.sd_responses;
      //   }
      // }
      // this.getPromptSteps(this.procedure_sessions);
      // this.getProcItems();
      promise = of(false).toPromise();
    }

    return promise;
  }

  getFilteredProcItems(): void {
    // now we need to filter by the groups
    if (this.procedure_sessions && this.procedure_sessions.length > 0) {
      const proc_item_ids = this.proc_item_ids;
      // get the first training procedure
      const proc_sessions = this.getTrainingOrBaselineSessions();

      this.current_group = proc_sessions != null && proc_sessions.length > 0 ? proc_sessions[0].state_group : this.current_group;
      if (proc_item_ids && proc_item_ids.length > 0) {
        const proc_items: ProcItem[] = this.all_proc_items.filter(pi => proc_item_ids.indexOf(pi.id) > -1);
        const groups = proc_items.reduce((accum, curr) => {
          const obj = [curr.group, curr.group];
          const idx = accum.findIndex(a => a[0] == obj[0]);
          if (idx === -1) {
            accum.push(obj.slice());
          }
          return accum;
        }, []);
        this.groups = this.constantsService.convertListToSelctOptions(groups);
      }
    }
  }

  getProcItems(is_group_change?: boolean): void {
    if (this.procedures && this.procedures.length > 0 && !this.therapy_session.after_complete) {
      let procedure_idxs: number[] = this.procedures.map(p => p.id);

      procedure_idxs = procedure_idxs != null ? procedure_idxs.filter(ps => ps != null) : [];
      if (procedure_idxs && procedure_idxs.length > 0) {
        this.dailySessionViewService.getProcItemsByProcedureIds(procedure_idxs)
          .then(proc_items => {
            const grouped_items = _(proc_items)
              .groupBy(st => st.procedure)
              .value();

            const keys = Object.keys(grouped_items);
            for (const key of keys) {
              let proc_idx = this.all_procedures.findIndex(p => p.id == +key);

              if (proc_idx > -1) {
                this.all_procedures[proc_idx].proc_items = grouped_items[key].slice();
              }

              proc_idx = this.procedures.findIndex(p => p.id == +key);
              if (proc_idx > -1) {
                this.procedures[proc_idx].all_proc_items = grouped_items[key].slice();
                if (!this.therapy_session.is_complete) {
                  this.procedures[proc_idx].proc_items = _.take(this.procedures[proc_idx].all_proc_items
                    .filter(s => this.proc_items_to_show.indexOf(s.stage) > -1), this.MAX_NUM_ITEMS);
                }
                console.log(this.procedures[proc_idx].proc_items);
                const idxs = this.commonService.getAllIndexes(this.procedure_sessions, +key, 'procedure');

                if (idxs && idxs.length > 0) {
                  for (const idx of idxs) {
                    if (idx > -1) {
                      if (this.procedures[proc_idx].proc_items != null) {
                        this.procedure_sessions[idx].proc_items = this.procedures[proc_idx].proc_items.slice();
                      } else {
                        this.procedure_sessions[idx].proc_items = [];
                      }
                    }
                  }
                }

                if (this.procedures[proc_idx].id == this.current_procedure_id) {
                  this.all_proc_items = this.procedures[proc_idx].all_proc_items;
                  this.proc_items = this.procedures[proc_idx].proc_items;
                  this.getFilteredProcItems();
                  for (let idx = 0; idx < this.procedure_sessions.length; idx++) {
                    this.procedure_sessions[idx].session_trials = this.padSessionTrials(this.procedure_sessions[idx].session_trials);
                  }
                }
              }
            }
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    } else if (this.therapy_session.after_complete) {
      for (const procedure of this.procedures) {
        const idx = this.procedure_sessions.findIndex(ps => ps.procedure == procedure.id);
        if (idx != null && idx > -1) {
          this.procedure_sessions[idx].proc_items = procedure.proc_items;
          // TODO: need to pad based on the proc items
          this.procedure_sessions[idx].session_trials = this.padSessionTrials(this.procedure_sessions[idx].session_trials);
        }
        if (procedure.id == this.current_procedure_id) {
          this.proc_items = procedure.proc_items;
          this.all_proc_items = procedure.all_proc_items;
          this.getFilteredProcItems();
        }
      }
      // now we delete the after complete because we are done
      delete this.therapy_session.after_complete;
      this.dailySessionViewService.currentSubject.next(this.therapy_session);
    }
    this.updateSession();
  }

  getPromptSteps(procedure_sessions?: ProcedureSession[]): void {
    if (this.current_procedure && this.procedures.length > 0 && !this.therapy_session.after_complete && !this.therapy_session.is_complete) {
      // const proc_sessions = _.filter(procedure_sessions, ps => ps.procedure == this.current_procedure_id);

      // this.procedure_sessions = proc_sessions != null ? proc_sessions.slice() : []; //  why??

      let procedure_idxs: number[] = this.procedures.map(p => p.id);

      procedure_idxs = procedure_idxs != null ? procedure_idxs.filter(ps => ps != null) : [];
      if (procedure_idxs && procedure_idxs.length > 0) {
        this.dailySessionViewService.getPromptFadingStepsByProcIds(procedure_idxs)
          .then(prompt_fading_steps => {
            const grouped_steps = _(prompt_fading_steps)
              .groupBy(st => st.procedure)
              .value();
            // this.max_col_span = session_trials.length > this.max_col_span ? session_trials.length : this.max_col_span;
            const keys = Object.keys(grouped_steps);
            for (const key of keys) {
              const all_proc_idx = this.all_procedures.findIndex(p => p.id == +key);
              if (all_proc_idx > -1) {
                this.all_procedures[all_proc_idx].prompt_steps = grouped_steps[key];
              }

              const proc_idx = this.procedures.findIndex(p => p.id == +key);
              if (proc_idx > -1) {
                if (grouped_steps[key] != null) {
                  this.procedures[proc_idx].prompt_steps = grouped_steps[key].slice();
                } else {
                  this.procedures[proc_idx].prompt_steps = [];
                }
              }
              if (this.current_procedure.id === +key) {
                if (grouped_steps[key] != null) {
                  this.prompt_steps = grouped_steps[key].slice();
                } else {
                  this.prompt_steps = [];
                }
              }
              const idxs = this.commonService.getAllIndexes(this.procedure_sessions, +key, 'procedure');

              if (idxs && idxs.length > 0) {
                for (const idx of idxs) {
                  if (idx > -1) {
                    this.procedure_sessions[idx].prompt_steps = grouped_steps[key];
                  }
                }
              }
            }
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    } else if (this.therapy_session.after_complete) {
      // const proc_sessions = _.filter(procedure_sessions, ps => ps.procedure == this.current_procedure_id);
      // this.procedure_sessions = proc_sessions != null ? proc_sessions.slice() : [];
      for (let i = 0; i < this.procedure_sessions.length; i++) {
        this.procedure_sessions[i].session_trials = this.padSessionTrials(this.procedure_sessions[i].session_trials);
      }
    }
    this.updateSession();
  }

  getTherapySession(date_filter?: string, host_id?: number, procedure_change?: boolean, is_complete?: boolean): void {
    this.dailySessionViewService.getProcedureSessionByTherapySessionAndProcedureAndDataSheet(this.therapy_session.id, this.current_procedure.id, this.data_sheet.id)
      .then((procedure_sessions) => {
        if (procedure_sessions && procedure_sessions.length > 0) {
          for (let i = 0; i < procedure_sessions.length; i++) {
            const procedure: Procedure = this.procedures.find(p => p.id == procedure_sessions[i].procedure);
            if (procedure && Object.keys(procedure).length > 0) {
              procedure_sessions[i].phase_types = procedure.phase_types;
            }
          }
          let proc_sessions = _.filter(procedure_sessions, ps => ps.procedure == this.current_procedure_id);
          proc_sessions = proc_sessions != null ? proc_sessions : [];
          // this.procedure_sessions = proc_sessions.slice();
          this.all_procedure_sessions = procedure_sessions.slice();

          this.setDefaults();

          this.setProcSessionValues();
          this.getMeiDataEntrySheet(proc_sessions);

          this.getSdsForProcedure()
            .then(val => {
              this.orderProceduresBySds();
              this.getSessionTrials();
              // this.getMeiDataEntrySheet();

              // should be done here instead of within the 'getSdsForProcedure' function
              this.getPromptSteps(this.procedure_sessions);
              this.getProcItems();
              this.filterSessionsByGroup(this.current_group);

              // and then add the other options from the procedure session here
              // now we add the values for phase/proc_item/etc
              if (this.procedure_sessions.length > 0) {
                this.phase = this.procedure_sessions[0].phase;
              }
              if (this.procedure_sessions.length > 0) {
                this.prompt_step = this.procedure_sessions[0].prompt_step;
              }
              if (this.procedure_sessions.length > 0) {
                this.stage = this.procedure_sessions[0].stage;
              }
              this.loading = !!val;
            })
            .catch(err => {
               console.log(err);
            });
          // need to add any missing trials

          // this.getPromptSteps(procedure_sessions);
          // this.getProcItems();
          //
        } else if (procedure_sessions && procedure_sessions.length == 0 &&
          this.selected_host != null) {
          // this.selected_date != null && this.selected_host != null) {
          this.procedure_sessions = [];
          this.all_procedure_sessions = [];

          this.loading = false;
        } else if (!this.therapy_session.filtering || !this.therapy_session.is_complete) {
          this.getNewProcedureSession();
        } else {
          this.procedure_sessions = [];
          this.loading = false;
        }
      });
  }

  getSessionTrials(): void {
    if (this.procedure_sessions && this.procedure_sessions.length > 0) {

      let proc_session_ids = this.procedure_sessions.map(ps => ps.id);
      proc_session_ids = proc_session_ids != null ? proc_session_ids.filter(ps => ps != null) : [];
      if (proc_session_ids && proc_session_ids.length > 0) {
        this.dailySessionViewService.getSessionTrialsByProcedureSessionIds(proc_session_ids)
          .then(session_trials => {
            const grouped_sessions = _(session_trials)
              .groupBy(st => st.procedure_session)
              .value();

            const keys = Object.keys(grouped_sessions);
            for (const key of keys) {
              const temp: SessionTrial[] = [];
              // for each session trial we then set the count to the index of the value
              // from static choices
              const trials = this.commonService.sortByProperty(grouped_sessions[key], '_order');
              for (let idx = 0; idx < trials.length; idx++) {
                trials[idx].count = this.daily_session_trial_options.findIndex(dst => dst.value == trials[idx].value);

                temp.push(_.cloneDeep(trials[idx]));
              }
              const proc_session_idx = this.procedure_sessions.findIndex(ps => ps.id === +key);
              if (proc_session_idx > -1) {
                this.procedure_sessions[proc_session_idx].session_trials = temp.slice();
                this.procedure_sessions[proc_session_idx].session_trials = this.padSessionTrials(this.procedure_sessions[proc_session_idx].session_trials);
              }
            }
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
    this.updateSession();
  }

  saveSession(i?: number, complete_session?: boolean): Promise<TherapySession> {
    if (i != null) {
      if (this.procedure_sessions[i].procedure == null) {
        this.procedure_sessions[i].procedure = this.current_procedure_id;
      }
      this.dailySessionViewService.currentProcedureSessions.next([this.procedure_sessions[i]]);
    }
    return this.dailySessionViewService.save(complete_session)
      .then((therapy_session) => {
        // in theory we should be ok as the values shold be passed by reference
        this.therapy_session = _.cloneDeep(therapy_session);
        if (this.therapy_session.id != null) {
          const saved_procedure_sessions = this.dailySessionViewService.currentProcedureSessions.getValue();
          for (const session of saved_procedure_sessions) {
            const idx = this.procedure_sessions.findIndex(ps => ps.id == session.id);
            if (idx > -1) {
              this.procedure_sessions[idx] = _.merge(this.procedure_sessions[idx] || {}, session);
              this.procedure_sessions[idx].session_trials = this.padSessionTrials(this.procedure_sessions[idx].session_trials || []);
            } else if (i != null) {
              // let's assume it's new and use 'i' from the parent context
              this.procedure_sessions[i] = _.merge(this.procedure_sessions[i] || {}, session);
              this.procedure_sessions[i].session_trials = this.padSessionTrials(this.procedure_sessions[i].session_trials || []);
            }
          }
        }

        return therapy_session;
        // for now we buffer up to the default max
      });
  }

  saveProcedureSession(idx: number): Promise<number | void> {
    // TODO: remove below line so we can save stuff
    // return Promise.resolve(this.therapy_session.id);


    // for now let's skip this so we don't save anyhing
    if (this.procedure_sessions[idx].procedure != null) {
      this.procedure_sessions[idx].saving = true;
      if (this.procedure_sessions[idx].therapy_session == null) {
        this.procedure_sessions[idx].therapy_session = this.therapy_session.id;
      }
      if (this.procedure_sessions[idx].perc_correct == '') {
        this.procedure_sessions[idx].perc_correct = null;
      }
      if (this.procedure_sessions[idx].total_trials == '') {
        this.procedure_sessions[idx].total_trials = null;
      }
      if (this.procedure_sessions[idx]._order == null) {
        this.procedure_sessions[idx]._order = idx;
      }
      return this.dailySessionViewService.saveProcedureSession(this.procedure_sessions[idx])
        .then((procedureSession: ProcedureSession) => {
          // in theory we should be ok as the values shold be passed by reference
          if (this.procedure_sessions[idx].default_session_idx != null) {
            this.setTherapySessionStateId(this.procedure_session_defaults[this.procedure_sessions[idx].default_session_idx], procedureSession.id);
            // this.therapy_session.state_sheet_meta[this.procedure_sessions[idx].default_session_idx].procedure_session_id = procedureSession.id;
            delete this.procedure_sessions[idx].default_session_idx;
          }
          // this.procedure_sessions[idx].id = procedureSession.id;
          this.procedure_sessions[idx] = _.merge(this.procedure_sessions[idx], procedureSession);

          this.procedure_sessions[idx].saving = false;
          if (this.procedure_sessions[idx].perc_correct == null) {
            this.procedure_sessions[idx].edit_perc_correct = false;
          }
          if (this.procedure_sessions[idx].total_trials == null) {
            this.procedure_sessions[idx].edit_total_trials = false;
          }
          if (this.saving_proc_session.has(idx)) {
            this.saving_proc_session.get(idx).next(this.procedure_sessions[idx].id);
          }
          // now we update all_session list
          let all_idx = this.all_procedure_sessions.findIndex(aps => aps.id == this.procedure_sessions[idx].id);
          if (all_idx > -1) {
            // if we have an index, then it's an update
            this.all_procedure_sessions[all_idx] = _.merge(this.all_procedure_sessions[all_idx], this.procedure_sessions[idx]);
          } else {
            // we may have a new procedure session, so we have to find it based on the group and the sd
            all_idx = this.all_procedure_sessions.findIndex(aps => aps.state_group == this.procedure_sessions[idx].state_group &&
              aps.sd == this.procedure_sessions[idx].sd && aps.procedure == this.procedure_sessions[idx].procedure);
            if (all_idx > -1) {
              this.all_procedure_sessions[all_idx] = _.merge(this.all_procedure_sessions[all_idx], this.procedure_sessions[idx]);
            }
          }
          return this.procedure_sessions[idx].id;
          // for now we buffer up to the default max
        })
        .catch(
          (err) => {
            this.procedure_sessions[idx].saving = false;

            throw err;
          });
    } else {
      return throwError('no phase has been selected').toPromise();
    }

  }

  updateSessions(): void {
    for (let i = 0; i < this.procedure_sessions.length; i++) {
      this.procedure_sessions[i].phase = this.phase;
      this.procedure_sessions[i].stage = this.stage;
      this.procedure_sessions[i].prompt_step = this.prompt_step;
      if (this.procedure_sessions[i].phase != null) {
        this.saveProcedureSession(i)
          .then((proc_session_id: number) => {
            if (this.isPhaseError) {
              this.isPhaseError = false;
            }
            console.log('finished saving proc session number: ' + i);
            const idx = this.all_procedure_sessions.findIndex(aps => aps.id == proc_session_id);
            if (idx == -1) {
              this.all_procedure_sessions.push(_.cloneDeep(this.procedure_sessions[i]));
            }
          }).catch((err) => {
            if (this.isPhaseError) {
              this.isPhaseError = false;
            }
            if (err.error) {
              for (let key in err.error) {
                this.addToast(err.error[key][0], 'error');
                if (key === 'phase') {
                  this.isPhaseError = true;
                }
              }
            }
            console.log(err);

            throw err;
          });
      }
    }
  }

  updateSession(is_trial_click?: boolean, procedure?: ProcedureSession, idx?: number,
                procedure_session_idx?: number): Promise<boolean | void> {
    console.log('the current this object: ', this);
    // don't need this anymore
    // this.dailySessionViewService.currentSubject.next(this.therapy_session);
    this.dailySessionViewService.currentProcedureSessions.next(this.procedure_sessions);
    if (is_trial_click) {

      // TODO: remove below line so we can save stuff

      // return Promise.resolve(true);

      // setTimeout(() => {
      let promise: Promise<number | void>;
      if (procedure.id == null) {
        promise = this.saveProcedureSession(procedure_session_idx);
      } else {
        promise = Promise.resolve(this.procedure_sessions[procedure_session_idx].id);
      }
      return promise.then((procedure_id: number) => {
        let sub_promise: Promise<any>;
        if (procedure.session_trials[idx].procedure_session == null) {
          procedure.session_trials[idx].procedure_session = procedure_id;
        }
        // save it if we have a value, otherwise we delete it
        if (procedure.session_trials[idx].value == null && procedure.session_trials[idx].id != null) {
          sub_promise = this.dailySessionViewService.deleteSessionTrial(procedure.session_trials[idx]);
        } else if (procedure.session_trials[idx].value != null) {
          console.log(procedure.session_trials[idx]);
          sub_promise = this.dailySessionViewService.saveSessionTrial(procedure.session_trials[idx]);
        } else {
          console.log(procedure.session_trials[idx]);
          sub_promise = Promise.resolve(procedure.session_trials[idx]);
        }
        return sub_promise;
      })
        .then((res: any) => {
          // because the return values could either be a session tiral or a success code
          // for deleting the object successfully, then need to check if we have the id
          // if we don't then it should've been deleted
          if (res.id != null) {
            procedure.session_trials[idx].id = res.id;
          } else {
            delete procedure.session_trials[idx].id;
          }

          const all_idx = this.all_procedure_sessions.findIndex(aps => aps.id == procedure.id);
          if (all_idx > -1) {
            this.all_procedure_sessions[all_idx].session_trials = _.cloneDeep(procedure.session_trials);
          }

          // now we get the procedure session
          if (procedure.getting_procedure_id == null) {
            // setting the trial that is getting the procedure
            procedure.getting_procedure_id = idx;
            return this.dailySessionViewService.getProcedureSessionById(procedure.id);
          } else {
            return Promise.resolve(procedure);
          }
        })
        .then((procedure_session) => {
          procedure = Object.assign(procedure, procedure_session);
          if (procedure.getting_procedure_id == idx) {
            procedure.getting_procedure_id = null;
          }
          if (procedure.perc_correct == null) {
            procedure.edit_perc_correct = false;
          }
          if (procedure.total_trials == null) {
            procedure.edit_total_trials = false;
          }

          return true;
        })
        .catch((err) => {
          throw err;
        });
      // }, 10);
    }
  }

  padSessionTrials(session_trials: SessionTrial[]): SessionTrial[] {
    if (!this.proc_items || this.proc_items.length === 0) {
      return session_trials;
    }
    // now we know the session trials, if any, are sorted by _order
    let new_session_trials: SessionTrial[] = this.fillSessionTrials(session_trials);

    if (this.proc_items && this.proc_items.length > 0) {
      if ((this.max_row_num * this.proc_items.length) - new_session_trials.length < 0) {
        this.addToast('There are too many session trials for procedure, Please contact your system administrator', 'error');

      } else {

        new_session_trials = [...new_session_trials, ...(Array
          .apply(null, Array((this.max_row_num * this.proc_items.length) - new_session_trials.length))
          .map(() => new SessionTrial()))];
      }

    }
    return new_session_trials;
  }

  padProcedureSessions(procedure_sessions: ProcedureSession[], sd_responses: SdResponse[]): ProcedureSession[] {
    // the number of procedure sessions shoulw be the number of sd_responses
    const padded_amt = sd_responses.length;
    return procedure_sessions.concat(Array
      .apply(null, Array(padded_amt))
      .map(() => {
        const session = new ProcedureSession();
        session.procedure = this.current_procedure_id;
        return session;
      }));
    // }
  }

  private setProcedureSessionDropdowns() {
    if (this.procedure_sessions && this.procedure_sessions.length > 0) {
      for (const procedure_session of this.procedure_sessions) {
        const proc_idx = this.all_procedures.findIndex(p => p.id == procedure_session.procedure);
        if (proc_idx > -1) {
          procedure_session.prompt_steps = this.all_procedures[proc_idx].prompt_steps;
          procedure_session.proc_items = this.all_procedures[proc_idx].proc_items;
        }
      }
    }
  }

  orderProceduresBySds(): void {
    // let's try using the sd_responses instead
    const procedure_sessions = _.cloneDeep(this.procedure_sessions);
    if (procedure_sessions && procedure_sessions.length > 0){
      let sd_ids = procedure_sessions[0]['state_sds'];
      if (!sd_ids) {
        sd_ids = procedure_sessions[0]['sd_responses'].map(sr => sr.id);
      }
      const new_procedures = [];
      if (sd_ids != null && sd_ids.length > 0) {
        // const keys = Object.keys(sd_order);
        // if (keys && keys.length > 0) {
          for (const sd of sd_ids) {
            const proc = procedure_sessions.find(p => p.sd == sd);
            if (proc) {
              new_procedures.push(_.cloneDeep(proc));
            }
          }
        // }
      }
      if (new_procedures && new_procedures.length > 0) {
        this.procedure_sessions = new_procedures.slice();
      }
    }
  }

  setDefaults(): void {
    const procedure_sessions: ProcedureSession[] = _.cloneDeep(this.all_procedure_sessions) || [];
    this.procedures = [];
    // now we loop over all the defaults we have found
    if (this.procedure_session_defaults && this.procedure_session_defaults.length > 0) {

      for (let i = 0; i < this.procedure_session_defaults.length; i++) {
        const session_default = this.procedure_session_defaults[i];
        if (this.all_procedures && this.all_procedures.length > 0) {
          const procedure = this.all_procedures.find(p => p.id == session_default.procedure);
          if (procedure) {
            if (this.procedures.length == 0 || this.procedures.findIndex(p => p.id === procedure.id) === -1) {
              procedure.prompt_step_ids = session_default.state_prompt_steps;
              procedure.proc_item_ids = session_default.state_items;
              this.procedures.push(_.cloneDeep(procedure));
            }

            const proc_item_ids = [...this.proc_item_ids, ...session_default.state_items];
            this.proc_item_ids = Array.from(new Set(proc_item_ids));

            if (procedure_sessions.findIndex(ps => ps.procedure == session_default.procedure &&
              ps.sd == session_default.sd && ps.state_group == session_default.item_group) > -1) {
              continue;
            }
            let procedure_session: ProcedureSession;
            if (session_default.procedure_session_id != null) {
              procedure_session = this.procedure_sessions.find(ps => ps.id == session_default.procedure_session_id);
            }

            if (!procedure_session) {
              let proc_sessions = _.filter(this.procedure_sessions, ps => ps.procedure == procedure.id);
              if (proc_sessions && proc_sessions.length > 0) {
                proc_sessions = _.filter(proc_sessions, ps => ps.prompt_step == session_default.prompt_step);
              }
              if (proc_sessions && proc_sessions.length > 0) {
                proc_sessions = _.filter(proc_sessions, ps => ps.sd == session_default.sd);
              }
              if (proc_sessions && proc_sessions.length > 0) {
                proc_sessions = _.filter(proc_sessions, ps => ps.state_proc_items &&
                  ps.state_proc_items.some(spi => session_default.state_items.includes(spi)));
              }
              if (proc_sessions && proc_sessions.length > 0) {
                procedure_session = proc_sessions[0];
              }
            }
            if (!procedure_session) {
              procedure_session = new ProcedureSession();
              procedure_session.default_session_idx = i;
              procedure_session.procedure = procedure.id;
            }
            if (session_default.warnings && session_default.warnings.length > 0) {
              procedure_session.warning = session_default.warnings.join(', ');
            }
            procedure_session.phase_types = procedure.phase_types;
            procedure_session.prompt_steps = [];
            procedure_session.prompt_step_ids = session_default.state_prompt_steps || [];
            procedure_session.proc_items = [];
            procedure_session.proc_item_ids = session_default.state_items || [];
            procedure_session.state_group = session_default.item_group;
            procedure_session.sd_order = session_default.sd_order;
            procedure_session['state_sds'] = session_default.state_sds;

            if (procedure_session.sd == null) {
              procedure_session.sd = session_default.sd;
            }
            if (procedure_session.phase == null) {
              procedure_session.phase = session_default.phase;
            }
            if (procedure_session.proc_item == null) {
              procedure_session.proc_item = session_default.item;
            }
            if (procedure_session.prompt_step == null) {
              procedure_session.prompt_step = session_default.prompt_step;
            }
            if (procedure_session.stage == null) {
              procedure_session.stage = session_default.stage;
            }
            // if (session_default && session_default.phase_type_tactics && 'mei' in session_default.phase_type_tactics) {
            //   this.phase_type_tactics_list.push(session_default.phase_type_tactics['mei']);
            // } else {
            //   this.phase_type_tactics_list.push(session_default.phase_type_tactics || procedure.phase_type_tactic);
            // }
            if (!this.phase_type_tactics_map.has(procedure.id)) {
              this.phase_type_tactics_map.set(procedure.id, []);
            }
            this.phase_type_tactics_map.get(procedure.id).push(session_default.phase_type_tactics || procedure.phase_type_tactic);

            if (!procedure_session.session_trials || procedure_session.session_trials.length == 0) {
              const proc_item_length = this.proc_items != null ? this.proc_items.length : 1;
              procedure_session.session_trials = Array
                .apply(null, Array((this.max_row_num * proc_item_length)))
                .map(() => new SessionTrial());
            }
            if (!procedure_session.id ||
              (procedure_session.id && procedure_sessions.findIndex(ps => ps.id === procedure_session.id) === -1)) {
              procedure_sessions.push(_.cloneDeep(procedure_session));
            }

            // updateing the procedure session in all_procedure_sesions
            const idx = this.all_procedure_sessions.findIndex(ps => ps.id === procedure_session.id);
            if (idx > -1) {
              this.all_procedure_sessions[idx] = _.merge(this.all_procedure_sessions[idx], procedure_session);
            }

          }
        }
      }
    }
    const proc_sessions = _.filter(procedure_sessions, ps => ps.procedure == this.current_procedure_id);

    this.procedure_sessions = proc_sessions != null ? proc_sessions.slice() : [];
    if (!this.all_procedure_sessions || this.all_procedure_sessions.length == 0) {
      this.all_procedure_sessions = procedure_sessions.slice();
    }
  }


  subscribeToTherapySession(): void {
    if (this.therapySessionSubscription) return;

    this.therapySessionSubscription = this.dailySessionViewService.currentSubject.subscribe(
      (therapy_session: TherapySession) => {

        if (this.therapy_session && !this.therapy_session.after_complete && !this.therapy_session.ignore_update && !this.therapy_session.is_error_finish) {
          this.therapy_session = therapy_session;
          if (this.therapy_session.is_complete && !this.therapy_session.filtering) {
            this.loading = true;
            this.saveSession(null, true)
              .then((completed) => {
                this.max_row_num = this.DEFAULT_SESSION_NUM;
                this.procedure_sessions = [];
                this.getNewProcedureSession(true);
                this.loading = false;
              })
              .catch((err) => {
                //this.loading = false;
                throw err;
              });
            return;
          }
          if (therapy_session && therapy_session.id != null) {
            if (!therapy_session.after_complete) {
              this.loading = true;
              this.therapy_session = therapy_session;
              // if (this.therapy_session.filtering) {
                this.procedures = [];
                this.procedure_sessions = [];
                this.proc_items = [];
                this.clearGlobalSessionValues();
              // }
              // if (!this.procedure_sessions || this.procedure_sessions.length == 0) {

                this.dailySessionViewService.getProcedureSessionByTherapySessionAndDataSheet(
                  this.therapy_session.id,
                  this.data_sheet.id,
                  this.therapy_session.filtering
                )
                  .then((procedure_sessions) => {
                    if (procedure_sessions && procedure_sessions.length > 0) {

                      for (let i = 0; i < procedure_sessions.length; i++) {
                        const procedure: Procedure = this.procedures.find(p => p.id == procedure_sessions[i].procedure);
                        if (procedure && Object.keys(procedure).length > 0) {
                          procedure_sessions[i].phase_types = procedure.phase_types;
                        }
                      }
                      // filter the proceudres by the current procedure_id

                      const filtered_procedure_sessions = _.filter(procedure_sessions, ps => ps.procedure == this.current_procedure_id).slice();
                      this.all_procedure_sessions = procedure_sessions.slice();
                      if (this.therapy_session.state_sheet_meta && this.therapy_session.state_sheet_meta.data) {
                        const data = this.therapy_session.state_sheet_meta.data.filter(d => d.data_sheet_code == this.data_sheet_type);
                        console.log(data);
                        this.procedure_session_defaults = data.slice();
                      } else {
                        this.procedure_session_defaults = [];
                      }

                      this.setDefaults();

                      // now we set our historical ones
                      this.getMeiDataEntrySheet(filtered_procedure_sessions);

                      this.setProcSessionValues();

                      this.getSdsForProcedure().then(val => {
                        // should be done here instead of within the 'getSdsForProcedure' function
                        this.orderProceduresBySds();
                        this.getSessionTrials();

                        this.getPromptSteps(this.procedure_sessions);
                        this.getProcItems();
                        this.filterSessionsByGroup(this.current_group);

                        // and then add the other options from the procedure session here
                        // now we add the values for phase/proc_item/etc
                        if (this.procedure_sessions.length > 0) {
                          this.phase = this.procedure_sessions[0].phase;
                        }
                        if (this.procedure_sessions.length > 0) {
                          this.prompt_step = this.procedure_sessions[0].prompt_step;
                        }
                        if (this.procedure_sessions.length > 0) {
                          this.stage = this.procedure_sessions[0].stage;
                        }
                        this.loading = !!val;
                      })
                        .catch(err => {
                          console.log(err);
                        });
                      // this.getPromptSteps(procedure_sessions);
                      // this.getProcItems();
                      //
                    } else if (procedure_sessions && procedure_sessions.length == 0 &&
                      this.selected_host != null) {
                      // this.selected_date != null && this.selected_host != null) {
                      this.procedure_sessions = [];
                      this.all_procedure_sessions = [];

                      this.loading = false;
                    } else if (!this.therapy_session.filtering || !this.therapy_session.is_complete) {
                      this.getNewProcedureSession();
                    } else {
                      this.procedure_sessions = [];
                      this.loading = false;
                    }
                  }).catch(err => {
                    console.log(err);

                    this.setProcedureSessionDropdowns();
                    this.loading = false;
                });

            }
          }
        } else if (this.therapy_session.ignore_update) {
          delete this.therapy_session.ignore_update;
          this.loading = false;
          // this.therapy_session = therapy_session;
        }
        if (this.therapy_session.is_error_finish) {
          delete this.therapy_session.is_error_finish;
          this.loading = false;
        }
      });
  }

  private setProcSessionValues() {
    const proc_sessions = this.getTrainingOrBaselineSessions();

    this.phase = proc_sessions != null && proc_sessions.length > 0 ? proc_sessions[0].phase : null;
    this.prompt_step = proc_sessions != null && proc_sessions.length > 0 ? proc_sessions[0].prompt_step : null;
    this.stage = proc_sessions != null && proc_sessions.length > 0 ? proc_sessions[0].stage : '';
    this.current_group = proc_sessions != null && proc_sessions.length > 0 ? proc_sessions[0].state_group : '';

    this.procedure_sessions = this.procedure_sessions.filter(aps => aps.state_group == this.current_group && aps.procedure == this.current_procedure_id);
    this.sessionFilter(this.current_group);
  }

  subscribeToCurrentUser(): void {
    const curr_user = this.userService.currentUserUpdated.getValue();
    if (curr_user) {
      this.current_user = curr_user;
      if (this.therapy_session && this.therapy_session.host == null) {
        this.therapy_session.host = this.current_user.id;
      }

    }
    this.subscriptions.push(this.userService.currentUserUpdated.subscribe(
      (current_user) => {
        this.current_user = current_user;
        if (this.therapy_session.can_edit && this.therapy_session.host == null) {
          this.therapy_session.host = this.current_user.id;
        }
        // this.selected_host = this.current_user.id
      }));
  }

  subscribeToUsers(): void {
    this.subscriptions.push(this.allUsersSubject.subscribe(
      (users: User[]) => {
        if (this.therapy_session && this.therapy_session.host != null && this.therapy_session.id != null) {
          this.current_therapist = users.find(ul => ul.id === this.therapy_session.host);
          if (this.current_therapist && this.therapy_session &&
            this.therapy_session.host == null && this.therapy_session.id != null) {
            this.therapy_session.host = this.current_therapist.id;
          }
        }
      }));
  }

  subscribeToSessionCache(): void {
    // TODO: simplify this data structure that's returned to a list of ids instead of a map
    if (this.dailySessionsSavedSubscription) return;

    this.dailySessionsSavedSubscription = this.dailySessionViewService.dailySessionsSaved.subscribe((sessions) => {
      const keys = sessions.keys();
      let key = keys.next();
      while (!key.done) {
        const k = key.value;
        // now need to check each of the procedure sessions found here and in the 'future' save to update with the id or delete
        // NOTE: we don't need to do this anymore since we are disabling the UI

        const idx = this.procedure_sessions.findIndex(ps => ps.id == k);
        const ret_list = sessions.get(k);
        for (let i = 0; i < ret_list.length; i++) {
          const saved = ret_list[i];
          if (this.procedure_sessions[idx] &&
            this.procedure_sessions[idx].session_trials != null &&
            this.procedure_sessions[idx].session_trials.length > saved._order) {
            this.procedure_sessions[idx].session_trials[saved._order].id = saved.id;
          }

        }

        // now we just reload the procedure sessions that were saved
        this.dailySessionViewService.getProcedureSessionById(k)
          .then((procedure_session: ProcedureSession) => {
            if (idx > -1) {
              this.procedure_sessions[idx] = _.merge(this.procedure_sessions[idx], procedure_session);
              const index = this.all_procedure_sessions.findIndex(ps => ps.id == procedure_session.id);
              if (index == -1) {
                this.all_procedure_sessions = [...this.all_procedure_sessions, ...[procedure_session]];
              } else {
                this.all_procedure_sessions[index] = _.cloneDeep(procedure_session);
              }
            }

          });
        key = keys.next();
      }

      this.saving_trial = false;
      if (this.toastyId != null) {
        this.toastyService.clear(this.toastyId);
      }
      if (sessions.size > 0) {
        this.addToast('Done', 'success');
      }
      this.toastyId = null;

    });
  }

  subscribeToSearchFilterSubject(): void {
    this.subscriptions.push(this.filterViewService.dataSheetSearchFilterSubject.subscribe(
      (filter?: string) => {
        if (filter !== null && filter !== undefined) {
          this.filterSessionsByProcedure(filter);
        }
      }));
  }

  getInitialData(): void {
    combineLatest([this.data_sheets, this.sheet_info, this.patient])
      .subscribe((res) => {
        // by now we should have everything, if we don't then this doesn't run anyway
        const data_sheets = res[0];
        const sheet_info = res[1];
        const patient = res[2];
        if (data_sheets && data_sheets.length > 0) {
          this.getDataSheet(data_sheets);
        }
        if (sheet_info && Object.keys(sheet_info).length > 0 && sheet_info['data'] != null) {
          const data = sheet_info['data'].filter(d => d.data_sheet_code == this.data_sheet_type);
          console.log(data);
          this.procedure_session_defaults = data.slice();

        }
        if (this.data_sheet && this.patient.getValue() && this.patient.getValue().id != null) {
          this.getData();
        }
      });
  }

  private getData(): void {
    this.getting_data = true;
    // this.getAllClients();
    this.subscribeToCurrentUser();
    this._userViewService.getUsersPromise().then((users) => {
      this.users_list = this.filterHosts(users);
      this.getProcedures().then((procedures) => {
        this.setDefaults();
        return this.getSdsForProcedure(false);
      }).then(val => {
        this.orderProceduresBySds();
        this.getPromptSteps(this.procedure_sessions);
        this.getProcItems();
        this.filterSessionsByGroup(this.current_group);

        this.getting_data = false;
        this.loading = false;
      });
    });
  }

  processReadonlyData(): void {
    if (this.data) {
      const filtered = this.data.data.filter(d => d.item_group != null);
      // // order by date first
      // const grouped_data = _.chain(filtered)
      //   .orderBy(['date'])
      //   .groupBy(['ts', 'item_group'])
      //   .value();
      const grouped_data = _(filtered)
        .groupBy(x => x.item_group)
        .value();
      console.log(grouped_data);
      const keys = Object.keys(grouped_data);
      for (const key of keys) {
        this.item_groups.push(key);
        this.readonly_sessions.set(key, []);
        for (const obj of grouped_data[key]) {
          const new_session_data = new SessionData();
          new_session_data.description = obj['description'];
          new_session_data.totals = obj['totals'];
          new_session_data.perc_correct = obj['perc_correct'];
          new_session_data.num_trials = obj['state_prescribed_trials'];
          new_session_data.sds = obj['headers'];
          new_session_data.items = obj['items'];
          new_session_data.item_group = obj['item_group'];
          new_session_data.session_trials = [];
          if (obj.counted_for_mastery != null) {
            new_session_data.counted_for_mastery = obj.counted_for_mastery;
          }
          const arr = obj['data'];

          if (arr && arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
              let new_trials = arr[i].map(a => {
                const trial = new SessionTrial();
                trial._order = a._order;
                trial.value = a.value;
                return trial;
              });

              new_trials = this.commonService.sortByProperty(new_trials, '_order');
              new_trials = this.fillSessionTrials(new_trials);
              new_trials = [...new_trials, ...(Array
                .apply(null, Array(Math.abs(new_session_data.num_trials - new_trials.length)))
                .map(() => new SessionTrial()))];
              new_session_data.session_trials.push(_.cloneDeep(new_trials));
            }

          }
          // new_session_data.session_trials = this.padSessionTrials(new_session_data.session_trials, new_session_data.num_trials);
          this.readonly_sessions.get(key).push(_.cloneDeep(new_session_data));
        }
      }
      console.log(this.readonly_sessions);
    }
    this.loading = false;
  }

  ngOnInit() {
    this.cmp_name = 'multiple example instruction';
    this.loading = true;
    this.procedure_sessions = [];
    this.all_procedure_sessions = [];
    this.subscriptions = [];
    this.prompt_types = [];
    this.phase_types = [];
    this.proc_items = [];
    this.sd_responses = [];
    this.prompt_steps = [];
    this.groups = [];
    this.proc_item_ids = [];
    this.phase_type_tactics_map = new Map<number, any[]>();
    this.saving_proc_session = new Map<number, BehaviorSubject<number>>();
    this.max_row_num = this.DEFAULT_SESSION_NUM;
    this.session_trial_index = Array.apply(null, Array(this.max_row_num)).map((curr, idx) => idx);

    this.subscribeToChoices();
    if (!this.is_readonly) {
      this.therapy_session = this.dailySessionViewService.currentSubject.getValue();
      if (!this.therapy_session || this.therapy_session.id == null) {
        this.therapy_session = new TherapySession();
        this.therapy_session.date_date = moment().toDate();
      }
      if (this.therapy_session.ignore_update) {
        delete this.therapy_session.ignore_update;
      }

      this.saving_trial = false;

      this.getInitialData();

      // since list and sheet code is passed in, we can do this here
      this.subscribeToTherapySession();
      this.subscribeToUsers();
      this.subscribeToSessionSave();
      this.subscribeToSessionCache();
      this.subscribeToSavingSessions();
      this.subscribeToSearchFilterSubject();

    } else {
      // else we have a readonly popup to show
      this.item_groups = [];
      this.readonly_sessions = new Map<string, any[]>();
      this.processReadonlyData();
    }

    this.showNavService.showButtons.next(false);

  }

  ngOnDestroy() {
    this.updateSession();
    this.unsubscribe();
    this.showNavService.showButtons.next(true);
    if (this.sessionSave) {
      // killing the incremental save for this page
      this.dailySessionViewService.killTrigger.next();
      this.sessionSave.unsubscribe();
    }
    if (this.sessionSaving) {
      this.sessionSaving.unsubscribe();
    }
    if (this.dailySessionsSavedSubscription) {
      this.dailySessionsSavedSubscription.unsubscribe();
    }
    if (this.therapySessionSubscription) {
      this.therapySessionSubscription.unsubscribe();
    }
  }

}
