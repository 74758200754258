class ItemListOption {
  id: number;
  name: string;
}

export class SheetData {
  procedure_session_id: number;
  chaining_type: string;
  phase: number;
  _procedure_name: string;
  steps: number[];
  num_trials: number;
  phase_type: string;
  _prompt_step_name: string;
  _training_step_name: string;
  data_sheet_code: string;
  prompt_step: number;
  procedure: number;
  training_step: number;
  item: number;
  item_group: string;
  state_sds: number[];
  _proc_change_increment_phase_counter: boolean;
  sd: number;
  items: ItemListOption[];
  stage: string;
  state_items: number[];
  state_prompt_steps: number[];
  _proc_change_increment_phase: boolean;
  sd_name: string;
  sd_order: any;
}

export class SheetInfo {
  data: SheetData[];
}
