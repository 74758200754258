import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getObject'
})
export class GetObjectPipe implements PipeTransform {

  transform(args?: any, id_key?: any, value?: any): any {

    if (!args || typeof args !== 'object' || args == null ||
      !value || value == null || !id_key || id_key == null) {
      return;
    }

    let ret_obj;
    switch(args.constructor.name.toLowerCase()) {
      case 'array':
        ret_obj= args.find(a => a[id_key] == value) || null;
        break;
      case 'object':
        ret_obj = args[value];
        break;
      case 'map':
        ret_obj = args.get(value);
        break;
      default:
        ret_obj = null;
    }

    return ret_obj;
  }

}
