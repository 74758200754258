import {Injectable} from '@angular/core';
// import {Headers, Http, RequestOptions, Response} from '@angular/http';
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';

import {environment as env} from '../../environments/environment';
import {AuthenticationService} from './authentication.service';
import {Observable, Subject, BehaviorSubject, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
// import 'rxjs/add/observable/throw';
import * as moment from 'moment';
import {User} from './models/user.model';
import {GraphData} from './models/graph-data.model';
import {SuccessResponse} from './models/response.model';
import * as _ from 'lodash';
import {IBaseService} from './interfaces/base-interface';

@Injectable()
export class BaseService<T> implements IBaseService<T> {
  apiUrl: string;
  usersUrl: string;
  subRoute: string;
  is_not_archived: string;
  raw_data_url: string;
  current: T;
  collection: T[];
  collectionUpdated: BehaviorSubject<T[]> = new BehaviorSubject([]);
  currentUpdated: Subject<T> = new Subject();
  currentSubject: BehaviorSubject<T>;
  defaultSearchParam: string;
  tagProperties: string[] = ['languages', 'opera_validated', 'attributes'];
  singleProp: string[] = ['opera_validated'];
  _authenticationService: AuthenticationService;
  _http: HttpClient;
  date_obj_keys: string[];
  graph_route: string;
  items_list_route: string;
  itemsToIgnore: string[];
  converted_objs: T[];
  sub_key: string; // key where the data is
  baseApi: string;

  constructor(authenticationService: AuthenticationService,
              http: HttpClient,
              api_endpoint: string,
              private newType: new () => T) {
    this._authenticationService = authenticationService;
    this._http = http;
    this.baseApi = env.api
    this.apiUrl = this.baseApi + api_endpoint
    this.subRoute = '';
    const headers = this.getHeaders();
    this.defaultSearchParam = 'id';
    this.is_not_archived = '';
    this.itemsToIgnore = [];
    this.converted_objs = [];
  }

  getOptions(returnResponse: boolean = false): object {
    const headers = this.getHeaders();
    const options: any = {headers: headers};

    if (returnResponse) {
      options.observe = 'response';
    }
    return options;
  }

  private getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      Accept: 'application/json'
    });
    const token = this._authenticationService.getToken();
    if (token) {
      headers.append(
        'Authorization',
        token.token_type + ' ' + token.access_token
      );
    }
    // headers['Accept'] = 'application/json';
    // headers.append('Content-Type', 'application/json');

    return headers;
  }

  toObj(obj: any, passedType?: new () => any): any {
    const keys = Object.keys(obj);
    const return_obj = passedType != null ? new passedType() : new this.newType();
    for (const key of keys) {
      if (return_obj[key] instanceof Date && typeof obj[key] === 'string') {
        return_obj[key] = new Date(obj[key]);
      } else if (typeof obj[key] === 'string') {
        return_obj[key] = obj[key].trim();
      } else {
        return_obj[key] = obj[key];
      }
    }

    return _.cloneDeep(return_obj);
  }

  // processArray(array, fn): Promise<T> {
  //   const results = [];
  //   return array.reduce(function(p, item) {
  //     return p.then(function() {
  //       return fn(item);
  //     });
  //   }, Promise.resolve());
  // }

  getFormDataObject(json_obj: any): FormData {
    if (!json_obj) {
      return null;
    }
    const fd = new FormData();
    const keys = Object.keys(json_obj);
    let key: string;
    let val: any;
    for (let i = 0; i < keys.length; i++) {
      key = keys[i];
      val = json_obj[key];
      if (val == null ||
        (typeof val == 'string' && (val == '' || val.trim() == '')) ||
        this.itemsToIgnore.indexOf(key) !== -1) {
        continue;
      }

      if (val.constructor === Object && val === Object(val)) {
        // if (val.constructor === Object || val.constructor === Array) {
        fd.append(key, JSON.stringify(val));
      } else if (val.constructor.name === 'File') {
        fd.append(key, val, val.name);
      } else if (typeof val !== 'function') {
        fd.append(key, val);
      }
    }
    return fd;
  }

  protected isArray(o): boolean {
    return Object.prototype.toString.call(o) === '[object Array]';
  }

  // not sure what I'm trying to do here
  get(): Promise<T> {
    // need to move this into the base class
    const url = this.apiUrl + this.subRoute + this.is_not_archived;
    const options = this.getOptions(true);

    return this._http.get(url, options).toPromise()
      .then(
        (res: HttpResponse<T>) => {
          let ret_obj: T;
          if (res && res.ok) {
            ret_obj = res.body;
            this.current = this.toObj(ret_obj);
            this.currentUpdated.next(this.current);
          }
          return ret_obj;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }

  getById(id: number): Promise<T> {
    // need to move this into the base class
    const url = this.apiUrl + id + '/';
    const options = this.getOptions(true);

    return this._http.get(url, options)
      .toPromise()
      .then(
        (res: HttpResponse<T>) => {
          let ret_obj: T;
          if (res && res.ok) {
            ret_obj = res.body;
            if (this.date_obj_keys && this.date_obj_keys.length > 0) {
              for (const date_obj_key of this.date_obj_keys) {
                if (ret_obj[date_obj_key]) {
                  ret_obj[date_obj_key + '_date'] = new Date(ret_obj[date_obj_key]);
                } else {
                  ret_obj[date_obj_key + '_date'] = null;
                }
              }
            }
            this.current = this.toObj(ret_obj);
            this.currentUpdated.next(this.current);
          }
          return ret_obj;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }

  getAll(): Observable<T[]> {
    const options = this.getOptions(true);
    const url = this.apiUrl + this.is_not_archived;

    console.log(url);
    return this._http.get(url, options)
      .pipe(map((res: HttpResponse<T[]>) => {
        this.converted_objs = [];
        let ret_objs: T[];
        if (res && res.ok) {
          ret_objs = !this.sub_key ? res.body : res.body[this.sub_key];
        }
        for (const item of ret_objs) {
          // converting date objects back to strings
          if (this.date_obj_keys && this.date_obj_keys.length > 0) {
            for (const date_obj_key of this.date_obj_keys) {
              if (item[date_obj_key]) {
                item[date_obj_key + '_date'] = new Date(item[date_obj_key]);
              } else {
                item[date_obj_key + '_date'] = null;
              }
            }
          }
          const obj: T = this.toObj(item);
          this.converted_objs.push(_.cloneDeep(obj));
        }
        this.collection = _.cloneDeep(this.converted_objs);
        this.collectionUpdated.next(this.collection);
        return this.collection;
      }));

    // .map((res) => res.json() as Contact[]);
  };

  getAllPromise(): Promise<T[]> {
    const options = this.getOptions(true);

    const url = this.apiUrl + this.is_not_archived;
    return this._http.get(url, options)
      .toPromise()
      .then((res: HttpResponse<T[]>) => {
        this.converted_objs = [];
        let ret_objs: T[];
        if (res && res.ok) {
          ret_objs = res.body;
        }
        for (const item of ret_objs) {
          // converting date objects back to strings
          if (this.date_obj_keys && this.date_obj_keys.length > 0) {
            for (const date_obj_key of this.date_obj_keys) {
              if (item[date_obj_key]) {
                item[date_obj_key + '_date'] = new Date(item[date_obj_key]);
              } else {
                item[date_obj_key + '_date'] = null;
              }
            }
          }
          const obj: T = this.toObj(item);
          this.converted_objs.push(_.cloneDeep(obj));
        }
        this.collection = _.cloneDeep(this.converted_objs);
        this.collectionUpdated.next(this.collection);
        return this.collection;
      })
      .catch((err) => throwError(err).toPromise());

    // .map((res) => res.json() as Contact[]);
  }

  /**
   * Obsolete at the moment, TODO: remove or use this function
   *
   * @param {string} value
   * @param {string} key
   * @returns {Observable<T[]>}
   */
  getByParam(value: string, key?: string): Observable<T[]> {
    if (!value) {
      return of([]);
    }
    key = key == null ? this.defaultSearchParam : key;
    const url = this.apiUrl + '?archived=false&' + key + '=' + value;

    const options = this.getOptions();

    return this._http.get<T[]>(url, options)
      .pipe(
        map((res: T[]) => {
          const ret_objs = res;
          this.converted_objs = [];
          for (const item of ret_objs) {
            // converting date objects back to strings
            if (this.date_obj_keys && this.date_obj_keys.length > 0) {
              for (const date_obj_key of this.date_obj_keys) {
                if (item[date_obj_key]) {
                  item[date_obj_key + '_date'] = new Date(item[date_obj_key]);
                } else {
                  item[date_obj_key + '_date'] = null;
                }
              }
            }
            const obj = this.toObj(item);
            this.converted_objs.push(_.cloneDeep(obj));
          }
          // this.collection = _.cloneDeep(this.converted_objs);
          return this.converted_objs;
        })
      );
  }

  getByParamPromise(value: string, key?: string, get_all?: boolean): Promise<T[]> {
    if (!value) {
      return of([]).toPromise();
    }
    key = key == null ? this.defaultSearchParam : key;
    let url = this.apiUrl + '?';
    if (!get_all) {
      url += 'archived=false&';
    }

    url += key + '=' + value;

    const options = this.getOptions();

    return this._http.get(url, options)
      .toPromise()
      .then((res: T[]) => {
        // should probably use a different object
        const ret_objs = res;
        this.converted_objs = [];
        for (const item of ret_objs) {
          // converting date objects back to strings
          if (this.date_obj_keys && this.date_obj_keys.length > 0) {
            for (const date_obj_key of this.date_obj_keys) {
              if (item[date_obj_key]) {
                item[date_obj_key + '_date'] = new Date(item[date_obj_key]);
              } else {
                item[date_obj_key + '_date'] = null;
              }
            }
          }
          const obj = this.toObj(item);
          this.converted_objs.push(_.cloneDeep(obj));
        }
        // this.collection = _.cloneDeep(this.converted_objs);
        return this.converted_objs;
      })
      .catch((err) => throwError(err).toPromise());
  }


  getByParamObjPromise(params: any, show_all?: boolean): Promise<T[]> {
    const keys = Object.keys(params);
    if (!params || typeof params !== 'object' || keys.length === 0) {
      return of([]).toPromise();
    }

    // let url = this.apiUrl + '?archived=false&';
    let url = this.apiUrl + '?' + (!show_all ? (!params.archived ? 'archived=false&' : '') : '');
    for (const key of keys) {
      if (key != 'archived') {
        url += key + '=' + params[key] + '&';
      }

    }

    url = url.substring(0, url.length - 1);
    const options = this.getOptions();

    return this._http.get(url, options)
      .toPromise()
      .then((res: T[]) => {
        // TODO: this should be merged with getByParamPromise
        const ret_objs = res;
        this.converted_objs = [];
        for (const item of ret_objs) {
          // converting date objects back to strings
          if (this.date_obj_keys && this.date_obj_keys.length > 0) {
            for (const date_obj_key of this.date_obj_keys) {
              if (item[date_obj_key]) {
                item[date_obj_key + '_date'] = new Date(item[date_obj_key]);
              } else {
                item[date_obj_key + '_date'] = null;
              }
            }
          }
          const obj = this.toObj(item);
          this.converted_objs.push(_.cloneDeep(obj));
        }
        // this.collection = _.cloneDeep(this.converted_objs);
        return this.converted_objs;
      })
      .catch((err) => throwError(err).toPromise());
  }

  getByParamObj(params: any, show_all?: boolean): Observable<T[]> {
    const keys = Object.keys(params);
    if (!params || typeof params !== 'object' || keys.length === 0) {
      return of([]);
    }

    // let url = this.apiUrl + '?archived=false&';
    let url = this.apiUrl + '?' + (!show_all ? (!params.archived ? 'archived=false&' : '') : '');
    for (const key of keys) {
      if (key != 'archived') {
        url += key + '=' + params[key] + '&';
      }

    }

    url = url.substring(0, url.length - 1);
    const options = this.getOptions();

    return this._http.get(url, options)
      .pipe(
        map((res) => {
          // should probably use a different object
          const ret_objs = res as T[];
          this.converted_objs = [];
          for (const item of ret_objs) {
            // converting date objects back to strings
            if (this.date_obj_keys && this.date_obj_keys.length > 0) {
              for (const date_obj_key of this.date_obj_keys) {
                if (item[date_obj_key]) {
                  item[date_obj_key + '_date'] = new Date(item[date_obj_key]);
                } else {
                  item[date_obj_key + '_date'] = null;
                }
              }
            }
            const obj = this.toObj(item);
            this.converted_objs.push(_.cloneDeep(obj));
          }
          return this.converted_objs;
        }),
        catchError((err) => throwError(err))
      );
  }


  save(obj?: T): Promise<T> {
    let val: T;
    if (!obj && this.currentSubject) {
      val = _.cloneDeep(this.currentSubject.getValue());
    } else if (obj != null) {
      val = _.cloneDeep(obj);
    }

    if (!val || Object.keys(val).length === 0) {
      throw new Error('no object to save, bubbling up error');
    }
    let callback: any;
    let url = this.apiUrl;
    let payload: any;
    let has_patch: boolean = false;
    // deleting notes as the save breaks sometimes if it's there
    const options = this.getOptions();

    // converting date objects back to strings
    if (this.date_obj_keys && this.date_obj_keys.length > 0) {
      for (const date_obj_key of this.date_obj_keys) {
        if (val[date_obj_key + '_date']) {
          val[date_obj_key] = moment(val[date_obj_key + '_date']).format('YYYY-MM-DD');
        }
      }
    }
    // now we check if we have a file so that we can post that as the payload
    if (val.hasOwnProperty('file') && typeof val['file'] === 'object') {
      payload = this.getFormDataObject(val);
      // options['headers'] = options['headers'].set('Accept', 'multipart/form-data');
      // options['headers'] = options['headers'].set('Content-Type', 'multipart/form-data');
    } else if (val.hasOwnProperty('file') && typeof val['file'] === 'string') {
      payload = val;
      delete payload.file;
      url += val['id'] + '/';
      callback = this._http.patch(url, payload, options);
      has_patch = true;
      // options['headers'] = options['headers'].set('Content-Type', 'application/json');
    } else {
      payload = val;
      // options['headers'] = options['headers'].set('Content-Type', 'application/json');
    }

    if (!!val['id'] && !has_patch) {
      url += val['id'] + '/';
      // delete obj['id'];
      callback = this._http.patch(url, payload, options);
    } else if (!has_patch) {
      callback = this._http.post(url, payload, options);
    }
    return callback
      .toPromise()
      .then((res: T) => {
        const result = res;
        if (this.date_obj_keys && this.date_obj_keys.length > 0) {
          for (const date_obj_key of this.date_obj_keys) {
            if (result[date_obj_key]) {
              result[date_obj_key + '_date'] = new Date(result[date_obj_key]);
            } else {
              result[date_obj_key + '_date'] = null;
            }
          }
        }

        this.current = this.toObj(result);
        return result;
      })
      .catch((err) => {
        return throwError(err).toPromise();
      });
  }

  save$(obj?: T): Observable<T> {
    let val: T;
    if (!obj && this.currentSubject) {
      val = _.cloneDeep(this.currentSubject.getValue());
    } else if (obj != null) {
      val = _.cloneDeep(obj);
    }

    if (!val || Object.keys(val).length === 0) {
      throw new Error('no object to save, bubbling up error');
    }
    let callback: any;
    let url = this.apiUrl;
    let payload: any;
    let has_patch: boolean = false;
    // deleting notes as the save breaks sometimes if it's there
    const options = this.getOptions();

    // converting date objects back to strings
    if (this.date_obj_keys && this.date_obj_keys.length > 0) {
      for (const date_obj_key of this.date_obj_keys) {
        if (val[date_obj_key + '_date']) {
          val[date_obj_key] = moment(val[date_obj_key + '_date']).format('YYYY-MM-DD');
        }
      }
    }
    // now we check if we have a file so that we can post that as the payload
    if (val.hasOwnProperty('file') && typeof val['file'] === 'object') {
      payload = this.getFormDataObject(val);
      // options['headers'] = options['headers'].set('Accept', 'multipart/form-data');
      // options['headers'] = options['headers'].set('Content-Type', 'multipart/form-data');
    } else if (val.hasOwnProperty('file') && typeof val['file'] === 'string') {
      payload = val;
      delete payload.file;
      url += val['id'] + '/';
      callback = this._http.patch(url, payload, options);
      has_patch = true;
      // options['headers'] = options['headers'].set('Content-Type', 'application/json');
    } else {
      payload = val;
      // options['headers'] = options['headers'].set('Content-Type', 'application/json');
    }

    if (!!val['id'] && !has_patch) {
      url += val['id'] + '/';
      // delete obj['id'];
      callback = this._http.patch(url, payload, options);
    } else if (!has_patch) {
      callback = this._http.post(url, payload, options);
    }
    return callback
      .pipe(
        map((res: T) => {
          const result = res;
          if (this.date_obj_keys && this.date_obj_keys.length > 0) {
            for (const date_obj_key of this.date_obj_keys) {
              if (result[date_obj_key]) {
                result[date_obj_key + '_date'] = new Date(result[date_obj_key]);
              } else {
                result[date_obj_key + '_date'] = null;
              }
            }
          }

          this.current = this.toObj(result);
          return result;
        })
      );
  }

  removeObj(obj: T): Promise<boolean> {
    let callback: any;
    let url = this.apiUrl;
    // deleting notes as the save breaks sometimes if it's there
    const options = this.getOptions();

    if (!!obj['id']) {
      url += obj['id'] + '/';
      // delete obj['id'];
      callback = this._http.delete(url, options).toPromise();
    } else {
      callback = Promise.reject('no id specified');
    }
    return callback
      .then((res) => {
        return true;
      })
      .catch((err) => {
        return throwError(err).toPromise();
      });
  }

  removeObj$(obj: T): Observable<boolean> {
    let callback: Observable<any>;
    let url = this.apiUrl;
    // deleting notes as the save breaks sometimes if it's there
    const options = this.getOptions();

    if (!!obj['id']) {
      url += obj['id'] + '/';
      // delete obj['id'];
      callback = this._http.delete(url, options);
    } else {
      callback = throwError('no id specified');
    }
    return callback
      .pipe(map((res) => {
          return true;
        })
      );
  }

  getGraphData(id: string): Promise<GraphData> {
    // need to move this into the base class
    const url = this.apiUrl + id + this.graph_route;
    const options = this.getOptions(true);

    return this._http.get(url, options)
      .toPromise()
      .then(
        (res: HttpResponse<GraphData>) => {
          let ret_obj: GraphData;
          if (res && res.ok) {
            ret_obj = res.body as GraphData;
            // lete's assume for now that the date data value has the key of 'date'
            if (ret_obj && ret_obj['data'] && ret_obj['data'].length > 0) {
              for (const obj of ret_obj['data']) {
                if (obj['date']) {
                  // need to change this to add to a new property
                  obj['converted_date'] = new Date(obj['date']);
                }
              }
            }
          }
          const graph_data: GraphData = this.toObj(ret_obj, GraphData);
          return graph_data;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }

  getRawData(id: string): Observable<any | void> {
    // need to move this into the base class
    const url = this.apiUrl + id + this.raw_data_url;
    const options = this.getOptions(true);

    // return this._http.get(url, options)
    return this._http.get(url)
      .pipe(
        map(
          (res: HttpResponse<object>) => {
            let ret_obj: object;
            if (res) {
              ret_obj = res;
              // lete's assume for now that the date data value has the key of 'date'
              if (ret_obj && ret_obj['data'] && ret_obj['data'].length > 0) {
                for (const obj of ret_obj['data']) {
                  if (obj['date']) {
                    // need to change this to add to a new property
                    obj['converted_date'] = new Date(obj['date']);
                  }
                }
              }
            }
            // const graph_data: GraphData = this.toObj(ret_obj, GraphData);
            return ret_obj;
          }),
        catchError(
          (err) => {
            // do something with error
            return throwError(err).toPromise();
          }
        )
      );
  }

  getItemsList(id: string): Promise<string> {
    // need to move this into the base class
    const url = this.apiUrl + id + this.items_list_route;
    const options = this.getOptions(true);

    return this._http.get(url, options)
      .toPromise()
      .then(
        (res: HttpResponse<any>) => {
          let ret_obj: any;
          if (res && res.ok) {
            ret_obj = res.body as any;
          }
          return ret_obj['html'] as string;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }

}
