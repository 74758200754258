import {Injectable} from '@angular/core';
import {IBaseViewService} from '../interfaces/base-view.interface';
// import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Session} from '../../core/models/session.model';
import {PromptFadingStep} from '../../core/models/prompt-fading-step.model';
import {Procedure} from '../../core/models/procedure.model';
import {PromptFadingStepService} from '../../core/services/prompt-fading-step.service';
import {Observable, BehaviorSubject, of, timer, Subject, throwError, interval} from 'rxjs';
import {TherapySessionService} from '../../core/services/therapy-session.service';
import {PatientService} from '../../core/services/patient.service';
import {TherapySession} from '../../core/models/therapy-session.model';
import {ProcedureSession} from '../../core/models/procedure-session.model';
import {ProcedureSessionService} from '../../core/services/procedure-session.service';
import {SessionTrial} from '../../core/models/session-trial.model';
import {ProcedureStep} from '../../core/models/procedure-step.model';
import {SessionTrialService} from '../../core/services/session-trial.service';
import {ProcedureStepService} from '../../core/services/procedure-step.service';
import {catchError, delay, delayWhen, map, retry, retryWhen, take, takeUntil, tap} from 'rxjs/operators';
// import 'rxjs/add/operator/retry';
import {ProcItem} from '../../core/models/proc-item.model';
import {ProcItemService} from '../../core/services/proc-item.service';
import {ProcedureService} from '../../core/services/procedure.service';
import {ConstantsService} from '../../core/services/constants.service';
import {CommonService} from '../../core/shared/common/common.service';
import * as _ from 'lodash';
import { ToastService } from 'app/shared/services/toast.service';

@Injectable()
export class DailySessionViewService implements IBaseViewService<any> {

  protected intervalTime = 5000;
  private is_saving = false;

  currentSubjectType: BehaviorSubject<string> = new BehaviorSubject('');

  currentSubject: BehaviorSubject<any> = new BehaviorSubject(new Session());
  currentSubjects: BehaviorSubject<any[]> = new BehaviorSubject([]);
  previousSubjects: BehaviorSubject<TherapySession[]> = new BehaviorSubject([]);
  currentProcedureSessions: BehaviorSubject<ProcedureSession[]> = new BehaviorSubject([]);
  currentProcedureSession: BehaviorSubject<ProcedureSession> = new BehaviorSubject(new ProcedureSession());
  currentSessionTrials: BehaviorSubject<SessionTrial[]> = new BehaviorSubject([]);
  procedure_sessions: ProcedureSession[] = [];
  session_trials: SessionTrial[] = [];
  dailySessions: BehaviorSubject<Map<number, SessionTrial[]>> = new BehaviorSubject(new Map<number, SessionTrial[]>());
  dailySessionsSaved: BehaviorSubject<Map<number, SessionTrial[]>> = new BehaviorSubject(new Map<number, SessionTrial[]>());
  cache: Map<number, SessionTrial[]>;
  savingSessions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  trialIdList: number[] = [];
  resetFilterValue: BehaviorSubject<boolean> = new BehaviorSubject(false);

  default_session_type = 'discrete_trial';
  test_procedure_data: Procedure[];
  test_proc_item_data: ProcItem[];
  test_procedure_session_data: ProcedureSession[];

  killTrigger: Subject<void> = new Subject();

  constructor(private promptFadingStepService: PromptFadingStepService,
              private therapySessionService: TherapySessionService,
              private patientService: PatientService,
              private procedureSessionService: ProcedureSessionService,
              private sessionTrialService: SessionTrialService,
              private procedureStepService: ProcedureStepService,
              private procItemService: ProcItemService,
              private procedureService: ProcedureService,
              private constantsService: ConstantsService,
              private commonService: CommonService,
              private toastService: ToastService) {

    this.test_procedure_data = Array.apply(null, Array(5))
      .map((curr, idx) => new Procedure(idx, 0, 'Test-' + idx, 'code' + idx, 4, [['mei', 'MEI']]));
    this.test_proc_item_data = Array.apply(null, Array(5))
      .map((curr, idx) => new ProcItem(idx, idx, 'Test-Proc-Item-' + idx))
      .concat(Array.apply(null, Array(5))
        .map((curr, idx) => new ProcItem(idx, idx, 'Test-Proc-Item-2-' + idx)));
    // id of manager1 is 3 (the usual one I test with)
    this.test_procedure_session_data = Array.apply(null, Array(5))
      .map((curr, idx) => new ProcedureSession(idx, 0, 0, 3, idx));

  }

  processArray(array, fn, is_procedure?, is_trial?): Promise<any> {
    const results = [];
    const that = this;
    if (is_procedure) {
      this.procedure_sessions = [];
    }

    if (is_trial) {
      this.session_trials = [];
    }
    return array.reduce(function (p, item) {
      return p.then(function () {
        return fn.apply(that, item);
      });
    }, Promise.resolve());
  }

  getProcedureSteps(id: string, get_all?: boolean): Promise<ProcedureStep[]> {
    return this.procedureStepService
      .getByParamPromise(id, 'procedure', get_all)
      .then(
        (steps: ProcedureStep[]) => {
          return steps;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  // TODO: Check what this actually does - ById usually means you are getting the item by id rather than by some other objects id
  // TODO: ok, I this does the exact same thing as the one below, but it does it with just the id, merge these together as they
  //  are redundant!
  getPromptFadingSteps(procedure: Procedure, get_all?: boolean): Promise<PromptFadingStep[]> {
    return this.promptFadingStepService
      .getByParamPromise(procedure.id.toString(), 'procedure', get_all)
      .then(
        (steps: PromptFadingStep[]) => {
          return steps;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getPromptFadingStepsByProcIds(ids: number[], get_all?: boolean): Promise<PromptFadingStep[]> {
    // make sure we filter out nulls
    ids = ids.filter(i => i != null);
    const params = {
      'procedure_session__in': ids
    };
    params['archived'] = get_all == null ? false : get_all;
    return this.promptFadingStepService.getByParamObjPromise(params)
      .then((prompt_steps: PromptFadingStep[]) => {
        if (prompt_steps && prompt_steps.length > 0) {
          prompt_steps = this.commonService.sortByProperty(prompt_steps, '_order');
        }
        return prompt_steps;
      })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getPromptFadingStepsById(id: string, get_all?: boolean): Promise<PromptFadingStep[]> {
    return this.promptFadingStepService
      .getByParamPromise(id, 'procedure', get_all)
      .then(
        (steps: PromptFadingStep[]) => {
          return steps;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  // TODO: Check what this actually does - ById usually means you are getting the item by id rather than by some other objects id
  // TODO: ok, I this does the exact same thing as the one below, but it does it with just the id, merge these together as they are redundant!
  getProcItems(procedure: Procedure, get_all?: boolean): Promise<ProcItem[]> {
    return this.procItemService
      .getByParamPromise(procedure.id.toString(), 'procedure', get_all)
      .then(
        (proc_items: ProcItem[]) => {
          return proc_items;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getProcItemsByProcedureIds(ids: number[], get_all?: boolean): Promise<ProcItem[]> {
    const params = {
      'procedure__in': ids
    };
    params['archived'] = get_all == null ? false : get_all;
    return this.procItemService.getByParamObjPromise(params)
      .then((proc_items: ProcItem[]) => {
        if (proc_items && proc_items.length > 0) {
          proc_items = this.commonService.sortByProperty(proc_items, '_order');
        }
        return proc_items;
      })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getProcItemsByProcedureIds$(ids: number[], get_all?: boolean): Observable<ProcItem[]> {
    const params = {
      'procedure__in': ids
    };
    if (!get_all) {
      params['archived'] = false;
    }
    return this.procItemService.getByParamObj(params, get_all)
      .pipe(map((proc_items: ProcItem[]) => {
          if (proc_items && proc_items.length > 0) {
            proc_items = this.commonService.sortByProperty(proc_items, '_order');
          }
          return proc_items;
        }),
        catchError(err => throwError(err)));
  }

  getProcItemsById(id: string, get_all?: boolean): Promise<ProcItem[]> {
    return this.procItemService
      .getByParamPromise(id, 'procedure', get_all)
      .then(
        (proc_items: ProcItem[]) => {
          return proc_items;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getPreviousTherapySessions(date_filter?: string): Promise<TherapySession[]> {
    // return undefined;
    const patientId = this.patientService.currentPatientId.getValue();
    const params = {
      patient: patientId,
      is_complete: true
    };
    if (date_filter != null) {
      params['date'] = date_filter;
    }

    return this.therapySessionService.getByParamObjPromise(params)
      .then((res: TherapySession[]) => {
        let response: TherapySession[];
        if (res && res.length > 0) {
          response = res;
          this.previousSubjects.next(response);
        }
        return response;
      })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getTherapySession(date_filter?: string, host_id?: number, is_complete?: boolean): Promise<TherapySession> {
    // return undefined;
    const patient = this.patientService.currentPatient.getValue();
    const params = {
      patient: patient.id,
      is_complete: !!is_complete
    };
    if (date_filter != null) {
      params['date'] = date_filter;
      delete params.is_complete;
    }

    if (host_id != null) {
      params['host'] = host_id;
      if (params.is_complete == null) {
        delete params.is_complete;
      }
    }

    return this.therapySessionService.getByParamObjPromise(params)
      .then((res: TherapySession[]) => {
        let response: TherapySession;
        if (res && res.length > 0) {
          response = res[0];
        }
        return response;
      })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getProcedureSessionById(ds_id: number): Promise<ProcedureSession> {

    return this.procedureSessionService.getById(ds_id)
      .then((procedure_session: ProcedureSession) => {
        procedure_session.num_trials = procedure_session.state_prescribed_trials;
        return procedure_session;
      })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getProcedureSessionByPatient(ds_id: number): Promise<ProcedureSession[]> {
    const patient = this.patientService.currentPatient.getValue();
    const params = {
      therapy_session__patient: patient.id,
      procedure__data_sheet: ds_id
    };
    return this.procedureSessionService.getByParamObjPromise(params)
      .then((procedure_sessions: ProcedureSession[]) => {
        return procedure_sessions;
      })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getProcedureSessionByTherapySessionAndProcedureAndDataSheet(id: number, proc_id: number, is_id: number): Promise<ProcedureSession[]> {
    const params = {
      therapy_session: id,
      procedure: proc_id,
      procedure__data_sheet: is_id
    };
    return this.procedureSessionService.getByParamObjPromise(params)
      .then((procedure_sessions: ProcedureSession[]) => {
        if (procedure_sessions && procedure_sessions.length > 0) {
          // need to order by _order
          procedure_sessions = this.commonService.sortByProperty(procedure_sessions, '_order');
          for (const procedure_session of procedure_sessions) {
            procedure_session.num_trials = procedure_session.state_prescribed_trials;
          }
        }
        return procedure_sessions;
      })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getProcedureSessionByTherapySessionAndProcedureAndDataSheet$(id: number, proc_id: number, is_id: number, all?: boolean): Observable<ProcedureSession[]> {
    const params = {
      therapy_session: id,
      procedure: proc_id,
      procedure__data_sheet: is_id
    };
    return this.procedureSessionService.getByParamObj(params, all)
      .pipe(
        map((procedure_sessions: ProcedureSession[]) => {
        if (procedure_sessions && procedure_sessions.length > 0) {
          // need to order by _order
          procedure_sessions = this.commonService.sortByProperty(procedure_sessions, '_order');
          for (const procedure_session of procedure_sessions) {
            procedure_session.num_trials = procedure_session.state_prescribed_trials;
          }
        }
        return procedure_sessions;
      }),
      catchError(
        (err) => {
          return throwError(err);
        })
      );
  }

  getProcedureSessionByTherapySessionAndDataSheet(id: number, ds_id: number, get_all?: boolean): Promise<ProcedureSession[]> {
    const params = {
      therapy_session: id,
      procedure__data_sheet: ds_id
    };
    params['archived'] = get_all == null ? false : get_all;

    return this.procedureSessionService.getByParamObjPromise(params)
      .then((procedure_sessions: ProcedureSession[]) => {
        if (procedure_sessions && procedure_sessions.length > 0) {
          // need to order by _order
          procedure_sessions = this.commonService.sortByProperty(procedure_sessions, '_order');
          for (const procedure_session of procedure_sessions) {
            procedure_session.num_trials = procedure_session.state_prescribed_trials;
          }
        }
        return procedure_sessions;
      })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getSessionTrialsByProcedureSession(id: number, get_all?: boolean): Promise<SessionTrial[]> {
    const params = {
      procedure_session: id
    };
    params['archived'] = get_all == null ? false : get_all;
    return this.sessionTrialService.getByParamObjPromise(params)
      .then((session_trials: SessionTrial[]) => {
        if (session_trials && session_trials.length > 0) {
          session_trials = this.commonService.sortByProperty(session_trials, '_order');
        }

        return session_trials;
      })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  getSessionTrialsByProcedureSessionIds(ids: number[], get_all?: boolean): Promise<SessionTrial[]> {
    // make sure we filter out nulls
    ids = ids.filter(i => i != null);
    const params = {
      'procedure_session__in': ids
    };
    if (!get_all) {
      params['archived'] = false;
    }
    return this.sessionTrialService.getByParamObjPromise(params, get_all)
      .then((session_trials: SessionTrial[]) => {
        if (session_trials && session_trials.length > 0) {
          session_trials = this.commonService.sortByProperty(session_trials, '_order');
        }
        return session_trials;
      })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  save(complete_session?: boolean): Promise<any> {
    let session = this.currentSubject.getValue();
    const patient = this.patientService.currentPatient.getValue();
    if (patient && session.patient == null) {
      session.patient = patient.id;
    }
    return this.therapySessionService.save(session)
      .then(
        (updated: TherapySession) => {
          session = updated;
          let promise: Promise<ProcedureSession>;
          if (!complete_session) {
            let sessions = this.currentProcedureSessions.getValue();
            const new_sessions = [];
            if (sessions && sessions.length > 0) {
              for (let idx = 0; idx < sessions.length; idx++) {
                sessions[idx].therapy_session = session.id;
                sessions[idx]._order = idx;
                if (sessions[idx].procedure != null) {
                  // needs to be an array of arrays otherwise apply won't work
                  new_sessions.push([sessions[idx]]);
                }
              }
            }
            sessions = new_sessions.slice();
            promise = this.processArray(sessions, this.saveProcedureSession, true);
          } else {
            promise = Promise.resolve(<ProcedureSession>{});
          }

          return promise;
        })
      .then(
        (last_procedure) => {
          if (complete_session) {
            // clearing therapy session and procedure sessions
            session = new TherapySession();
            this.procedure_sessions = [];
            session.after_complete = true;
          }
          this.currentProcedureSessions.next(this.procedure_sessions);
          this.currentSubject.next(session);

          return session;
        })
      .catch((err) => {
        if (err.error) {
          for (let key in err.error) {
            this.toastService.addToast(err.error[key][0], 'error', null, null, this.onCloseToastHandle.bind(this));
          }
        }
        return throwError(err).toPromise();
      });
  }

  onCloseToastHandle(): void {
    let session = this.currentSubject.getValue();
    session.is_complete = false;
    session.ignore_update = true;
    session.is_error_finish = true;
    this.currentSubject.next(session);
  }

  saveTherapySession(therapySession: TherapySession): Promise<TherapySession> {
    if (this.is_saving) {
      // throwing an error if we are saving the therapy session already
      return throwError('Therapy session is already being saved').toPromise();
    }
    this.is_saving = true;
    let therapy_session: TherapySession;
    const patient = this.patientService.currentPatient.getValue();
    if (patient && therapySession.patient == null) {
      therapySession.patient = patient.id;
    }
    return this.therapySessionService
      .save(therapySession)
      .then(
        (response: TherapySession) => {
          therapy_session = response;
          this.is_saving = false;
          // don't need to save trials as we are doing these as the user clicks on them
          return therapy_session;
        })
      .catch(
        (err) => {
          this.is_saving = false;
          return throwError(err).toPromise();
        });
  }

  saveProcedureSession(procedureSession: ProcedureSession): Promise<ProcedureSession> {
    let procedure_session: ProcedureSession;
    return this.procedureSessionService
      .save(procedureSession)
      .then(
        (response: ProcedureSession) => {
          procedure_session = response;

          // don't need to save trials as we are doing these as the user clicks on them
          this.procedure_sessions.push(_.cloneDeep(procedure_session));
          return procedure_session;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  saveSessionTrial(sessionTrial: SessionTrial): Promise<SessionTrial> {
    return this.sessionTrialService
      .save(sessionTrial)
      .then(
        (response: SessionTrial) => {
          sessionTrial = response;
          this.session_trials.push(_.cloneDeep(sessionTrial));
          return response;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  deleteSessionTrial(sessionTrial: SessionTrial): Promise<SessionTrial> {
    return this.sessionTrialService
      .removeObj(sessionTrial)
      .then(
        (response: boolean) => {
          sessionTrial.is_deleted = response;
          return sessionTrial;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  private updateSessionTrial(procedure_session: number, sessionTrial: SessionTrial): Promise<SessionTrial> {
    let promise: Promise<any>;
    if (sessionTrial && (sessionTrial.value == null || sessionTrial.value == '')) {
      // if we have the instance when there is no id, we just return a positive
      if (sessionTrial.id == null) {
        sessionTrial.is_deleted = true;
        promise = of(sessionTrial).toPromise();
      } else {
        promise = this.deleteSessionTrial(sessionTrial);
      }
    } else {
      if (sessionTrial && sessionTrial.procedure_session == null) {
        sessionTrial.procedure_session = procedure_session;
      }
      promise = this.saveSessionTrial(sessionTrial);
    }
    return promise.then((res: SessionTrial) => {
      const ret_val: SessionTrial = res;
      // because the return values could either be a session trial or a success code
      // for deleting the object successfully, then need to check if we have the id
      // if we don't then it should've been deleted
      if (sessionTrial) {
        if (ret_val && !ret_val.is_deleted) {
          sessionTrial.id = ret_val.id;
        } else {
          if (sessionTrial.id != null) {
            delete sessionTrial.id;
          }
        }
      }

      if (!this.cache.has(procedure_session)) {
        this.cache.set(procedure_session, []);
      }
      this.cache.get(procedure_session).push(sessionTrial);
      // now we get the procedure session
      const idx = this.trialIdList.findIndex(til => til === procedure_session);
      if (idx > -1) {
        this.trialIdList = this.trialIdList.slice(0, idx).concat(this.trialIdList.slice(idx + 1))
      }
      return sessionTrial;
    })
      .catch((err) => throwError(err).toPromise());
  }

  incrementalSave(): Observable<any> {
    return interval(this.intervalTime)
      .pipe(
        // takeUntil(this.killTrigger),
        map(() => {
          const dailySessions = this.dailySessions.getValue();
          // now we clear it
          if (dailySessions != null && dailySessions.size > 0 && !this.savingSessions.getValue()) {
            this.savingSessions.next(true);

            this.dailySessions.next(null);
            this.cache = new Map<number, SessionTrial[]>();
            const keys = dailySessions.keys();
            let key = keys.next();
            while (!key.done) {
              const k = key.value;
              this.trialIdList.push(k);
              const arr = dailySessions.get(k);
              if (!this.cache.has(k)) {
                this.cache.set(k, [])
              }

              const new_arr = arr.reduce((prev, a) => {
                prev.push([k, a]);
                return prev;
              }, []);
              // now we need to check in updateSessionTrial if it's null or undefined
              this.processArray(new_arr, this.updateSessionTrial)
                .then(final => {
                  this.dailySessionsSaved.next(this.cache);
                  // if everything has saved then we should be at the end of the list of trials to save
                  if (this.trialIdList.length === 0) {
                    this.savingSessions.next(false);
                  }
                })
                .catch((err) => {
                  // now we handle the bubble up of the error
                  console.log(err);
                  this.savingSessions.next(false);

                  return throwError(err).toPromise();
                });
              key = keys.next();
            }
          }
        }),
        // retryWhen(errors => errors.pipe(delay(1000), take(10)))
        retryWhen(errors => errors.pipe(delay(500)))
        // retry(4)
      );

  }

  getProcedures(patient_id: number, data_sheet_id: number, get_test?: boolean, get_all: boolean = false): Promise<Procedure[]> {
    if (get_test) {
      return Promise.resolve(this.test_procedure_data);
    }

    // TODO: change 'active' to active status from choices
    const params = {
      patient: patient_id,
      data_sheet: data_sheet_id
    };
    if (!get_all) {
      params['status'] = 'active';
    }
    return this.procedureService.getByParamObjPromise(params)
      .then((procedures: Procedure[]) => {
        // for each procedure we want to add a procedure session
        // now we have the procedures, we get the latest session instead
        // this.getNewProcedureSession();
        if (procedures && procedures.length > 0) {
          for (let i = 0; i < procedures.length; i++) {
            procedures[i].phase_types = this.constantsService.convertListToSelctOptions(procedures[i].valid_phase_types);
          }
        }
        return procedures;
      })
      .catch((err) => {
        // need to show toasty with error

        return throwError(err).toPromise();
      });
  }

  getProcedures$(patient_id: number, data_sheet_id: number, get_test?: boolean, get_all: boolean = false): Observable<Procedure[]> {
    if (get_test) {
      return Observable.create(observer => {
        observer.next(this.test_procedure_data);
      });
    }

    const params = {
      patient: patient_id,
      data_sheet: data_sheet_id
    };
    if (!get_all) {
      params['status'] = 'active';
    }
    return this.procedureService.getByParamObj(params)
      .pipe(
        map((procedures: Procedure[]) => {
          if (procedures && procedures.length > 0) {
            for (let i = 0; i < procedures.length; i++) {
              procedures[i].phase_types = this.constantsService.convertListToSelctOptions(procedures[i].valid_phase_types);
            }
          }
          return procedures;
        }),
        catchError((err) => {
          return throwError(err);
        })
      )
  }

  getProcItemsByIds(ids: number[]): Promise<ProcItem[]> {
    ids = ids.filter(i => i != null);
    const params = {};
    if (ids.length == 1) {
      params['id'] = ids[0];
    } else {
      params['id__in'] = ids;
    }
    return this.procItemService.getByParamObjPromise(params, true)
      .then((proc_items: ProcItem[]) => {
        return proc_items;
      })
      .catch((err) => {
        // TODO: need to show toasty with error or bubble error up
        return throwError(err).toPromise();
      });
  }

  getProcItemsByIds$(ids: number[]): Observable<ProcItem[]> {
    ids = ids.filter(i => i != null);
    const params = {};
    if (ids.length == 1) {
      params['id'] = ids[0];
    } else {
      params['id__in'] = ids;
    }
    return this.procItemService.getByParamObj(params, true)
      .pipe(
        map((proc_items: ProcItem[]) => {
          return proc_items;
        }),
          catchError((err) => {
          // TODO: need to show toasty with error or bubble error up
          return throwError(err).toPromise();
        })
      );
  }

  update(): Promise<any> {
    return undefined;
  }

  getByPatient(id: string): Promise<any[]> {
    return undefined;
  }

  getSubjects(): void {
  }

  search(status?: string, search_text?: string): Promise<any[]> {
    return undefined;
  }

  getById(id: number): Promise<any> {
    return undefined;
  }
}
