import {Base} from './base.model';
import {IFilterable} from '../../shared/interfaces/ifilterable';
import {SelectOption} from './select-option.model';
import {PromptFadingStep} from './prompt-fading-step.model';
import {ProcItem} from './proc-item.model';
import {SdResponse} from './sd-response.model';

export class ReinforcementSchedule {

}

export class Procedure extends Base<Procedure> implements IFilterable {
  patient: number;
  service_plan: number;
  name: string;
  st_objective: string;
  materials: string;
  code: string;
  procedure: string;
  baseline: string;
  generalisation: string;
  teaching_format: string;
  reinforecement_schedule: ReinforcementSchedule[];
  prompt_type: string[];
  prompt_fading_strategy: string;
  num_active_targets: number;
  mastery_criteria: string;
  exit_criteria: string;
  status: string;
  related_skill_objective: number;
  data_sheet: number;
  mastery_date: string;
  mastery_date_date: string;
  untrained_steps_proc: string;
  valid_phase_types: any[][];
  phase_types: SelectOption[];
  // addind prompt steps and proc_items onto procedure so we can get them from memory instead of
  // having to make a call to the server all the time
  prompt_steps: PromptFadingStep[];
  proc_items: ProcItem[];
  all_proc_items: ProcItem[];
  sd_responses: SdResponse[];
  increment_phase: boolean;
  prompt_step_ids: number[];
  proc_item_ids: number[];
  note: string;
  warning: string;
  num_pretr_to_move: number;
  num_tr_for_pretr_move: number;
  phase_type_tactic: any;
  training_format: string;
  data_collection_method: string;

  constructor(id?: number, patient?: number, name?: string, code?: string, data_sheet?: number, valid_phase_types?: any[][]) {
    super(Procedure);
    this.id = id;
    this.patient = patient;
    this.name = name;
    this.code = code;
    this.data_sheet = data_sheet;
    this.valid_phase_types = valid_phase_types;
  }

  public isTeachingFormat(format: string): boolean {
    return this.teaching_format && format && this.teaching_format.toLocaleLowerCase() == format.toLocaleLowerCase();
  }
}
