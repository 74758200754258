import { Injectable } from '@angular/core';
import {BaseService} from '../base.service';
import {Domain} from '../models/domain.model';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';

@Injectable()
export class DomainService extends BaseService<Domain> {

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.domain/', Domain);
    this.subRoute = '';
    this.date_obj_keys = [];
  }

}
