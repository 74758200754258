import {SessionTrial} from './session-trial.model';

export class SessionData {
  date: string;
  sds: string[];
  items: string[];
  description: string;
  host: string;
  perc_correct: number;
  ts_id: number;
  num_trials: number;
  diff_trials: number;
  trial_extra_range: number[];
  totals: number[];
  item_group: string;
  // trial_padding: number[];
  session_trials: SessionTrial[];
  data: SessionTrial[][];
  total_duration: string;
  mean_duration: string;
  calc_total_duration: string;
  calc_mean_duration: string;
  include: boolean;
  counted_for_mastery?: boolean;
}
