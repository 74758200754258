import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getSubList'
})
export class GetSubListPipe implements PipeTransform {

  transform(args?: any, id_key?: any, value?: any): any {
    if (!args || !args.hasOwnProperty('length') || !value || !id_key) {
      return;
    }

    const ret_list = args.filter(a => a[id_key] == value) || [];

    return ret_list.slice();
  }

}
