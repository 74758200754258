import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../shared/base/base.component';
import {PatientViewService} from '../patient-view.service';
import {ServicePlanService} from '../../core/services/service-plan.service';
import {ServicePlan} from '../../core/models/service-plan.model';
import {ConstantsService} from '../../core/services/constants.service';
import {UserService} from '../../core/services/user.service';
import {ParentDetail} from '../../core/models/parent-detail.model';
import {User} from '../../core/models/user.model';
import {DialogCloseResult, DialogService} from '@progress/kendo-angular-dialog';
import {AddContactComponent} from '../../shared/add-contact/add-contact.component';
import {AddServicePlanComponent} from '../../shared/add-service-plan/add-service-plan.component';
import {ServicePlanViewService} from '../../shared/services/service-plan-view.service';
import {PatientService} from '../../core/services/patient.service';
import {UserViewService} from '../../shared/services/user-view.service';
import {CurrentFormService} from '../../core/services/current-form.service';
import {ShowNavService} from '../../core/services/show-nav.service';
import {Assessment} from '../../core/models/assessment.model';
import {ToastyService} from 'ng2-toasty';
import {SelectOption} from '../../core/models/select-option.model';
import {catchError} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import { ConfirmDeleteService } from 'app/core/services/confirm-delete.service';
import { HttpErrorCodes } from 'app/core/shared/enum';

@Component({
  selector: 'app-service-plan',
  templateUrl: './service-plan.component.html',
  styleUrls: ['./service-plan.component.css']
})
export class ServicePlanComponent extends BaseComponent implements OnInit, OnDestroy {

  @ViewChild('servicePlanForm') public servicePlanForm;
  @ViewChild('dialogActions') public dialogActions;
  service_plan: ServicePlan;
  service_plans: ServicePlan[];
  statuses: SelectOption[];
  patient: User;
  servicePlanViewService: ServicePlanViewService;
  loading: boolean;
  currentServicePlanId: string;

  // servicePlanService: ServicePlanService;

  constructor(dialogService: DialogService,
              private patientViewService: PatientViewService,
              constantsService: ConstantsService,
              private servicePlanService: ServicePlanService,
              servicePlanViewService: ServicePlanViewService,
              userViewService: UserViewService,
              private patientService: PatientService,
              currentFormService: CurrentFormService,
              private showNavService: ShowNavService,
              toastyService: ToastyService,
              private route: ActivatedRoute,
              private router: Router,
              private confirmDeleteService: ConfirmDeleteService) {
    super(constantsService, userViewService, dialogService, servicePlanViewService, currentFormService, toastyService);
    // this.servicePlanService = servicePlanService;
    this.servicePlanViewService = servicePlanViewService;
  }

  private sendReviewStatus(obs: Observable<any>, success_text: string, error_text: string) {
    obs.pipe(
      catchError(err => {
        // do something with the error her
        console.log(err);
        return of();
      })
    )
      .subscribe(res => {
        console.log(res);
        if (res.ok && res.body && res.body.status == 'success') {
          this.addToast(success_text, 'Success');
        }
        // now we close
        this.close();
        document.body.style.overflow = '';
        this.refreshSubjects.next(true);
      }, err => {
        this.addToast(error_text, 'Error');

      })
  }

  public sendForReview() {
    console.log('send for review');
    this.sendReviewStatus(this.servicePlanViewService.sendForReview(), 'Program plan was sent for review.',
      'An error occurred while sending the program plan for review, please try again later');
  }

  public markAsReviewed() {
    console.log('mark as reviewed');
    this.sendReviewStatus(this.servicePlanViewService.markAsReviewed(), 'Program plan was updated.',
      'An error occurred while updating the program plan, please try again later');
  }

  subscribeToPatient(): void {
    this.subscriptions.push(this.patientService.currentPatient.subscribe(
      (patient: User) => {
        this.patient = patient;
        if (this.patient && this.patient.id != null) {
          this.getServicePlans();
        }
        // if (this.patient.dob_date) {
        //   // now we get the calculated age
        //   const now = moment();
        //   const age = moment(this.patient.dob_date);
        //   const diff = now.diff(age, 'years');
        //   this.patient.calculated_age = diff.toString();
        // }
      }, (err) => {
        this.loading = false;
      }));
  }

  getServicePlans(): void {
    // we should have a patient at this point, if we don't then something is very wrong
    this.servicePlanService.getByParamPromise(this.patient.id.toString(), 'patient')
      .then(plans => {
        this.service_plans = plans;
        // now we have the service plans, let's get the current service plan and show it
        if (this.currentServicePlanId != null) {
          const service_plan = this.service_plans.find(sp => sp.id == parseInt(this.currentServicePlanId || '-1'));
          if (service_plan != null) {
            this.editPopup(service_plan, 'service_plan', null, this.dialogActions, this.servicePlanViewService);
          }
        }
        this.loading = false;
      })
      .catch(
        err => {
          console.log(err);
          this.loading = false;
          throw err;
        })
  }

  subscribeToServicePlans(): void {
    // we should have a patient at this point, if we don't then something is very wrong
    this.subscriptions.push(this.servicePlanViewService.currentSubjects.subscribe(
        plans => {
          this.service_plans = plans;

        }, (err) => {
        this.loading = false;
      }));
  }

  save(): void {
    console.log('saving details');
    this.servicePlanService.save(this.service_plan).then((res) => {

    }).catch(
      (err) => {
        throw err;
      });
  }

  openConfirmDelete(idx: number) {
    const content = `Are you sure you want to delete program plan ${this.service_plans[idx].name}?`
    this.confirmDeleteService.openConfirmDialog(content)
      .subscribe(res => {
        if (res) {
          this.remove(idx);
        }
      })
  }

  remove(idx: number): void {
    if (idx == null) {
      return
    }
    this.servicePlanService.removeObj(this.service_plans[idx])
      .then((res) => {
        this.service_plans.splice(idx, 1);
        this.addToast('Program plan was deleted.', 'Success');
      })
      .catch((err) => {
        if (typeof err === 'object' && err.status === HttpErrorCodes.UNAUTHORIZED) {
          this.addToast('You do not have permission to remove this program plan...', 'Error');
        } else {
          this.addToast('Could not delete program plan, please try again...', 'Error');
        }
        throw err;
      });
  }

  subscribeToChoices(): void {
    this.subscriptions.push(this.choicesSubject.subscribe(
      (choices: Map<string, any>) => {
        if (choices.size > 0 && choices.has('procedure')) {
          this.statuses = choices.get('procedure').get('status');
          this.publish_statuses = choices.get('global').get('publish_status');
        }
      }, (err) => {
        this.loading = false;
      }));
  }

  subscribeToFiltered(): void {
    this.subscriptions.push(this.servicePlanViewService.currentSubjectsFiltered.subscribe(
      (filtered: ServicePlan[]) => {
        this.service_plans = filtered;
      }));
  }

  subscribeToRefreshSubjects(): void {
    this.subscriptions.push(this.refreshSubjects.subscribe(
      (refresh: boolean) => {
        if (refresh) {
          this.loading = true;
          // clearing the param
          this.currentServicePlanId = null;
          this.getServicePlans();
          // and re now redirect to the program page again
          this.router.navigate(['/patient', this.patient.id, 'program-plan']);
        }
      }, (err) => {
        this.loading = false;
      }));
  }

  ngOnInit() {
    this.title = 'Service Plan';
    this.loading = true;
    this.service_plans = [];
    this.service_plan = new ServicePlan();
    this.patientViewService.currentTitle.next(this.title);
    this.subscriptions = [];
    this.publish_statuses = [];
    const paramId = this.route.snapshot.paramMap.get("id");
    if (paramId) {
      this.currentServicePlanId = paramId;
      // need to get the service plan
    }

    this.getChoices();
    this.subscribeToPatient();
    this.current_form = this.servicePlanForm;
    this.showNavService.showButtons.next(false);
    this.subscribeToServicePlans();
    this.subscribeToFiltered();
    this.subscribeToChoices();
    this.subscribeToRefreshSubjects();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.showNavService.showButtons.next(true);
  }
}
