import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-behaviour-interventions',
  templateUrl: './behaviour-interventions.component.html',
  styleUrls: ['./behaviour-interventions.component.css']
})
export class BehaviourInterventionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
