import {Injectable} from '@angular/core';
import {IBaseViewService} from '../interfaces/base-view.interface';
import {User} from '../../core/models/user.model';
import {UserService} from '../../core/services/user.service';
import {Observable, BehaviorSubject, throwError} from 'rxjs';
import {switchMap, tap, catchError, map} from 'rxjs/operators'
import {PatientService} from '../../core/services/patient.service';
import {SUCCESS_STATUS, SuccessResponse} from '../../core/models/response.model';
import {Auth} from '../../core/models/auth.model';
import {TokenService} from '../../core/services/token.service';
import {AuthGroupService} from '../../core/services/auth-group.service';
import {SheetInfo} from '../../core/models/sheet-info.model';
import * as _ from 'lodash';
import {PermissionModel} from '../../core/models/permission.model';
import {PermissionService} from '../../core/services/permission.service';

@Injectable()
export class UserViewService implements IBaseViewService<User> {

  currentSubjectType: BehaviorSubject<string> = new BehaviorSubject('');
  currentSubject: BehaviorSubject<User> = new BehaviorSubject(new User());
  currentSubjects: BehaviorSubject<User[]> = new BehaviorSubject([]);
  currentPermissionSubjects: BehaviorSubject<PermissionModel[]> = new BehaviorSubject([]);
  hostsSubjects: BehaviorSubject<User[]> = new BehaviorSubject([]);
  currentFullUserSubjects: BehaviorSubject<User[]> = new BehaviorSubject([]);
  currentSubjectsFiltered: BehaviorSubject<User[]> = new BehaviorSubject([]);

  // collection: User[];
  exclude_types = ['patient', 'parent'];
  exclude_contact_types = ['patient'];
  keys_to_search: string[] = ['first_name', 'last_name'];

  constructor(private userService: UserService,
              private patientService: PatientService,
              private tokenService: TokenService,
              private authGroupService: AuthGroupService,
              private permissionService: PermissionService) {
  }

  search(status: string, search_text: string): Promise<User[] | any> {
    let promise: Promise<User[]>;
    let params: any;
    if (status) {
      params = {
        user_type: status
      }
    }

    const patient = this.patientService.currentPatient.getValue();
    if (!params) {
      promise = this.getUsersPromise();
    } else {
      params['patient'] = patient.id.toString();
      promise = this.userService.getByParamObjPromise(params);
    }
    return promise
      .then(
        (users: User[]) => {
          // check through all keys, not sure if there is an aggregate yet
          let result: User[] = [];
          const filtered: User[] = [];
          for (const relationship of patient.parents_repr) {
            const user = users.find(u => u.id == relationship.user);
            if (user) {
              user.relationship_type = relationship.relationship_type;
              user.relationship_type_desc = relationship.relationship_type_description;
              filtered.push(user);
            }
          }
          if (search_text) {
            for (const item of filtered) {
              // const keys = Object.keys(item);
              for (const key of this.keys_to_search) {
                if (typeof item[key] === 'string' &&
                  item[key].toLocaleLowerCase().indexOf(search_text.toLocaleLowerCase()) > -1) {
                  result.push(Object.assign({}, item));
                  break;
                }
              }
            }
          } else {
            result = filtered;
          }
          this.currentSubjectsFiltered.next(result);
          return result;
        })
      .catch((err) => throwError(err).toPromise);
  }

  getSubjects(): void {
    // all we need to do here is get the patient and set it as the component does the rest
    // TODO: have a look at moving the logic here for getting the contacts for a patient
    const patient = this.patientService.currentPatient.getValue();
    // we should have a patient at this point, if we don't then something is very wrong
    this.userService.getById(patient.id)
      .then(user => {
        this.patientService.currentPatient.next(user);
      })
      .catch(
        err => {
          console.log(err);
          throw err;
        })
  }

  // don't actually need this function, just need to define it
  getByPatient(id: string): Promise<User[]> {
    return undefined;
  }

  get_users(): Observable<User[]> {
    const result$ = this.userService.userNames.pipe(
      map((users: User[]) => {
        const user_list = [];
        let new_user: User = new User();
        for (const user of users) {
          new_user = new_user.toObj(user);
          new_user.setDisplayName();
          user_list.push(Object.assign({}, new_user));
        }
        this.currentSubjects.next(users);
        return user_list;
      }),
      catchError(((err) => {
          return throwError(err).toPromise();
        })
      ));

    // return toPromise()(result$);
    return result$;
  }


  getUsersPromise(): Promise<User[]> {
    const users = this.userService.userNames.getValue();
    let result$: Promise<User[]>;
    if (!users || users.length === 0) {
     result$ = this.userService.getUsers()
      .then(
        (users: User[]) => {
          const user_list = [];
          let new_user: User = new User();
          for (const user of users) {
            new_user = new_user.toObj(user);
            new_user.setDisplayName();
            user_list.push(Object.assign({}, new_user));
          }
          this.currentSubjects.next(users);
          return user_list;
        })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        }
      );
    } else {
      result$ = Promise.resolve(users);
    }

    // return toPromise()(result$);
    return result$;
  }


  getAllUsers(): Observable<User[]> {
    return this.userService.userNames.pipe(
      map((users: User[]) => {
        const user_list = [];
        let new_user: User = new User();
        for (const user of users) {
          if (user.user_type != null && this.exclude_types.indexOf(user.user_type.toLowerCase()) === -1) {
            new_user = new_user.toObj(user);
            new_user.setDisplayName();
            user_list.push(Object.assign({}, new_user));
          }
        }
        this.currentSubjects.next(user_list);
        return user_list;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getAddUserList(): Observable<User[]> {
    return this.userService.userNames.pipe(
      map((users: User[]) => {
        const user_list = [];
        let new_user: User = new User();
        for (const user of users) {
          if (user.user_type != null && this.exclude_contact_types.indexOf(user.user_type.toLowerCase()) === -1) {
            new_user = new_user.toObj(user);
            new_user.setDisplayName();
            user_list.push(Object.assign({}, new_user));
          }
        }
        this.currentSubjects.next(user_list);
        return user_list;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getFullUsersList(): Promise<User[]> {
    return this.userService.getFullUsersList().then(
      (users: User[]) => {
        const user_list = [];
        let new_user: User = new User();
        for (const user of users) {
          new_user = new_user.toObj(user);
          new_user.setDisplayName();
          user_list.push(_.cloneDeep(new_user));
        }
        this.currentFullUserSubjects.next(user_list);
        return user_list;
      })
      .catch((err) => {
        return throwError(err).toPromise();
      });
  }

  /**
   * hosts can be any user that are not patients or parents
   *
   * @returns {Promise<User[]>}
   */
  get_hosts(): Observable<User[]> {
    return this.userService.userNames.pipe(
      map((users: User[]) => {
        const user_list = [];
        let new_user: User = new User();
        for (const user of users) {
          if (user.user_type != null && this.exclude_types.indexOf(user.user_type.toLowerCase()) === -1) {
            new_user = new_user.toObj(user);
            new_user.setDisplayName();
            user_list.push(Object.assign({}, new_user));
          }
        }
        // this.collection = users;
        this.hostsSubjects.next(users);
        return user_list;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  save(): Promise<boolean | void> {
    // now we save the user connection to a patient, if we need to do something down the track, need to change
    // this.userService.
    let promise: Promise<User>;
    const info: User = this.currentSubject.getValue();
    const patient: User = this.patientService.currentPatient.getValue();
    let has_updated: boolean;
    // first we check if we need to save the user with the entered email address
    if (info.update_user) {
      promise = this.userService.save(info);
    } else {
      promise = Promise.resolve(<User>{});
    }
    return promise
      .then(() => {
        let promise;
        if (info.update_child_link) {
          promise = this.userService.editAssignChild(info.id, patient.id, info.relationship_type);
        } else {
          promise = this.userService.assignChild(info.id, patient.id, info.relationship_type, info.relationship_type_desc);
        }
        return promise;
      })
      .then((res: SuccessResponse) => {
        has_updated = res.status === SUCCESS_STATUS;
        return this.getFullUsersList();
      })
      .then(() => {
        return has_updated;
      })
      .catch((err) => throwError(err).toPromise());
  }

  update(): Promise<any> {
    return undefined;
  }

  getById(id: number): Promise<User> {
    return this.userService.getById(id)
      .then(res => {
        this.patientService.currentPatient.next(res);

        return res;
      }).catch((err) => throwError(err).toPromise());
  }

  autoRefresh(): Observable<Auth> {
    // this.unsubscribe();
    return this.tokenService.autoRefresh().pipe(switchMap((auth) => {
      return [auth];
    }));

  }

  getSheetInfo(): Promise<SheetInfo> {
    const patient: User = this.patientService.currentPatient.getValue();

    return this.userService.prepareSheets(patient.id)
      .then((result: SheetInfo) => {
        return result;
      })
      .catch((err) => throwError(err).toPromise());
  }

  getPermissions(): Observable<PermissionModel[]> {
    return this.permissionService.getAll()
      .pipe(
        tap(permissions => {
          this.currentPermissionSubjects.next(permissions);
        })
      );
  }

  hasPermission(prefix: string, customAction: string, method:string = 'PUT'): boolean {
    const permissions = this.currentPermissionSubjects.getValue();
    if (!permissions || permissions.length === 0) {
      return false;
    }
    return permissions.findIndex(
      p => p.prefix === prefix &&
        p.custom_action === customAction &&
        p.method === method &&
        p.is_allowed
    ) > -1;
  }
  // isInGroup()

}
