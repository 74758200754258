import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DeviceDetectorService} from './device-detector.service';

@NgModule({
  declarations: [],
  providers: [
      DeviceDetectorService
  ],
  imports: [
    CommonModule
  ]
})
/**
 * reference: https://github.com/KoderLabs/ngx-device-detector/tree/master/projects/ngx-device-detector/src/lib
 */
export class DeviceDetectorModule { }
