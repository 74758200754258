import {Base} from './base.model';

export class NumTrial extends Base<NumTrial> {
  id: number;
  created: string;
  updated: string;
  _order: number;
  archived: boolean;
  type: string;
  trials: number;
  created_by: number;
  last_updated_by: number;
  procedure: number;
  edit: boolean;

  constructor() {
    super(NumTrial);
  }
}
