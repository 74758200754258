export enum ContactExport {
  contact = 1,
  number
}

export enum HttpSuccessCodes {
  SUCCESS = 200,
  DELETED = 202
}

export enum HttpErrorCodes {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  SERVER_ERROR = 404,
  TOKEN_EXPIRING = 488
}
