import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment as env} from '../../../environments/environment';
import {AuthenticationService} from '../authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.toLowerCase().indexOf(env.auth_api) === -1 && (req.url.toLowerCase().indexOf('reset_password')) === -1) {
      req = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${this.authenticationService.getToken().access_token}`,
        },
      });
    } else {
      req = req.clone({
        setHeaders: {
          'Authorization': `${env.auth_headers.Authorization}`,
        },
      });
    }

    return next.handle(req);
  }
}
