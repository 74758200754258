import {Base} from './base.model';

export class SdResponse extends Base<SdResponse> {
  procedure: number;
  _order: number;
  sd: string;
  correct_response: string;

  constructor(id?: number, procedure?: number, sd?: string, correct_response?: string) {
    super(SdResponse);
    this.id = id;
    this.procedure = procedure;
    this.sd = sd;
    this.correct_response = correct_response;
  }
}
