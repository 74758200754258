import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {BaseService} from '../base.service';
import {NumTrial} from '../models/num-trial.model';
import {IBaseService} from '../interfaces/base-interface';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class NumTrialService extends BaseService<NumTrial> {
  currentSubject: BehaviorSubject<NumTrial>;

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.numtrial/', NumTrial);
  }

}
