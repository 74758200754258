import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartupService } from './startup.service';

// export function startupServiceFactory(startupService: StartupService): Function {
//   return () => startupService.load();
// }

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
  ],
  providers: [
    StartupService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: startupServiceFactory,
    //   deps: [StartupService],
    //   multi: true
    // }
  ]
})
export class StartupModule { }
