import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicePlanComponent } from './service-plan.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '../../core/core.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    ServicePlanComponent
  ],
  exports: [
    ServicePlanComponent
  ]
})
export class ServicePlanModule { }
