import {Injectable} from '@angular/core';
import {IBaseViewService} from '../interfaces/base-view.interface';
import {Document} from '../../core/models/document.model';
import {DocumentService} from '../../core/services/document.service';
import {throwError, BehaviorSubject, Observable} from 'rxjs';
import {PatientService} from '../../core/services/patient.service';
import {Note} from '../../core/models/note.model';
import {ContentTypeService} from '../../core/services/content-type.service';
import {catchError, map, tap} from 'rxjs/operators';
import {Procedure} from '../../core/models/procedure.model';

@Injectable()
export class DocumentViewService implements IBaseViewService<Document> {

  currentSubjectType: BehaviorSubject<string> = new BehaviorSubject('');
  currentSubject: BehaviorSubject<Document> = new BehaviorSubject(new Document());
  currentSubjects: BehaviorSubject<Document[]> = new BehaviorSubject([]);
  currentSubjectsFiltered: BehaviorSubject<Document[]> = new BehaviorSubject([]);
  currentSubjectParent: BehaviorSubject<any> = new BehaviorSubject({});

  keys_to_search: string[] = ['name'];

  constructor(private documentService: DocumentService,
              private patientService: PatientService,
              private contentTypeService: ContentTypeService) {

  }

  // TODO: move to base service by passing in the context as a parameter instead of creating it with const that = this
  // need to do this because when processArray tries to call the function it doesn't know where it's calling from
  processArray(array, fn): Promise<Document> {
    const results = [];
    const that = this;
    return array.reduce(function (p, item) {
      return p.then(function () {
        return fn.call(that, item);
      });
    }, Promise.resolve());
  }

  search(status?: string, search_text?: string): Promise<Document[]> {
    let promise: Promise<Document[]>;
    let params: any;
    if (status) {
      params = {
        status: status
      }
    }
    // if (search_text) {
    //   if (!params) {
    //     params = {};
    //   }
    // }
    const patient = this.patientService.currentPatient.getValue();
    if (!params) {
      promise = this.documentService.getByParamPromise(patient.id.toString(), 'patient');
    } else {
      params['patient'] = patient.id.toString();
      promise = this.documentService.getByParamObjPromise(params);
    }
    return promise
      .then((filtered: Document[]) => {
        // check through all keys, not sure if there is an aggregate yet
        let result = [];
        if (search_text) {
          for (const item of filtered) {
            // const keys = Object.keys(item);
            for (const key of this.keys_to_search) {
              if (typeof item[key] === 'string' &&
                item[key].toLocaleLowerCase().indexOf(search_text.toLocaleLowerCase()) > -1) {
                result.push(Object.assign({}, item));
                break;
              }
            }
          }
        } else {
          result = filtered;
        }
        this.currentSubjectsFiltered.next(result);
        return result;
      })
      .catch((err) => throwError(err).toPromise());
  }

  save(): Promise<any> {
    let document = this.currentSubject.getValue();
    const type = this.currentSubjectType.getValue();
    const parent = this.currentSubjectParent.getValue();
    const content_types = this.contentTypeService.collectionUpdated.getValue();
    if (content_types && content_types.length > 0) {
      const content_type = content_types.find(ct => ct.model == type);
      if (document.content_type == null && content_type != null) {
        document.content_type = content_type.id;
      }
    }

    if (parent != null && parent.id != null) {
      document.object_id = parent.id;
    }

    this.documentService.currentSubject.next(document);
    return this.documentService.save()
      .then(
        (updated: Document) => {
          document = updated;
          this.currentSubject.next(document);
          // return procedure;
          return document
        })
      .catch((err) => throwError(err).toPromise());
  }

  save$(): Observable<Document> {
    let document = this.currentSubject.getValue();
    const type = this.currentSubjectType.getValue();
    const parent = this.currentSubjectParent.getValue();
    const content_types = this.contentTypeService.collectionUpdated.getValue();
    if (content_types && content_types.length > 0) {
      const content_type = content_types.find(ct => ct.model == type);
      if (document.content_type == null && content_type != null) {
        document.content_type = content_type.id;
      }
    }

    if (parent != null && parent.id != null) {
      document.object_id = parent.id;
    }

    this.documentService.currentSubject.next(document);
    return this.documentService.save$()
      .pipe(
        tap((updated: Document) => {
          document = updated;
          this.currentSubject.next(document);
        })
      );
  }

  update(): Promise<any> {
    return undefined;
  }

  getByPatient(id: string): Promise<Document[]> {
    return undefined;
  }

  getByType(type: string): Promise<Document[]> {
    const patient = this.patientService.currentPatient.getValue();
    // we should have a patient at this point, if we don't then something is very wrong
    const params = {patient: patient.id.toString()};
    if (type) {
      params['type'] = type;
    }
    return this.documentService.getByParamObjPromise(params)
      .then(documents => {
        this.currentSubjects.next(documents);
        return documents;
      })
      .catch(
        err => {
          console.log(err);
          return throwError(err).toPromise();
        });
  }

  getByType$(type: string): Observable<Document[]> {
    const patient = this.patientService.currentPatient.getValue();
    // we should have a patient at this point, if we don't then something is very wrong
    const params = {patient: patient.id.toString()};
    if (type) {
      params['type'] = type;
    }
    return this.documentService.getByParamObj(params)
      .pipe(
        tap(documents => {
          this.currentSubjects.next(documents);
          return documents;
        }),
        catchError(err => throwError(err))
      );
  }

  getSubjects(): void {
    const type = this.currentSubjectType.getValue();
    this.getByType(type)
      .then((documents) => {
        console.log(documents);
        // TODO: return documents or figure out what to do here
      })
      .catch((err) => {
        return throwError(err).toPromise();
      });
  }

  getById(id: number): Promise<Document> {
    return this.documentService.getById(id)
      .then((document) => {
        return document;
      })
      .catch((err) => {
        return throwError(err).toPromise();
      });
  }

  getFileById(id: number): Promise<Document> {
    return this.documentService.getFileById(id)
      .then((document) => {
        return document;
      })
      .catch((err) => {
        return throwError(err).toPromise();
      });
  }

  getPreviewById(id: number): Promise<Document> {
    return this.documentService.getPreviewById(id)
      .then((document) => {
        return document;
      })
      .catch((err) => {
        return throwError(err).toPromise();
      });
  }

  getByObject(assessment_id: number, content_type?: number): Promise<Document> {
    const params = {
      object_id: assessment_id,
      // content_type: content_type,
      archived: false
    };
    if (content_type != null) {
      params['content_type'] = content_type;
    }

    return this.documentService.getByParamObjPromise(params)
      .then((documents: Document[]) => {
        // there should only be 1, so return null or 1
        return documents[0];
      })
      .catch((err) => {
        return throwError(err).toPromise();
      });
  }

  getAllByObject(assessment_id: number, content_type?: number): Promise<Document[]> {
    const params = {
      object_id: assessment_id,
      // content_type: content_type,
      archived: false
    };
    if (content_type != null) {
      params['content_type'] = content_type;
    }

    return this.documentService.getByParamObjPromise(params)
      .then((documents: Document[]) => {
        // there should only be 1, so return null or 1
        return documents;
      })
      .catch((err) => {
        return throwError(err).toPromise();
      });
  }

  getByObject$(assessment_id: number, content_type?: number, type?: string): Observable<Document[]> {
    const params = {
      object_id: assessment_id,
      // content_type: content_type,
      archived: false
    };
    if (content_type != null) {
      params['content_type'] = content_type;
    }
    if (type != null) {
      params['type'] = type;
    }

    return this.documentService.getByParamObj(params)
      .pipe(
        tap((documents: Document[]) => {
          // there should only be 1, so return null or 1
          console.log(documents);
        })
      );
  }

  remove$(document: Document): Observable<boolean> {
    return this.documentService
      .removeObj$(document)
      .pipe(
        tap((isRemoved: boolean) => {
          console.log(isRemoved);
        })
      );
  }
}
