
import {BaseDetail} from './base-detail.model';

export class RelationshipDescs {
  relationship_desc: string;
  to_user_id: number;
}

export class ParentDetail extends BaseDetail {
  dob: string;
  dob_date: Date;
  age: number;
  relationship_desc: string;
  relationship_descs: RelationshipDescs[];
}
