import { Injectable } from '@angular/core';
import {ProcItem} from '../models/proc-item.model';
import {BaseService} from '../base.service';
import {IBaseService} from '../interfaces/base-interface';
import { HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';

@Injectable()
export class ProcItemService extends BaseService<ProcItem> {

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.procitem/', ProcItem);
    this.date_obj_keys = ['mastery_date'];
  }
}
