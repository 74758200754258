import {Injectable} from '@angular/core';
import {IBaseViewService} from '../interfaces/base-view.interface';
import {Note} from '../../core/models/note.model';
import {BehaviorSubject, throwError} from 'rxjs';
import {NoteService} from '../../core/services/note.service';
import {PatientService} from '../../core/services/patient.service';
import {HttpErrorCodes} from '../../core/shared/enum';
import {SuccessResponse} from '../../core/models/response.model';

@Injectable()
export class CommunicationViewService implements IBaseViewService<Note> {

  currentSubjectType: BehaviorSubject<string> = new BehaviorSubject('');

  currentSubject: BehaviorSubject<Note> = new BehaviorSubject(new Note());
  currentSubjects: BehaviorSubject<Note[]> = new BehaviorSubject([]);
  currentSubjectsFiltered: BehaviorSubject<Note[]> = new BehaviorSubject([]);

  keys_to_search: string[] = ['text'];

  constructor(private noteService: NoteService,
              private patientService: PatientService) {

  }

  processArray(array, fn): Promise<any> {
    const results = [];
    const that = this;
    return array.reduce(function (p, item) {
      return p.then(function () {
        return fn.call(that, item);
      });
    }, Promise.resolve());
  }

  search(status?: string, search_text?: string): Promise<Note[]> {
    let promise: Promise<Note[]>;
    let params: any;
    if (status) {
      params = {
        status: status
      }
    }
    // if (search_text) {
    //   if (!params) {
    //     params = {};
    //   }
    // }
    const patient = this.patientService.currentPatient.getValue();
    if (!params) {
      promise = this.noteService.getByParamPromise(patient.id.toString(), 'patient');
    } else {
      params['patient'] = patient.id.toString();
      promise = this.noteService.getByParamObjPromise(params);
    }
    return promise
      .then((filtered: Note[]) => {
        // check through all keys, not sure if there is an aggregate yet
        let result = [];
        if (search_text) {
          for (const item of filtered) {
            // const keys = Object.keys(item);
            for (const key of this.keys_to_search) {
              if (typeof item[key] === 'string' &&
                item[key].toLocaleLowerCase().indexOf(search_text.toLocaleLowerCase()) > -1) {
                result.push(Object.assign({}, item));
                break;
              }
            }
          }
        } else {
          result = filtered;
        }
        this.currentSubjectsFiltered.next(result);
        return result;
      })
      .catch((err) => throwError(err).toPromise());
  }


  save(): Promise<Note> {
    const note = this.currentSubject.getValue();
    const patient = this.patientService.currentPatient.getValue();

    note.patient = patient.id;
    return this.noteService.save(note)
      .then((ret_val) => {
        this.currentSubject.next(ret_val);
        return ret_val;
      })
      .catch(
        (err) => {
          return throwError(err).toPromise();
        });
  }

  update(): Promise<any> {
    return undefined;
  }

  getByPatient(): Promise<Note[]> {
    const patient = this.patientService.currentPatient.getValue();
    return this.noteService.getByParamObjPromise({patient: patient.id, type: 'comm_book'})
      .then((notes: Note[]) => {
        return notes;
      })
      .catch(
        err => {
          return throwError(err).toPromise();
        });
  }

  getSubjects(): void {
    const patient = this.patientService.currentPatient.getValue();

    this.noteService.getByParamObjPromise({patient: patient.id, type: 'comm_book'})
      .then((notes: Note[]) => {
        this.currentSubjects.next(notes);
      })
      .catch((err) => {
        // need to show toasty with error
      });
  }

  remove(note: Note): Promise<any> {
    if (note == null) {
      throw new Error('note not supplied');
    }
    // need to find out if we have to archive it or just blatently remove
    return this.noteService.removeObj(note)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  getById(id: number): Promise<Note> {
    return undefined;
  }
}
