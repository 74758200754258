import {Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: '[appCutMultilineText]'
})
export class CutMultilineTextDirective {

  constructor(private el: ElementRef, public renderer: Renderer2) {
  }

  getHeight() {
    const box = this.el.nativeElement,
          text = box.innerHTML,
          nextSibling = box.nextSibling,
          clone = document.createElement('div');

    clone.style.position = 'absolute';
    clone.style.visibility = 'hidden';
    clone.style.width = box.clientWidth - 16 + 'px';
    clone.style.lineHeight = 20 + 'px';
    clone.innerHTML = text;
    document.body.appendChild(clone);

    let l = text.length - 1;
    for (; l >= 0 && clone.clientHeight > box.clientHeight; --l) {
      clone.innerHTML = text.substring(0, l) + '...';
      box.classList.add("top-align");
      nextSibling.style.display = 'block';
    }
    box.innerHTML = clone.innerHTML;
    document.body.removeChild(clone);
  }

  ngOnInit() {
    setTimeout(() => {
      this.getHeight();
    })
  }
}
