import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListPatientComponent } from './list-patient.component';
import {RouterModule} from '@angular/router';
import {CoreModule} from '../core/core.module';
import {NgxPaginationModule} from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    NgxPaginationModule,
    FormsModule,
    DropDownsModule
  ],
  declarations: [ListPatientComponent]
})
export class ListPatientModule { }
