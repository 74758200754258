import { Injectable } from '@angular/core';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class ConfirmDeleteService {

  overrideDialogSizeSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dialog: DialogRef;

  constructor(private dialogService: DialogService) {
  }

  openConfirmDialog(content: string): Observable<any> {
    this.overrideDialogSizeSubject.next(true);
      this.dialog = this.dialogService.open({
        title: 'Delete confirmation',
        content,
        actions: [
          {text: 'No'},
          {text: 'Yes', primary: true}
        ],
        height: 200,
        minWidth: 450
      });

      return this.dialog.result.pipe(
        mergeMap(result => {
          let obs: Observable<boolean> = of();
          if (result instanceof DialogCloseResult) {
            console.log('close');
          } else {
            if (result && result.text.toLocaleLowerCase() === 'yes') {
              obs = of(true);
            }
          }
          this.dialog = null;
          this.overrideDialogSizeSubject.next(false);
          return obs;
        })
      );
  }
}
