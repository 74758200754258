import {Base} from './base.model';
import {SessionTrial} from './session-trial.model';

export class ProcItem extends Base<ProcItem> {
  procedure: number;
  _order: number;
  name: string;
  mastery_date: string;
  mastery_date_date: Date;
  stage: string;
  session_trials: SessionTrial[];
  help_text: string;
  group: string;
  group_desc: string;

  constructor(id?: number, procedure?: number, name?: string) {
    super(ProcItem);
    this.id = id;
    this.procedure = procedure;
    this.name = name;
  }
}
