import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TherapySession} from '../../core/models/therapy-session.model';
import {User} from '../../core/models/user.model';
import {BaseComponent} from '../../shared/base/base.component';
import {ConstantsService} from '../../core/services/constants.service';
import {DataSheetService} from '../../core/services/data-sheet.service';
import {DailySessionViewService} from '../../shared/services/daily-session-view.service';
import {UserService} from '../../core/services/user.service';
import {UserViewService} from '../../shared/services/user-view.service';
import {PatientService} from '../../core/services/patient.service';
import {DialogService, DialogCloseResult, DialogRef} from '@progress/kendo-angular-dialog';
import {ShowNavService} from '../../core/services/show-nav.service';
import {ProcedureSession} from '../../core/models/procedure-session.model';
import {IFilterable} from '../../shared/interfaces/ifilterable';
import {FilterViewService} from '../../shared/services/filter-view.service';
import {DataSheet} from '../../core/models/data-sheet.model';
import * as moment from 'moment';
import {SelectOption} from '../../core/models/select-option.model';
import {SheetInfo} from '../../core/models/sheet-info.model';
import * as _ from 'lodash';
import {ToastyService} from 'ng2-toasty';
import {ProcedureViewService} from '../../shared/services/procedure-view.service';
import {throwError} from 'rxjs';
import {TherapySessionService} from '../../core/services/therapy-session.service';
import { HttpErrorCodes } from 'app/core/shared/enum';
import { Session } from 'app/core/models/session.model';
// import 'libs/vue-app/cs.min';
// import 'libs/vue-app/cs-therapy-sessions.umd.min';
// import 'libs/vue-app/cs-component.umd.min';
// import 'libs/vue-app/cs-comp.umd';
// import 'libs/vue-app/cs-components.min';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.css']
  // styleUrls: ['./sessions.component.css', '../../../libs/vue-app/cs-therapy-sessions.css']
})
export class SessionsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  selected: string = 'DT';
  therapy_session: TherapySession;
  dailySessionViewService: DailySessionViewService;
  // hosts: User[];
  current_therapist: User = new User();
  current_user: User;
  search_objs: IFilterable[];
  procedure_sessions: ProcedureSession[];
  patient: User;
  data_sheets: DataSheet[];
  DELIMITER: string = '&';
  components: any[] = [
    {component: 'DT', title: 'Discrete Trial'},
    {component: 'TA', title: 'Task Analysis'},
    {component: 'IT', title: 'Incidental Teaching'},
    {component: 'MEI', title: 'Multiple Example Instruction'},
    {component: 'DUR', title: 'Duration'},
  ];
  sheet_info: SheetInfo;
  toastyService: ToastyService;
  editable: boolean;
  locationList:  SelectOption[] = [];
  dialog: DialogRef;

  constructor(
      dialogService: DialogService,
      constantsService: ConstantsService,
      dailySessionViewService: DailySessionViewService,
      userViewService: UserViewService,
      private therapySessionService: TherapySessionService,
      private showNavService: ShowNavService,
      private userService: UserService,
      private filterViewService: FilterViewService,
      private patientService: PatientService,
      private dataSheetService: DataSheetService,
      toastyService: ToastyService,
      private procedureViewService: ProcedureViewService
  ) {
    super(constantsService, userViewService, dialogService, null, null, toastyService);
    this.dailySessionViewService = dailySessionViewService;
    this.toastyService = toastyService;

    // // Check and set cookie
    // const haveCookie = this.authenticationService.getCookie()
    // if (!haveCookie) this.authenticationService.setCookieFromStorage()
  }

  sessionGroupClick(component: string): void {
    this.procedure_sessions = [];
    this.selected = component;
  }

  searchChange(event: string): void {
    if (event) {
      this.editable = false;
      let previous_therapy_sessions: TherapySession[] = this.dailySessionViewService.previousSubjects.getValue();
      const param_vals = event.split(this.DELIMITER);
      if (param_vals[0] != '') {
        const host = +param_vals[0];
        previous_therapy_sessions = previous_therapy_sessions.filter(pts => pts.host == host);
      }
      if (param_vals[1] != '') {
        const date = param_vals[1];
        previous_therapy_sessions = previous_therapy_sessions.filter(pts => pts.date == date);
      }
      if (param_vals[2] != '') {
        const session_id = +param_vals[2];
        previous_therapy_sessions = previous_therapy_sessions.filter(pts => pts.id == session_id);
      }
      const therapy_session = previous_therapy_sessions[0];
      if (therapy_session) {
        this.therapy_session = therapy_session;
        this.therapy_session.filtering = true;
        this.sheet_info = this.therapy_session.state_sheet_meta;
        this.dailySessionViewService.currentSubject.next(this.therapy_session);
      }
      // this.filterViewService.dataSheetSearchFilterSubject.next(event);
    }

  }

  searchClear(val: boolean): void {
    if (val) {
      this.editable = true;
      // this.filterViewService.dataSheetSearchFilterSubject.next('');
      this.getTherapySession(null, null, null, true);
    }
  }

  /**
   * passing a null date_filter and host_id will get the latest therapy session that
   * hasn't been completed
   *
   * @param {string} date_filter
   * @param {number} host_id
   * @param is_complete
   * @param clearFilter
   */
  getTherapySession(date_filter?: string, host_id?: number, is_complete?: boolean, clearFilter?: boolean): void {

    this.dailySessionViewService.getTherapySession(date_filter, host_id, is_complete)
      .then((session: TherapySession) => {
        this.therapy_session = session;
        let promise: Promise<boolean | void>;
        if (this.therapy_session) {
          this.therapy_session = _.merge(this.therapy_session, session);
          this.therapy_session.in_data_sheet = true;
        // } else if (session == null) {
        } else {
          if (this.therapy_session == null) {
            this.therapy_session = new TherapySession();
          }

          // this is a brand new session so we delete the host and the date
          if (this.therapy_session.is_complete != null) {
            delete this.therapy_session.is_complete;
          }
        }
        this.therapy_session.clear_filter = clearFilter;
        // if ((this.therapy_session.state_sheet_meta && Object.keys(this.therapy_session.state_sheet_meta).length > 0) || this.therapy_session.is_complete) {
        //   this.sheet_info = this.therapy_session.state_sheet_meta;
        //   promise = Promise.resolve(true);
        // } else {
        //   promise = this.getSheetInfo();
        // }
        promise = Promise.resolve(true);

        if (this.all_users && this.all_users.length > 0) {
          this.current_therapist = this.all_users.find(ul => ul.id === this.therapy_session.host);
        }
        if (this.current_therapist && this.therapy_session.host == null) {
          this.therapy_session.host = this.current_therapist.id;
        }
        // if it's still null, set it to the current user
        if (this.therapy_session.host == null) {
          this.therapy_session.host = this.current_user.id;
        }
        if (this.therapy_session.date_date == null) {
          this.therapy_session.date_date = moment().toDate();
        }
        if (this.therapy_session.patient == null) {
          this.therapy_session.patient = this.patient.id;
        }
        if (this.therapy_session.location == null) {
          this.therapy_session.location = null;
        }
        return promise;
      })
      .then((have_sheet_info) => {
        this.loading = false;
        this.updateSession();
      })
      .catch((err) => {
        this.loading = false;
        // something happened
        // TODO: do something here
        throw err;
      });
  }

  // get list of therpay sessions to filter datasheets by
  getSearchText(): void {
    this.dailySessionViewService.getPreviousTherapySessions(null)
      .then((sessions: TherapySession[]) => {
        const search_list: SelectOption[] = [];
        // this.all_procedure_sessions = sessions;
        if (sessions) {
          // we filter out the unique ones so we don't get any duplicates in the
          // search list
          for (const session of sessions) {
            let value = '', text = '';
            const user = this.users_list.find(ul => ul.id == session.host);
            if (user) {
              text += user.display_name + ' - ';
              value += user.id + this.DELIMITER;
            } else {
              value += this.DELIMITER;
            }

            const new_date = moment(session.date_date);
            if (new_date.isValid()) {
              text += new_date.format('DD/MM/YYYY');
              value += session.date + this.DELIMITER;
            } else {
              value += this.DELIMITER;
            }

            if (text.trim() && value.trim()) {
              // we tack on the therapy_session for uniqueness
              value += session.id;
              search_list.push(new SelectOption(value, text));
            } else {
              value += this.DELIMITER;
            }
          }
          // this.filterViewService.dataSheetSearchSubjects.next(search_list.slice());
        }
        this.search_objs = search_list;
      });
  }

  updateTherapySession(event: any): void {
    if (!this.therapy_session) {
      return;
    }
    this.saveSession();
  }

  completeSession(): void {
    console.log('completing session');
    this.therapy_session.is_complete = true;
    this.dailySessionViewService.currentSubject.next(this.therapy_session);
  }

  getDataSheets(): void {
    this.procedureViewService.getDataSheets()
      .then((data_sheets) => {
        this.data_sheets = data_sheets;
      });
  }

  getSheetInfo(): Promise<boolean | void> {
    return this.userViewService.getSheetInfo()
      .then((sheet_info: SheetInfo) => {
        this.sheet_info = sheet_info;
        this.therapy_session.state_sheet_meta = sheet_info;
        return true;
      })
      .catch((err) => {
        this.sheet_info = new SheetInfo();
        this.therapy_session.state_sheet_meta = this.sheet_info;
        this.addToast('Could not load sheet metadata, please try again later...', 'error');
        return throwError(err).toPromise();
      });
  }

  saveSession(): void {
    // clearing 'filtering'
    this.therapy_session.filtering = false;
    if (this.therapy_session.patient == null) {
      this.therapy_session.patient = this.patient.id;
    }
    this.dailySessionViewService.saveTherapySession(this.therapy_session)
      .then((therapy_session: TherapySession) => {
        // we only need to update the behaviour subject if the id's are different
        // since the children components only care about the id
        if (this.therapy_session.id == null || this.therapy_session.id !== therapy_session.id) {
          // in theory we should be ok as the values should be passed by reference
          this.therapy_session = Object.assign(this.therapy_session, therapy_session);
          this.therapy_session.in_data_sheet = true;
          this.dailySessionViewService.currentSubject.next(this.therapy_session);
        } else {
          this.therapy_session = Object.assign(this.therapy_session, therapy_session);
        }
      }).catch(err => {
        console.log(err);
        throw err;
      // return throwError(err).toPromise();
    });
  }

  subscribeToCurrentUser(): void {
    const curr_user = this.userService.currentUserUpdated.getValue();
    if (curr_user) {
      this.current_user = curr_user;
      if (this.therapy_session && this.therapy_session.host == null) {
        this.therapy_session.host = this.current_user.id;
      }
    }
    this.subscriptions.push(this.userService.currentUserUpdated.subscribe(
      (current_user) => {
        this.current_user = current_user;
        if (this.therapy_session.can_edit && this.therapy_session.host == null) {
          this.therapy_session.host = this.current_user.id;
        }
      }));
  }

  subscribeToProcedures(): void {
    this.subscriptions.push(this.dailySessionViewService.currentProcedureSessions.subscribe((data) => {
      this.procedure_sessions = data;
    }));
  }

  subscribeToPatient(): void {
    this.subscriptions.push(this.patientService.currentPatient.subscribe(
      (patient: User) => {
        this.patient = patient;
        if (this.patient && this.patient.id != null) {
          if (this.therapy_session.patient == null) {
            this.therapy_session.patient = this.patient.id;
          }
          // this.getTherapySession();

          this.loading = false;
        }
      }));
  }

  // startTherapySession(): void {
  //   this.loading = true;
  //   // this is a brand new session so we delete the host and the date
  //   if (this.therapy_session.is_complete != null) {
  //     delete this.therapy_session.is_complete;
  //   }
  //   if (this.therapy_session.patient == null) {
  //     this.therapy_session.patient = this.patient.id;
  //   }
  //   if (this.current_therapist && this.therapy_session.host == null) {
  //     this.therapy_session.host = this.current_therapist.id;
  //   }
  //   // if it's still null, set it to the current user
  //   if (this.therapy_session.host == null) {
  //     this.therapy_session.host = this.current_user.id;
  //   }
  //   if (this.therapy_session.date_date == null) {
  //     this.therapy_session.date_date = moment().toDate();
  //   }
  //   if (this.therapy_session.patient == null) {
  //     this.therapy_session.patient = this.patient.id;
  //   }
  //
  //   //debugger;
  //   this.getSheetInfo().then((has_info: boolean) => {
  //     return this.dailySessionViewService.saveTherapySession(this.therapy_session);
  //   }).then((session) => {
  //       if (session && session.id != null) {
  //         this.therapy_session = _.merge(this.therapy_session, session);
  //       }
  //       if (this.therapy_session.after_complete) {
  //         delete this.therapy_session.after_complete;
  //       }
  //     this.loading = false;
  //       this.updateSession();
  //     })
  //     .catch((err) => {
  //       this.loading = false;
  //       console.log(err);
  //       // now we notify the user something went wrong
  //       this.addToast('Could not create Therapy session', 'Error');
  //       // something happened
  //       // TODO: do something here
  //       throw err;
  //     });
  // }

  deleteSession() {
    let host = this.users_list.find(el => el.id === this.therapy_session.host),
        hostname = null;
    if (host != null) {
      hostname = `${host.first_name} ${host.last_name}`;
    } else {
      hostname = `unknown-${this.therapy_session.host}`;
    }
    let content = `Are you sure you want to delete the therapy session conducted by ${hostname} on ${this.therapy_session.date}?`;
    this.procedureViewService.overrideDialogSizeSubject.next(true);
    this.dialog = this.dialogService.open({
      title: 'Delete confirmation',
      content,
      actions: [
        {text: 'Yes', primary: true},
        {text: 'Cancel'},
      ],
      height: 200,
      width: 400
    });

    this.dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        console.log('close');
      } else {
        if (result.primary) {
          this.remove();
        }
      }
      this.procedureViewService.overrideDialogSizeSubject.next(false);
    })
  }

  remove(): void {
    this.therapySessionService.removeObj(this.therapy_session)
      .then((res) => {
        if (this.therapy_session.is_complete) {
          this.getSearchText();
          this.searchClear(true);
          this.dailySessionViewService.resetFilterValue.next(true);
        } else {
          this.dailySessionViewService.currentSubject.next(new Session());
          this.getTherapySession();
        }
        this.addToast('Therapy session was deleted.', 'Success');
      })
      .catch((err) => {
        if (typeof err === 'object' && err.status === HttpErrorCodes.UNAUTHORIZED) {
          this.addToast('You do not have permission to remove this therapy session...', 'Error');
        } else {
          this.addToast('Could not delete therapy session, please try again...', 'Error');
        }
        throw err;
      });
  }

  subscribeToTherapySession(): void {
    // do nothing until we are certain it's a new therapy_session
    this.subscriptions.push(this.dailySessionViewService.currentSubject.subscribe(
      (session: TherapySession) => {
        if (session.after_complete) {
          // let's not delete it yet
          // delete session.after_complete;
          this.therapy_session = session;
          if (this.therapy_session.after_complete) {
            delete this.therapy_session.after_complete;
          }
          this.getSearchText();
        } else if (session.ignore_update) {
          delete session.ignore_update;
          this.therapy_session = session;
        }
      }));
  }

  getLocationChoices(): void {
    if (!this.constantsService.choices || this.constantsService.choices.size === 0) {
      this.constantsService.getChoices().then(
        (choices: Map<string, any>) => {
          this.choices = choices.get('sessiontrial');
          this.locationList = this.choices.get('location_choices');
        });
    } else {
      this.choices = this.constantsService.choices.get('sessiontrial');
      this.locationList = this.choices.get('location_choices');
    }
  }

  updateSession(): void {
    this.dailySessionViewService.currentSubject.next(this.therapy_session);
  }

  showRefreshButton() {
    this.editable = false;
    // this.ngOnInit();

  }

  showEditSection () {
    this.editable = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    this.updateSession();
    this.unsubscribe();
    this.showNavService.showButtons.next(true);
  }

  // async refreshToken() {
  //   let token
  //   try {
  //     token = await this.tokenService.refresh()
  //   } catch (e) {
  //     console.log('error refreshing', e)
  //   }
  //   return token
  // }

  async ngOnInit() {
    // // Check and set cookie
    // const haveCookie = this.authenticationService.getCookie()
    // if (!haveCookie) {
    //   await this.refreshToken()
    // }

    this.editable = true;
    this.loading = true;
    this.therapy_session = new TherapySession();
    this.patient = new User();
    this.procedure_sessions = [];
    this.subscriptions = [];
    this.search_objs = [];
    this.data_sheets = [];
    this.locationList = [];
    // this.getLocationChoices();
    // this.getDataSheets();
    this.subscribeToPatient();
    // this.subscribeToCurrentUser();
    // this.subscribeToProcedures();
    // this.subscribeToTherapySession();
    // this.subscriptions.push(this.getAllUsers().subscribe((users) => {
    //   this.users_list = this.filterHosts(users);
    //   this.getSearchText();
    // }));
  }

}
