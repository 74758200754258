import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShowNavService} from '../../core/services/show-nav.service';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit, OnDestroy {

  constructor(private showNavService: ShowNavService,
              private location: Location,
              private router: Router) {

  }

  goBack(): void {
    this.router.navigate(['/patients']);
  }

  ngOnInit() {
    this.showNavService.set(false);
  }

  ngOnDestroy() {
    this.showNavService.set(true);
  }

}
