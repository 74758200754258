import * as _ from 'lodash';

export interface IBaseModel {
  required_fields: string[];
  isValid(): boolean;
}

export abstract class Base<T> implements IBaseModel {
  id: number;
  can_edit: boolean;
  can_delete: boolean;
  can_plot: boolean;
  edit: boolean;
  created_by?: number;
  last_updated_by?: number;
  created: string;
  updated: string;
  archived: boolean;
  required_fields: string[];
  _order: number;
  publish_status: string;

  constructor(private newType: new () => T) {
    this.publish_status = 'published';
  }

  public toObj(obj: any): T {
    const keys = Object.keys(obj);
    const return_obj = new this.newType();
    for (const key of keys) {
      if (return_obj[key] instanceof Date && typeof obj[key] === 'string') {
        return_obj[key] = new Date(obj[key]);
      } else if (typeof obj[key] === 'string') {
        return_obj[key] = obj[key].trim();
      } else {
        return_obj[key] = obj[key];
      }
    }

    return _.cloneDeep(return_obj);
    // return return_obj;
  }

  public isValid(): boolean {
    let is_valid = true;
    for (const prop of this.required_fields) {
      if (this[prop] == null || (typeof this[prop] == 'string' && this[prop] == '')) {
        is_valid = false;
      }
    }
    return is_valid;
  }
}
