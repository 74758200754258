import { Injectable } from '@angular/core';
import {AuthenticationService} from '../authentication.service';
import { HttpClient} from '@angular/common/http';
import {BaseService} from '../base.service';
import {ProcedureSession} from '../models/procedure-session.model';

@Injectable()
export class ProcedureSessionService extends BaseService<ProcedureSession> {

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.proceduresession/', ProcedureSession);
    this.subRoute = '';
    this.date_obj_keys = ['date'];
  }
}
