import { Pipe, PipeTransform } from '@angular/core';
import {CommonService} from '../shared/common/common.service';

@Pipe({
  name: 'toFormat'
})
export class ToFormatPipe implements PipeTransform {
  RADIX = 10;
  MAX_SECS = 60;

  format_map_regex = {
    'XmYs': /([0-9]+m)?[\s]?([0-9]+s|[0-9]+)?/
  };

  constructor(private commonService: CommonService){

  }

  private getMatchedValues(val: string, format?: string){
    let mins = 0, secs = 0;
    if (typeof val === 'string') {
      val = val.replace(' ', '');

    } else {
      val = '' + val;
    }
    const matches = val.match(this.format_map_regex[format]);

    const min_matched = matches[1];
    const sec_matched = matches[2];
    if (min_matched != null && min_matched.includes('m')){
      mins += parseInt(min_matched.split('m')[0] || '0', this.RADIX);
    }
    if (sec_matched != null && sec_matched.includes('s')) {
      secs += parseInt(sec_matched.split('s')[0] || '0', this.RADIX)
    } else if (sec_matched != null) {
      // if we got here then the user must've entered a value without a unit
      secs += this.commonService.TryParseInt(sec_matched, 0);
    }
    return {mins: mins, secs: secs};
  }

  private getDurationString(val: string | number, format: string): string {
    if (!val || val == null) return '0s';
    let mins = 0, secs = 0;

    if (typeof val === 'string') {
      const ret_val = this.getMatchedValues(val, format);
      mins = ret_val.mins;
      secs = ret_val.secs;
    } else if(typeof val === 'number') {
      secs = val;
    } else {
      // TODO: show error that the duration couldn't be parsed
      console.log('Note: duration could not be parsed');
      return '';
    }


    // now we check if we have gone over 60s
    if (secs != null && secs >= this.MAX_SECS) {
      mins += Math.floor(secs / this.MAX_SECS);
      secs = secs % this.MAX_SECS;
    }

    let ret_str = '';
    if (mins > 0) {
      ret_str = mins + 'm';
    }
    if (secs > 0) {
      if (ret_str != null) {
        ret_str += ' ';
      }
      ret_str += secs + 's';
    }
    return ret_str;
  }

  transform(value: any, format?: any): any {
    if (value == null || format == null || !(format in this.format_map_regex)) return value;
    return this.getDurationString(value, format);
  }

}
