// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const client_id = 'TPhcmSeZkDCYtiwdmuKbKdItA9oxcivzBcPpdtxB';
const client_secret = 'EC8hR9OkD7JH3GTrxDeFfPXCOsSZd0GYeCsEvkDjWm1GRCg2D5WK931b1nvIAiYE7IQ14stxn3PheoDVDbvlZ4EqPn9UvERfZn3IJGXNRXrf26MUDT2ouTSqt4FXScSB';

const env = 'Dev';

// Dev
// const endpoint = 'http://192.168.0.136:8095/';
//const endpoint = 'https://nextt-dev-api.codesource.com.au/';

 // const endpoint = 'https://nextt-test-api.codesource.com.au/';
// const endpoint = 'http://localhost:8000/';
// const endpoint = 'http://cc6f02b6.ngrok.io/';

// const cookieDomain = '.nextt.codesource.localhost';
//const cookieDomain = '.nextt-dev.codesource.com.au'; // TODO: need to test this

// const mobileUrl = 'm.nextt.codesource.localhost';
//const mobileUrl = 'm.data-entry.nextt-dev.codesource.com.au';
// const vueApp = 'data-entry.nextt.codesource.localhost';
//const vueApp = 'data-entry.nextt-dev.codesource.com.au';

 const sentry_env = 'local';
// const sentry_env = 'development';
//const sentry_env = 'staging';
const mobileUrl = 'localhost';
const vueApp = 'http://195.35.23.181:3001/';
const cookieDomain = '195.35.23.181'; 
const endpoint = 'http://195.35.23.181:8000/';

export const environment = {
  app: 'Nextt digital',
  production: false,
  endpoint: endpoint,
  sentry_environment: sentry_env,
  api: endpoint + 'api/',
  auth_api: endpoint + 'auth/token/',
  cookie_domain: cookieDomain,
  mobile_url: mobileUrl,
  vue_app_url: vueApp,
  storage_accessor: 'nextt_token',
  session_accessor: 'nextt_login_time',
  version: 'Dev',
  default_expiry: 3600, // 86400,
  refresh_token_timeout: 3600000, // need to be in milli seconds for call to interval()
  expires_within: 120, // if token expires within 15 seconds
  client_id: client_id,
  client_secret: client_secret,
  auth_headers: {
    Authorization: 'Basic ' + btoa(client_id + ':' + client_secret),
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  constants_map_key: 'global',
  logger: {
    enabled_default: true,
    headers: {
      Authorization: '9~VF~7XJ+rL#{ay0L(%I{opW}op0G',
      'Content-Type': 'application/json'
    },
    api: location.protocol + '//' + 'logging.codesource.com.au/api/report/',
    payload:  {
      source: 'Nextt-' + env,
      data: {
        appType: 'Nextt',
        appVersion: '1.2.8',
        environment : env,
        osVersion: 'web',
        deviceType: 'browser',
        deviceID : navigator.userAgent,
        eventType: '',
        currentState: '',
        currentParams: '',
        userID: '',
        tenancy: '',
        trace: []
      }
    }
  }
};
