import { Injectable } from '@angular/core';
// import {Subject} from 'rxjs/Subject';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../authentication.service';
// import {Observable} from 'rxjs/Observable';
import { User } from '../models/user.model';
import { BaseService } from '../base.service';
import { IBaseService } from '../interfaces/base-interface';
import * as moment from 'moment';
// import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import { SuccessResponse } from '../models/response.model';
import { SheetInfo } from '../models/sheet-info.model';
import { Observable, Subject, BehaviorSubject, throwError } from 'rxjs';

@Injectable()
export class UserService extends BaseService<User> {

  users: User[];
  current_user: User;
  currentUserUpdated: BehaviorSubject<User> = new BehaviorSubject(new User());
  newUser: BehaviorSubject<User> = new BehaviorSubject(new User());
  // TODO: need to use this instead of newUser, need to refactor
  userCollection: User[] = [];
  currentSubject: BehaviorSubject<User> = new BehaviorSubject(new User());
  currentSubjects: BehaviorSubject<User[]> = new BehaviorSubject([]);
  userNames: BehaviorSubject<User[]> = new BehaviorSubject([]);
  gettingUser: boolean = false;
  gettingUsers: boolean = false;

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.user/', User);
    this.subRoute = 'current_user/';
    this.is_not_archived = '?archived=false';
    // this.date_obj_keys = ['dob'];
  }

  findUser(id: number): User {
    if (!id || !this.collection || this.collection.length === 0) {
      return null;
    }
    // first we check this collection, then we check the one with everythin
    let result = this.collection.filter(c => c.id === id);
    if (result && result.length > 0) {
      return result[0];
    } else {
      result = this.userCollection.filter(c => c.id === id);
      if (result && result.length > 0) {
        return result[0];
      } else {
        return null;
      }
    }
  }

  setDisplayNames(collection: User[]) {
    if (collection && collection.length > 0) {
      for (const user of collection) {
        user.setDisplayName();
      }
    }
  }

  getCurrentUserUpdated(): Observable<User> {
    return this.currentUserUpdated.asObservable();
  }

  resetPassword(username: string, callback_url: string): Promise<boolean> {
    const params = {
      username: username,
      callback: callback_url
    };
    const url = this.apiUrl + 'reset_password/';
    return this.http.post(url, params)
      .toPromise()
      .then(
        (res) => {
          if (res) {
            // do something if we actually get something back from the api
            console.log(res);
          }
          return true;
        })
      .catch(
        (err) => {
          return Promise.reject(err);
        });
  }

  setPassword(password: string, verify_password: string): Promise<boolean> {
    const params = {
      new_password: password,
      verify_password: verify_password,
    };
    const url = this.apiUrl + 'change_password/';
    const options = this.getOptions();

    return this.http.post(url, params, options)
      .toPromise()
      .then(
        (res) => {
          if (res) {
            // do something if we actually get something back from the api
            console.log(res);
          }
          return true;
        })
      .catch(
        (err) => {
          return Promise.reject(err);
        });
  }

  save(user: User): Promise<User> {
    // need to move this into the base class
    let data: any;
    let url;
    let observable: Observable<User>;

    if (user.dob_date) {
      user.dob = moment(user.dob_date).format('YYYY-MM-DD');
    }

    data = user;
    // const options = this.options;
    const options = this.getOptions();

    if (user.id != null) {
      url = this.apiUrl + user.id + '/'; // need to put stupid slash on the end
      observable = this.http.patch<User>(url, data, options);
    } else {
      url = this.apiUrl;
      observable = this.http.post<User>(url, data, options);
    }
    return observable.toPromise()
      .then(
        (res: User) => {
          const ret = res;
          this.current_user = this.toObj(ret);
          this.currentSubject.next(this.current_user);
          return ret;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }

  getCurrentUser(): Promise<User> {
    if (this.gettingUser) {
      return Promise.resolve(new User);
    }
    // need to move this into the base class
    const url = this.apiUrl + this.subRoute;
    this.gettingUser = true;
    const options = this.getOptions();

    return this.http.get<User>(url, options)
      .toPromise()
      .then(
        (res: User) => {
          const user = res;
          // user.setDisplayName();
          this.current_user = this.toObj(user);
          this.currentUserUpdated.next(this.current_user);
          this.gettingUser = false;
          return user;
        }).catch(
        (err) => {
          // do something with error
          this.gettingUser = false;
          return throwError(err).toPromise();
        }
      );
  }

  assignRole(user: number, group: number): Promise<User> {
    // need to move this into the base class
    let data: any;
    let url;
    if (user != null) {
      url = this.apiUrl + user + '/assign_role/'; // need to put stupid slash on the end
    } else {
      return throwError('no id supplied').toPromise();
    }

    data = {
      group: group
    };
    // const options = this.options;
    const options = this.getOptions();

    return this.http.put<User>(url, data, options)
      .toPromise()
      .then(
        (res: User) => {
          return res;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }

  assignChild(user: number, child: number, relationship_type: string, relationship_desc?: string): Promise<SuccessResponse> {
    // need to move this into the base class
    let data: any;
    let url;
    if (user != null) {
      url = this.apiUrl + user + '/assign_child/'; // need to put stupid slash on the end
    } else {
      return throwError('no id supplied').toPromise();
    }

    data = {
      user: child,
      relationship_type: relationship_type
    };
    if (relationship_desc) {
      data.relationship_type = 'PARENT';
      data.relationship_desc = relationship_desc;
    }
    // const options = this.options;
    const options = this.getOptions();

    return this.http.put<SuccessResponse>(url, data, options)
      .toPromise()
      .then(
        (res: SuccessResponse) => {
          // should be in form: { "status": "success" }
          return res;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }

  editAssignChild(user: number, child: number, relationship_type: string): Promise<SuccessResponse> {
    // need to move this into the base class
    let data: any;
    let url;
    if (user != null) {
      url = this.apiUrl + user + '/edit_assign_child/'; // need to put stupid slash on the end
    } else {
      return throwError('no id supplied').toPromise();
    }

    data = {
      user: child,
      relationship_type: relationship_type
    };
    // const options = this.options;
    const options = this.getOptions();

    return this.http.put<SuccessResponse>(url, data, options)
      .toPromise()
      .then(
        (res: SuccessResponse) => {
          // should be in form: { "status": "success" }
          return res;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }

  unassignChild(parent: number, child: number, relationship_type: string): Promise<SuccessResponse> {
    // need to move this into the base class
    let data: any;
    let url;
    if (parent != null) {
      url = this.apiUrl + parent + '/unassign_child/'; // need to put stupid slash on the end
    } else {
      return throwError('no id supplied').toPromise();
    }

    data = {
      user: child,
      relationship_type: relationship_type
    };
    // const options = this.options;
    const options = this.getOptions();

    return this.http.put<SuccessResponse>(url, data, options)
      .toPromise()
      .then(
        (res: SuccessResponse) => {
          return res;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }

  addContact(user: User, patient_id: number): Promise<User> {
    // need to move this into the base class
    let data: any;
    let url;
    let observable: Observable<User>;

    if (user == null || patient_id == null) {
      return throwError('no id supplied').toPromise();
    }

    if (user.dob_date) {
      user.dob = moment(user.dob_date).format('YYYY-MM-DD');
    }

    data = user;
    // const options = this.options;
    const options = this.getOptions();

    url = this.apiUrl + patient_id + '/add_contact/'; // need to put stupid slash on the end
    observable = this.http.put<User>(url, data, options);

    return observable.toPromise()
      .then(
        (res: User) => {
          const ret = res;
          this.current_user = ret;
          this.getUsers();
          // this.currentUserUpdated.next(this.current_user);
          return ret;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }

  getUsers(): Promise<User[]> {
    // need to move this into the base class
    const url = this.apiUrl + 'names/';
    const options = this.getOptions();

    return this.http.get<User[]>(url, options)
      .toPromise()
      .then(
        (res: User[]) => {
          const user = res;
          // user.setDisplayName();
          this.userNames.next(user);
          this.userCollection = user;
          return user;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }

  getFullUsersList(): Promise<User[]> {
    // need to move this into the base class
    const url = this.apiUrl;
    const options = this.getOptions();

    return this.http.get<User[]>(url, options)
      .toPromise()
      .then(
        (res: User[]) => {
          const users = res;
          // user.setDisplayName();

          return users;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }

  inviteUser(user_id: number): Promise<SuccessResponse> {
    // need to move this into the base class
    let data: any;
    let url;
    if (user_id == null) {
      return throwError('no id supplied').toPromise();
    }
    url = this.apiUrl + user_id + '/invite_user/'; // need to put stupid slash on the end
    data = {
      invite_callback: document.baseURI + 'invite'
    };
    // const options = this.options;
    const options = this.getOptions(true);

    return this.http.put<SuccessResponse>(url, data, options)
      .toPromise()
      .then(
        (res: SuccessResponse) => {
          return res;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }

  prepareSheets(user_id: number): Promise<SheetInfo> {
    // need to move this into the base class
    let data: any;
    let url;
    if (user_id == null) {
      return throwError('no id supplied').toPromise();
    }
    url = this.apiUrl + user_id + '/prepare_sheets/';
    // const options = this.options;
    const options = this.getOptions();

    return this.http.get<SheetInfo>(url, options)
      .toPromise()
      .then(
        (res: SheetInfo) => {
          const ret_val = res;
          const converted: SheetInfo = this.toObj(ret_val, SheetInfo);
          return converted;
        }).catch(
        (err) => {
          // do something with error
          return throwError(err).toPromise();
        }
      );
  }


}
