import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getListNames'
})
export class GetListNamesPipe implements PipeTransform {

  transform(value: any, args?: any, id_key?: any, key?: any): any {
    if (!value || !value.hasOwnProperty('length') ||
      !args || !args.hasOwnProperty('length') || !key || !id_key) {
      return;
    }

    let ret_str: string = '';
    const delim: string = ', ';

    for (const id of value) {
      const val = args.find(a => a[id_key] == id);
      if (val) {
        ret_str += val[key] + delim;
      }
    }

    if (ret_str) {
      ret_str = ret_str.substring(0, ret_str.length - delim.length);
    }
    console.log(ret_str);
    return ret_str;
  }

}
