import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {environment as env} from '../../../../environments/environment';
import {Subscription} from "rxjs/index"
import {Observable, of, throwError} from 'rxjs';
import {UserService} from '../../services/user.service';
import {ConstantsService} from '../../services/constants.service';
import {AuthenticationService} from '../../authentication.service';
import {TokenService} from '../../services/token.service';
import {HttpErrorCodes} from '../enum';
import {AuthGroupService} from '../../services/auth-group.service';
import {DataSheetService} from '../../services/data-sheet.service';
import {ContentTypeService} from '../../services/content-type.service';
import {AuthGroup} from '../../models/auth-group.model';
import {SkillObjectiveService} from '../../services/skill-objective.service';
import {SkillObjective} from '../../models/skill-objective.model';
import {Domain} from '../../models/domain.model';
import {DomainService} from '../../services/domain.service';
import {Skill} from '../../models/skill.model';
import {SkillService} from '../../services/skill.service';
import {DataSheet} from '../../models/data-sheet.model';
import {ContentType} from '../../models/content-type.model';

@Injectable()
export class StartupService {

  ignoreRoutes = ['/restpassword'];
  defaultExpiry = env.expires_within; // if token expiry is less than 15 seconds, renew
  private subscriptions: Array<Subscription> = [];

  constructor(private injector: Injector,
              private tokenService: TokenService,
              private authenticationService: AuthenticationService,
              private constantsService: ConstantsService,
              private userService: UserService,
              private authGroupService: AuthGroupService,
              private dataSheetService: DataSheetService,
              private contentTypeService: ContentTypeService,
              private skillObjectiveService: SkillObjectiveService,
              private domainService: DomainService,
              private skillService: SkillService) {
  }

  public get router(): Router {
    return this.injector.get(Router);
  }

  load(): Promise<any> {

    const token = this.authenticationService.getToken();
    let promise: Promise<any>;
    const url = this.router.url;
    const haveCookie = this.authenticationService.getCookie()
    console.log('have cookie', haveCookie)

    if (!haveCookie) {
      // else we delete the token entry and go back to login
      this.authenticationService.clearToken();
      this.authenticationService.clearCookie()

      return this.router.navigate(['/login']);
    } else if (this.ignoreRoutes.indexOf(url) === -1 && token && (this.authenticationService.isExpired(token) ||
        this.authenticationService.expiresWithin(this.defaultExpiry))) {
      if (this.authenticationService.isRefreshExpired(token)) {
        // else we delete the token entry and go back to login
        this.authenticationService.clearToken();
        this.authenticationService.clearCookie()

        this.router.navigate(['/login']);
      } else if (!this.tokenService.getToken &&
        this.authenticationService.accessExpiresWithin(this.defaultExpiry)) {
        promise = this.tokenService.refresh();

      } else {
        promise = Promise.resolve({});
      }
    } else {
      // this.router.navigate(['/login']);
      if (!this.userService.current_user) {
        promise = Promise.resolve({});
      } else {
        promise = Promise.resolve(this.userService.current_user);
      }
    }

    const ret = promise.then(
      (t) => {
        // let's get the user first
        // if we have an id, then we have the current user
        if (t.id != null) {
          // we have the current user so we don't need to worry
          return Promise.resolve({});
        } else {
          return this.userService.getCurrentUser();
        }
      })
      .then(
        res => {
          let p: Promise<any[]>;
          if (this.userService.userCollection && this.userService.userCollection.length > 0) {
            p = of(this.userService.userCollection).toPromise();
          } else {
            p = this.userService.getUsers();
          }
          return p;
        })
      .then(
        res => {
          let p: Promise<any[]>;
          if (this.constantsService.collection && this.constantsService.collection.length > 0) {
            p = of(this.constantsService.collection).toPromise();
          } else {
            p = this.constantsService.getChoices();
          }
          return p;
        })
      .then(
        () => {
          let p: Promise<ContentType[]>;
          if (this.contentTypeService.collection && this.contentTypeService.collection.length > 0) {
            p = of(this.contentTypeService.collection).toPromise();
          } else {
            p = this.contentTypeService.getAllPromise();
          }
          return p;
        })
      .then(
        () => {
          let p: Promise<SkillObjective[]>;
          if (this.skillObjectiveService.collection && this.skillObjectiveService.collection.length > 0) {
            p = of(this.skillObjectiveService.collection).toPromise();
          } else {
            p = this.skillObjectiveService.getAllPromise();
          }
          return p;
        })
      .then(
        () => {
          let p: Promise<Domain[]>;
          if (this.domainService.collection && this.domainService.collection.length > 0) {
            p = of(this.domainService.collection).toPromise();
          } else {
            p = this.domainService.getAllPromise();
          }
          return p;
        })
      .then(
        () => {
          let p: Promise<Skill[]>;
          if (this.skillService.collection && this.skillService.collection.length > 0) {
            p = of(this.skillService.collection).toPromise();
          } else {
            p = this.skillService.getAllPromise();
          }
          return p;
        })
      .then(
        () => {
          let p: Promise<DataSheet[]>;
          if (this.dataSheetService.collection && this.dataSheetService.collection.length > 0) {
            p = of(this.dataSheetService.collection).toPromise();
          } else {
            p = this.dataSheetService.getAllPromise();
          }
          return p;
        })
      .then(
        () => {
          let p: Promise<AuthGroup[]>;
          if (this.authGroupService.collection && this.authGroupService.collection.length > 0) {
            p = of(this.authGroupService.collection).toPromise();
          } else {
            p = this.authGroupService.getAllPromise();
          }
          return p;
        })
      .then(
        (permissions: AuthGroup[]) => {
          const user = this.userService.currentUserUpdated.getValue();
          if (user) {
            const filtered_perms = permissions.find(p => user.groups.indexOf(p.id) > -1);
            this.authGroupService.currentUserPermissions.next(filtered_perms);
          }

          if (this.router.url === '/patient') {
            console.log(this.router);
            this.router.navigate(['/patients']);
          }
          return true;
        }).catch(
        (err: any) => {
          // need to log the error here
          if (err && (err.status === HttpErrorCodes.SERVER_ERROR ||
              err.status === HttpErrorCodes.UNAUTHORIZED)) {
            this.tokenService.remove();

            this.router.navigate(['/login']);
          } else {
            console.log(err);
          }
          console.log('catch');
          return throwError(err).toPromise();
        });
    return ret;
  }
}
