import {Component, Injectable, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {ToastData, ToastOptions, ToastyService} from 'ng2-toasty';
import {Subscription, BehaviorSubject, of, Observable} from 'rxjs';
import {ConstantsService} from '../../core/services/constants.service';
import {SelectOption} from '../../core/models/select-option.model';
import {UserService} from '../../core/services/user.service';
import {User} from '../../core/models/user.model';
import {AddServicePlanComponent} from '../add-service-plan/add-service-plan.component';
import {DialogCloseResult, DialogService} from '@progress/kendo-angular-dialog';
import {AddContactComponent} from '../add-contact/add-contact.component';
import {AddSapComponent} from '../add-sap/add-sap.component';
import {IBaseService} from '../../core/interfaces/base-interface';
import {IBaseViewService} from '../interfaces/base-view.interface';
import {UserViewService} from '../services/user-view.service';
import {AddSessionComponent} from '../add-session/add-session.component';
import {CurrentFormService} from '../../core/services/current-form.service';
import {AddUserComponent} from '../add-user/add-user.component';
import {AddCommunicationComponent} from '../add-communication/add-communication.component';
import {AddDocumentComponent} from '../add-document/add-document.component';
import {ListDocumentComponent} from '../list-document/list-document.component';
import {AddClientComponent} from '../add-client/add-client.component';
import {HttpErrorCodes} from '../../core/shared/enum';
import {AddAssessmentComponent} from '../add-assessment/add-assessment.component';
import {ContentTypeService} from '../../core/services/content-type.service';
import {ContentType} from '../../core/models/content-type.model';
import * as _ from 'lodash';
import {SessionTrial} from '../../core/models/session-trial.model';
import {map} from 'rxjs/operators';
import {Procedure} from '../../core/models/procedure.model';

declare var jQuery: any;
declare const $: any;

@Injectable()
export class BaseComponent implements OnInit {
  TIMEOUT_VAL = 1000;
  timeout: any;
  current_form: any;
  subscriptions: Subscription[];
  choices: Map<string, any>;
  filter_status: string;
  filter_text: string;
  DEFAULT_TITLE = 'Error';
  title: string;
  constantsService: ConstantsService;
  userViewService: UserViewService;
  dialogService: DialogService;
  currentFormService: CurrentFormService;
  toastyService: ToastyService;
  contentTypeService: ContentTypeService;
  // toastyService: ToastyService;
  safety_information_options: SelectOption[];
  toileting_options: SelectOption[];
  comm_profile_options: SelectOption[];
  relationship_types: SelectOption[];
  publish_statuses: SelectOption[];
  regions: SelectOption[];
  phase_types: SelectOption[];
  users_list: User[];
  all_users: User[];
  full_users_list: User[];
  modalService: IBaseViewService<any>;
  popups = {
    contact: AddContactComponent,
    service_plan: AddServicePlanComponent,
    saps: AddSapComponent,
    session: AddSessionComponent,
    user: AddUserComponent,
    communication: AddCommunicationComponent,
    document: AddDocumentComponent,
    documents: ListDocumentComponent,
    patient: AddClientComponent,
    assessment: AddAssessmentComponent
  };
  dialog;
  loading: boolean;
  document_type: string;
  content_type: ContentType;
  current_modal_obj: any;
  toastyId: number;

  choicesSubject: BehaviorSubject<Map<string, any>> = new BehaviorSubject(new Map<string, any>());
  hostsSubjects: BehaviorSubject<User[]> = new BehaviorSubject([]);
  allUsersSubject: BehaviorSubject<User[]> = new BehaviorSubject([]);
  refreshSubjects: BehaviorSubject<boolean> = new BehaviorSubject(false);
  allUserSubjects: BehaviorSubject<User[]> = new BehaviorSubject([]);
  public defaultItem: {
    text: string,
    value: number,
    id: number,
    step: string,
    display_name: string
  } = {
    text: 'Select item...',
    value: null,
    id: null, step: 'Select item...', display_name: 'Select item...'
  };

  // constructor(constantsService: ConstantsService,
  //             userService: UserService,
  //             toastyService: ToastyService,) {
  /**
   *  popup service is the base service for saving/updating the objects in the popup
   * @param {ConstantsService} constantsService
   * @param userViewService
   * @param {DialogService} dialogService
   * @param {IBaseService} modalService
   * @param currentFormService
   */
  constructor(
      constantsService: ConstantsService,
      userViewService: UserViewService,
      dialogService?: DialogService,
      modalService?: IBaseViewService<any>,
      currentFormService?: CurrentFormService,
      toastyService?: ToastyService,
      contentTypeService?: ContentTypeService
  ) {
    this.constantsService = constantsService;
    this.userViewService = userViewService;
    this.modalService = modalService;
    this.dialogService = dialogService;
    this.currentFormService = currentFormService;
    this.toastyService = toastyService;
    // this.toastyService = toastyService;
    this.contentTypeService = contentTypeService;

    this.getChoices();
  }

  getDocumentContentType(): void {
    if (this.contentTypeService) {
      const content_types = this.contentTypeService.collectionUpdated.getValue();
      if (content_types && content_types.length > 0) {
        this.content_type = content_types.find(ct => ct.model == this.document_type);
      }
    }
  }

  validateNumber(val: any) {
    let ret_val: boolean = true;
    if (val == null) {
      ret_val = false;
    }
    // if (!val || typeof val === 'number') {
    //   ret_val = false;
    // }
    try {
      const temp = parseFloat(val);
      if (temp >= 0) {
        ret_val = false;
      }
    } catch(e) {
      console.log(e);
    }
    return ret_val;
  }

  getChoices(): void {
    if (!this.constantsService.choices || this.constantsService.choices.size === 0) {
      this.constantsService.getChoices().then(
        (choices: Map<string, any>) => {
          this.choices = choices;
          this.safety_information_options = choices.get('patientdetail').get('safety_information');
          this.toileting_options = choices.get('patientdetail').get('toileting');
          this.comm_profile_options = choices.get('patientdetail').get('comm_profile');
          this.relationship_types = choices.get('relationship_type');
          this.regions = choices.get('user').get('region');
          this.publish_statuses = choices.get('global').get('publish_status');
          this.choicesSubject.next(this.choices);
        });
    } else {
      this.choices = this.constantsService.choices;
      this.safety_information_options = this.choices.get('patientdetail').get('safety_information');
      this.toileting_options = this.choices.get('patientdetail').get('toileting');
      this.comm_profile_options = this.choices.get('patientdetail').get('comm_profile');
      this.relationship_types = this.choices.get('relationship_type');
      this.regions = this.choices.get('user').get('region');
      this.publish_statuses = this.choices.get('global').get('publish_status');
      this.choicesSubject.next(this.choices);
    }
  }

  getToastOptions(message: string, title: string, theme?: string, timeout?: number): ToastOptions {
    title = title != null ? title : this.DEFAULT_TITLE;
    timeout = timeout != null ? timeout : 5000;
    theme = theme != null ? theme : 'default';

    return {
      title: title,
      msg: message,
      showClose: true,
      timeout: timeout,
      theme: theme,
      onAdd: (toast: ToastData) => {
        console.log('Toast ' + toast.id + ' has been added!');
        this.toastyId = toast.id;
      }
    };
  }

  addToast(message: string, title: string, theme?: string, timeout?: number) {
    // Or create the instance of ToastOptions
    const toastOptions = this.getToastOptions(message, title, theme, timeout);
    // // Add see all possible types in one shot
    // this.toastyService.info(toastOptions);
    // this.toastyService.success(toastOptions);
    // this.toastyService.wait(toastOptions);
    // this.toastyService.error(toastOptions);
    // this.toastyService.warning(toastOptions);

    switch (title.toLowerCase()) {
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
      case 'saving':
        // toastOptions
        this.toastyService.wait(toastOptions);
        break;
    }
  }

  getAllClients(): Observable<User[]> {
    // const users = this.userViewService.currentSubjects.getValue();
    // let promise: Promise<User[]>;
    // if (!users || users.length === 0) {
    // if (!this.userViewService.collection || this.userService.collection.length === 0) {
    return this.userViewService.get_users()
      .pipe(
        map(
          (user_list) => {
            this.users_list = user_list.slice();
            return user_list;
          })
      );
    // } else {
    //   this.users_list = users.slice();
    //   promise = of(users.slice()).toPromise();
    // }
    // return promise;
  }

  getAllUsers(): Observable<User[]> {
    // const users = this.userViewService.currentSubjects.getValue();
    // let promise: Promise<User[]>;
    // if (!users || users.length === 0) {
    return this.userViewService.getAllUsers().pipe(
      map((user_list: User[]) => {
        this.all_users = user_list.slice();
        this.allUsersSubject.next(user_list.slice());
        return user_list;
      }));
    // } else {
    //   promise = of(users.slice()).toPromise();
    // }
    // return promise;
  }

  getFullUsersList(reload?: boolean): Promise<User[]> {
    const users = this.userViewService.currentFullUserSubjects.getValue();
    if (!reload) {
      return this.userViewService.getFullUsersList().then(
        (ret_users) => {
          this.full_users_list = ret_users;
          // this.allUsersSubject.next(users);
          return ret_users;
        })
    } else {
      this.full_users_list = users.slice();
      return of(this.full_users_list.slice()).toPromise();
    }
  }

  filterHosts(users: User[]): User[] {
    const user_list = [];
    let new_user: User = new User();
    for (const user of users) {
      if (user.user_type != null && this.userViewService.exclude_types.indexOf(user.user_type.toLowerCase()) === -1) {
        new_user = new_user.toObj(user);
        new_user.setDisplayName();
        user_list.push(_.cloneDeep(new_user));
      }
    }
    // this.collection = users;
    return user_list;
  }

  getHosts(): void {
    // const users = this.userViewService.hostsSubjects.getValue();
    // if (!users || users.length === 0) {
    this.userViewService.get_hosts().subscribe(
      (ret_users) => {
        this.users_list = ret_users;
        this.hostsSubjects.next(ret_users);
      });
    // } else {
    //   this.users_list = users.slice();
    //   this.hostsSubjects.next(users);
    // }
  }

  unsubscribe(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  /**
   * generic add/edit popup modal
   * should really be renamed
   *
   * @param obj
   * @param {string} component
   * @param event
   * @param {TemplateRef<any>} actionTemplate
   * @param {IBaseViewService<any>} modal_service
   * @param {string} obj_type
   * @param {string} title
   * @param parent
   */
  editPopup(obj?: any, component?: string, event?: any,
            actionTemplate?: TemplateRef<any>,
            modal_service?: IBaseViewService<any>,
            obj_type?: string,
            title?: string,
            parent?: any): void {
    // let promise: Promise<ParentDetail[]>;
    // if (obj != null) {
    //   // contact = this.contacts[idx];
    // } else {
    //   return;
    // }
    this.current_modal_obj = obj;
    if (modal_service) {
      this.modalService = modal_service;
    }
    if (obj_type != null && obj_type !== '') {
      this.modalService.currentSubjectType.next(obj_type);
    }
    if (title != null && this.modalService.currentTitle) {
      this.modalService.currentTitle.next(title);
    }
    // do this only if we have a parent to add and the modal service
    // has instantiated currentSubjectParent
    if (parent && this.modalService.currentSubjectParent) {
      this.modalService.currentSubjectParent.next(parent);
    }
    this.modalService.currentSubject.next(obj);
    if (event) {
      event.preventDefault();
    }
    const jq_height = jQuery(window).height();
    const height = jq_height < window.innerHeight ? jq_height : window.innerHeight;
    const options = {
      // title: "Add Contact",

      // Show component
      content: this.popups[component],

      // actions: actionTemplate
      actions: actionTemplate,
      height: height
    };

    this.dialog = this.dialogService.open(options);
    this.dialog.result.subscribe((result) => {
      if (event) {
        event.preventDefault();
      }
      if (result instanceof DialogCloseResult) {
        console.log('close');
        this.modalService.currentSubjectType.next(null);
        // now force
      } else {
        // TODO check if below is obsolete since we use
        // custom actions
        console.log('action', result);
        if (!result['primary']) {
          this.modalService.currentSubject.next(null);
        } else {
          // TODO: add save
          this.modalService.save()
            .then((res) => {
              this.modalService.getSubjects();
            })
            .catch((err) => {
              console.log(err);
              if (err && typeof err === 'object' && !err.status) {
                // take the first error key
                const keys = Object.keys(err);
                if (keys.length > 0) {
                  const key = keys[0];
                  // err object comes back in the form of { <field>: [<message>]}
                  let message: string;
                  if (typeof err[key] === 'string') {
                    message = key + ': ' + err[key];
                  } else {
                    message = key + ': ' + err[key][0];
                  }

                  this.addToast(message, 'error');
                }
              } else {
                this.addToast('Could not add the selected user, please try again later', 'error');
              }

              throw err;
            });
        }
      }
      // this.result = JSON.stringify(result);
    });
    document.body.style.overflow = 'hidden';
  }

  print(obj: any): void {
    if (!obj['id']) {
      return;
    }
    // let's get the thing to print
    this.modalService.getById(obj['id'])
      .then((res) => {
        const content = res['html'];
        if (content) {
          const popupWin = window.open(document.baseURI);
          popupWin.document.write('<!DOCTYPE html>' + content);
          popupWin.document.close();
          setTimeout(() => {
            // popupWin.opener = null;
            popupWin.focus();
            // popupWin.print();
            // popupWin.close();
          }, 10);

        } else {
          this.addToast('Could not print Service plan...', 'error');
        }

      });
  }

  printByDomId(selector: string): void {
    if (!selector) {
      return;
    }
    let printContents, popupWin, container, container_list, content_head;
    container_list = document.getElementsByClassName(selector);
    // there should only be one so we get the first one
    container = <HTMLElement>container_list[0];
    content_head = document.getElementsByTagName('head')[0].innerHTML;

    printContents = container.innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          ${content_head}
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  fillSessionTrials(session_trials: SessionTrial[]) {
    // now we know the session trials, if any, are sorted by _order
    let new_session_trials: SessionTrial[] = [];
    if (session_trials && session_trials.length > 0) {
      for (let i = 0; i < session_trials.length; i++) {
        let order_diff: number;
        // if (i !== session_trials.length - 1) {
        // first we pre-pad the array with trials
        if (i === 0 && session_trials[i]._order != null && session_trials[i]._order !== i) {
          order_diff = session_trials[i]._order - i;
          new_session_trials = [...new_session_trials, ...(Array
            .apply(null, Array(order_diff))
            .map(() => new SessionTrial()))];
        }
        new_session_trials.push(_.cloneDeep(session_trials[i]));

        if (i !== session_trials.length - 1) {
          order_diff = session_trials[i + 1]._order - session_trials[i]._order;
        }
        if (order_diff != null && order_diff > 1) {
          new_session_trials = [...new_session_trials, ...(Array
            .apply(null, Array(order_diff - 1))
            .map(() => new SessionTrial()))];
          // updating order - the array could be of length 0 cause we still get here if the order_diff is 1
          if (new_session_trials && new_session_trials.length > 0) {
            for (let j = 0; j < new_session_trials.length; j++) {
              new_session_trials[j]._order = j;
            }
          }

        }
      }
    }
    return new_session_trials;
  }

  public filter(isDiscardLoad?: boolean): Promise<any | void> {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    let promise = new Promise<any | void>(resolve => {
      this.timeout = setTimeout(() => {
        if (!isDiscardLoad) {
          this.loading = true;
          this.modalService.search(this.filter_status, this.filter_text)
            .then(res => {
              this.loading = false;
              return resolve(res);
            })
            .catch(err => {
              this.loading = false;
              throw err;
            });
        } else {
          this.modalService.search(this.filter_status, this.filter_text)
            .then(res => resolve(res))
            .catch(err => {throw err})
        }
      }, 100);
    })

    return promise;
  }

  public close() {
    if (this.dialog) {
      this.dialog.close();
    }

    document.body.style.overflow = '';
  }

  public custom(dialogTemplate?: TemplateRef<any>) {
    this.current_form = this.currentFormService.currentSubject.getValue();

    console.log('saving from the custom function')
    this.current_form.ngSubmit.emit();
    this.current_form.submitted = true;
    if (this.current_form.valid) {
        this.saveSubjects(dialogTemplate);
    }
    // need to clear the overflow
    document.body.style.overflow = '';
  }

  private saveSubjects(dialogTemplate?: TemplateRef<any>): void{
    this.dialog.content._component.loading = true;
    // TODO: add save
    // this.loading = true;
    this.modalService.save()
      .then((res) => {
        // this.dialog.close();
        this.addToast('Save completed successfully', 'success');
        this.modalService.getSubjects();
        this.dialog.content._component.loading = false;
        // force the subscriber to refresh the list
        this.close();

        this.refreshSubjects.next(true);
      })
      .catch((err) => {
        // TODO:: need to show error
        console.log(err);
        this.dialog.content._component.loading = false;
        this.currentFormService.currentErrorMessage.next(err);

        if (err && typeof err === 'object' && (!err.status ||
          err.status === HttpErrorCodes.SERVER_ERROR || err.status == HttpErrorCodes.BAD_REQUEST)) {

          if (err.non_field_errors) {
            // take the first error message
            const error_message = err.non_field_errors[0];
            this.currentFormService.currentNonFieldErrorMessage.next(error_message);
          } else if (err.error) {
            const errors = err.error;
            if (typeof errors == 'string' || errors.detail) {
              // striping out inner html from error message
              const div = document.createElement('div');
              div.innerHTML = errors.detail || errors;
              const text = div.textContent || div.innerText || '';
              this.addToast(text, 'error');
            } else {
              if (err.error.error) {
                this.addToast(err.error.error, 'error');
              } else {
                const keys = Object.keys(errors);
                if (keys.length > 0) {
                  const key = keys[0];
                  // err object comes back in the form of { <field>: [<message>]}
                  const message = key + ': ' + errors[key][0];
                  this.addToast(message, 'error');
                }
              }
            }

          } else {
            // take the first error key
            const keys = Object.keys(err);
            if (keys.length > 0) {
              const key = keys[0];
              // err object comes back in the form of { <field>: [<message>]}
              const message = key + ': ' + err[key][0];
              this.addToast(message, 'error');
            }
          }
        } else {
          this.addToast('Could not add the selected user, please try again later', 'error');
        }

        throw err;
      });
  }

  processArray(array, fn): Promise<any> {
    const that = this;

    return array.reduce(function (p, item) {
      return p.then(function () {
        return fn.apply(that, item);
      });
    }, Promise.resolve());
  }

  ngOnInit() {
  }

}
