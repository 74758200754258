import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResetPassword} from '../core/models/reset-password.model';
import {VerifyPassword} from '../core/models/verify-password.model';
import {UserService} from '../core/services/user.service';
import {TokenService} from '../core/services/token.service';
import {DOCUMENT, Location} from '@angular/common';
import {ShowNavService} from '../core/services/show-nav.service';
import {HttpErrorCodes} from '../core/shared/enum';
import {ToastData, ToastOptions, ToastyService} from 'ng2-toasty';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetPassword: ResetPassword;
  loading = false;
  uidb36: string;
  token: string;
  callback_url: string;
  message: string;
  error = '';
  success = '';
  password: VerifyPassword;
  changeSuccessful = false;
  title = 'Password Reset';
  btn_text = 'Reset Password';
  toastyId: number;
  DEFAULT_TITLE = 'Error';

  constructor(private location: Location,
              // private showNavService: ShowNavService,
              private userService: UserService,
              private tokenService: TokenService,
              private router: Router,
              private route: ActivatedRoute,
              private showNavService: ShowNavService,
              private toastyService: ToastyService,
              @Inject(DOCUMENT) private document: Document) {
  }

  cancel(): void {
    this.location.back()
  }


  goBack(): void {
    this.tokenService.remove();
    this.router.navigate(['/login']);
  }

  reset(): void {
    if (this.resetPassword.email) {
      this.loading = true;
      this.error = '';
      this.success = '';
      this.userService.resetPassword(this.resetPassword.email, this.callback_url)
        .then((res) => {
          // need to do something on successful login
          console.log(res);
          this.success = 'Successfully sent password reset, please check your email ';
          this.loading = false;
        })
        .catch((error) => {
          let errors: any;
          try {
            errors = error;
          } catch (e) {
            console.log(e);
          }
          if (errors && typeof errors === 'object') {
            const keys = Object.keys(errors);
            if (errors.hasOwnProperty('status') && errors.status === 400) {
              this.error = errors.error.username;
            } else if (errors.hasOwnProperty('status') && errors.status === 500) {
              this.error = 'An error occurred when resetting your password, please try again..';
            } else {
              // there should be only one key - if there's more... then something went wrong
              for (const key of keys) {
                this.error = errors[key];
              }
            }
          } else {
            this.error = 'An error occurred when resetting your password, please try again..';

          }

          this.loading = false;

          throw error;
        });
    }
  }

  changePassword(): void {
    this.message = '';
    this.changeSuccessful = false;
    this.userService.setPassword(this.password.newPassword, this.password.verifyPassword).then(
      (successful) => {
        this.changeSuccessful = successful;
        if (successful) {
          this.message = 'Successfully changed password, redirecting to login...';
          setTimeout(() => {
            this.tokenService.remove();
            this.router.navigate(['/login']);
          }, 2000);
        }
      }).catch(
      (err) => {
        // there was an error loading the link, redirect back to login
        // this.router.navigate(['/login']);
        this.changeSuccessful = false;
        // this.message = 'Could not update your password, please try again...';
        if (err && typeof err === 'object' && (!err.status ||
          err.status === HttpErrorCodes.SERVER_ERROR || err.status == HttpErrorCodes.BAD_REQUEST)) {

          if (err.error && err.error.non_field_errors) {
            // take the first error message
            this.message = err.error.non_field_errors[0];
          } else if (err.error) {
            const errors = err.error;
            if (typeof errors == 'string') {
              // striping out inner html from error message
              const div = document.createElement('div');
              div.innerHTML = errors;
              const text = div.textContent || div.innerText || '';
              this.addToast(text, 'error');
            } else {
              const keys = Object.keys(errors);
              if (keys.length > 0) {
                const key = keys[0];
                // err object comes back in the form of { <field>: [<message>]}
                const message = key + ': ' + errors[key][0];
                this.addToast(message, 'error');
              }
            }

          } else {
            // take the first error key
            const keys = Object.keys(err);
            if (keys.length > 0) {
              const key = keys[0];
              // err object comes back in the form of { <field>: [<message>]}
              const message = key + ': ' + err[key][0];
              this.addToast(message, 'error');
            }
          }
        } else {
          this.addToast('Could not add the selected user, please try again later', 'error');
        }

        throw err;
      });
  }

  checkToken(): void {
    this.tokenService.obtain(this.uidb36, this.token).then(
      (access_token) => {
        if (access_token) {
          // this.message = 'su';
        }
      }).catch(
      (error) => {
        // there was an error loading the link, redirect back to login
        this.router.navigate(['/login']);
      });
  }

  getToastOptions(message: string, title: string, theme?: string, timeout?: number): ToastOptions {
    title = title != null ? title : this.DEFAULT_TITLE;
    timeout = timeout != null ? timeout : 2000;
    theme = theme != null ? theme : 'default';

    return {
      title: title,
      msg: message,
      showClose: true,
      timeout: timeout,
      theme: theme,
      onAdd: (toast: ToastData) => {
        console.log('Toast ' + toast.id + ' has been added!');
        this.toastyId = toast.id;
      }
    };
  }

  addToast(message: string, title: string, theme?: string, timeout?: number) {
    // Or create the instance of ToastOptions
    const toastOptions = this.getToastOptions(message, title, theme, timeout);
    // // Add see all possible types in one shot
    // this.toastyService.info(toastOptions);
    // this.toastyService.success(toastOptions);
    // this.toastyService.wait(toastOptions);
    // this.toastyService.error(toastOptions);
    // this.toastyService.warning(toastOptions);

    switch (title.toLowerCase()) {
      case 'error':
        this.toastyService.error(toastOptions);
        break;
      case 'success':
        this.toastyService.success(toastOptions);
        break;
      case 'saving':
        // toastOptions
        this.toastyService.wait(toastOptions);
        break;
    }
  }

  ngOnInit() {
    this.resetPassword = new ResetPassword;
    this.password = new VerifyPassword();
    // this.showNavService.set(false);
    // let test_route = this.location.path(true);
    this.callback_url = this.document.location.origin + this.router.createUrlTree(['/resetpassword']).toString();
    // this.route.paramMap
    //   .switchMap((params: ParamMap) => )
    this.uidb36 = this.route.snapshot.paramMap.get('uidb36');
    this.token = this.route.snapshot.paramMap.get('token');

    if (this.router.url && this.router.url.indexOf('invite') > -1) {
      this.title = 'Enter password';
      this.btn_text = 'Sign up';
    }
    if (this.uidb36 && this.token) {
      this.checkToken();
    }
    this.showNavService.set(false);
  }

  ngOnDestroy() {
    this.showNavService.set(true);
  }

}
