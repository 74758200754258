import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'sortByDate'
})
export class SortByDatePipe implements PipeTransform {

  transform(value: any[], order: string = 'asc'): any[] {
    if (!value || !(order === 'asc' || order === 'desc')) { return value; }
    if (value.length <= 1) { return value; }

    value.sort((a, b) => {
      if (moment(a.created).isSameOrBefore(b.created)) {
        return -1;
      } else {
        return 1;
      }
    })

    if (order === 'desc') {
      value.reverse();
    }

    return value;
  }

}
