import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IFilterable} from '../interfaces/ifilterable';

@Injectable()
export class FilterViewService {

  dataSheetSearchSubjects: BehaviorSubject<IFilterable[]> = new BehaviorSubject([]);
  dataSheetSearchFilterSubject: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor() {
  }

}
