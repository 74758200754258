import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';
import {BaseService} from '../base.service';
import {Skill} from '../models/skill.model';

@Injectable()
export class SkillService extends BaseService<Skill> {

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'web.skill/', Skill);
    this.subRoute = '';
    this.date_obj_keys = [];
  }

}
