import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getObjectFromArray'
})
export class GetObjectFromArrayPipe implements PipeTransform {

  transform(args?: any, value?: number): any {
    if (args == null || args.constructor.name.toLowerCase() !== 'array' ||
      value == null || typeof value != 'number') {
      return;
    }
    // value should be a number
    return args[value];
  }

}
