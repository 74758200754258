import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import {Subscription} from "rxjs/index"
import {BehaviorSubject} from 'rxjs';
import { DailySessionViewService } from '../services/daily-session-view.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit, OnDestroy, OnChanges {
  private _items = new BehaviorSubject<any[]>([]);
  @Output() change = new EventEmitter();
  @Output('clear') clearFilter = new EventEmitter();
  filtered: any[];
  data_items: any[];
  selected_filter: string;
  subscriptions: Subscription[];

  constructor(private dailySessionViewService: DailySessionViewService) {
  }

  @Input()
  set items(value: any[]) {
    this._items.next(value);
  }

  get data() {
    return this._items;
  }

  handleSearchFilter($event: string) {
    this.filtered = this.data_items.filter((s) => s.text.toLowerCase().indexOf($event.toLowerCase()) !== -1);
  }

  filterSessionsByProcedure($event: string) {
    this.change.emit($event);
  }

  clearSearchFilters($event: any): void {
    this.selected_filter = null;
    this.clearFilter.emit(true);
  }

  subscribeToResetFilterValue(): void {
    this.subscriptions.push(this.dailySessionViewService.resetFilterValue.subscribe(res => {
      if (res) {
        this.selected_filter = null;
      }
    }));
  }

  unsubscribe(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  ngOnInit() {
    this.subscriptions = [];
    this.selected_filter = '';
    this.subscriptions.push(this.data.subscribe((items) => {
      this.filtered = items;
      this.data_items = items;
    }));
    this.subscribeToResetFilterValue();
  }

  ngOnChanges(changes: SimpleChanges) {
    const objs: SimpleChange = changes.items;
    this._items.next(objs.currentValue);
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

}
