import {Injectable} from '@angular/core';
import {IBaseViewService} from '../interfaces/base-view.interface';
import {Assessment} from '../../core/models/assessment.model';
import {BehaviorSubject, throwError} from 'rxjs';
import {PatientService} from '../../core/services/patient.service';
import {AssessmentService} from '../../core/services/assessment.service';
import {DocumentService} from '../../core/services/document.service';
import {Document} from '../../core/models/document.model';
import {DocumentViewService} from './document-view.service';

@Injectable()
export class AssessmentViewService implements IBaseViewService<Assessment> {

  currentSubject: BehaviorSubject<Assessment> = new BehaviorSubject(new Assessment());
  currentSubjects: BehaviorSubject<Assessment[]> = new BehaviorSubject([]);
  currentSubjectType: BehaviorSubject<string> = new BehaviorSubject('');
  currentSubjectsFiltered: BehaviorSubject<Assessment[]> = new BehaviorSubject([]);

  document_type: string = 'assessment';
  keys_to_search: string[] = ['name'];

  constructor(private patientService: PatientService,
              private assessmentService: AssessmentService,
              private documentService: DocumentService,
              private documentViewService: DocumentViewService) {
  }

  saveDocument(doc: Document): Promise<Document> {
    this.documentViewService.currentSubject.next(doc);
    return this.documentViewService.save()
      .then(res => {
        return res;
      }).catch(err => throwError(err).toPromise());
  }

  processArray(array, fn): Promise<any> {
    const results = [];
    const that = this;
    return array.reduce(function (p, item) {
      return p.then(function () {
        return fn.call(that, item);
      });
    }, Promise.resolve());
  }

  save(): Promise<Assessment> {
    let assessment = this.currentSubject.getValue();

    return this.assessmentService.save(assessment).then((res: Assessment) => {
      this.currentSubject.next(res);
      this.documentViewService.currentSubjectParent.next(res);
      this.documentViewService.currentSubjectType.next(this.document_type);
      return this.processArray(assessment.documents, this.saveDocument);
    })
      .then((updated: Document) => {
        this.documentViewService.currentSubjectParent.next(null);
        this.documentViewService.currentSubjectType.next(null);
        return assessment;
      })
      .catch((err) => throwError(err).toPromise());
  }

  update(): Promise<any> {
    return undefined;
  }

  getByPatient(id: string): Promise<Assessment[]> {
    return undefined;
  }

  getSubjects(): void {
    const patient = this.patientService.currentPatient.getValue();
    this.assessmentService.getByParamPromise(patient.id.toString(), 'patient')
      .then((assessments: Assessment[]) => {
        this.currentSubjects.next(assessments);
      })
      .catch((err) => {
        // TODO: need to show toasty with error
        throw err;
      });
  }

  search(status?: string, search_text?: string): Promise<Assessment[]> {
    let promise: Promise<Assessment[]>;
    let params: any;
    if (status) {
      params = {
        status: status
      }
    }
    // if (search_text) {
    //   if (!params) {
    //     params = {};
    //   }
    // }
    const patient = this.patientService.currentPatient.getValue();
    if (!params) {
      promise = this.assessmentService.getByParamPromise(patient.id.toString(), 'patient');
    } else {
      params['patient'] = patient.id.toString();
      promise = this.assessmentService.getByParamObjPromise(params);
    }
    return promise
      .then((filtered: Assessment[]) => {
        // check through all keys, not sure if there is an aggregate yet
        let result = [];
        if (search_text) {
          for (const item of filtered) {
            // const keys = Object.keys(item);
            for (const key of this.keys_to_search) {
              if (typeof item[key] === 'string' &&
                item[key].toLocaleLowerCase().indexOf(search_text.toLocaleLowerCase()) > -1) {
                result.push(Object.assign({}, item));
                break;
              }
            }
          }
        } else {
          result = filtered;
        }
        this.currentSubjectsFiltered.next(result);
        return result;
      })
      .catch((err) => throwError(err).toPromise());
  }

  getById(id: number): Promise<Assessment> {
    return undefined;
  }

}
