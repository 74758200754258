import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {

  constructor() { }

  // TODO: move to a different service, maybe one for primitives
  TryParseInt(str: string, defaultValue: number) {
    let retValue = defaultValue;
    if (str !== null) {
      if (str.length > 0) {
        if (str != null) {
          retValue = parseInt(str, 10);
        }
      }
    }
    return retValue;
  }

  getAllIds(arr: any[], val: string[], prop?: string): number[] {
    if (!prop) {
      prop = 'id';
    }
    const ids = [];
    let i;
    for(i = 0; i < arr.length; i++) {
      if (val.findIndex(v => v == arr[i][prop]) > -1) {
        ids.push(arr[i].id);
      }
    }
    return ids;
  }

  getAllIndexes(arr: any[], val: any, prop?: string): number[] {
    if (!prop) {
      prop = 'id';
    }
    const indexes = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][prop] === val) {
        indexes.push(i);
      }
    }
    return indexes;
  }

  b64toBlob(b64Data: string, contentType: string, sliceSize?: number): Blob {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
  }

  objectToMap( obj): Map<any, any> {
    const mp = new Map<any, any>();
    Object.keys ( obj ). forEach (k => { mp.set(k, obj[k]) });
    return mp;
  }

  sortByProperty(arr: any[], property: string): any[] {
    let result: any[] = [], left, right, i, j;
    if (!property) return arr;

    if (arr.length === 0 || arr.length === 1)
      return arr;

    left = this.sortByProperty(arr.slice(0, arr.length / 2), property);
    right = this.sortByProperty(arr.slice(arr.length / 2, arr.length), property);

    for (i = 0, j = 0; i < left.length && j < right.length;) {
      let comparison: number;
      if (typeof left[i][property] == 'string') {
        comparison = left[i][property].localeCompare(right[j][property]);
      } else {
        comparison = left[i][property] - right[j][property];
      }
      if (comparison < 0) {
        result.push(left[i]);
        i++;
      } else if (comparison > 0) {
        result.push(right[j]);
        j++;
      } else {
        result.push(left[i]);
        result.push(right[j]);
        i++;
        j++;
      }
    }

    if (i < left.length) {
      result = [...result, ...left.slice(i)];
    }

    if (j < right.length) {
      result = [...result, ...right.slice(j)];
    }

    return result;

  };
}
