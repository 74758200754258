import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkNext'
})
export class CheckNextPipe implements PipeTransform {

  transform(idx: number, args?: any, prop?: string): any {
    if (idx == null || !args || args.length == 0 || !prop || idx == args.length - 1) {
      return false;
    }

    return args[idx][prop] !== args[idx + 1][prop];
  }

}
