import { Injectable } from '@angular/core';
import {BaseService} from '../base.service';
import {PermissionModel} from '../models/permission.model';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService extends BaseService<PermissionModel> {

  constructor(private http: HttpClient,
              authenticationService: AuthenticationService) {
    super(authenticationService, http, 'user.permissions/', PermissionModel);
    this.sub_key = 'permissions';
  }
}
